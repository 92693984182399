<template>
  <a-modal title="提示" :visible="acceptInfo.boolean" @ok="handleOk" @cancel="handleCancel">
    <p>{{acceptInfo.msg}}</p>
  </a-modal>
</template>

<script>
export default {
  props: {
    acceptInfo: {
      type: Object,
      required: true,
    },
  },
  methods:{
    handleOk(){
        this.$emit('delEmitEvent',{
            type:'ok'
        });
    },
    handleCancel(){
        this.$emit('delEmitEvent',{
            type:'cancel'
        });
    },
  }
};
</script>

<style></style>
