<template>
  <div>
    <a-input placeholder="请选择客户" v-model="chosenShowName" :disabled="!editable">
      <a-button slot="suffix" type="primary" @click="startChoose">选择</a-button>
    </a-input>
    <a-modal v-if="choosing" :visible="true" title="请选择客户" :footer="null" @cancel="onCancel" width="1200">
      <div class="table-header">
        <div class="header-item">
          <a-form layout="inline">
            <a-form-item label="所属机构">
              <DepartmentChoose :department-code.sync="departmentCode" @change="onSearch" :disabled="!searchable"></DepartmentChoose>
            </a-form-item>
            <a-form-item label="客户编号">
              <a-input v-model="custnum" :disabled="!searchable"></a-input>
            </a-form-item>
            <a-form-item label="客户名称">
              <a-input v-model="custname" :disabled="!searchable"></a-input>
            </a-form-item>
          </a-form>
        </div>
        <div class="header-item">
          <a-button type="primary" @click="onSearch">查询</a-button>
          <a-button @click="onReset">重置</a-button>
        </div>
      </div>
      <div class="table-main">
        <a-table :columns="columns" :data-source="data" :pagination="false" rowKey="custnum" size="small">
          <span slot="action" slot-scope="text, record">
            <a-button @click="onChoose(record)" type="primary">选择</a-button>
          </span>
        </a-table>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-pagination v-model="pageNo" :total="total" :page-size="pageSize" @change="onPagesizeChange">
        </a-pagination>
      </div>
    </a-modal>
  </div>
</template>

<script>
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

export default {
  name: 'CustChooseDialog',
  components: {DepartmentChoose},
  model: {
    prop: 'chosenShowName',
    event: 'change'
  },
  props: {
    scope: {
      required: false,
      // type: Object,
    },
    editable: {
      require: false,
      type: Boolean,
      default: false,
    },
    chosenShowName: {
      required: false,
      type: String,
    },
    departmentCode: { // 机构
      required: false,
      type: String,
    },
    custtypecd: { // 	客户类型 1:个人 2:对公
      required: true,
      type: String,
    },
    custnum: { // 客户号
      required: false,
      type: String,
    },
    custname: { // 客户名称
      required: false,
      type: String
    },
    searchable: { // 是否可以自定义搜索
      required: false,
      type: Boolean,
      default: true,
    }
  },
  data() {
    const columns = [
      {
        title: '客户类型',
        dataIndex: 'custtypecd',
        align: "center",
        width: 80,
        customRender: (text)=>{
          return {"1":"个人","2":"企业"}[text]??""
        }
      },
      {
        title: '客户名称',
        dataIndex: 'custname',
        key: "custname",
        align: 'center',
      },
      {
        title: '性别',
        dataIndex: 'gendercd',
        align: 'center',
        size: 60,
        customRender:(text)=>{return {'1':'男','2':'女'}[text]??''}
      },
      {
        title: '证件号',
        dataIndex: 'certnum',
        align: 'center',
        size: 100,
        customRender: (text) => {
          if (!text) return ""
          let starizedText = text.replace(/^(.{6}).+(.{4}$)/, "$1****$2")
          return <a-tooltip>
            <template slot="title"> {{ text }} </template>
            {starizedText} </a-tooltip>
        }
      },
      {
        title: '客户编号',
        dataIndex: 'custnum',
        key: 'custnum',
        align: 'center',
      },
      {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        align: 'center',
      },
    ]
    const data = []
    return {
      choosing: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      columns,
      data,
    }
  },
  created() {
  },
  methods: {
    startChoose() {
      this.choosing = true
      // 加载机构列表
      // this.getDeptTree()
      // 加载客户列表
      this.loadTableData()
    },
    // 获取列表信息page
    async loadTableData() {
      let queryParam = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        obj: {
          departmentCode: this.departmentCode,
          custnum: this.custnum,
          custname: this.custname,
        }
      }
      let res
      if (this.custtypecd === '2') {  // 根据类型调用个人/企业接口
        res = await this.api.custEnterprise(queryParam)
      } else {
        res = await this.api.custPage(queryParam)
      }
      if (res.code == 200) {
        this.data = res.data.records
        this.pageNo = res.data.current
        this.pageSize = res.data.size
        this.total = res.data.total
      } else {
        return this.$message.error(res.info)
      }
    },
    // 点击选择
    onChoose(record) {
      this.choosing = false
      this.$emit('change', record.custname)
      this.$emit('chooseDone', record, this.scope)
    },
    // 分页事件
    onPagesizeChange(page) {
      this.pageNo = page
      this.loadTableData()
    },
    onSearch(){
      this.pageNo = 1
      this.loadTableData()
    },
    onReset() {
      this.departmentCode = ''
      this.custnum = ''
      this.custname = ''
      this.pageNo = 1
      this.loadTableData()
    },
    //取消事件
    onCancel() {
      this.choosing = false
      this.$emit('chooseCancel')
    },
  },
}
</script>

<style lang="less" scoped>
.table-hide {
  display: none;
}

.table-show {
  display: revert;
}

/deep/ .ant-modal {
  width: 1200px;

  .ant-modal-content {
    width: 100%;
  }
}

/deep/ .table-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  .ant-select-selection--single {
    width: 200px;
  }
}
.ant-btn {
  min-width: 70px;
  margin: 0px;
  right: -12px;
}
.paginationBox {
  margin: 10px 0;
  display: flex;
  justify-content: end;
}

/deep/ .ant-input-suffix {
  width: 0;
}
</style>
