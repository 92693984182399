import PaymentProtocolManage from '@/view/Orders/PaymentProtocolManage/index.vue'
import Orderentry from '@/view/Orders/Orderentry/index.vue'
import OrderDraft from '@/view/Orders/OrderDraft/index.vue'
import Originalordermanagement from '@/view/Orders/Originalordermanagement/index.vue'
import ViewOriginalOrder from '@/view/Orders/Originalordermanagement/view/ViewOriginalOrder.vue'
import OriginalOrderRevisionHistory from '@/view/Orders/Originalordermanagement/view/OriginalOrderRevisionHistory.vue'
import Summaryorder from '@/view/Orders/Summaryorder/index.vue'
import Summaryorderview from '@/view/Orders/Summaryorder/view/Summaryorderview.vue'
import Summarizeorderrevisionrecords from '@/view/Orders/Summaryorder/view/Summarizeorderrevisionrecords.vue'
import Liquidationandrepayment from '@/view/Orders/Summaryorder/view/Liquidationandrepayment.vue'
import AddPayment from '@/view/Orders/PaymentProtocolManage/AddPayment/index.vue'
import PaymentOrders from '@/view/Orders/PaymentOrders/index.vue'
import AddPaymentOrders from '@/view/Orders/PaymentOrders/AddPaymentOrders/index.vue'
import payWithhold from '@/view/Orders/payWithhold/index.vue'
import payWithholdAdd from '@/view/Orders/payWithhold/add.vue'

export default [
    { path: '/orders/PaymentProtocolManage', name: "PaymentProtocolManage", component: PaymentProtocolManage, meta: '支付协议管理' },
    { path: '/orders/Orderentry', name: 'Orderentry', component: Orderentry, meta: '订单导入' },
    { path: '/orders/OrderDraft', name: 'OrderDraft', component: OrderDraft, meta: '订单录入' },
    { path: '/orders/Originalordermanagement', name: 'Originalordermanagement', component: Originalordermanagement, meta: '原始订单管理' },
    { path: '/orders/ViewOriginalOrder', name: 'ViewOriginalOrder', component: ViewOriginalOrder, meta: '原始订单查看' },
    { path: '/orders/OriginalOrderRevisionHistory', name: 'OriginalOrderRevisionHistory', component: OriginalOrderRevisionHistory, meta: '原始订单修订历史' },
    { path: '/orders/Summaryorder', name: 'Summaryorder', component: Summaryorder, meta: '汇总订单' },
    { path: '/orders/Summaryorderview', name: 'Summaryorderview', component: Summaryorderview, meta: '汇总订单查看' },
    { path: '/orders/Summarizeorderrevisionrecords', name: 'Summarizeorderrevisionrecords', component: Summarizeorderrevisionrecords, meta: '汇总订单修订记录' },
    { path: '/orders/Liquidationandrepayment', name: 'Liquidationandrepayment', component: Liquidationandrepayment, meta: '清算还款' },
    { path: '/orders/PaymentProtocolManage/AddPayment', name: 'AddPayment', component: AddPayment, meta: '支付协议管理-新增' },
    { path: '/orders/PaymentOrders', name: 'PaymentOrders', component: PaymentOrders, meta: '订单支付' },
    { path: '/orders/PaymentOrders/AddPaymentOrders', name: 'AddPaymentOrders', component: AddPaymentOrders, meta: '新增支付订单' },
    { path: '/orders/payWithhold', name: 'payWithhold', component: payWithhold, meta: '归集协议管理' },
    { path: '/orders/payWithholdAdd', name: 'payWithholdAdd', component: payWithholdAdd, meta: '归集协议管理新增' },
]
