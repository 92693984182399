<template>
  <!-- 个人客户新增、编辑、查看页面 -->
  <a-card :title="title" style="margin: 20px">
    <template slot="extra">
      <a-button type="primary" v-if="$route.query.type !== 'view'" @click="handleSave">保存</a-button>
      <a-button @click="handleCancelBtn">取消</a-button>
    </template>
    <!-- 主体部分 -->
    <div class="container">
      <div class="conHeader">基础信息</div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="left">
        <!-- 影像信息 -->
        <a-form-model-item ref="name" class="pricebox" label="证照照片">
          <div style="color:red">上传证件照 进行识别</div>
          <div class="row">
            <div class="item">
              <span style="margin-right: 20px; display: inline-block; width: 100px">人像面</span>
              <a-upload :disabled="isView()" accept="image/*" :beforeUpload="handleBeforeUpload" name="file"
                ref="uploadRef" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
                :action="busiURL" :headers="headers" @change="changeIdUpload">
                <uploadImg v-show="Upimgshow" @completedUpload="completedUpload" @onloadsuccsse="Upimgshow = $event"
                  @fileData="getUpFile = $event" @filePath="UpimgfilePath = $event" @Editimg="UpimgEdit"
                  @deleteimg="opreatDel('up')" @CallEditimgApi="CallEditimgApi('up')" :showEditicon="true"
                  :showMask="$route.query.type == 'view' ? false : true" ref="IdUp"></uploadImg>
                <div v-show="!Upimgshow">
                  <!-- <a-icon :type="loading ? 'loading' : 'plus'" /> -->
                  <a-icon v-if="!loading" type="plus" />
                  <a-spin v-else />
                </div>

              </a-upload>
            </div>
            <div class="item">
              <span style="margin-right: 20px; display: inline-block; width: 100px">国徽面</span>
              <a-upload :disabled="isView()" accept="image/*" name="file" ref="Backimg" list-type="picture-card"
                class="avatar-uploader" :beforeUpload="handleBeforeUpload" :show-upload-list="false" :action="busiURL"
                :headers="headers" @change="changeIdBack">
                <uploadImg v-show="Backimgshow" @completedUpload="completedUpload" @onloadsuccsse="Backimgshow = $event"
                  @fileData="getBackFile = $event" :showEditicon="true" @Editimg="BackimgEdit"
                  @filePath="BackimgefilePath = $event" @CallEditimgApi="CallEditimgApi('back')"
                  :showMask="$route.query.type == 'view' ? false : true" @deleteimg="opreatDel('back')" ref="IdBack">
                </uploadImg>
                <div v-show="!Backimgshow">
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                </div>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item ref="name" label="所属机构" prop="custPersonInfo.departmentId">
          <DepartmentChoose :department-id.sync="form.custPersonInfo.departmentId" :disabled="isView()"></DepartmentChoose>
        </a-form-model-item>
        <a-form-model-item ref="name" label="客户名称" prop="custPersonInfo.custname">
          <a-input :disabled="isView()" v-model.trim="form.custPersonInfo.custname" placeholder="请填写客户名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="证件号码" prop="custIdInfo.certnum">
          <a-input :disabled="isView()" v-model.trim="form.custIdInfo.certnum" placeholder=" 请输入证件号码"></a-input>
        </a-form-model-item>
        <a-form-model-item ref="name" label="出生日期" prop="custPersonInfo.birthdt">
          <a-date-picker disabled valueFormat="YYYY-MM-DD" v-model.trim="form.custPersonInfo.birthdt" />
        </a-form-model-item>
        <a-form-model-item ref="name" label="性别" prop="custPersonInfo.gendercd">
          <a-radio-group disabled="disabled" v-model.trim="form.custPersonInfo.gendercd" :options="busiDict.GndFlg">
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item ref="name" label="民族" prop="custPersonInfo.ethniccd">
          <a-select :disabled="isView()" v-model.trim="form.custPersonInfo.ethniccd" :options="busiDict.RaceCd"
            placeholder="请选择民族">
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="name" label="证件生效日期" prop="custIdInfo.certeffectdt">
          <a-date-picker :disabled="isView()" valueFormat="YYYY-MM-DD" v-model.trim="form.custIdInfo.certeffectdt"
            @change="changeCertData($event, 'certeffectdt')" />
        </a-form-model-item>
        <a-form-model-item ref="name" label="证件失效日期" prop="custIdInfo.certinvaliddt">
          <a-date-picker ref="datePicker" :disabled="isView() || isForbidden()" valueFormat="YYYY-MM-DD"
            v-model.trim="form.custIdInfo.certinvaliddt" @change="changeCertData($event, 'certinvaliddt')" />
          <span class="check-box" style="margin-left: 50px" v-if="!isView()">
            <a-checkbox v-model="checkBoxValue" @change="changeData">
              长期
            </a-checkbox>
          </span>
        </a-form-model-item>
        <a-form-model-item ref="name" label="签发机关名称" prop="custIdInfo.certissueorgname">
          <a-input :disabled="isView()" v-model.trim="form.custIdInfo.certissueorgname" placeholder="请填写签发机关名称"></a-input>
        </a-form-model-item>

        <!-- 联系信息 -->
        <a-form-model-item ref="name" label="移动电话" prop="custContactInfos">
          <!--          <table class="tableBody">-->
          <!--            <thead>-->
          <!--              <tr>-->
          <!--                <th>联系类型</th>-->
          <!--                <th>联系信息</th>-->
          <!--                <th>主要标志</th>-->
          <!--                <th>操作</th>-->
          <!--              </tr>-->
          <!--            </thead>-->
          <!--            <tbody>-->
          <span v-for="(item, ind) in form.custContactInfos" :key="ind">
            <!--                <td>-->
            <!--                  <el-select :disabled="true" v-model.trim="item.conttypecd" placeholder="请选择联系类型"-->
            <!--                    :options="busiDict.CtcTp">-->
            <!--                    <el-option v-for="item in busiDict.CtcTp" :key="item.value" :label="item.label"-->
            <!--                      :value="item.value"></el-option>-->
            <!--                  </el-select>-->
            <!--                </td>-->
            <!--                <td>-->
            <a-input :disabled="isView()" :type="'number'" v-model.trim="item.continfo" placeholder="请填写手机号"
              @blur="changeNumber(ind)" class="info-input"></a-input>
<!--          <span class="check-box" style="margin-left: 50px" v-if="!isView()">-->
<!--            <a-checkbox v-model="rememberPhone"> 记住 </a-checkbox>-->
<!--          </span>-->
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <el-select :disabled="isView()" v-model.trim="item.majorind" placeholder="主要标志">-->
            <!--                    <el-option v-for="item in busiDict.MajorInd" :key="item.value" :label="item.label"-->
            <!--                      :value="item.value"></el-option>-->
            <!--                  </el-select>-->
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <button v-if="addBtnShow(ind, 'custContactInfos')" :disabled="isView()" @click="handleAdd">-->
            <!--                    +-->
            <!--                  </button>-->
            <!--                  <button :disabled="isView()" v-if="deleteBtnShow()" @click="handleDel(ind)">-->
            <!--                    - -->
            <!--                  </button>-->
            <!--                </td>-->
          </span>
          <!--            </tbody>-->
          <!--          </table>-->
          <div class="cust-contact-remind-area" v-if="contactRemindText">
            <span class="remind-text">{{ contactRemindText }}</span>
          </div>
        </a-form-model-item>
        <!-- 地址信息 -->
        <a-form-model-item ref="name" label="证件地址" prop="custAddressInfos">
          <!--          <table class="tableBody">-->
          <!--            <thead>-->
          <!--              <tr>-->
          <!--                <th>地址类型</th>-->
          <!--                <th>国家地区</th>-->
          <!--                <th>行政区划</th>-->
          <!--                <th>详细地址</th>-->
          <!--                <th>主要标志</th>-->
          <!--                <th>操作</th>-->
          <!--              </tr>-->
          <!--            </thead>-->
          <!--            <tbody>-->
          <span v-for="(item, ind) in form.custAddressInfos" :key="ind">
            <!--                <td>-->
            <!--                  <el-select :disabled="isView()" v-model.trim="item.addrtypecd" placeholder="请选择地址类型">-->
            <!--                    <el-option v-for="item in busiDict.AdTyp" :key="item.value" :label="item.label"-->
            <!--                      :value="item.value"></el-option>-->
            <!--                  </el-select>-->
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <el-select :disabled="isView()" v-model.trim="item.ctyzonecd" placeholder="请选择国家地区">-->
            <!--                    <el-option v-for="item in busiDict.NtntyCd" :key="item.value" :label="item.label" :value="item.value"-->
            <!--                      :disabled="item.value != '156'"></el-option>-->
            <!--                  </el-select>-->
            <!--                </td>-->
            <!--                <td>-->
            <el-cascader style="width:200px;display: inline-block;" :disabled="isView()" v-model.trim="item.distcd"
              :options="busiDict.DstcCd" placeholder="请选择行政区划" :show-all-levels="false" :props="{ emitPath: false }"/>
            <!--                </td>-->
            <!--                <td>-->
            <el-input style="width: 300px;display: inline-block; " :disabled="isView()" v-model.trim="item.addrinfo"
              placeholder="请填写详细地址"></el-input>
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <el-select :disabled="isView()" v-model.trim="item.majorind" placeholder="主要标志">-->
            <!--                    <el-option v-for="item in busiDict.MajorInd" :key="item.value" :label="item.label"-->
            <!--                      :value="item.value"></el-option>-->
            <!--                  </el-select>-->
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <button :disabled="isView()" v-if="addBtnShow(ind, 'custAddressInfos')" @click="handleAddress">-->
            <!--                    +-->
            <!--                  </button>-->
            <!--                  <button v-if="delAddressBtn()" :disabled="isView()" @click="delAdress(ind)">-->
            <!--                    - -->
            <!--                  </button>-->
            <!--                </td>-->
          </span>
          <!--            </tbody>-->
          <!--          </table>-->
          <div class="cust-address-remind-area" v-if="addressRemindText">
            <span class="remind-text">{{ addressRemindText }}</span>
          </div>
        </a-form-model-item>

        <a-form-model-item v-if="$route.query.type == 'view'" ref="name" label="用户信息" prop="name">
          <table class="UserTableBody">
            <thead>
              <tr>
                <th>用户名</th>
                <th>真实姓名</th>
                <th>手机号</th>
                <th>所属机构</th>
                <th>用户状态</th>
                <th>用户类型</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in custUserInfos" :key="index">
                <td>
                  <span>{{ item.username }}</span>
                </td>
                <td>
                  <span>{{ item.name }}</span>
                </td>
                <td>
                  <span>{{ item.mobile }}</span>
                </td>
                <td>
                  <span>{{
                    findItemById(institutiontrees, item.departmentId)
                  }}</span>
                </td>
                <td>
                  <span>{{ item.status == false ? "锁定" : "未锁定" }}</span>
                </td>
                <td>
                  <span>{{
                    (item.custtypecd == item.custtypecd) == 1 ? "个人" : "对公"
                  }}</span>
                </td>
                <td>
                  <a-button @click="userInfoDel(index)" type="primary">删除</a-button>
                </td>
              </tr>
            </tbody>
          </table>
        </a-form-model-item>
        <a-form-model-item ref="name" label="账户信息" v-if="$route.query.type == 'view'" prop="name">
          <table class="UserTableBody">
            <thead>
              <tr>
                <th>账号</th>
                <th>户名</th>
                <th>账户类型</th>
                <th>当前余额</th>
                <th>可提现余额</th>
                <th>账户状态</th>
                <th>账户关系</th>
                <th>主账号</th>
                <th>手机号</th>
                <!-- <th>用户类型</th> -->
                <th>渠道编号</th>
                <th>应用编号</th>
                <th>所属机构</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in custAccountInfos" :key="index">
                <!-- 账号 -->
                <td>
                  <span>{{ item.accountNo }}</span>
                </td>
                <!-- 户名 -->
                <td>
                  <span>{{ item.accountName }}</span>
                </td>
                <!-- 账户类型 -->
                <td>
                  <span>{{
                    item.accountClass == "0"
                    ? "活期"
                    : item.accountClass == "6"
                      ? "冻结"
                      : item.accountClass == "7"
                        ? "贷款"
                        : "---"
                  }}</span>
                </td>
                <!-- 当前余额 -->
                <td>
                  <span>{{ item.balance }}</span>
                </td>
                <!-- 可提现余额 -->
                <td>
                  <span>{{ item.withdrawBalance }}</span>
                </td>
                <!-- 账户状态 //封装方法-->
                <td>
                  <span>{{ handleAccountStatus(item.accountStatus) }}</span>
                </td>
                <!-- 账户关系 -->
                <td>
                  <span>{{
                    item.accountRelation == "0"
                    ? "主"
                    : item.accountRelation == "1"
                      ? "从"
                      : "---"
                  }}</span>
                </td>
                <!-- 主账号 -->
                <td>
                  <span>{{
                    item.majorAccountNo == null ? "---" : item.majorAccountNo
                  }}</span>
                </td>
                <!-- 手机号 -->
                <td>
                  <span>{{ item.mobile }}</span>
                </td>
                <!-- 用户类型 //暂时找不到字段-->
                <!-- <td>
                <span>{{ item.balance }}</span>
              </td> -->
                <!-- 渠道编号 -->
                <td>
                  <span>{{ item.channelNo }}</span>
                </td>
                <!-- 应用编号 -->
                <td>
                  <span>{{ item.appNo }}</span>
                </td>
                <!-- 所属机构 -->
                <td>
                  <span>{{
                    findItemById(institutiontrees, item.departmentId)
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </a-form-model-item>
        <a-collapse>
          <a-collapse-panel header="更多信息">
            <a-form-model-item ref="name" label="证件类型" prop="custIdInfo.certtypecd">
              <a-select disabled v-model.trim="form.custIdInfo.certtypecd" placeholder="请选择证件类型" class="certtypecd-style">
                <a-select-option v-for="(item, index) in busiDict.IdentTp" :key="'certtypecd' + index"
                  :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="国籍">
              <a-select :disabled="true" v-model.trim="form.custPersonInfo.nationcd" placeholder="请选择国家或地区"
                :options="busiDict.NtntyCd">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="婚姻状况">
              <a-select :disabled="isView()" v-model.trim="form.custPersonInfo.marriagesitucd" placeholder="请选择当前婚姻状况"
                :options="busiDict.MrtlSt">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="居住状况" prop="resdntsitucd">
              <a-select :disabled="isView()" v-model.trim="form.custPersonInfo.resdntsitucd" placeholder="请选择当前居住状况"
                :options="busiDict.RsdntDsc">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="居民性质" prop="resdntcharcd">
              <a-select :disabled="isView()" v-model.trim="form.custPersonInfo.resdntcharcd" placeholder="请选择当前居民性质"
                :options="busiDict.RsdnCstSt">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="最高学历">
              <a-select :disabled="isView()" v-model.trim="form.custPersonInfo.highestedudegreecd" placeholder="请选择最高学历"
                :options="busiDict.HestEdct">
              </a-select>

              <a-select :disabled="isView()" v-model.trim="form.custPersonInfo.highestdegreecd" placeholder="请选择最高学位"
                :options="busiDict.HestDgr" style="margin-left: 50px">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="主要经济来源">
              <a-select :disabled="isView()" v-model.trim="form.custPersonInfo.majoreconsrccd" placeholder="请选择主要经济来源"
                :options="busiDict.MajorEconSrcCD">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="收入" prop="yearin">
              <span style="margin-right: 10px">个人年收入</span>
              <a-input :disabled="isView()" suffix="￥" v-model.trim="form.custPersonInfo.yearin"
                @blur="limitNumber($event, 'yearin')">
              </a-input>
              <span style="margin: 0 10px 0 50px">家庭年收入</span>
              <a-input :disabled="isView()" @blur="limitNumber($event, 'familyin')" suffix="￥"
                v-model.trim="form.custPersonInfo.familyin"></a-input>
            </a-form-model-item>

            <a-form-model-item ref="name" :disabled="isView()" label="从业状况" prop="workstatcd">
              <a-select :disabled="isView()" v-model.trim="form.custPersonInfo.workstatcd" placeholder="请选择从业状况"
                :options="busiDict.WorkStatCD">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="职业类型" prop="careercd">
              <el-cascader v-model.trim="form.custPersonInfo.careercd" placeholder="请选择职业类型" :options="busiDict.OcpCd"
                :props="{ emitPath: false }" :show-all-levels="false" :disabled="isView()">
              </el-cascader>
            </a-form-model-item>
            <a-form-model-item ref="name" label="工作单位名称" prop="workcorpname">
              <a-input :disabled="isView()" v-model.trim="form.custPersonInfo.workcorpname"
                placeholder="请填写工作单位名称"></a-input>
            </a-form-model-item>
            <a-form-model-item ref="name" label="工作单位所属行业">
              <el-cascader :disabled="isView()" v-model.trim="form.custPersonInfo.workcorpinduscd"
                :options="busiDict.WorkCorpIndusCD" placeholder="请选择行业" :show-all-levels="false"
                :props="{ emitPath: false }">
              </el-cascader>
            </a-form-model-item>
            <a-form-model-item ref="name" label="职务类型" prop="poslevelcd">
              <a-select :disabled="isView()" v-model.trim="form.custPersonInfo.poslevelcd" placeholder="请选择职务类型"
                :options="busiDict.JobCd">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="职称" prop="titlecd">
              <a-select :disabled="isView()" v-model.trim="form.custPersonInfo.titlecd" placeholder="请选择职称"
                :options="busiDict.OcpGrdg">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="参加本单位时间" prop="prtcptcorpdt">
              <a-date-picker :disabled="isView()" valueFormat="YYYY-MM-DD" :disabled-date="disabledDate"
                v-model.trim="form.custPersonInfo.prtcptcorpdt" />
            </a-form-model-item>
            <a-form-model-item ref="name" label="是否涉农客户" prop="agrcltrelatedcustind">
              <a-radio-group :disabled="isView()" v-model.trim="form.custPersonInfo.agrcltrelatedcustind"
                :options="busiDict.AgrcltRelatedCustInd">
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item ref="name" label="境内境外" prop="memotxt">
              <a-radio-group :disabled="isView()" v-model.trim="form.custPersonInfo.oversind"
                :options="busiDict.DmstOrOvrsInd">
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item ref="name" label="客户状态" prop="custPersonInfo.custstatuscd">
              <a-select :disabled="isView() || $route.query.type == 'add'" v-model.trim="form.custPersonInfo.custstatuscd"
                placeholder="请选择客户状态">
                <a-select-option v-for="(item, index) in busiDict.CstSt" :key="'custstatuscd' + index" :value="item.value"
                  :disabled="cstStOptionDisabled(item)">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="备注">
              <a-textarea :disabled="isView()" v-model.trim="form.custPersonInfo.memotxt" />
            </a-form-model-item>
          </a-collapse-panel>
        </a-collapse>
      </a-form-model>
    </div>
    <!-- 取消和保存弹框 -->
    <div class="saveBox">
      <a-modal title="提示" :visible="isSave" :body-style="{ textAlign: 'center' }" :centered="true" @cancel="handleCancel">
        <template slot="footer">
          <a-button type="primary" @click="handleOk">保存</a-button>
          <a-button @click="handleCancel">取消</a-button>
        </template>
        <p>
          {{
            this.$route.query.type == "add"
            ? "是否保存客户信息？"
            : "是否保存该客户资料？"
          }}
        </p>
      </a-modal>
    </div>
    <div class="cancelBox">
      <a-modal title="提示" :visible="isCancel" :body-style="{ textAlign: 'center' }" :centered="true" @cancel="cancelBtn">
        <template slot="footer">
          <a-button type="primary" @click="cancleEvent">确定</a-button>
          <a-button @click="cancelBtn">取消</a-button>
        </template>
        <p>是否取消保存该客户资料？取消后该页面信息将不会被保存</p>
      </a-modal>
    </div>
    <!-- 个人信息里删除弹框 -->
    <BoxComponentVue v-if="isShowDelete" :acceptInfo="deleteInfo" @delEmitEvent="delEmitEvent"></BoxComponentVue>
  </a-card>
</template>

<script>
import urlConfig from "@/utils/urlConfig";
// import axios from "axios";

import { handleAccountStatus } from "@/utils/commonMethods";
import { BizDict, BizDictCascade } from "@/utils/bizDict/kvMap";
import { dict_rawdata_cascade } from "@/utils/bizDict/rawdata";
import uploadImg from "../../../../components/uploadImg/index";
import BoxComponentVue from "../../../components/CustComponents/BoxComponent.vue";
import dayjs from "dayjs";
import storage from "@/utils/storage";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
const token = sessionStorage.getItem("token");
export default {
  name: "addPersonCust",
  components: {
    DepartmentChoose,
    uploadImg,
    BoxComponentVue,
  },

  data() {
    let checkCertNum = (rule, value, callback) => {
      // console.log('校验身份证');
      let custNumReg =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (value === "") {
        callback(new Error("请填写身份证号码"));
      } else if (!custNumReg.test(value)) {
        callback(new Error("身份证不符合规范"));
      } else {
        let lastStr = value.substring(value.length - 1)
        if (lastStr === 'x') {
          callback(new Error('身份证最后一位字母要求大写!'))
        } else {
          callback()
          this.blurCardNum();
        }

      }
    };
    return {
      checkCertNum,
      busiURL: urlConfig.busiURL + "/base/ocr/recognizeIdcard",
      headers: {
        token,
      },
      // 绑定长期
      checkBoxValue: false,
      // 记住手机号
      // rememberPhone: storage.getBoolOrDefault(storage.IS_REMEMBER_PER_PHONE_NUM,true),
      time: "",
      form: {
        // 基本信息
        custPersonInfo: {
          // 所属机构
          departmentId: undefined,
          // 客户编号
          custnum: "",
          // 客户名称
          custname: "",
          // 性别
          gendercd: "0",
          // 国籍
          nationcd: "156",
          // 民族
          ethniccd: "01",
          // 出生日期
          birthdt: "",
          // 婚姻状况
          marriagesitucd: undefined,
          // 居住状况
          resdntsitucd: undefined,
          // 居民性质
          resdntcharcd: undefined,
          //最高学历和学位
          highestedudegreecd: undefined,
          highestdegreecd: undefined,
          // 主要经济来源
          majoreconsrccd: undefined,
          // 个人年收入
          yearin: "",
          // 家庭年收入
          familyin: "",
          // 客户状态
          custstatuscd: "200",
          // 从业状况
          workstatcd: undefined,
          // 职业类型
          careercd: "40199",
          // 工作单位名称
          workcorpname: "",
          // 工作单位所属行业
          workcorpinduscd: "",
          // 职务类型
          poslevelcd: undefined,
          // 职称
          titlecd: undefined,
          // 参加本单位时间
          prtcptcorpdt: "",
          // 是否涉农客户
          agrcltrelatedcustind: "0",
          // 境内境外
          oversind: "1",
          // 备注
          memotxt: "",
        },
        // 证件信息
        custIdInfo: {
          // 客户编号
          custnum: "",
          // 证件号码
          certnum: "",
          // 证件类型
          certtypecd: "1001",
          // 证件生效日期
          certeffectdt: "",
          // 证件失效日期
          certinvaliddt: "",
          // 签发机关名称
          certissueorgname: "",
        },
        // 影像信息
        custVedioInfos: [],
        // 联系信息
        custContactInfos: [
          {
            // 客户编号
            custnum: '',
            // 联系类型
            conttypecd: "105",
            // 联系信息
            continfo: "",// storage.getBoolOrDefault(storage.IS_REMEMBER_PER_PHONE_NUM,true)?storage.getStringOrDefault(storage.REMEMBERED_PER_PHONE_NUM,""):"",
            // 主要标志
            majorind: "1",
          },
        ],
        // 地址信息
        custAddressInfos: [
          {
            // 客户编号
            custnum: "",
            // 地址类型
            addrtypecd: "307",
            // 国家和地区
            ctyzonecd: "156",
            // 行政区划
            distcd: "",
            // 详细地址
            addrinfo: "",
            // 主要标志
            majorind: "1",
          },
        ],
      },
      // 用户信息
      custUserInfos: [],
      // 账户信息
      custAccountInfos: [],
      //页面数据
      rules: {
        // 个人基本信息
        custPersonInfo: {
          departmentId: [
            { required: true, message: "请填写客户名称", trigger: "change" },
          ],
          custname: [
            { required: true, message: "请填写客户名称", trigger: "blur" },
          ],
          gendercd: [
            { required: true, message: "请选择性别", trigger: "change" },
          ],
          ethniccd: [
            { required: true, message: "请选择民族", trigger: "change" },
          ],
          birthdt: [
            { required: true, message: "请选择出生日期", trigger: "change" },
          ],
          // custstatuscd: [
          //   { required: true, message: "请选择客户状态", trigger: "change" },
          // ],
        },
        // 证件信息
        custIdInfo: {
          certnum: [
            { required: true, validator: checkCertNum, trigger: "blur" },
          ],
          certtypecd: [
            { required: true, message: "请选择证件类型", trigger: "blur" },
          ],
          certeffectdt: [
            {
              required: true,
              message: "请选择证件生效日期",
              trigger: "change",
            },
          ],
          certinvaliddt: [
            {
              required: true,
              message: "请选择证件失效日期",
              trigger: "change",
            },
          ],
          certissueorgname: [
            {
              required: true,
              message: "请填写签发机关名称",
              trigger: "blur",
            },
          ],
        },
        // 联系信息
        custContactInfos: [{ required: true, message: '请选择至少一个选项', type: 'array' }],
        // 地址信息
        custAddressInfos: [{ required: true }],
      }, //验证
      // 树形结构
      // 平级结构
      institutiontrees: [],
      imageUrl: "",
      file: null,
      loading: false,
      Upimgshow: "",
      Backimgshow: "",
      getUpFile: "",
      Upimgedit: false, // 正
      UpimgfilePath: "",
      getBackFile: "",
      Backimgedit: false, // 反
      BackimgefilePath: "",
      labelCol: { span: 3 },
      wrapperCol: { span: 14 },
      isSave: false,
      isCancel: false,
      upId: "",
      backId: "",
      // 是否展示删除弹框
      isShowDelete: false,
      deleteInfo: {
        boolean: true,
        msg: "是否取消关联该用户信息？",
        type: "delete",
      },
      delConnectUserIndex: "",
      contactRemindText: "",//客户联系信息表格校验的提示语
      addressRemindText: "",//客户地址信息表格校验的提示语

    };
  },
  watch: {
    "form.custIdInfo.certinvaliddt": {
      handler: function (newVal) {
        if (newVal == "9999-12-31") {
          this.checkBoxValue = true;
        } else {
          this.checkBoxValue = false;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.busiDict = { ...BizDict, ...BizDictCascade };
    this.handleAccountStatus = handleAccountStatus;
    if (this.$route.query.type == "add") {
      // 生成时间戳

    } else if (this.$route.query.type == "edit") {
      this.getFormData();
    } else if (this.$route.query.type == "view") {
      this.getFormData();
    }
  },
  mounted() {
    if (this.$route.query.type != 'add') {
      sessionStorage.setItem('msgInfo', true)
    }
  },
  computed: {
    title: function () {
      if (this.$route.query.type == "add") {
        return "个人客户管理>新增";
      } else if (this.$route.query.type == "edit") {
        return "个人客户管理>编辑";
      } else if (this.$route.query.type == "view") {
        return "个人客户管理>查看";
      } else {
        return "";
      }
    },
    isView() {
      return function () {
        return this.$route.query.type == "view";
      };
    },
    addBtnShow() {
      return function (index, type) {
        if (type == "custContactInfos") {
          // 联系信息
          return index == this.form.custContactInfos.length - 1;
        } else if (type == "custAddressInfos") {
          // 地址信息
          return index == this.form.custAddressInfos.length - 1;
        }
      };
    },
    // 联系信息里的删除按钮
    deleteBtnShow() {
      return function () {
        if (this.form.custContactInfos.length == 1) {
          return false;
        } else {
          return true;
        }
      };
    },
    // 地址信息里的删除按钮
    delAddressBtn() {
      return function () {
        if (this.form.custAddressInfos.length == 1) {
          return false;
        } else {
          return true;
        }
      };
    },
    cstStOptionDisabled() {
      return function (option) {
        if (this.$route.query.type == "add") {
          if (option.value == "100") {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      };
    },
    // 是否禁用失效日期
    isForbidden() {
      return function () {
        if (this.form.custIdInfo.certinvaliddt == "9999-12-31") {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  methods: {
    // 校验手机号
    changeNumber(ind) {
      // console.log('blur', ind)
      let value = this.form.custContactInfos[ind].continfo;
      if (value != "") {
        let regs = /^1[3-9]\d{9}$/;
        if (!regs.test(value)) {
          this.form.custContactInfos[ind].continfo = "";
          return this.$message.warning("请输入有效的手机号!");
        }
      }
      this.onValidateData('cust-contact');
    },
    // 设置长期日期
    changeData() {
      if (this.checkBoxValue) {
        this.form.custIdInfo.certinvaliddt = "9999-12-31";
        this.$refs.ruleForm.validateField(
          ["custIdInfo.certinvaliddt"]
          // async (errorMsg) => {
          //   if (errorMsg) {
          //     // 校验未通过
          //     // console.log(errorMsg)
          //   } else {
          //     console.log('111');
          //   }
          //   //之后的操作
          // }
        );
      } else {
        this.form.custIdInfo.certinvaliddt = "";
      }
    },
    blurCardNum() {
      let cert_no = this.form.custIdInfo.certnum.trim();
      // 根据身份证回显性别 ， 第17位奇数为男，偶数为女
      if (parseInt(cert_no[16]) % 2 === 1) {
        this.form.custPersonInfo.gendercd = "1";
      } else {
        this.form.custPersonInfo.gendercd = "2";
      }
      // 根据证件号前6位推测签发机关 和联系地址行政区划
      let distCdInCert = cert_no.substring(0, 6);
      let distNameInCert = dict_rawdata_cascade.region_raw.find(
        (i) => i.r === distCdInCert
      );
      if (distNameInCert) {
        // 签发机关 区 + “公安局“
        this.form.custIdInfo.certissueorgname = distNameInCert.d + "公安局";
        // 回显行政区划
        this.form.custAddressInfos.forEach(
          (row) => (row.distcd = row.distcd || distCdInCert)
        );
      }
      // 根据身份证回显出生日期
      this.form.custPersonInfo.birthdt =
        cert_no.substring(6, 10) +
        "-" +
        cert_no.substring(10, 12) +
        "-" +
        cert_no.substring(12, 14);
    },
    // 限制选择时间
    disabledDate(current) {
      return current && current > dayjs().endOf("day");
    },
    //控制生效日期和失效日期
    changeCertData(event, type) {
      if (
        this.form.custIdInfo.certeffectdt != "" &&
        this.form.custIdInfo.certinvaliddt != ""
      ) {
        const start = dayjs(this.form.custIdInfo.certeffectdt);
        const end = dayjs(this.form.custIdInfo.certinvaliddt);
        const diffDay = end.diff(start, "day");
        if (diffDay < 0) {
          if (type == "certeffectdt") {
            this.form.custIdInfo.certeffectdt = "";
          } else if (type == "certinvaliddt") {
            this.form.custIdInfo.certinvaliddt = "";
          }
          return this.$message.warning("生效日期不能大于失效日期!");
        }
      }
    },
    // 校验收入输入框
    limitNumber(event, type) {
      // console.log(event, "event");
      const value = this.form.custPersonInfo.yearin;
      const pattern = /^[1-9]\d*$/;
      // console.log(value.indexOf("+"), value, "99");
      if (!pattern.test(value)) {
        if (type == "yearin") {
          this.form.custPersonInfo.yearin = "";
        } else if (type == "familyin") {
          this.form.custPersonInfo.familyin = "";
        }
        return this.$message.warning("只能输入正整数!");
      }
    },
    // 根据id找到对应机构名称
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name;
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind);
          if (foundName) {
            return foundName;
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null;
    },

    // 点击查看接口
    async getFormData() {
      let data = this.$route.query.custnum;
      let res = await this.api.getCustData(data);
      // console.log(res, 'view')
      if (res.code == 200) {
        if (res.data.custPersonInfo) {
          this.form.custPersonInfo = res.data.custPersonInfo;
        }
        if (res.data.custAddressInfos) {
          this.form.custAddressInfos = res.data.custAddressInfos;
        }
        if (res.data.custContactInfos) {
          this.form.custContactInfos = res.data.custContactInfos;
        }
        if (res.data.custIdInfo) {
          this.form.custIdInfo = res.data.custIdInfo;
        }
        // 关联信息
        if (res.data.custUserInfos) {
          this.custUserInfos = res.data.custUserInfos;
        }
        // 账户信息
        if (res.data.custAccountInfos) {
          this.custAccountInfos = res.data.custAccountInfos;
        }
        // // 影像信息
        // if (res.data.custVedioInfos) {
        //   this.form.custVedioInfos = res.data.custVedioInfos
        // }
        this.form.custVedioInfos = this.getCustVideoArr(
          res.data.custVedioInfos
        );
        this.form.custVedioInfos.forEach((item) => {
          if (item.fileType == "01") {
            this.upId = item.id;
          } else if (item.fileType == "02") {
            this.backId = item.id;
          }
        });
        // 排序
        this.form.custVedioInfos.sort((image1, image2) => {
          return Number(image1.fileType) - Number(image2.fileType);
        });
        this.form = JSON.parse(JSON.stringify(this.form));
        this.$refs.IdUp.setImageUrl(this.form.custVedioInfos[0]?.fileNo);
        this.$refs.IdBack.setImageUrl(this.form.custVedioInfos[1]?.fileNo);
        // console.log(this.form, 'form')
      }
    },
    handleSave() {
      // 打开保存弹框
      this.isSave = true;
    },
    handleCancelBtn() {
      if (this.$route.query.type == "add") {
        // 打开取消弹框
        this.isCancel = true;
      } else {
        this.$router.back(-1);
      }
    },
    // 上传之前的处理
    handleBeforeUpload(file) {
      // console.log(file, 'file');
      const isLt1M = file.size / 1024 / 1024 > 1
      if (isLt1M) {
        // 图片大小大于了3m
        return this.compressImage(file);
      }
      return true
    },
    async compressImage(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 1920; // 设置压缩后的图像宽度
            const maxHeight = 1080; // 设置压缩后的图像高度

            let newWidth = img.width;
            let newHeight = img.height;

            if (img.width > maxWidth) {
              newWidth = maxWidth;
              newHeight = (img.height * maxWidth) / img.width;
            }

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              newWidth = (img.width * maxHeight) / img.height;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // 将压缩后的图像转换为 Blob 对象
            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], file.name, {
                  type: 'image/jpeg',
                });
                resolve(compressedFile);
              },
              'image/jpeg',
              0.9
            );
          };
        };
      });
    },

    // 上传身份证正面
    changeIdUpload(info) {
      // console.log(info);
      if (this.Upimgedit) {
        this.$refs.IdUp.handleChangeUpload(info, "Edit");
      } else {
        // console.log('up');
        this.$refs.IdUp.handleChangeUpload(info, "");
      }
    },
    // 身份证反面
    changeIdBack(info) {
      if (this.Backimgedit) {
        this.$refs.IdBack.handleChangeUpload(info, "Edit");
      } else {
        this.$refs.IdBack.handleChangeUpload(info, "");
      }
    },
    // 拿到识别到的数据
    completedUpload(ocrInfo) {
      const { ocrRecognizeResult: result } = ocrInfo.data
      // console.log(result);
      if (result.validDate) {
        // 签发机关名称
        this.form.custIdInfo.certissueorgname = result.authority
        // 证件生效日期
        this.form.custIdInfo.certeffectdt = result.validDate.substr(0, 10).replaceAll(/(\d{4})\D*(\d{2})\D*(\d{2})/g, "$1-$2-$3")
        // 证件失效日期
        if (result.validDate.includes('长期')) {
          this.form.custIdInfo.certinvaliddt = "9999-12-31";
        } else {
          this.form.custIdInfo.certinvaliddt = result.validDate.substr(11, 10).replaceAll(/(\d{4})\D*(\d{2})\D*(\d{2})/g, "$1-$2-$3")
        }

      } else {
        // 客户名称
        this.form.custPersonInfo.custname = result.name
        // 证件号码
        this.form.custIdInfo.certnum = result.idcard
        // 出生日期
        this.form.custPersonInfo.birthdt = result.birth.replace('年', '-').replace('月', '-').replace('日', '')

        // 性别
        this.form.custPersonInfo.gendercd = result.sex == '女' ? '2' : '1'
        // 地址
        this.form.custAddressInfos.forEach(
          (row) => (row.addrinfo = result.address)
        );
        this.addressNormalization()
      }
    },
    // 身份正面修改
    UpimgEdit(type) {
      const inputElement =
        this.$refs.uploadRef.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
      this.Upimgedit = type;
    },
    // 身份反面修改
    BackimgEdit(type) {
      const inputElement =
        this.$refs.Backimg.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
      this.Backimgedit = type;
    },
    // 编辑图片接口
    async CallEditimgApi(type) {
      // 调用接口修改成功后改变状态
      let data = {};
      data.departmentId = this.form.companyInfo.departmentId;
      data.createTime = dayjs().format("YYYY-MM-dd");
      if (type == "up") {
        data.id = this.upId;
        (data.fileNo = this.getUpFile),
          (data.fileType = "01"),
          (data.filePath = this.UpimgfilePath);
      } else if (type == "back") {
        data.id = this.backId;
        (data.fileNo = this.getBackFile),
          (data.fileType = "02"),
          (data.filePath = this.BackimgefilePath);
      }
      const res = await this.api.editImageApi(data);
      if (res.code == 200) {
        // console.log(res, "resimg");
        if (type == "up") {
          this.Upimgedit = false;
        }
        if (type == "back") {
          this.Backimgedit = false;
        }
      }
    },
    // 编辑上传文件事件
    opreatEdit(type) {
      // console.log('edit')
    },
    // 删除上传文件
    async opreatDel(type) {
      if (this.$route.query.type == "add") {
        // 直接删除不调接口
        if (type == "up") {
          //删除人像面
          this.Upimgshow = false;
        } else if (type == "back") {
          // 删除国徽面
          this.Backimgshow = false;
        }
      } else if (this.$route.query.type == "edit") {
        // console.log('edit')
        // 调用接口
        let idArr = [];
        let id =
          type == "up"
            ? this.form.custVedioInfos[0].id
            : this.form.custVedioInfos[1].id;
        idArr.push(id);
        let res = await this.api.delImageApi(idArr);
        if (res.code == 200) {
          if (type == "up") {
            this.Upimgshow = false;
            this.getUpFile = "";
            this.upId = "";
          } else {
            this.Backimgshow = false;
            this.getBackFile = "";
            this.backId = "";
          }
          return this.$message.success(res.info);
        } else {
          return this.$message.error(res.info);
        }
      }
    },
    // 联系信息添加方法
    handleAdd() {
      let obj = {
        // 联系类型
        conttypecd: "105",
        // 联系信息
        continfo: "",
        // 主要标志
        majorind: '1',
      };
      this.form.custContactInfos.push(obj);
      this.onValidateData('cust-contact');
    },
    // 联系信息删除方法
    handleDel(index) {
      this.form.custContactInfos.splice(index, 1);
      this.onValidateData('cust-contact');
    },
    // 地址信息添加方法
    handleAddress() {
      let obj = {
        // 地址类型
        addrtypecd: "305",
        // 国家和地区
        ctyzonecd: "156",
        // 行政区划
        distcd: '',
        // 详细地址
        addrinfo: "",
        // 主要标志
        majorind: '1',
      };
      this.form.custAddressInfos.push(obj);
      this.onValidateData('cust-address');
    },
    // 地址信息删除
    delAdress(ind) {
      this.form.custAddressInfos.splice(ind, 1);
      this.onValidateData('cust-address');
    },
    async addressNormalization(){
      try{

      let res = await this.api.addressNormalization(this.form.custAddressInfos[0].addrinfo)
      if(res.code === 200){
        let area = this.busiDict.DstcCd.find(prov=>prov.label===res.data.prov)
            ?.children.find(city=>city.label===res.data.city)
            ?.children.find(district=>district.label===res.data.district)
        this.form.custAddressInfos[0].distcd=area.value
      }
      }catch(e){

      }
    },
    // 用户信息删除
    userInfoDel(index) {
      this.delConnectUserIndex = index;
      this.isShowDelete = true;
    },
    // 删除弹框自定义事件
    delEmitEvent(obj) {
      if (obj.type == "ok") {
        // 删除操作
        this.delPersonConnectUser();
      }
      // 关闭弹框
      this.isShowDelete = false;
    },
    // 调用删除关联用户接口
    async delPersonConnectUser() {
      let idArr = [];
      idArr.push(this.custUserInfos[this.delConnectUserIndex].id);
      // console.log(idArr)
      let res = await this.api.delPersonConnectUserApi(idArr);
      if (res.code == 200) {
        this.custUserInfos.splice(this.delConnectUserIndex, 1);
        return this.$message.success(res.info);
      } else {
        return this.$message.error(res.info);
      }
    },
    // 点击保存和取消按钮事件
    handleEvent(info) {
      // console.log(info, 'baocun');
      // 显示弹框
      this.visible = true;
      if (info === "save") {
        // 保存按钮
        this.isSave = true;
      } else if (info === "cancel") {
        // 取消按钮
        this.isSave = false;
      }
    },
    // 保存接口
    async saveEvent() {
      this.form.custVedioInfos = [];
      // 影像信息-如果有上传影像信息就传值，如果没有就传空
      if (this.getUpFile) {
        this.form.custVedioInfos.push(this.getImage("image_up"));
      }
      if (this.getBackFile) {
        this.form.custVedioInfos.push(this.getImage("image_back"));
      }
      let data = JSON.parse(JSON.stringify(this.form))
      let res = await this.api.custDetailSave(data);
      if (res.code === 200) {
        this.$message.success(res.info);
        let that = this
        this.$confirm({
          title: '是否要开钱包？',
          content: '是否需要开钱包',
          okText: '现在去',
          cancelText: '以后',
          // 跳转到钱包开立
          onOk(){
            that.$router.replace({
              name: 'Individualaccountopening',
              params: {
                type: "add",
                custnum: res.data.custnum,
              }
            })
          },
          onCancel(){
            let query = {}
            if (that.$route.query.type === 'add') {
              query.fromAddPage = true
            } else if (this.$route.query.type === 'edit') {
              query.fromEditPage = true
            }
            that.$router.replace({ name: "Personage",query});
          },
        })
      } else {
        return this.$message.error(res.info);
      }
    },
    //获取图片对象
    getImage(type) {
      let image = {};
      image.departmentId = this.form.custPersonInfo.departmentId;
      image.custnum = this.form.custPersonInfo.custnum;
      image.fileNo = "";
      image.filePath = "";
      image.memotxt = "";
      image.createTime = "";
      // image.createTime = getFormatTime(new Date(), 'yyyy-mm-dd HH:mm:ss')
      image.creater = sessionStorage.getItem("username");
      image.updateTime = "";
      // image.updateTime = getFormatTime(new Date(), 'yyyy-mm-dd')
      image.updater = sessionStorage.getItem("username");
      image.recordStatus = 0;
      if (type == "image_up") {
        image.fileType = "01";
        image.id = this.$route.query.type == "edit" ? this.upId : "";
        image.fileNo = this.getUpFile;
      } else if (type == "image_back") {
        image.id = this.$route.query.type == "edit" ? this.backId : "";
        image.fileType = "02";
        image.fileNo = this.getBackFile;
      }
      return image;
    },
    getBasicVideo(fileType) {
      let image = {};
      image.departmentId = this.form.custPersonInfo.departmentId;
      image.custnum = this.form.custPersonInfo.custnum;
      image.fileType = fileType;
      image.fileNo = "";
      image.filePath = "";
      image.memotxt = "";
      image.createTime = "";
      // image.createTime = getFormatTime(new Date(), 'yyyy-mm-dd');
      image.creater = sessionStorage.getItem("username");
      image.updateTime = "";
      // image.updateTime = getFormatTime(new Date(), 'yyyy-mm-dd');
      image.updater = sessionStorage.getItem("username");
      image.recordStatus = 0;
      image.fileNo = "";
      return image;
    },
    getCustVideoArr(videos) {
      let newVideos = [];
      if (videos.length == 0) {
        newVideos.push(this.getBasicVideo("01"));
        newVideos.push(this.getBasicVideo("02"));
      } else if (videos.length == 1) {
        if (videos[0].fileType == "01") {
          newVideos = JSON.parse(JSON.stringify(videos));
          newVideos.push(this.getBasicVideo("02"));
        } else {
          newVideos.push(this.getBasicVideo("02"));
          newVideos.push(JSON.parse(JSON.stringify(videos[0])));
        }
      } else {
        newVideos = JSON.parse(JSON.stringify(videos));
      }
      return newVideos;
    },
    //校验表格中的数据
    onValidateData(type) {
      if (type == "cust-contact") {
        let phoneIndexArr = [];
        for (let i = 0; i < this.form.custContactInfos.length; i++) {
          if (this.form.custContactInfos[i].continfo == "") {
            phoneIndexArr.push(i + 1);
          }
        }
        if (phoneIndexArr.length > 0) {
          this.contactRemindText = "请填写客户的手机号码";
          // this.contactRemindText = "第" + phoneIndexArr.toString() + "个客户的手机号码为空";
        } else {
          this.contactRemindText = "";
        }
      }
      if (type == "cust-address") {
        let distIndexArr = [];
        let addrIndexArr = [];
        for (let i = 0; i < this.form.custAddressInfos.length; i++) {
          if (this.form.custAddressInfos[i].distcd == "") {
            distIndexArr.push(i + 1);
          }
          if (this.form.custAddressInfos[i].addrinfo == "") {
            addrIndexArr.push(i + 1);
          }
        }

        if (distIndexArr.length > 0 && addrIndexArr.length > 0) {
          this.addressRemindText = "请填写客户的行政区划和详细地址";
          // this.contactRemindText = "第" + phoneIndexArr.toString() + "个客户的手机号码为空";
        } else if (distIndexArr.length > 0 && addrIndexArr.length == 0) {
          this.addressRemindText = "请填写客户的行政区划";
        } else if (distIndexArr.length == 0 && addrIndexArr.length > 0) {
          this.addressRemindText = "请填写客户的详细地址";
        } else {
          this.addressRemindText = "";
        }
      }
    },

    // 校验
    async onSubmit() {

      this.onValidateData('cust-contact');
      // // 是否记住手机号
      // storage.set(storage.IS_REMEMBER_PER_PHONE_NUM, this.rememberPhone);
      // if(this.rememberPhone){
      //   storage.set(storage.REMEMBERED_PER_PHONE_NUM, this.form.custContactInfos[0]?.continfo);
      // }else{
      //   storage.del(storage.REMEMBERED_PER_PHONE_NUM);
      // }
      this.onValidateData('cust-address');
      this.$refs.ruleForm.validate((valid) => {
        // console.log(valid, 'valid')
        if (valid) {
          // 校验成功调用保存接口
          this.saveEvent();
        } else {
          console.log("error submit!!");
        }
        // 关闭弹框
        this.isSave = false;
      });
    },
    // 重置
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 弹框确定按钮
    async handleOk() {
      // console.log('ok')
      // 进行表单校验
      await this.onSubmit();
    },
    // 弹框取消按钮
    handleCancel() {
      // console.log('cancel')
      this.isSave = false;
      // this.resetForm()
    },
    cancleEvent() {
      this.isCancel = false;
      // this.$router.push({ name: "Personage" });
      this.$router.go(-1)
    },
    cancelBtn() {
      this.isCancel = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}

/deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;

  .ant-form-item-label {
    width: 260px;
    background-color: rgba(242, 242, 242, 1);
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
    border: 1px solid rgba(242, 242, 242, 1);
    padding: 5px 40px;

    .ant-input,
    .el-input__inner {
      max-width: 200px;
      width: 200px;
    }

    .ant-input-affix-wrapper {
      width: 200px;
    }

    .ant-select {
      width: 200px;
    }

    .ant-calendar-picker {
      width: 200px;
    }

    textarea {
      resize: none;
      width: 610px !important;
      height: 137px;
    }

    .el-input__inner {
      height: 32px;
    }
  }

  .certtypecd-style {
    .ant-select-arrow {
      display: none;
    }
  }

  .tableBody {
    min-width: 500px;
    border: 1px solid #f2f2f2;

    thead {
      tr:nth-child(1) {
        background-color: #f2f2f2;
        text-align: center;
        height: 30px;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #f2f2f2;
      }

      td {
        border-right: 1px solid #f2f2f2;
        position: relative;
        box-sizing: border-box;
      }

      //td:last-child {
      //  display: flex;
      //  width: 100px;
      //  height: 32px;
      //  align-items: center;
      //  justify-content: space-around;
      //
      //  button {
      //    width: 20px;
      //    height: 20px;
      //    border-radius: 5px;
      //    border: 1px solid #aaaaaa;
      //    background-color: #fff;
      //    text-align: center;
      //    line-height: 20px;
      //    cursor: pointer;
      //  }
      //}
    }

    .el-input.is-disabled .el-input__inner {
      background-color: #f5f5f5;
    }

    .el-input__inner {
      border: none;
    }
  }

  .UserTableBody {
    min-width: 800px;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;


    thead {
      tr:nth-child(1) {
        background-color: #f2f2f2;
        text-align: center;
        height: 30px;
      }
    }

    //tbody {
    //  td {
    //    min-width: 100px;
    //    box-sizing: border-box;
    //    padding: 5px;
    //    border-right: 1px solid #f2f2f2;
    //    border-bottom: 1px solid #f2f2f2;
    //  }
    //
    //  td:last-child {
    //    text-align: center;
    //
    //    .ant-btn {
    //      margin-right: 0;
    //    }
    //  }
    //
    //  span {
    //    display: inline-block;
    //    width: 100%;
    //    text-align: center;
    //  }
    //}
  }


  /deep/ .ant-form-item-control-wrapper {
    min-height: 180px;
  }

  .remind-text-area {
    width: 100%;
    height: 40px;
    position: relative;

    .remind-text {
      color: red;
    }

    .remind-text1 {
      position: absolute;
      left: 175px;
      top: 0px;
    }

    .remind-text2 {
      position: absolute;
      left: 560px;
      top: 0px;
    }
  }
}

.cust-contact-remind-area {
  width: 100%;
  height: 40px;
  position: relative;

  .remind-text {
    color: red;
  }
}

.cust-address-remind-area {
  width: 100%;
  height: 40px;
  position: relative;

  .remind-text {
    color: red;
  }
}

.pricebox {
  width: 100%;

  /deep/ .ant-form-item-label {
    min-height: 180px;
    padding-left: 30px;
    display: flex;
    align-items: center;
  }

  /deep/ .ant-form-item-control-wrapper {
    min-height: 180px;
  }

  .row {
    display: flex;
    margin-top: 25px;

    .item {
      margin-left: 50px;
      display: flex;
      // width: 600px;
      align-items: center;

      /deep/.ant-upload {
        width: 200px;
        height: 122px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        padding: 0px;
        overflow: hidden;

        border: 1px dashed #d9d9d9 !important;
        background-color: #fafafa !important;

        .anticon-plus {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .ant-upload.ant-upload-select-picture-card {
        border: 1px solid #ccc;
      }

      .ant-upload.ant-upload-select-picture-card>.ant-upload {
        padding: 0;
      }

      .ant-upload-select-picture {
        width: 200px;
        height: 122px;
        border: 0;
      }
    }
  }
}
</style>

