import Home from '@/view/Home'
import Login from '@/view/Login'
import Configparam from '@/view/Base/Configparam'
import Loanproduct from '@/view/Base/loanproduct/index.vue'
import Salesplatform from '@/view/Base/Salesplatform/index.vue'
import Coreenterprise from '@/view/Base/Coreenterprise/index.vue'
import Filestoragemode from '@/view/Base/Filestoragemode/index.vue'
import Smstemplate from '@/view/Base/Smstemplate/index.vue'
import Servicechargeallocation from '@/view/Base/Servicechargeallocation/index.vue'

export default [
    {path: '/home', component: Home, meta: '首页'},
    {path: '/login', component: Login, meta: '登录'},
    {path: '/logistics/login', component: Login, meta: '登录'},
    {path: '/base/configparam', component: Configparam, meta: '参数配置'},
    {path: '/base/loanproduct', component: Loanproduct, meta: '贷款产品'},
    {path: '/base/salesplatform', component: Salesplatform, meta: '销售平台'},
    {path: '/base/servicechargeallocation', component: Servicechargeallocation, meta: '服务费'},
    {path: '/base/coreenterprise', component: Coreenterprise, meta: '核心企业'},
    {path: '/base/smstemplate', component: Smstemplate, meta: '短信模版'},
    {path: '/base/filestoragemode', component: Filestoragemode, meta: '文件存储方式'},
]
