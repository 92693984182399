<template>
  <div class="not-found-container">
    <div class="not-found">
      <h1 class="error-code">404</h1>
      <p class="error-message">抱歉，您访问的页面找不到。</p>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.not-found {
  text-align: center;
}

.error-code {
  font-size: 100px;
  font-weight: bold;
  color: #ff5722;
}

.error-message {
  font-size: 24px;
  margin: 10px 0;
  color: #333;
}

.error-actions {
  margin-top: 20px;
}

.btn-home,
.btn-help {
  padding: 10px 20px;
  font-size: 16px;
  margin: 10px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
}

.btn-home:hover,
.btn-help:hover {
  background-color: #0056b3;
}
</style>