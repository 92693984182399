<template>
  <div style="overflow-y: scroll ; overflow-x:hidden" class="wrapper">
    <div class="header">
      <!-- 顶部信息 -->
      <a-page-header
          style="border: 1px solid rgb(235, 237, 240)"
          title="自助开户"
          :sub-title="currentType?.title"></a-page-header>
    </div>
    <!--  导航区域-->
    <div class="body">
      <a-steps :current="step" finish-status="success" direction="vertical" @change="onStepChange">
        <a-step title="服务确认" v-if="currentType.steps.indexOf('info')>=0"
                :disabled="currentType.steps.indexOf('info')>step">>
          <template slot="description" v-if="currentType.steps.indexOf('info')===step">
            <a-descriptions bordered :column="1" size="small">
              <a-descriptions-item v-for="(k,v) in exData" :key="v" :label="v">{{ k }}</a-descriptions-item>
            </a-descriptions>
            <a-checkbox v-model="agreed" @click.stop="agreed=!agreed">
              <span>已阅读并同意</span>
              <span>
              <a v-for="(item,index) in currentType.contracts" :key="index"
                 :href="item.url"> 《{{ item.title }}》 </a>
              </span>
            </a-checkbox>
            <a-button type="primary" @click.stop="agreeCheck" block>同意并确认</a-button>
          </template>
        </a-step>
        <a-step title="身份证照" v-if="currentType.steps.indexOf('idCard')>=0"
                :disabled="currentType.steps.indexOf('idCard')>step">
          <template slot="description" v-if="currentType.steps.indexOf('idCard')===step">
            <a-alert type="info" message="请上传身份证正反面照片，四角完整，画面清晰"></a-alert>
            <a-upload
                list-type="picture-card"
                accept="image/*"
                capture="environment"
                :show-upload-list="false"
                :before-upload="file=>beforeUpload(file, 'idCardFace')"
            >
              <div class="id-placeholder id-face">
                <img v-if="files.idCardFace.preview" :src="files.idCardFace.preview"/>
                <img v-else src="../assets/img/id-face@2x.webp"/>
              </div>
            </a-upload>
            <a-upload
                list-type="picture-card"
                accept="image/*"
                capture="environment"
                :show-upload-list="false"
                :before-upload="file=>beforeUpload(file, 'idCardNes')"
            >
              <div class="id-placeholder id-nes">
                <img v-if="files.idCardNes.preview" :src="files.idCardNes.preview"/>
                <img v-else src="../assets/img/id-emblem@2x.webp"/>
              </div>
            </a-upload>
            <a-button type="primary" @click.stop="nextStep" block v-if="files.idCardFace.file && files.idCardNes.file">
              下一步
            </a-button>
          </template>
        </a-step>
        <a-step title="人脸识别" v-if="currentType.steps.indexOf('face')>=0"
                :disabled="currentType.steps.indexOf('face')>step">
          <template slot="description" v-if="currentType.steps.indexOf('face')===step">
            <a-upload
                list-type="picture-card"
                accept="image/*"
                capture="user"
                :show-upload-list="false"
                :before-upload="file=>beforeUpload(file, 'face')"
                ref="inputFileFace"
            >
              <div class="id-placeholder face">
                <img v-if="files.face.preview" :src="files.face.preview"/>
                <img v-else src="../assets/img/face-rec.webp"/>
              </div>
            </a-upload>
            <a-alert type="info" message="请保证光线充足，摘除帽子，正视屏幕，点击上方图标开始采集"></a-alert>
            <a-button type="primary" @click.stop="nextStep" block v-if="files.face.file">下一步</a-button>
          </template>
        </a-step>
        <a-step title="银行卡验证" v-if="currentType.steps.indexOf('bankCardInfo')>=0"
                :disabled="currentType.steps.indexOf('bankCardInfo')>step">
          <template slot="description" v-if="currentType.steps.indexOf('bankCardInfo')===step">
            <a-alert type="info">
              <template slot="message">
                <div> 须使用<strong>本人</strong>银行卡,卡号为“62”开头</div>
                <div> 请使用<strong>储蓄卡</strong>，勿使用信用卡。</div>
                <div> 仅用于身份认证，不会产生扣款</div>
              </template>
            </a-alert>
            <a-input v-model.trim="reqData.primary_account" addon-before="银行卡"
                     placeholder="请输入“62”开头16或19位本人储蓄卡"></a-input>
            <a-button type="primary" @click.stop="openAccTaskAdd" block>下一步</a-button>
          </template>
        </a-step>
        <a-step title="手机验证" v-if="currentType.steps.indexOf('mobile')>=0"
                :disabled="currentType.steps.indexOf('mobile')>step">
          <template slot="description" v-if="currentType.steps.indexOf('mobile')===step">
            <a-alert type="info">
              <template slot="message">
                 请使用在以上银行卡开户行预留的手机号
              </template>
            </a-alert>
            <a-row>
              <a-input v-model.trim="reqData.mobile" addon-before="手机号" :max-length="11"></a-input>
            </a-row>
            <a-button type="primary" @click.stop="confirmVisible=true" :disabled="reqData.mobile?.length!==11" block>下一步，信息确认</a-button>
          </template>
        </a-step>
        <a-step title="完成" v-if="currentType.steps.indexOf('result')>=0"
                :disabled="currentType.steps.indexOf('result')>step">
          <template slot="description" v-if="currentType.steps.indexOf('result')===step">
            <a-result
                status="success"
                title="办理完成"
                :sub-title="resultMsg"
            ></a-result>
          </template>
        </a-step>

        <a-step title="进度查询" v-if="currentType.steps.indexOf('error')>=0">>
          <template slot="description" v-if="currentType.steps.indexOf('error')===step"
                    :disabled="currentType.steps.indexOf('error')>step">
            <a-result
                status="warning"
                title="出错啦"
                :sub-title="errorMsg || resultMsg"
            ></a-result>
          </template>
        </a-step>
      </a-steps>
    </div>
    <!-- loading -->
    <div class="loading" v-if="showLoading">
      <a-spin size="large"></a-spin>
    </div>
    <a-modal v-model="confirmVisible" :footer="false">
      <a-descriptions :column="1" bordered title="要素确认" size="small">
        <a-descriptions-item label="姓名">{{ reqData.account_name }}</a-descriptions-item>
        <a-descriptions-item label="证件号">{{ reqData.id_no }}</a-descriptions-item>
        <a-descriptions-item label="银行卡号">{{ reqData.primary_account }}</a-descriptions-item>
        <a-descriptions-item label="手机号">{{ reqData.mobile }}</a-descriptions-item>
      </a-descriptions>
      <div >
        <div style="display: flex">
          <a-input v-model="reqData.verify_code" :max-length="6" size="large" addon-before="验证码"></a-input>
          <a-button :disabled="sendCodeWait!==0" type="link" size="large"
                    @click.stop="sendCode">
            发送{{ sendCodeWait === 0 ? '' : '(' + sendCodeWait + ')' }}
          </a-button>
        </div>
        <div style="display: flex">
          <a-button type="primary" @click.stop="submit" block icon="check">确认无误，开户</a-button>
          <a-button type="link" @click.stop="confirmVisible=false" icon="arrow-left">返回修改</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import extraApi from "@/extra/extraApi";
import axios from "axios";
import urlConfig from "@/utils/urlConfig";
import {BizDict} from "@/utils/bizDict/kvMap";

// info: 业务确认
// idCardFace: 身份证人脸
// idcardNes: 身份证人脸
// face: 人脸比对
// mobile: 联系方式
// bankCardInfo: 银行卡号
// contract: 相关协议
// result: 办理结果
// error: 异常
let agreement_payment = {
      title: "浙江稠州商业银行用户支付服务协议",
      agreed: false,
      url: "https://oss-lcfs.oss-cn-hangzhou.aliyuncs.com/PLAT/%E6%B5%99%E6%B1%9F%E7%A8%A0%E5%B7%9E%E5%95%86%E4%B8%9A%E9%93%B6%E8%A1%8C%E7%94%A8%E6%88%B7%E6%94%AF%E4%BB%98%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf",
    },
    agreement_privacy = {
      title: "隐私政策",
      agreed: false,
      url: "https://oss-lcfs.oss-cn-hangzhou.aliyuncs.com/PLAT/%E6%B5%99%E6%B1%9F%E7%A8%A0%E5%B7%9E%E5%95%86%E4%B8%9A%E9%93%B6%E8%A1%8C%E7%94%A8%E6%88%B7%E6%94%AF%E4%BB%98%E6%9C%8D%E5%8A%A1%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf",
    }
const taskDefine = {
  7: {
    title: "稠州自助开户-个人电子钱包",
    steps: ['info', 'idCard', 'face', 'bankCardInfo', 'mobile', 'result'],
    contracts: [agreement_payment, agreement_privacy],
    resultMsg: '开户成功'
  },
  8: {
    title: "稠州企业开户采集",
    steps: ['info', 'mobile', 'result'],
    resultMsg: '开户成功'
  },
  99: {
    title: "未知业务",
    steps: ['error'],
    resultMsg: "业务异常，请联系渠道方"
  }
}
export default {
  name: 'App',
  data() {
    return {
      exData: {},

      title: "业务办理确认",
      currentType: taskDefine[99],//1 个人开户
      step: -1,
      agreed: false,
      files: {
        idCardFace: {
          file: undefined,
          preview: undefined,
        },
        idCardNes: {
          file: undefined,
          preview: undefined,
        },
        face: {
          file: undefined,
          preview: undefined,
        }
      },


      taskInfo: {
        taskNo: undefined,
        departmentId: undefined,
        channelNo: "",
        appNo: "",
      },
      sendCodeWait: 0,
      confirmVisible:false,
      showLoading: true,
      resultMsg: "",
      resultTaskNo: "",
      errorMsg: "",
      reqData: {
        verify_code: "",
        custnum: "",
        account_name: "",
        channel_no: "",
        app_no: "",
        mobile: "",
        account_property: "",
        primary_account: "",
        id_type: "",
        id_no: "",
        id_startdate: "",
        id_enddate: "",
        sign_name: "",
        id_address: "",
        sex: "",
        ethnic: "",
        job: "40199",
        file_list: [
          {
            file_no: "",
            channel_file_no: "",
            file_type: "01",  //人像面
            page: "1"
          },
          {
            file_no: "",
            channel_file_no: "",
            file_type: "02", // 国徽面
            page: "2"
          },
          {
            file_no: "",
            channel_file_no: "",
            file_type: "04",  // 人脸识别
            page: "4"
          },
        ]
      }
    }
  },
  components: {
    // Loading,
  },
  mounted() {
    this.parseBusiParam()
    this.loadTask()
  },
  methods: {
    async parseBusiParam() {
      return new Promise((resolve) => {
        const params = new URLSearchParams(window.location.search);
        this.taskInfo.taskNo = params.get("t");
        resolve();
      });
    },
    loadTask() {

      this.showLoading = true
      // 加载数据
      axios.get(urlConfig.busiURL + `/custOpenAccTask/get?taskNo=${this.taskInfo.taskNo}`).then((res) => {
        this.showLoading = false
        if (res.data.code !== 200) {
          this.currentType = taskDefine[99]
          this.errorMsg = res.data.info
        } else {
          this.busiData = res.data.data
          this.taskInfo = this.busiData
          try {
            if (this.busiData.exData) {
              this.exData = JSON.parse(this.busiData.exData)
            }
          } catch (e) {
            this.alert("解析业务数据异常")
          }
          this.currentType = taskDefine[this.busiData?.taskType]
        }
      }).catch(e => {
        this.showLoading = false
        this.alert("加载数据失败")
      })
      this.step = 0
    },
    check() {
      this.nextStep()
    },
    alert(msg) {
      this.$error({title: "哎呀，出错了", content: msg})
    },
    async agreeCheck() {
      if (!this.agreed && window.confirm("是否同意各项协议？")) {
        this.agreed = true
      }
      if (!this.agreed) {
        this.$message.info("请先阅读并同意相关协议")
      } else {
        this.nextStep()
      }
    },

    beforeUpload(file, type) {
      if (type === "idCardFace") {
        this.uploadIdCardFace(file)
      } else if (type === "idCardNes") {
        this.uploadIdCardNes(file)
      } else if (type === "face") {
        this.uploadFace(file)
      }
      // 阻止默认上传
      return false
    },
    async uploadIdCard(file) {
      this.showLoading = true
      const res = await extraApi.uploadFile(file, {
        taskNo: this.taskInfo.taskNo,
        channelNo: this.taskInfo.channelNo,
        depatId: this.taskInfo.departmentId,
        custNum: this.reqData.custnum,
      }, '/custOpenAccTask/idcard/opaccRecognize')
      this.showLoading = false
      if (res.code !== 200) {
        this.alert(res.info);
        return undefined
      }
      return res.data
    },
    async uploadIdCardFace(file) {
      try {
        const {ocrRecognizeResult, appNo, channelNo, file_no, channel_file_no, custnum} = await this.uploadIdCard(file)
        const extracted = {
          channel_no: channelNo,
          app_no: appNo,
          account_name: ocrRecognizeResult.name,
          custnum: custnum,
          id_no: ocrRecognizeResult.idcard,
          id_type: "1",
          id_address: ocrRecognizeResult.address,
          sex: ocrRecognizeResult.sex === "男" ? "0" : "1",
          ethnic: BizDict.RaceCd.filter(item =>
              item.label.includes(ocrRecognizeResult.nation)
          )[0]?.value,
          birthdt: ocrRecognizeResult.birth
              .replace("年", "-")
              .replace("月", "-")
              .replace("日", ""),
          account_property: "1",
          job: "40199",
        }
        this.reqData.file_list[0] = {file_no, channel_file_no, file_type: "01"}
        this.reqData = {...this.reqData, ...extracted}
        this.files.idCardFace.preview = URL.createObjectURL(file)
        this.files.idCardFace.file = file
      } catch (e) {
        console.error(e)
        return this.alert("身份证（人像面）识别失败，请重新上传")
      }
    },
    async uploadIdCardNes(file) {
      try {
        const {ocrRecognizeResult, file_no, channel_file_no} = await this.uploadIdCard(file)
        const validDates = ocrRecognizeResult.validDate.split("-")
        const extracted = {
          id_startdate: validDates[0].replaceAll(".", "-"),
          id_enddate: validDates[1].replaceAll(".", "-"),
          sign_name: ocrRecognizeResult.authority,
        }
        this.reqData.file_list[1] = {file_no, channel_file_no, file_type: "02"}
        this.reqData = {...this.reqData, ...extracted}
        this.files.idCardNes.preview = URL.createObjectURL(file);
        this.files.idCardNes.file = file
      } catch (e) {
        console.error(e)
        return this.alert("身份证（国徽面）识别失败，请重新上传")
      }
    },
    async uploadFace(file) {
      try {
        this.showLoading = true
        const res = await extraApi.uploadFile(file, {
          taskNo: this.taskInfo.taskNo,
          channel_no: this.reqData.channel_no,
          app_no: this.reqData.app_no,
          id_no: this.reqData.id_no,
          name: this.reqData.account_name,
        }, '/custOpenAccTask/faceRecognizeH5');
        this.showLoading = false

        if (res.code !== 200) {
          this.alert(res.info);
          return;
        }
        const {file_no, channel_file_no} = res.data;
        this.reqData.file_list[2] = {
          file_no,
          channel_file_no,
          file_type: "04",
        };
        this.files.face.file = file
        this.files.face.preview = URL.createObjectURL(file);
      } catch (e) {
        console.error(e)
        this.alert("人脸识别失败，请重新拍照上传")
      }
    },
    async sendCode() {
      //判断是否填写手机号
      if (this.reqData.mobile.length !== 11) {
        this.$error({title: "手机号填写不正确", content: "请填写正确的手机号"})
        return
      }
      let params = {
        "taskNo": this.taskInfo.taskNo,
        "channelNo": this.reqData.channel_no,
        "mobile": this.reqData.mobile,
        "cardNo": this.reqData.primary_account,
        "accountName": this.reqData.account_name,
        "idNo": this.reqData.id_no,
        "tradeType": 0
      }

      this.showLoading = true;
      const res = await extraApi.get(`/custOpenAccTask/sendOpenAccMsg`, params, {isFormData: true});
      this.showLoading = false

      if (res.code !== 200) {
        this.alert(res.info);
        return;
      } else {
        this.$success({title: "发送成功", content: "验证码已发送至您的手机，请查收"})
        this.sendCodeWait = 60
        let intHandler = window.setInterval(() => {
          if (this.sendCodeWait === 0) {
            window.clearInterval(intHandler)
            return
          }
          this.sendCodeWait--
        }, 1000)
      }
    },
    validateCardNo(cardNo) {
      // 银联储蓄卡校验规则：16位或19位以62开头
      const cardPattern = /^62\d{14}(\d{3})?$/;
      return !(!cardNo || !cardPattern.test(cardNo));
    },

    openAccTaskAdd() {
      // 调用校验方法
      if (!this.validateCardNo(this.reqData.primary_account)) {
        this.$error({
          title: "卡号填写不正确",
          content: "请输入任意银行的银行卡号，仅支持16位或19位“62”开头的银联储蓄卡",
        });
        return; // 停止后续操作
      }

      this.showLoading = false
      this.nextStep()

    },
    submit: async function () {
      //判断是否填写验证码
      if (this.reqData.verify_code.length !== 6) {
        this.$error({title: "验证码格式填写不正确", content: "请填写正确的验证码"})
        return
      }
      this.confirmVisible = false

      this.showLoading = true;
      const res = await extraApi.post(`/custOpenAccTask/open`, {
        taskNo: this.taskInfo.taskNo,
        verify_code: this.reqData.verify_code,
        openAccPersonalReq: this.reqData
      });
      this.showLoading = false
      if (res.code !== 200) {
        this.alert(res.info);
        return;
      }

      this.nextStep()
    },
    nextStep() {
      this.step++
      // 因a-input不支持capture，每次切换步骤把input框的capture更改为指定类型
      this.hackCapture()
    },
    onStepChange(step) {
      if (typeof step === "number")
        this.step = step
    },
    hackCapture() {
      window.setTimeout(() => {
        this.$refs?.inputFileFace?.$el?.querySelector("input")?.setAttribute("capture", "user")
      }, 200)
    }
  },
}
</script>
<style lang="less">
</style>



