import Personage from '@/view/Cust/Personage'
import connectCust from '@/view/Cust/Personage/connectCust'
import Enterprise from '@/view/Cust/Enterprise'
import connectEnterprise from '@/view/Cust/Enterprise/connectEnterprise'
import addCust from '@/view/Cust/Personage/addPersonCust'
import addEnterprise from '@/view/Cust/Enterprise/addEnterprise'

export default [
    {path: '/cust/personage', name: 'Personage', component: Personage, meta: '个人客户管理'},
    {path: '/cust/personage/connectCust', component: connectCust, meta: '关联个人客户'},
    {path: '/cust/enterprise', name: 'Enterprise', component: Enterprise, meta: '企业客户管理'},
    {path: '/cust/enterprise/connectEnterprise', component: connectEnterprise, meta: '企业客户关联'},
    {path: '/cust/personage/addCust', name: 'addCust', component: addCust, meta: '新增个人客户',},
    {path: '/cust/enterprise/addEnterprise', name: 'addEnterprise', component: addEnterprise, meta: '企业客户新增',},

]
