import Authority from '@/view/System/Authority'
import Organization from '@/view/System/Organization'
import Log from '@/view/System/Log'
import Qrcode from '@/view/System/Qrcode'
import User from '@/view/System/User'
import Role from '@/view/System/Role'
import Filestorageinformation from '@/view/System/Filestorageinformation/index.vue'
import Shortmessagerecord from '@/view/System/Shortmessagerecord/index.vue'

export default [
    { path: '/system/user', component: User, meta: '用户管理' },
    { path: '/system/role', component: Role, meta: '角色管理' },
    { path: '/system/authority', component: Authority, meta: '权限管理' },
    { path: '/system/organization', component: Organization, meta: '机构管理' },
    { path: '/system/filestorageinformation', component: Filestorageinformation, meta: '文件存储信息' },
    { path: '/system/shortmessagerecord', component: Shortmessagerecord, meta: '短信记录' },
    { path: '/system/log', component: Log, meta: '日志管理' },
    { path: '/system/qrcode', component: Qrcode, meta: '二维码业务' },
]
