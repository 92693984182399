import loan from '@/view/FinancingManagement/loan/index.vue'
import Personalloan from '@/view/FinancingManagement/Personalloan/index.vue'
import Financingwhitelist from '@/view/FinancingManagement/Financingwhitelist'
import Enterpriseloan from '@/view/FinancingManagement/Enterpriseloan/index.vue'
import GuarantorManage from "@/view/FinancingManagement/GuarantorManage/index.vue"

export default [
    { path: '/Financingmanagement/loan', name: 'loan', component: loan, meta: '贷款申请' },
    { path: '/Financingmanagement/Financingwhitelist', name: 'Financingwhitelist', component: Financingwhitelist, meta: '融资白名单' },
    { path: '/Financingmanagement/GuarantorManage', name: 'GuarantorManage', component: GuarantorManage, meta: '担保人管理' },
    { path: '/Financingmanagement/Personalloan', name: 'Personalloan', component: Personalloan, meta: '个人贷款' },
    { path: '/Financingmanagement/Enterpriseloan', name: 'Enterpriseloan', component: Enterpriseloan, meta: '企业贷款' },
]
