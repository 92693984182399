<template>
  <!-- 企业客户新增、编辑、查看页面 -->
  <a-card :title="title()" style="margin: 20px">
    <template slot="extra">
      <a-button type="primary" v-if="$route.query.type !== 'view'" @click="isSave = true">保存</a-button>
      <a-button @click="clickCancel">取消</a-button>
    </template>
    <!-- 主体部分 -->
    <div class="container">
      <div class="conHeader">基础信息</div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="left">
        <a-form-model-item ref="name" class="pricebox" label="证件照片" prop="license">
          <div style="color:red">上传证件照 进行识别</div>
          <div class="row">
            <div class="item">
              <span style="width: 100px">营业执照</span>
              <a-upload :disabled="isView()" name="file" accept="image/*" :beforeUpload="handleBeforeUpload"
                        list-type="picture-card" class="avatar-uploader" :show-upload-list="false" ref="license"
                        :action="licenseBusiURL" :headers="headers" @change="changeLicense">
                <uploadImg v-show="license" @completedUpload="completedUpload"
                           @CallEditimgApi="CallEditimgApi('business')"
                           :showEditicon="$route.query.type == 'view' ? false : true"
                           :showdelete="$route.query.type == 'view' ? false : true" :showMask="true"
                           @Editimg="licenseimgEdit"
                           @onloadsuccsse="license = $event" @fileData="getLicense = $event"
                           @filePath="licensefilePath = $event"
                           @deleteimg="opreatDel('business')" ref="licenseEvent"></uploadImg>
                <div v-show="!license">
                  <a-icon :type="loading ? 'loading' : 'plus'"/>
                </div>
              </a-upload>
            </div>
            <!--            <div class="item">-->
            <!--              <span style="margin-right: 20px; display: inline-block; width: 100px">法代身份证人像面</span>-->
            <!--              <a-upload :disabled="isView()" name="file" accept="image/*" :beforeUpload="handleBeforeUpload"-->
            <!--                  ref="uploadRef" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"-->
            <!--                  :action="busiURL" :headers="headers" @change="changeIdUpload">-->
            <!--                <uploadImg v-show="Upimgshow" @CallEditimgApi="CallEditimgApi('up')" @onloadsuccsse="Upimgshow = $event"-->
            <!--                    @fileData="getUpFile = $event" @deleteimg="opreatDel('up')" @filePath="UpimgfilePath = $event"-->
            <!--                    :showEditicon="$route.query.type == 'view' ? false : true"-->
            <!--                    :showdelete="$route.query.type == 'view' ? false : true" :showMask="true" @Editimg="UpimgEdit"-->
            <!--                    ref="IdUp"></uploadImg>-->
            <!--                <div v-show="!Upimgshow">-->
            <!--                  <a-icon :type="loading ? 'loading' : 'plus'" />-->
            <!--                </div>-->
            <!--              </a-upload>-->
            <!--            </div>-->
            <!-- 国徽面 -->
            <!--            <div class="item">-->
            <!--              <span style="margin-right: 20px; display: inline-block; width: 100px">法代身份证国徽面</span>-->
            <!--              <a-upload :disabled="isView()" name="file" ref="Backimg" accept="image/*" :beforeUpload="handleBeforeUpload"-->
            <!--                  list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :action="busiURL"-->
            <!--                  :headers="headers" @change="changeIdBack">-->
            <!--                &lt;!&ndash; 上传子组件 &ndash;&gt;-->
            <!--                <uploadImg v-show="Backimgshow" @CallEditimgApi="CallEditimgApi('back')"-->
            <!--                    @onloadsuccsse="Backimgshow = $event" @fileData="getBackFile = $event" @Editimg="BackimgEdit"-->
            <!--                    @filePath="BackimgefilePath = $event" :showEditicon="$route.query.type == 'view' ? false : true"-->
            <!--                    :showdelete="$route.query.type == 'view' ? false : true" :showMask="true" @deleteimg="opreatDel('back')"-->
            <!--                    ref="IdBack">-->
            <!--                </uploadImg>-->
            <!--                <div v-show="!Backimgshow">-->
            <!--                  <a-icon :type="loading ? 'loading' : 'plus'" />-->
            <!--                </div>-->
            <!--              </a-upload>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div class="remind-text-area" v-if="custNumImageRemindTextShow(3)">-->
            <span class="remind-text remind-text1" v-if="custNumImageRemindTextShow(3)">请上传营业执照</span>
          </div>
          <!--          <div class="remind-text-area" v-if="custNumImageRemindTextShow(1) || custNumImageRemindTextShow(2)-->
          <!--            ">-->
          <!--            <span class="remind-text remind-text1" v-if="custNumImageRemindTextShow(1)">请上传身份证人像面</span>-->
          <!--            <span class="remind-text remind-text2" v-if="custNumImageRemindTextShow(2)">请上传身份证国徽面</span>-->
          <!--          </div>-->
        </a-form-model-item>
        <!--法定代表人影像  -->
        <!--        <a-form-model-item ref="name" class="pricebox" label="法定代表人影像" prop="custVedioInfos">-->
        <!--          <div class="row">-->
        <!--          </div>-->
        <!--        </a-form-model-item>-->
        <a-form-model-item ref="name" label="所属机构" prop="companyInfo.departmentId">
          <DepartmentChoose :department-id.sync="form.companyInfo.departmentId"></DepartmentChoose>
        </a-form-model-item>
        <a-form-model-item ref="name" label="企业名称" prop="companyInfo.custname">
          <a-input :disabled="isView()" v-model.trim="form.companyInfo.custname" placeholder="企业名称"></a-input>
        </a-form-model-item>
        <!--        <a-form-model-item ref="name" prop="companyInfo.locactyzonecd" label="所在国家或地区">-->
        <!--          <a-select :disabled="true" v-model="form.companyInfo.locactyzonecd">-->
        <!--            <a-select-option v-for="(item, index) in busiDict.NtntyCd" :key="'locactyzonecd' + index" :value="item.value">-->
        <!--              {{ item.label }}-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-model-item>-->

        <!--        <a-form-model-item ref="name" label="证件类型" prop="custIdInfo.certtypecd">-->
        <!--          <a-select disabled v-model="form.custIdInfo.certtypecd" class="certtypecd-style">-->
        <!--            <a-select-option v-for="(item, index) in busiDict.IdentTp" :key="'certtypecd' + index" :value="item.value">-->
        <!--              {{ item.label }}-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
        <!--        </a-form-model-item>-->
        <a-form-model-item label="营业执照号" prop="custIdInfo.certnum">
          <a-input :disabled="isView()" v-model="form.custIdInfo.certnum" placeholder="营业执照号"></a-input>
        </a-form-model-item>
        <a-form-model-item ref="name" label="证件生效日期" prop="custIdInfo.certeffectdt">
          <a-date-picker :disabled="isView()" valueFormat="YYYY-MM-DD" v-model="form.custIdInfo.certeffectdt"
                         @change="changeCertData($event, 'certeffectdt')"/>
        </a-form-model-item>
        <a-form-model-item ref="name" label="证件失效日期" prop="custIdInfo.certinvaliddt">
          <a-date-picker ref="datePicker" :disabled="isView() || isForbidden()" valueFormat="YYYY-MM-DD"
                         v-model="form.custIdInfo.certinvaliddt" @change="changeCertData($event, 'certinvaliddt')">
          </a-date-picker>
          <span class="check-box" v-if="!isView()">
            <a-checkbox v-model="checkBoxValue" @change="changeData">
              长期
            </a-checkbox>
          </span>
        </a-form-model-item>
        <!--        <a-form-model-item ref="name" label="签发机关名称">-->
        <!--          <a-input disabled v-model="form.custIdInfo.certissueorgname"></a-input>-->
        <!--        </a-form-model-item>-->


        <a-form-model-item ref="name" label="手机号" prop="custContactInfos">
          <!--          <table class="tableBody">-->
          <!--            <thead>-->
          <!--              <tr>-->
          <!--                <th>联系类型</th>-->
          <!--                <th>联系信息</th>-->
          <!--                <th>主要标志</th>-->
          <!--                <th>操作</th>-->
          <!--              </tr>-->
          <!--            </thead>-->
          <!--            <tbody>-->
          <span v-for="(item, index) in form.custContactInfos" :key="index">
            <!--                <td>-->
            <!--                  <el-select :disabled="isView()" v-model="item.conttypecd" placeholder="请选择联系类型"-->
            <!--                    :options="busiDict.CtcTp">-->
            <!--                    <el-option v-for="item in busiDict.CtcTp" :key="item.value" :label="item.label"-->
            <!--                      :value="item.value"></el-option>-->
            <!--                  </el-select>-->
            <!--                </td>-->
            <!--                <td>-->
            <a-input :type="'number'" :disabled="isView()" v-model="item.continfo" placeholder="请填写手机号"
                     @blur="changeNumber(index)"></a-input>
            <!--          <span class="check-box" style="margin-left: 50px" v-if="!isView()">-->
            <!--            <a-checkbox v-model="rememberPhone"> 记住 </a-checkbox>-->
            <!--          </span>-->
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <el-select :disabled="isView()" v-model="item.majorind" placeholder="主要标志">-->
            <!--                    <el-option v-for="item in busiDict.MajorInd" :key="item.value" :label="item.label"-->
            <!--                      :value="item.value"></el-option>-->
            <!--                  </el-select>-->
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <button v-if="addBtnShow(index, 'custContactInfos')" :disabled="$route.query.type == 'view'"-->
            <!--                    @click="handleAddContactInfo">-->
            <!--                    +-->
            <!--                  </button>-->
            <!--                  <button v-if="deleteBtnShow()" @click="handleDelContactInfo(index)">-->
            <!--                    - -->
            <!--                  </button>-->
            <!--                </td>-->
          </span>
          <!--            </tbody>-->
          <!--          </table>-->
          <div class="cust-contact-remind-area" v-if="contactRemindText">
            <span class="remind-text">{{ contactRemindText }}</span>
          </div>
        </a-form-model-item>
        <a-form-model-item ref="name" label="证件地址" prop="custAddressInfos">
          <!--          <table class="tableBody">-->
          <!--            <thead>-->
          <!--              <tr>-->
          <!--                <th>地址类型</th>-->
          <!--                <th>国家地区</th>-->
          <!--                <th>行政区划</th>-->
          <!--                <th>详细地址</th>-->
          <!--                <th>主要标志</th>-->
          <!--                <th>操作</th>-->
          <!--              </tr>-->
          <!--            </thead>-->
          <!--            <tbody>-->
          <span v-for="(item, index) in form.custAddressInfos" :key="index">
            <!--                <td>-->
            <!--                  <el-select :disabled="isView()" v-model="item.addrtypecd" placeholder="请选择地址类型">-->
            <!--                    <el-option v-for="item in busiDict.AdTyp" :key="item.value" :label="item.label"-->
            <!--                      :value="item.value"></el-option>-->
            <!--                  </el-select>-->
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <el-select :disabled="isView()" v-model="item.ctyzonecd" :options="busiDict.NtntyCd"-->
            <!--                    placeholder="请选择国家地区">-->
            <!--                    <el-option v-for="item in busiDict.NtntyCd" :key="item.value" :label="item.label"-->
            <!--                      :value="item.value"></el-option>-->
            <!--                  </el-select>-->
            <!--                </td>-->
            <!--                <td>-->
            <el-cascader :disabled="isView()" v-model="item.distcd" :options="busiDict.DstcCd" placeholder="请选择地区"
                         :show-all-levels="false" :props="{ emitPath: false }"/>
            <!--                </td>-->
            <!--                <td>-->
            <a-input style="width: 400px" :disabled="isView()" v-model="item.addrinfo"
                     placeholder="请填写详细地址"></a-input>
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <el-select :disabled="isView()" v-model="item.majorind" placeholder="主要标志">-->
            <!--                    <el-option v-for="item in busiDict.MajorInd" :key="item.value" :label="item.label"-->
            <!--                      :value="item.value"></el-option>-->
            <!--                  </el-select>-->
            <!--                </td>-->
            <!--                <td>-->
            <!--                  <button v-if="addBtnShow(index, 'custAddressInfos')" :disabled="$route.query.type == 'view'"-->
            <!--                    @click="handleAddress">-->
            <!--                    +-->
            <!--                  </button>-->
            <!--                  <button v-if="delAddressBtn()" @click="delAdress(index)">-->
            <!--                    - -->
            <!--                  </button>-->
            <!--                </td>-->
          </span>
          <!--            </tbody>-->
          <!--          </table>-->
          <div class="cust-address-remind-area" v-if="addressRemindText">
            <span class="remind-text">{{ addressRemindText }}</span>
          </div>
        </a-form-model-item>

        <a-form-model-item ref="name" label="经营范围" prop="companyInfo.operbiz">
          <a-textarea placeholder="请填写经营范围" :disabled="isView()" v-model="form.companyInfo.operbiz"/>
        </a-form-model-item>
        <!-- start -->
        <a-collapse>
          <a-collapse-panel header="更多信息">
            <a-form-model-item ref="name" label="单位性质">
              <a-select :disabled="isView()" v-model="form.companyInfo.unitcharcd" placeholder="请选择单位性质"
                        :options="busiDict.CoChrctrstcTp">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="经济成分">
              <a-select :disabled="isView()" v-model="form.companyInfo.ecomncompntcd" placeholder="请选择经济成分"
                        :options="busiDict.EcomnCompntCD">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="机构类别(组织机构类别)">
              <a-select :disabled="isView()" v-model="form.companyInfo.orgcatecd" placeholder="请选择机构类别"
                        :options="busiDict.InstCtgry">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="经济性质(经济类型)">
              <a-select :disabled="isView()" v-model="form.companyInfo.econtypecd" placeholder="请选择经济性质代码"
                        :options="busiDict.EcnmChrctrstcCd">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="国民经济部门" prop="yearin">
              <a-select :disabled="isView()" v-model="form.companyInfo.nationecodeptcd" placeholder="请选择国民经济部门"
                        :options="busiDict.NtnEcnmSctrCd">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="注册资本">
              <span style="margin-right: 10px">注册资本</span>
              <a-input :disabled="isView()" :type="'number'" v-model="form.companyInfo.rgstcap" suffix="万元">
              </a-input>
              <span style="margin: 0 10px 0 50px">币种</span>
              <a-select :disabled="isView()" v-model="form.companyInfo.rgstcurrcd" placeholder="请选择币种"
                        :options="busiDict.RgstrtnFndCcyCd">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="公司成立日期(企业成立日期)" prop="workstatcd">
              <a-date-picker :disabled="isView()" valueFormat="YYYY-MM-DD" v-model="form.companyInfo.corpfounddt"/>
            </a-form-model-item>
            <a-form-model-item ref="name" label="实收资本">
              <span style="margin-right: 10px">实收资本</span>
              <a-input :disabled="isView()" suffix="万元" :type="'number'"
                       v-model="form.companyInfo.actlrecvcap"></a-input>
              <span style="margin: 0 10px 0 50px">币种</span>
              <a-select :disabled="isView()" v-model="form.companyInfo.actlcurrcd" placeholder="请选择币种"
                        :options="busiDict.ActRcvFndCcyCd">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="上年末企业从业人员">
              <a-input :disabled="isView()" :type="'number'" v-model="form.companyInfo.corpemplypersoncnt"
                       suffix="人次">
              </a-input>
            </a-form-model-item>
            <a-form-model-item ref="name" label="上年末资产总额">
              <a-input :disabled="isView()" :type="'number'" v-model="form.companyInfo.corptotalasset" suffix="￥">
              </a-input>
            </a-form-model-item>
            <a-form-model-item ref="name" label="上年营业收入">
              <a-input :disabled="isView()" :type="'number'" v-model="form.companyInfo.corpbizin" suffix="￥">
              </a-input>
            </a-form-model-item>
            <a-form-model-item ref="name" label="净资产">
              <a-input :disabled="isView()" :type="'number'" v-model="form.companyInfo.netasset" suffix="￥">
              </a-input>
            </a-form-model-item>
            <a-form-model-item ref="name" label="基本账户信息">
              <span style="margin-right: 10px">基本账户开户行名称</span>
              <a-input :disabled="isView()" v-model="form.companyInfo.basicacctopenbankname"
                       placeholder="请填写开户行名称"></a-input>
              <span style="margin: 0 10px 0 50px">基本账户账号</span>
              <a-input :disabled="isView()" :type="'number'" v-model="form.companyInfo.basicacctnum"
                       placeholder="请输入基本账户账号"></a-input>
            </a-form-model-item>
            <a-form-model-item ref="name" label="开户信息">
              <span style="margin-right: 10px">开户许可证号</span>
              <a-input :disabled="isView()" v-model="form.companyInfo.basicacctauthcertnum"
                       placeholder="请输入开户许可证号"></a-input>
              <span style="margin: 0 10px 0 50px">开户许可证到期日</span>
              <a-date-picker :disabled="isView()" valueFormat="YYYY-MM-DD"
                             v-model="form.companyInfo.basicacctauthmaturedt"/>
            </a-form-model-item>
            <a-form-model-item ref="name" label="贷款卡号">
              <a-input :disabled="isView()" :type="'number'" v-model="form.companyInfo.loancardnum"
                       placeholder="请输入贷款卡号"></a-input>
            </a-form-model-item>
            <a-form-model-item ref="name" label="企业规模">
              <a-select :disabled="isView()" v-model="form.companyInfo.corpsizecd" placeholder="请选择企业规模"
                        :options="busiDict.CorpSizeCD">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="外汇企业类型(外汇企业属性)">
              <a-select :disabled="isView()" v-model="form.companyInfo.forgninducorpcd" placeholder="请选择外汇企业类型"
                        :options="busiDict.FrgnExgEntpTp">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="人行金融机构编码">
              <a-input :disabled="isView()" v-model="form.companyInfo.pbcfinorgcdv"
                       placeholder="请输入人行金融机构编码"></a-input>
            </a-form-model-item>
            <a-form-model-item ref="name" label="金融机构行业分类">
              <a-select :disabled="isView()" v-model="form.companyInfo.finorginduscd" placeholder="请选择金融机构行业"
                        :options="busiDict.FrgnExgEntpTp">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="同业客户类型">
              <a-select :disabled="isView()" v-model="form.companyInfo.finorgcustcd" placeholder="请选择同业客户类型"
                        :options="busiDict.FinOrgCustCD">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="经营状态">
              <a-select :disabled="isView()" v-model="form.companyInfo.corpoperstatcd" placeholder="请选择经营状态"
                        :options="busiDict.CorpOperStatCD">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="客户状态">
              <a-select :disabled="true" v-model="form.companyInfo.custstatuscd" placeholder="请选择客户状态"
                        :options="busiDict.CstSt">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="联系信息" prop="name">
              <table class="tableBody">
                <thead>
                <tr>
                  <th>联系类型</th>
                  <th>联系信息</th>
                  <th>主要标志</th>
                  <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in form.custContactInfos" :key="index">
                  <td>
                    <el-select :disabled="isView()" v-model="item.conttypecd" placeholder="请选择联系类型"
                               :options="busiDict.CtcTp">
                      <el-option v-for="item in busiDict.CtcTp" :key="item.value" :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-input :type="'number'" :disabled="isView()" v-model="item.continfo" placeholder="请填写手机号"
                              @blur="changeNumber(index)"></el-input>
                  </td>
                  <td>
                    <el-select :disabled="isView()" v-model="item.majorind" placeholder="主要标志">
                      <el-option v-for="item in busiDict.MajorInd" :key="item.value" :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </td>
                  <td>
                    <button v-if="addBtnShow(index, 'custContactInfos')" @click="handleAddContactInfo">
                      +
                    </button>
                    <button v-if="deleteBtnShow()" @click="handleDelContactInfo(index)">
                      -
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </a-form-model-item>
            <a-form-model-item ref="name" label="地址信息" prop="name">
              <table class="tableBody">
                <thead>
                <tr>
                  <th>地址类型</th>
                  <th>国家地区</th>
                  <th>行政区划</th>
                  <th>详细地址</th>
                  <th>主要标志</th>
                  <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in form.custAddressInfos" :key="index">
                  <td>
                    <el-select :disabled="isView()" v-model="item.addrtypecd" placeholder="请选择地址类型">
                      <el-option v-for="item in busiDict.AdTyp" :key="item.value" :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select :disabled="isView()" v-model="item.ctyzonecd" :options="busiDict.NtntyCd"
                               placeholder="请选择国家地区">
                      <el-option v-for="item in busiDict.NtntyCd" :key="item.value" :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-cascader :disabled="isView()" v-model="item.distcd" :options="busiDict.DstcCd"
                                 placeholder="请选择行政区划" :show-all-levels="false" :props="{ emitPath: false }"/>
                  </td>
                  <td>
                    <el-input :disabled="isView()" v-model="item.addrinfo" placeholder="请填写详细地址"></el-input>
                  </td>
                  <td>
                    <el-select :disabled="isView()" v-model="item.majorind" placeholder="主要标志">
                      <el-option v-for="item in busiDict.MajorInd" :key="item.value" :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </td>
                  <td>
                    <button v-if="addBtnShow(index, 'custAddressInfos')" @click="handleAddress">
                      +
                    </button>
                    <button v-if="delAddressBtn()" @click="delAdress(index)">
                      -
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </a-form-model-item>
            <a-form-model-item ref="name" label="是否涉农客户">
              <a-radio-group :disabled="isView()" v-model="form.companyInfo.agrcltrelatedcustind"
                             :options="busiDict.AgrcltRelatedCustInd">
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item ref="name" label="涉农范围">
              <a-select :disabled="isView()" v-model="form.companyInfo.agrcltrelatedscope" placeholder="请选择涉农范围">
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="境内境外">
              <a-radio-group :disabled="isView()" v-model="form.companyInfo.oversind" :options="busiDict.DmstOrOvrsInd">
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item ref="name" label="是否绿色企业">
              <a-radio-group :disabled="isView()" v-model="form.companyInfo.greencorpind"
                             :options="busiDict.GreenCorpInd">
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item ref="name" label="是否集团客户">
              <a-radio-group :disabled="isView()" v-model="form.companyInfo.groupcustind"
                             :options="busiDict.ClsGrpSsts">
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item ref="name" label="是否个体工商户">
              <a-radio-group :disabled="isView()" v-model="form.companyInfo.indvbizind" :options="busiDict.IndvBizInd">
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item ref="name" label="是否上市公司">
              <a-radio-group :disabled="isView()" v-model="form.companyInfo.listcorpind"
                             :options="busiDict.LstdEntpFlg">
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item ref="name" label="是否小微企业">
              <a-radio-group :disabled="isView()" v-model="form.companyInfo.microbizind"
                             :options="busiDict.microbizind">
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item ref="name" label="是否科技企业">
              <a-radio-group :disabled="isView()" v-model="form.companyInfo.techcorpind"
                             :options="busiDict.TechEntpFlg">
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item ref="name" label="备注">
              <a-textarea :disabled="isView()" v-model="form.companyInfo.memotxt"/>
            </a-form-model-item>
          </a-collapse-panel>
        </a-collapse>

        <!-- end -->

        <!-- 公司股东与高管信息 -->
        <div v-for="(item, index) in form.custEandpInfos" :key="'a' + index">
          <div class="conHeader">
            公司股东与高管信息
            <span style="float: right">
              <!-- 新增按钮 -->
              <a-button v-if="userAddBtn(index) && isView() == false" type="primary" @click="handleAddForm('user')">
                <a-icon type="plus"></a-icon>
              </a-button>
              <!-- 删除按钮 -->
              <a-button v-if="userDelBtn('user') && isView() == false" type="danger"
                        @click="handleDelForm('user', index)">
                <a-icon type="minus"></a-icon>
              </a-button>
            </span>
          </div>
          <a-form-model-item label="是否法定代表人">
            <a-radio-group :disabled="isView()" v-model="item.islegalperson" :options="busiDict.IsFlg1">
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item :prop="`custEandpInfos[${index}].custname`" :rules="rules.custEandpRules.custname"
                             label="客户名称">
            <!--            <a-input :ref="'persional-input' + (index + 1)" disabled v-model="item.custname" placeholder="客户名称"></a-input>-->
            <!--            <span style="margin-left: 50px"><a-button type="primary" v-if="!isView()"-->
            <!--                @click="customEvent(index, 'eandp')">选择</a-button></span>-->
            <CustChooseDialog v-model="item.custname" custtypecd="1" @chooseDone="frChosen" :scope="index">
            </CustChooseDialog>
          </a-form-model-item>
          <a-form-model-item label="证件类型" :prop="`custEandpInfos[${index}].certtypecd`" :rules="{
            required: true,
            message: '请选择证件类型',
            trigger: 'change',
          }">
            <a-select disabled v-model="item.certtypecd" :options="busiDict.IdentTp" placeholder="身份证">
            </a-select>
          </a-form-model-item>
          <a-form-model-item :prop="`custEandpInfos[${index}].certnnum`" :rules="rules.custEandpRules.certnnum"
                             label="证件号码">
            <a-input disabled v-model="item.certnnum" placeholder="证件号码"></a-input>
          </a-form-model-item>
          <a-form-model-item ref="name" :prop="'custEandpInfos.' + index + '.certeffectdt'" :rules="{
            required: true,
            message: '请选择证件生效日期',
            trigger: 'change',
          }" label="证件生效日期">
            <a-date-picker valueFormat="YYYY-MM-DD" :disabled="isView()" v-model="item.certeffectdt"/>
          </a-form-model-item>
          <a-form-model-item ref="name" :prop="'custEandpInfos.' + index + '.certinvaliddt'" :rules="{
            required: true,
            message: '请选择证件失效日期',
            trigger: 'change',
          }" label="证件失效日期">
            <a-date-picker valueFormat="YYYY-MM-DD" :disabled="isView()" v-model="item.certinvaliddt"/>
          </a-form-model-item>
          <a-form-model-item label="国籍">
            <a-select :disabled="isView()" v-model="item.nationcd" :options="busiDict.NtntyCd" placeholder="请选择国籍">
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="name" label="性别">
            <a-radio-group :disabled="isView()" v-model="item.gendercd" :options="busiDict.GndFlg">
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="职业类型">
            <el-cascader :disabled="isView()" v-model="item.careercd" :options="busiDict.OcpCd"
                         placeholder="请选择职业类型"
                         :props="{ emitPath: false }" :show-all-levels="false"/>
          </a-form-model-item>
          <a-form-model-item label="联系电话">
            <a-input :disabled="isView()" :type="'number'" v-model="item.continfo" placeholder="联系电话"></a-input>
          </a-form-model-item>
          <a-form-model-item label="地址信息">
            <a-input :disabled="isView()" v-model="item.addrinfo" placeholder="详细地址"></a-input>
          </a-form-model-item>
          <a-form-model-item label="客户编号">
            <a-input v-model="item.custnum" placeholder="显示客户编号" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="出资相关">
            <div class="contribution">
              <div class="contributionItem">
                <span style="margin-right: 10px">出资方式</span>
                <a-select :disabled="isView()" v-model="item.investtype" :options="busiDict.InvestType"
                          placeholder="请选择出资方式">
                </a-select>
              </div>
              <div class="contributionItem">
                <span style="margin-right: 10px">币种</span>
                <a-select :disabled="isView()" v-model="item.investcurrcd" :options="busiDict.InvestCurrCD">
                </a-select>
              </div>
              <div class="contributionItem">
                <span style="margin-right: 10px">应出资金额</span>
                <a-input :disabled="isView()" type="number" placeholder="请填写应出资金额"
                         v-model="item.oughtinvestamt"></a-input>
              </div>
              <div class="contributionItem">
                <span style="margin-right: 10px">实际出资金额</span>
                <a-input :disabled="isView()" type="number" placeholder="请填写实际出资金额"
                         v-model="item.actlinvestamt"></a-input>
              </div>
              <div class="contributionItem">
                <span style="margin-right: 10px">出资比例</span>
                <a-input type="number" :disabled="isView()" placeholder="请填写出资比例" v-model="item.investratio"
                         suffix="%"></a-input>
              </div>
              <div class="contributionItem">
                <span style="margin-right: 10px">出资日期</span>
                <a-date-picker valueFormat="YYYY-MM-DD" :disabled="isView()" v-model="item.investdate"/>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="职务">
            <span style="margin-right: 10px">担任职务</span>
            <a-input :disabled="isView()" v-model="item.holdpos" placeholder="请填写所担任职务"></a-input>
            <span style="margin: 0 10px 0 20px">担任职务时间</span>
            <a-date-picker valueFormat="YYYY-MM-DD" :disabled="isView()" v-model="item.holdposdt"/>
          </a-form-model-item>
          <a-form-model-item label="相关行业从业年限">
            <a-input :disabled="isView()" v-model="item.indusworkyears" placeholder="请填写从业年限"></a-input>
          </a-form-model-item>
          <a-form-model-item label="持股状况">
            <a-input :disabled="isView()" v-model="item.holdstockcnt" placeholder="请填写持股状况" suffix="%"></a-input>
          </a-form-model-item>
          <a-form-model-item label="关系客户编号">
            <a-input disabled v-model="item.relacustnum" placeholder="显示关系客户编号"></a-input>
          </a-form-model-item>

          <a-form-model-item ref="name" label="备注">
            <a-textarea :disabled="isView()" v-model="item.memotxt"/>
          </a-form-model-item>
        </div>
        <!-- 对外投资关系 -->
        <div v-for="(item, index) in form.custEandeInfos" :key="'b' + index">
          <div class="conHeader">
            对外投资关系
            <span style="float: right">
              <!--新增按钮 -->
              <a-button v-if="userAddBtn(index) && isView() == false" type="primary"
                        @click="handleAddForm('enterprise')">
                <a-icon type="plus"></a-icon>
              </a-button>
              <!-- 删除按钮 -->
              <a-button v-if="userDelBtn('enterprise') && isView() == false" type="danger"
                        @click="handleDelForm('enterprise', index)">
                <a-icon type="minus"></a-icon>
              </a-button>
            </span>
          </div>
          <a-collapse>
            <a-collapse-panel header="更多信息">
              <a-form-model-item label="客户名称">
                <!-- <a-input :ref="'enterprise-input' + (index + 1)" disabled v-model="item.custname"
                  placeholder="客户名称"></a-input>
                <span style="margin-left: 50px"><a-button type="primary" v-if="!isView()"
                    @click="customEvent(index, 'eande')">选择</a-button></span> -->
                <CustChooseDialog v-model="item.custname" :editable="!isView()" custtypecd="2"
                                  @chooseDone="enterPriseChose" :scope="index"></CustChooseDialog>
              </a-form-model-item>
              <a-form-model-item label="证件">
                <span style="margin-right: 10px">证件类型</span>
                <a-select disabled v-model="item.certtypecd" :options="busiDict.IdentTp" placeholder="请选择证件类型">
                </a-select>
                <span style="margin: 0 10px 0 20px">证件号码</span>
                <a-input disabled v-model="item.certnnum" placeholder="请输入证件号码"></a-input>
                <span style="margin: 0 10px 0 20px">失效时间</span>
                <a-date-picker valueFormat="YYYY-MM-DD" disabled v-model="item.certinvaliddt"/>
              </a-form-model-item>
              <a-form-model-item label="持股状况">
                <a-input :disabled="isView()" v-model="item.holdstockcnt" suffix="%"
                         placeholder="请填写持股状况"></a-input>
              </a-form-model-item>

              <a-form-model-item label="出资相关">
                <div class="contribution">
                  <div class="contributionItem">
                    <span style="margin-right: 10px">出资方式</span>
                    <a-select :disabled="isView()" v-model="item.investtype" placeholder="请选择出资方式"
                              :options="busiDict.InvestType">
                    </a-select>
                  </div>
                  <div class="contributionItem">
                    <span style="margin-right: 10px">币种</span>
                    <a-select :disabled="isView()" v-model="item.investcurrcd" :options="busiDict.InvestCurrCD">
                    </a-select>
                  </div>
                  <div class="contributionItem">
                    <span style="margin-right: 10px">应出资金额</span>
                    <a-input :disabled="isView()" v-model="item.oughtinvestamt" placeholder=""></a-input>
                  </div>
                  <div class="contributionItem">
                    <span style="margin-right: 10px">实际出资金额</span>
                    <a-input :disabled="isView()" v-model="item.actlinvestamt" placeholder=""></a-input>
                  </div>
                  <div class="contributionItem">
                    <span style="margin-right: 10px">出资比例</span>
                    <a-input :disabled="isView()" v-model="item.investratio" placeholder=""></a-input>
                  </div>
                  <div class="contributionItem">
                    <span style="margin-right: 10px">出资日期</span>
                    <a-date-picker valueFormat="YYYY-MM-DD" :disabled="isView()" v-model="item.investdate"/>
                  </div>
                  <div class="contributionItem">
                    <span style="margin-right: 10px">出资人经济成分</span>
                    <a-select :disabled="isView()" v-model="item.investecomncompntcd" placeholder="请选择出资人经济成分"
                              :options="busiDict.EcomnCompntCD">
                    </a-select>
                  </div>
                </div>
              </a-form-model-item>
              <a-form-model-item label="关系客户编号">
                <a-input v-model="item.relacustnum" placeholder="显示客户编号" disabled></a-input>
              </a-form-model-item>

              <a-form-model-item label="关系类型">
                <a-select v-model="item.relatype" :disabled="isView()" placeholder="请选择关系类型"
                          :options="busiDict.RltnpTp">
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="是否有效">
                <a-radio-group :disabled="isView()" v-model="item.validind">
                  <a-radio value="1"> 是</a-radio>
                  <a-radio value="0"> 否</a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="关系到期时间">
                <span style="margin-right: 10px">关系到期时间</span>
                <a-date-picker :disabled="isView()" valueFormat="YYYY-MM-DD" v-model="item.relaexpiresdate"/>
              </a-form-model-item>

              <a-form-model-item ref="name" label="备注">
                <a-textarea :disabled="isView()" v-model="item.memotxt"/>
              </a-form-model-item>
            </a-collapse-panel>
          </a-collapse>
        </div>
        <!-- 关联信息 -->
        <div class="conHeader" v-if="$route.query.type == 'view'">关联信息</div>
        <!-- 用户信息 -->
        <a-form-model-item v-if="$route.query.type == 'view'" ref="name" label="用户信息" prop="name">
          <table class="UserTableBody">
            <thead>
            <tr>
              <th>用户名</th>
              <th>真实姓名</th>
              <th>手机号</th>
              <th>所属机构</th>
              <th>用户状态</th>
              <th>用户类型</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in form.custUserInfos" :key="index">
              <!-- 用户名 -->
              <td>
                <span>{{ item.username }}</span>
              </td>
              <!-- 真实姓名 -->
              <td>
                <span>{{ item.name }}</span>
              </td>
              <!-- 手机号 -->
              <td>
                <span>{{ item.mobile }}</span>
              </td>
              <!-- 用户状态 -->
              <td>
                <span>{{ item.status == false ? "锁定" : "未锁定" }}</span>
              </td>
              <!-- 用户类型 -->
              <td>
                  <span>{{
                      (item.custtypecd == item.custtypecd) == 1 ? "个人" : "对公"
                    }}</span>
              </td>
              <td>
                <a-button @click="userInfoDel(index)" type="primary">删除</a-button>
              </td>
            </tr>
            </tbody>
          </table>
        </a-form-model-item>
        <!-- 账户信息 -->
        <a-form-model-item ref="name" label="账户信息" v-if="$route.query.type == 'view'" prop="name">
          <table class="UserTableBody">
            <thead>
            <tr>
              <th>账号</th>
              <th>户名</th>
              <th>账户类型</th>
              <th>当前余额</th>
              <th>可提现余额</th>
              <th>账户状态</th>
              <th>账户关系</th>
              <th>主账号</th>
              <th>手机号</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in form.custAccountInfos" :key="index">
              <!-- 账号 -->
              <td>
                <span>{{ item.accountNo }}</span>
              </td>
              <!-- 户名 -->
              <td>
                <span>{{ item.accountName }}</span>
              </td>
              <!-- 账户类型 -->
              <td>
                  <span>{{
                      item.accountClass == "0"
                          ? "活期"
                          : item.accountClass == "6"
                              ? "冻结"
                              : item.accountClass == "7"
                                  ? "贷款"
                                  : "---"
                    }}</span>
              </td>
              <!-- 当前余额 -->
              <td>
                <span>{{ item.balance }}</span>
              </td>
              <!-- 可提现余额 -->
              <td>
                <span>{{ item.withdrawBalance }}</span>
              </td>
              <!-- 账户状态 //封装方法-->
              <td>
                <span>{{ handleAccountStatus(item.accountStatus) }}</span>
              </td>
              <!-- 账户关系 -->
              <td>
                  <span>{{
                      item.accountRelation == "0"
                          ? "主"
                          : item.accountRelation == "1"
                              ? "从"
                              : "---"
                    }}</span>
              </td>
              <!-- 主账号 -->
              <td>
                <span>{{ item.majorAccountNo }}</span>
              </td>
              <!-- 手机号 -->
              <td>
                <span>{{ item.mobile }}</span>
              </td>
              <!-- 用户类型 //暂时找不到字段-->
              <!-- <td>
                <span>{{ item.balance }}</span>
              </td> -->
            </tr>
            </tbody>
          </table>
        </a-form-model-item>
      </a-form-model>
    </div>
    <!-- 取消和保存弹框 -->
    <div class="saveBox">
      <a-modal title="提示" :visible="isSave" :body-style="{ textAlign: 'center' }" :centered="true"
               @cancel="handleCancel">
        <template slot="footer">
          <a-button type="primary" @click="handleOk">确认</a-button>
          <a-button @click="handleCancel">取消</a-button>
        </template>
        <p>是否保存客户信息？</p>
      </a-modal>
    </div>
    <div class="cancelBox">
      <a-modal title="提示" :visible="isCancel" :body-style="{ textAlign: 'center' }" :centered="true"
               @cancel="isCancel = false">
        <template slot="footer">
          <a-button type="primary" @click="cancleEvent">确定</a-button>
          <a-button @click="isCancel = false">取消</a-button>
        </template>
        <p>是否取消保存该客户资料？取消后该页面 信息将不会被保存</p>
      </a-modal>
    </div>
    <!-- 个人信息里删除弹框 -->
    <BoxComponentVue v-if="isShowDelete" :acceptInfo="deleteInfo" @delEmitEvent="delEmitEvent"></BoxComponentVue>
  </a-card>
</template>

<script>
import BoxComponentVue from "../../../components/CustComponents/BoxComponent.vue";
import dayjs from "dayjs";
import {handleAccountStatus} from "@/utils/commonMethods";
import {BizDict, BizDictCascade} from "@/utils/bizDict/kvMap";
import uploadImg from "../../../../components/uploadImg/index";
import {getFormatTime} from "@/utils/commonMethods";
import urlConfig from "@/utils/urlConfig";
import CustChooseDialog from "@/view/components/ChooseDialog/CustChooseDialog.vue"
import storage from "@/utils/storage";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

const token = sessionStorage.getItem("token");

const data = [];
export default {
  name: "addEnterprise",
  components: {
    DepartmentChoose,
    uploadImg,
    BoxComponentVue,
    CustChooseDialog,
  },

  data() {
    let checkCustname = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请填写客户名称"));
      } else {
        callback();
      }
    };
    let checkCertinvaliddt = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择失效日期"));
      } else {
        callback();
      }
    };

    let checkCertNum = (rule, value, callback) => {
      // console.log(value, 'value00')
      let custNumReg =
          // /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
          // 营业执照校验
          /^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$/;
      if (value === "") {
        callback(new Error("请填写营业执照编号"));
      } else if (!custNumReg.test(value)) {
        callback(new Error("请输入正确的营业执照"));
      } else {
        callback();
      }
    };
    const columns = [
      {
        title: "No",
        customRender: (text, record, index) =>
            `${Number(this.pageNo - 1) * Number(this.pageSize) + Number(index + 1)
            }`,
        align: "center",
      },
      {
        title: "客户编号",
        dataIndex: "custnum",
        align: "center",
      },
      {
        title: "客户名称",
        dataIndex: "custname",
        align: "center",
      },
      {
        title: "证件号码",
        dataIndex: "certnum",
        align: "center",
      },
      {
        title: "性别",
        dataIndex: "gendercd",
        align: "center",
        customRender: (text) => {
          // console.log(record);
          if (text === "0") {
            return "未知的性别";
          } else if (text === "1") {
            return "男";
          } else if (text === "2") {
            return "女";
          }
        },
      },
      {
        title: "操作",
        scopedSlots: {customRender: "handle"},
        align: "center",
      },
    ];
    return {
      checkCustname,
      checkCertinvaliddt,
      checkCertNum,
      // busiURL: urlConfig.busiURL + "/base/ocr/recognizeIdcard",
      busiURL: urlConfig.busiURL + "/file/manager/upload", // 人像照不用OCR
      licenseBusiURL: urlConfig.busiURL + "/base/ocr/recognizeBusinessLicense",
      headers: {
        token,
      },
      // 机构树形数组
      treeData: [],
      // 平级结构
      institutiontrees: [],
      data,
      columns,
      // 单位与个人信息page参数
      pageNo: 1,
      pageSize: 10,
      total: 20,
      paramsData: {
        // 客户编号
        custnum: "",
        // 客户名称
        custname: "",
        // 所属机构
        departmentId: undefined,
      },
      // rememberPhone: storage.getBoolOrDefault(storage.IS_REMEMBER_ENT_PHONE_NUM,true),
      // 表单数据
      form: {
        // 企业客户基本信息
        companyInfo: {
          // 所属机构
          departmentId: undefined,
          // 客户编号
          custnum: "",
          // 客户名称
          custname: "",
          // 所在国家或地区
          locactyzonecd: "156",
          // 经营范围
          operbiz: "",
          // 单位性质
          unitcharcd: undefined,
          // 经济成分
          ecomncompntcd: undefined,
          // 组织机构类别
          orgcatecd: undefined,
          // 经济性质
          econtypecd: undefined,
          // 国民经济部门
          nationecodeptcd: undefined,
          // 注册资本
          rgstcap: "",
          // 注册资本币种
          rgstcurrcd: "156",
          // 公司成立日期
          corpfounddt: "",
          // 实收资本
          actlrecvcap: " ",
          // 实收资本币种
          actlcurrcd: "156",
          // 上年末企业从业人员
          corpemplypersoncnt: "",
          // 上年末资产总额
          corptotalasset: "",
          // 上年营业收入
          corpbizin: "",
          // 净资产
          netasset: "",
          // 基本账户开户行名称
          basicacctopenbankname: "",
          // 基本账户账号
          basicacctnum: "",
          // 开户许可证号
          basicacctauthcertnum: "",
          // 开户许可证到期日
          basicacctauthmaturedt: "",
          // 贷款卡号
          loancardnum: "",
          // 企业规模
          corpsizecd: undefined,
          // 外汇企业类型
          forgninducorpcd: undefined,
          // 人行金融机构编码
          pbcfinorgcd: "",
          // 金融机构行业分类
          finorginduscd: undefined,
          // 同业客户类型
          finorgcustcd: undefined,
          // 经营状态
          corpoperstatcd: undefined,
          // 客户状态
          custstatuscd: "100",
          // 是否涉农客户
          agrcltrelatedcustind: "",
          // 涉农范围
          agrcltrelatedscope: "",
          // 境内境外
          oversind: "",
          // 是否绿色企业
          greencorpind: "",
          // 是否集团客户
          groupcustind: "",
          // 是否个体工商户
          indvbizind: "",
          // 是否上市公司
          listcorpind: "",
          // 是否小微企业
          microbizind: "",
          // 是否科技企业
          techcorpind: "",
          // 备注
          memotxt: "",
        },
        // 证件信息
        custIdInfo: {
          // 证件号码
          certnum: "",
          // 证件类型
          certtypecd: "2002",
          // 证件生效日期
          certeffectdt: "",
          // 证件失效日期
          certinvaliddt: "",
          // 签发机关名称
          certissueorgname: "国家市场监督管理总局",
        },
        // 影像信息
        custVedioInfos: [],
        // 联系信息
        custContactInfos: [
          {
            // 联系类型
            conttypecd: '105',
            // 联系信息
            continfo: "",//storage.getBoolOrDefault(storage.IS_REMEMBER_ENT_PHONE_NUM,true)?storage.getStringOrDefault(storage.REMEMBERED_ENT_PHONE_NUM,""):"",
            // 主要标志
            majorind: "1",
          },
        ],
        // 地址信息
        custAddressInfos: [
          {
            // 地址类型
            addrtypecd: '307',
            // 国家和地区
            ctyzonecd: '156',
            // 行政区划
            distcd: "",
            // 详细地址
            addrinfo: "",
            // 主要标志
            majorind: "1",
          },
        ],
        // 公司股东与高管信息
        custEandpInfos: [
          {
            // 客户名称
            custname: "",
            // 证件类型
            certtypecd: undefined,
            // 证件号码
            certnnum: "",
            // 证件生效日期
            certeffectdt: "",
            // 证件失效日期
            certinvaliddt: "",
            // 国籍
            nationcd: "156",
            // 性别
            gendercd: "",
            // 职业类型
            careercd: undefined,
            // 联系电话
            continfo: "",
            // 地址信息
            addrinfo: "",
            // 客户编号
            custnum: "",
            // 出资方式
            investtype: undefined,
            // 币种
            investcurrcd: "156",
            // 应出资金额
            oughtinvestamt: "",
            // 实际出资金额
            actlinvestamt: "",
            // 出资比例
            investratio: "",
            // 出资日期
            investdate: "",
            // 担任职务
            holdpos: "",
            // 担任职务时间
            holdposdt: "",
            // 相关行业从业年限
            indusworkyears: "",
            // 持股状况
            holdstockcnt: "",
            // 关系客户编号
            relacustnum: "",
            // 关系类型
            relatype: "",
            // 是否有效
            validind: "1",
            // 关系到期时间
            relaexpiresdate: "9999-12-31",
            // 是否法定代表人
            islegalperson: "1",
            memotxt: "",
          },
        ],
        // 对外投资关系
        custEandeInfos: [
          {
            // 客户名称
            custname: "",
            // 客户编号
            custnum: "",
            // 证件类型
            certtypecd: undefined,
            // 证件号码
            certnnum: "",
            // 证件失效日期
            certinvaliddt: "",
            // 地址信息
            // 出资方式
            investtype: undefined,
            // 币种
            investcurrcd: "156",
            // 应出资金额
            oughtinvestamt: "",
            // 实际出资金额
            actlinvestamt: "",
            // 出资比例
            investratio: "",
            // 出资日期
            investdate: "",
            // 出资人经济成分
            investecomncompntcd: undefined,
            // 持股状况
            holdstockcnt: "",
            // 关系客户编号
            relacustnum: "",
            // 关系类型
            relatype: undefined,
            // 是否有效
            validind: "",
            // 关系到期时间
            relaexpiresdate: "",
            // 备注
            memotxt: "",
          },
        ],
        // 用户信息
        custUserInfos: [],
        // 账户信息
        custAccountInfos: [],
      }, //页面数据

      rules: {
        companyInfo: {
          custname: [
            {required: true, message: "请填写客户名称", trigger: "blur"},
          ],
          departmentId: [
            {required: true, message: "请选择所属机构", trigger: "change"},
          ],
          locactyzonecd: [
            {
              required: true,
              message: "请选择所在国家或地区",
              trigger: "change",
            },
          ],
          operbiz: [
            {required: true, message: "请填写经营范围", trigger: "blur"},
          ],
        },
        //证件校验
        custIdInfo: {
          certnum: [
            {required: true, message: "请填写营业执照号", trigger: "blur"},
          ],
          certtypecd: [
            {required: true, message: "请选择证件类型", trigger: "change"},
          ],
          certeffectdt: [
            {
              required: true,
              message: "请选择证件生效日期",
              trigger: "change",
            },
          ],
          certinvaliddt: [
            {
              required: true,
              validator: checkCertinvaliddt,
              trigger: "change",
            },
            {},
          ],
        },
        // 个人校验
        custEandpRules: {
          custname: [
            {
              required: true,
              validator: checkCustname,
            },
          ],
          certnnum: [
            {
              required: true,
              trigger: "change",
              validator: checkCertNum,
            },
          ],
        },
        // custVedioInfos: [{ required: true, message: "", type: 'array' }],
        // license: [{ required: true, message: '', trigger: 'blur' }],
        custContactInfos: [{required: true, message: "", type: 'array'}],
        custAddressInfos: [{required: true, message: "", type: 'array'}],
      }, //验证
      // 是否显示选择客户弹框
      isShowUser: false,
      labelCol: {span: 3},
      wrapperCol: {span: 14},
      isSave: false,
      isCancel: false,
      loading: false,
      Upimgshow: "",
      Upimgedit: false, // 正
      UpimgfilePath: "",
      Backimgshow: "",
      Backimgedit: false, // 反
      BackimgefilePath: "",
      license: "",
      licenseedit: false, // 营业执照
      licensefilePath: "",
      getUpFile: "",
      getBackFile: "",
      getLicense: "",
      upId: "",
      backId: "",
      businessId: "",
      selectType: "",
      selectedIndex: "",
      isValidated: false,
      isShowOpreation: false,
      checkBoxValue: false,
      // 是否展示删除弹框
      isShowDelete: false,
      deleteInfo: {
        boolean: true,
        msg: "是否取消关联该用户信息？",
        type: "delete",
      },
      delConnectUserIndex: "",
      contactRemindText: "",//客户联系信息表格校验的提示语
      addressRemindText: "",//客户地址信息表格校验的提示语
    };
  },
  computed: {
    title() {
      return function () {
        if (this.$route.query.type == "add") {
          return "企业客户管理>新增";
        } else if (this.$route.query.type == "edit") {
          return "企业客户管理>编辑";
        } else if (this.$route.query.type == "view") {
          return "企业客户管理>查看";
        }
      };
    },
    isView() {
      return function () {
        return this.$route.query.type == "view";
      };
    },
    // 增加按钮
    userAddBtn() {
      return function (index) {
        // console.log(index)
        return index == 0;
      };
    },
    // 是否显示删除按钮
    userDelBtn() {
      return function (type) {
        if (type == "user") {
          // 公司与高管信息
          if (this.form.custEandpInfos.length == 1) {
            return false;
          } else {
            return true;
          }
        } else if (type == "enterprise") {
          // 对外投资关系
          if (this.form.custEandeInfos.length == 1) {
            return false;
          } else {
            return true;
          }
        }
      };
    },
    // 联系信息或者地址信息里的增加按钮
    addBtnShow() {
      return function (index, type) {
        if (type == "custContactInfos") {
          // 联系信息
          return index == this.form.custContactInfos.length - 1;
        } else if (type == "custAddressInfos") {
          // 地址信息
          return index == this.form.custAddressInfos.length - 1;
        }
      };
    },
    // 联系信息里的删除按钮
    deleteBtnShow() {
      return function () {
        if (this.form.custContactInfos.length == 1) {
          return false;
        } else {
          return true;
        }
      };
    },
    // 地址信息里的删除按钮
    delAddressBtn() {
      return function () {
        if (this.form.custAddressInfos.length == 1) {
          return false;
        } else {
          return true;
        }
      };
    },
    custNumImageRemindTextShow() {
      return function (type) {
        return false
        //1表示人像面，2表示国徽面，3代表营业执照
        // if (!this.isValidated) {
        //   return false;
        // }
        // if (type == 1) {
        //   return !this.Upimgshow;
        // } else if (type == 2) {
        //   return !this.Backimgshow;
        // } else if (type == 3) {
        //   return !this.license;
        // }
      };
    },
    // 是否禁用失效日期
    isForbidden() {
      return function () {
        if (this.form.custIdInfo.certinvaliddt == "9999-12-31") {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  watch: {
    "form.custIdInfo.certinvaliddt": {
      handler: function (newVal) {
        if (newVal == "9999-12-31") {
          this.checkBoxValue = true;
        } else {
          this.checkBoxValue = false;
        }
      },
      immediate: true,
    },
  },
  created() {
    // console.log(this.$route, 'this.$router')
    this.busiDict = {...BizDict, ...BizDictCascade};
    this.handleAccountStatus = handleAccountStatus;
    // 组织机构下拉框
    if (this.$route.query.type == "add") {
      // 生成时间戳
    } else if (this.$route.query.type == "edit") {
      this.getEnterpriseCustInfo();
    } else if (this.$route.query.type == "view") {
      this.getEnterpriseCustInfo();
      // 编辑页面的时候获取用户信息
    }
  },
  mounted() {
    if (this.$route.query.type != 'add') {
      sessionStorage.setItem('msgInfo', true)
    }
  },
  methods: {
    changeNumber(ind) {
      // console.log('blur', ind)
      let value = this.form.custContactInfos[ind].continfo;
      let regs = /^1[3-9]\d{9}$/;
      if (value != "") {
        if (!regs.test(value)) {
          this.form.custContactInfos[ind].continfo = "";
          return this.$message.warning("请输入有效的手机号!");
        }
      }
      this.onValidateData('cust-contact');

    },
    //控制生效日期和失效日期
    changeCertData(event, type) {
      if (
          this.form.custIdInfo.certeffectdt != "" &&
          this.form.custIdInfo.certinvaliddt != ""
      ) {
        const start = dayjs(this.form.custIdInfo.certeffectdt);
        const end = dayjs(this.form.custIdInfo.certinvaliddt);
        const diffDay = end.diff(start, "day");
        if (diffDay < 0) {
          if (type == "certeffectdt") {
            this.form.custIdInfo.certeffectdt = "";
          } else if (type == "certinvaliddt") {
            this.form.custIdInfo.certinvaliddt = "";
          }
          return this.$message.warning("生效日期不能大于失效日期!");
        }
      }
    },
    // 设置长期日期
    changeData() {
      if (this.checkBoxValue) {
        this.form.custIdInfo.certinvaliddt = "9999-12-31";
        this.$refs.ruleForm.validateField(
            ["custIdInfo.certinvaliddt"]
        );
      } else {
        this.form.custIdInfo.certinvaliddt = "";
      }
    },
    // 添加form表单
    handleAddForm(str) {
      if (str == "user") {
        //
        let obj = {
          // 客户名称
          custname: "",
          // 证件类型
          certtypecd: "",
          // 证件号码
          certnnum: "",
          // 证件生效日期
          certeffectdt: "",
          // 证件失效日期
          certinvaliddt: "",
          // 国籍
          nationcd: "",
          // 性别
          gendercd: "",
          // 职业类型
          careercd: "",
          // 联系电话
          continfo: "",
          // 地址信息
          addrinfo: "",
          // 客户编号
          custnum: "",
          // 出资方式
          investtype: "",
          // 币种
          investcurrcd: "",
          // 应出资金额
          oughtinvestamt: "",
          // 实际出资金额
          actlinvestamt: "",
          // 出资比例
          investratio: "",
          // 出资日期
          investdate: "",
          // 担任职务
          holdpos: "",
          // 担任职务时间
          holdposdt: "",
          // 相关行业从业年限
          indusworkyears: "",
          // 持股状况
          holdstockcnt: "",
          // 关系客户编号
          relacustnum: "",
          // 关系类型
          relatype: "",
          // 是否有效
          validind: "1",
          // 关系到期时间
          relaexpiresdate: "",
          // 是否法定代表人
          islegalperson: "1",
          memotxt: "",
        };
        this.form.custEandpInfos.push(obj);
      } else if (str == "enterprise") {
        let obj = {
          // 客户名称
          custname: "",
          // 证件类型
          certtypecd: "",
          // 证件号码
          certnnum: "",
          // 证件失效日期
          certinvaliddt: "",
          // 地址信息

          // 出资方式
          investtype: "",
          // 币种
          investcurrcd: "",
          // 应出资金额
          oughtinvestamt: "",
          // 实际出资金额
          actlinvestamt: "",
          // 出资比例
          investratio: "",
          // 出资日期
          investdate: "",
          // 出资人经济成分
          investecomncompntcd: "",
          // 持股状况
          holdstockcnt: "",
          // 关系客户编号
          relacustnum: "",
          // 关系类型
          relatype: "",
          // 是否有效
          validind: "1",
          // 关系到期时间
          relaexpiresdate: "",
          // 备注
          memotxt: "",
        };
        this.form.custEandeInfos.push(obj);
      }
      // 调用函数以将页面滚动到底部
      this.scrollToBottom();
    },
    scrollToBottom() {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    },

    //删除form表单
    handleDelForm(type, ind) {
      // console.log(type, ind, '00')
      if (type == "user") {
        // 删除公司与高管信息
        this.form.custEandpInfos.splice(ind, 1);
      } else if (type == "enterprise") {
        // 删除对外投资关系
        this.form.custEandeInfos.splice(ind, 1);
      }
    },
    //获取客户详细信息
    async getEnterpriseCustInfo() {
      let res = await this.api.getEnterpriseCust(this.$route.query.custnum);
      if (res.code == 200) {
        let form = {};
        if (res.data.custCompanyInfo) {
          form.companyInfo = res.data.custCompanyInfo;
        }
        if (res.data.custAccountInfos) {
          form.custAccountInfos = res.data.custAccountInfos;
        }
        if (res.data.custAddressInfos) {
          form.custAddressInfos = res.data.custAddressInfos;
        }
        if (res.data.custContactInfos) {
          form.custContactInfos = res.data.custContactInfos;
        }
        if (res.data.custEandeInfos) {
          form.custEandeInfos = res.data.custEandeInfos;
        }
        if (res.data.custEandpInfos) {
          form.custEandpInfos = res.data.custEandpInfos;
        }
        // 关联用户信息
        if (res.data.custUserInfos) {
          form.custUserInfos = res.data.custUserInfos;
        }
        // 账户信息
        if (res.data.custAccountInfos) {
          form.custAccountInfos = res.data.custAccountInfos;
        }
        // 证件信息
        if (res.data.custIdInfo) {
          form.custIdInfo = res.data.custIdInfo;
        }
        // 影像信息
        if (res.data.custVedioInfos) {
          form.custVedioInfos = this.getCustVideoArr(res.data.custVedioInfos);
          form.custVedioInfos.forEach((item) => {
            if (item.fileType == "01") {
              this.upId = item.id;
            } else if (item.fileType == "02") {
              this.backId = item.id;
            } else if (item.fileType == "12") {
              this.businessId = item.id;
            }
          });
          // 排序
          form.custVedioInfos.sort((image1, image2) => {
            return Number(image1.fileType) - Number(image2.fileType);
          });
        }
        this.form = JSON.parse(JSON.stringify(form));
        // 回显图片
        // this.$refs.IdUp.setImageUrl(this.form.custVedioInfos[0]?.fileNo);
        // this.$refs.IdBack.setImageUrl(this.form.custVedioInfos[1]?.fileNo);
        this.$refs.licenseEvent.setImageUrl(
            this.form.custVedioInfos.find(x => x.fileType == 12)?.fileNo
        );
        // console.log(JSON.parse(JSON.stringify(this.form)))
      } else {
        return this.$message.error("页面加载出错了!");
      }
    },
    async frChosen(cust, i) {
      let res = await this.api.getCustData(cust.custnum);
      if (res.code == 200) {
        this.form.custEandpInfos[i].custname =
            res.data.custPersonInfo.custname;
        this.form.custEandpInfos[i].certnnum = res.data.custIdInfo.certnum;
        this.form.custEandpInfos[i].certtypecd =
            res.data.custIdInfo.certtypecd;
        this.form.custEandpInfos[i].certeffectdt =
            res.data.custIdInfo.certeffectdt;
        this.form.custEandpInfos[i].certinvaliddt =
            res.data.custIdInfo.certinvaliddt;
        this.form.custEandpInfos[i].nationcd =
            res.data.custPersonInfo.nationcd;
        this.form.custEandpInfos[i].gendercd =
            res.data.custPersonInfo.gendercd;
        this.form.custEandpInfos[i].careercd =
            res.data.custPersonInfo.careercd;
        this.form.custEandpInfos[i].continfo =
            res.data.custPersonInfo.continfo;
        this.form.custEandpInfos[i].addrinfo =
            res.data.custPersonInfo.addrinfo;
        this.form.custEandpInfos[i].custnum = res.data.custPersonInfo.custnum;
        this.form.custEandpInfos[i].islegalperson = "1";
      }
    },
    async enterPriseChose(cust, i) {
      let res = await this.api.getEnterpriseCust(cust.custnum);
      if (res.code == 200) {
        // 客户名称
        this.form.custEandeInfos[i].custname =
            res.data.custCompanyInfo.custname;
        // 客户编号
        this.form.custEandeInfos[i].custnum =
            res.data.custCompanyInfo.custnum;
        // 证件类型
        this.form.custEandeInfos[i].certtypecd =
            res.data.custIdInfo?.certtypecd;
        // 证件号码
        this.form.custEandeInfos[i].certnnum = res.data.custIdInfo?.certnum;
        // 失效日期
        this.form.custEandeInfos[i].certinvaliddt =
            res.data.custIdInfo?.certinvaliddt;
      }
    },
    // 选中列表
    async handleButtonClick(record) {
      // console.log(record, 'record')
      let i = this.selectedIndex;
      // console.log(this.selectType, 'this.selectType')
      if (this.selectType == "1") {
        // 个人
        // console.log(this.selectedIndex)
        // 获取个人客户详情
        let res = await this.api.getCustData(record.custnum);
        if (res.code == 200) {
          this.form.custEandpInfos[i].custname =
              res.data.custPersonInfo.custname;
          this.form.custEandpInfos[i].certnnum = res.data.custIdInfo.certnum;
          this.form.custEandpInfos[i].certtypecd =
              res.data.custIdInfo.certtypecd;
          this.form.custEandpInfos[i].certeffectdt =
              res.data.custIdInfo.certeffectdt;
          this.form.custEandpInfos[i].certinvaliddt =
              res.data.custIdInfo.certinvaliddt;
          this.form.custEandpInfos[i].nationcd =
              res.data.custPersonInfo.nationcd;
          this.form.custEandpInfos[i].gendercd =
              res.data.custPersonInfo.gendercd;
          this.form.custEandpInfos[i].careercd =
              res.data.custPersonInfo.careercd;
          this.form.custEandpInfos[i].continfo =
              res.data.custPersonInfo.continfo;
          this.form.custEandpInfos[i].addrinfo =
              res.data.custPersonInfo.addrinfo;
          this.form.custEandpInfos[i].custnum = res.data.custPersonInfo.custnum;
          this.form.custEandpInfos[i].islegalperson = "1";
          // this.$refs.ruleForm.validateField(["custEandpRules.certnnum"]);
          // "custEandpRules.certnnum"
          // async (errorMsg) => {
          //   if (errorMsg) {
          //     // 校验未通过
          //     // console.log(errorMsg)
          //   } else {
          //     console.log('111');
          //   }
          //   //之后的操作
          // }

          // 提示成功
          this.$message.success(res.info);
        } else {
          return this.$message.error(res.info);
        }
      } else if (this.selectType == "2") {
        // 单位
        // 获取企业客户详情
        // console.log('企业')
        let res = await this.api.getEnterpriseCust(record.custnum);
        if (res.code == 200) {
          // console.log(res.data, 'enterprise')
          // 客户名称
          this.form.custEandeInfos[i].custname =
              res.data.custCompanyInfo.custname;
          // 客户编号
          this.form.custEandeInfos[i].custnum =
              res.data.custCompanyInfo.custnum;
          // 证件类型
          this.form.custEandeInfos[i].certtypecd =
              res.data.custIdInfo?.certtypecd;
          // 证件号码
          this.form.custEandeInfos[i].certnnum = res.data.custIdInfo?.certnum;
          // 失效日期
          this.form.custEandeInfos[i].certinvaliddt =
              res.data.custIdInfo?.certinvaliddt;
          // 提示信息
          this.$message.success(res.info);
        } else {
          return this.$message.error(res.info);
        }
      }
      // this.form = JSON.parse(JSON.stringify(this.form))
      // this.$nextTick(() => {
      //   if (this.selectType == "1") {
      //     let persionalInputDoms =
      //       this.$refs["persional-input" + (this.selectedIndex + 1)];

      //     persionalInputDoms[0].onBlur();
      //   } else if (this.selectType == "2") {
      //     let enterpriseInputDoms =
      //       this.$refs["enterprise-input" + (this.selectedIndex + 1)];
      //     enterpriseInputDoms[0].onBlur();
      //   }
      // });
      this.isShowUser = false;
      this.isValidated = true;
      this.$refs.ruleForm.validate(async (valid) => {
        // console.log(valid, 'valid');
        if (valid) {
          if (
              this.custNumImageRemindTextShow(1) ||
              this.custNumImageRemindTextShow(2)
          ) {
            console.log("success");
          }
        } else {
          console.log("error submit!!");
        }
      });
    },
    // 重置按钮
    reSet() {
      this.paramsData = {
        // 客户编号
        custnum: "",
        // 客户名称
        custname: "",
        // 所属机构
        departmentId: "",
      };
      this.handleQuery();
    },
    // 查询按钮
    handleQuery() {
      this.pageNo = 1;
      if (this.selectType == "1") {
        this.getPersonList();
      } else if (this.selectType == "2") {
        // 单位
        this.getEnterpriseList();
      }
    },
    async getPersonList() {
      // 调用个人客户接口
      let data = {};
      data.pageNo = this.pageNo;
      data.pageSize = this.pageSize;
      data.obj = JSON.parse(JSON.stringify(this.paramsData));
      let res = await this.api.custPage(data);
      console.log(res, "eandp");
      if (res.code == 200) {
        this.pageNo = res.data.current;
        this.pageSize = res.data.size;
        this.total = res.data.total;
        this.data = res.data.records;
      }
    },
    async getEnterpriseList() {
      // 企业
      let data = {};
      data.pageNo = this.pageNo;
      data.pageSize = this.pageSize;
      data.obj = JSON.parse(JSON.stringify(this.paramsData));
      let res = await this.api.custEnterprise(data);
      // console.log(res, 'eande')
      if (res.code == 200) {
        this.pageNo = res.data.current;
        this.pageSize = res.data.size;
        this.total = res.data.total;
        this.data = res.data.records;
      }
    },
    // 点击选择获取个人与单位、单位与单位page
    customEvent(ind, str) {
      // console.log(ind,str);
      // 选中客户的索引
      this.selectedIndex = ind;
      this.pageNo = 1;
      if (str == "eandp") {
        // 个人
        this.$set(this.columns, 4, {
          title: "性别",
          dataIndex: "gendercd",
          align: "center",
          customRender: (text) => {
            // console.log(text);
            if (text === "0") {
              return "未知的性别";
            } else if (text === "1") {
              return "男性";
            } else if (text === "2") {
              return "女性";
            }
          },
        });
        this.selectType = "1";
        this.getPersonList();
      } else if (str == "eande") {
        // 调用单位与单位接口
        this.$set(this.columns, 4, {});
        this.selectType = "2";
        this.getEnterpriseList();
      }
      // 点击客户名称后面的选择按钮，显示弹框，发送请求
      this.isShowUser = true;
    },
    // 分页
    changeSize(page) {
      this.pageNo = page;
      if (this.selectType == "1") {
        this.getPersonList();
      } else if (this.selectType == "2") {
        this.getEnterpriseList();
      }
    },
    // 首页和尾页方法
    jumpMethods(type) {
      // console.log(type);
      if (type === "home") {
        // 首页
        this.pageNo = 1;
        if (this.selectType == "1") {
          this.getPersonList();
        } else if (this.selectType == "2") {
          this.getEnterpriseList();
        }
      } else if (type === "last") {
        // 尾页
        this.pageNo = Math.ceil(this.total / this.pageSize);
        if (this.selectType == "1") {
          this.getPersonList();
        } else if (this.selectType == "2") {
          this.getEnterpriseList();
        }
      }
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    // 联系信息添加方法
    handleAddContactInfo() {
      let obj = {
        // 联系类型
        conttypecd: '105',
        // 联系信息
        continfo: "",
        // 主要标志
        majorind: "1",
      };
      this.form.custContactInfos.push(obj);
      this.onValidateData('cust-contact');

    },
    // 联系信息删除方法
    handleDelContactInfo(index) {
      this.form.custContactInfos.splice(index, 1);
      this.onValidateData('cust-contact');

    },
    // 地址信息添加方法
    handleAddress() {
      let obj = {
        // 地址类型
        addrtypecd: '307',
        // 国家和地区
        ctyzonecd: '156',
        // 行政区划
        distcd: undefined,
        // 详细地址
        addrinfo: "",
        // 主要标志
        majorind: "",
      };
      this.form.custAddressInfos.push(obj);
      this.onValidateData('cust-address');

    },
    // 地址信息删除
    delAdress(ind) {
      this.form.custAddressInfos.splice(ind, 1);
      this.onValidateData('cust-address');

    },
    // 用户信息删除
    userInfoDel(index) {
      // console.log(index)
      this.delConnectUserIndex = index;
      this.isShowDelete = true;
    },
    // 删除弹框自定义事件
    delEmitEvent(obj) {
      // console.log(obj, "obj");
      if (obj.type == "ok") {
        // 删除操作
        this.delPersonConnectUser();
      }
      // 关闭弹框
      this.isShowDelete = false;
    },
    // 调用删除关联用户接口
    async delPersonConnectUser() {
      let idArr = [];
      idArr.push(this.form.custUserInfos[this.delConnectUserIndex].id);
      // console.log(idArr)
      let res = await this.api.delPersonConnectUserApi(idArr);
      if (res.code == 200) {
        this.form.custUserInfos.splice(this.delConnectUserIndex, 1);
        return this.$message.success(res.info);
      } else {
        return this.$message.error(res.info);
      }
    },
    // 点击保存和取消按钮事件
    handleEvent(info) {
      // console.log(info, 'baocun');
      // 显示弹框
      this.visible = true;
      if (info === "save") {
        // 保存按钮
        this.isSave = true;
      } else if (info === "cancel") {
        // 取消按钮
        this.isSave = false;
      }
    },
    // 保存接口
    async saveEvent() {
      // 公司股东与高管信息关系类型
      this.form.custEandpInfos.forEach((item) => {
        if (item.islegalperson == "1") {
          item.relatype = "0100";
        } else if (item.islegalperson == "0") {
          item.relatype = "0113";
        }
        item.indusworkyears = Number(item.indusworkyears);
      });
      if (this.form.custEandpInfos.length == 1) {
        if (this.form.custEandpInfos[0].custname == "") {
          this.form.custEandpInfos = [];
        }
      }
      if (this.form.custEandeInfos.length == 1) {
        if (this.form.custEandeInfos[0].custname == "") {
          this.form.custEandeInfos = [];
        }
      }

      // 影像信息
      this.form.custVedioInfos = [];
      if (this.getUpFile) {
        this.form.custVedioInfos.push(this.getImage("image_up"));
      }
      if (this.getBackFile) {
        this.form.custVedioInfos.push(this.getImage("image_back"));
      }
      if (this.getLicense) {
        this.form.custVedioInfos.push(this.getImage("business_license"));
      }

      // 统一给归属机构赋值
      let deptId = this.form.companyInfo.departmentId;
      this.form.custAddressInfos.forEach(
          (item) => (item.departmentId = item.departmentId || deptId)
      );
      this.form.custContactInfos.forEach(
          (item) => (item.departmentId = item.departmentId || deptId)
      );
      this.form.custEandeInfos.forEach(
          (item) => (item.departmentId = item.departmentId || deptId)
      );
      this.form.custEandpInfos.forEach(
          (item) => (item.departmentId = item.departmentId || deptId)
      );
      this.form = JSON.parse(JSON.stringify(this.form));
      let res = await this.api.addEnterprise(this.form);
      if (res.code === 200) {
        this.$message.success(res.info);

        let that = this
        this.$confirm({
          title: '是否要开钱包？',
          content: '是否需要开钱包',
          okText: '现在去',
          cancelText: '以后',
          // 跳转到钱包开立
          onOk() {
            that.$router.replace({
              name: 'CorporateAccountOpening',
              params: {
                type: "add",
                custnum: res.data.custnum,
              }
            })
          },
          onCancel() {
            let query = {}
            if (that.$route.query.type === 'add') {
              query.fromAddPage = true
            }
            that.$router.replace({name: "Enterprise", query});
          },
        })
      } else {
        return this.$message.error(res.info);
      }
      // console.log(res, 'entireprise')
    },
    onSubmit() {
      this.isValidated = true;
      this.onValidateData('cust-contact');

      // 是否记住手机号
      // storage.set(storage.IS_REMEMBER_ENT_PHONE_NUM, this.rememberPhone);
      // if(this.rememberPhone){
      //   storage.set(storage.REMEMBERED_ENT_PHONE_NUM, this.form.custContactInfos[0]?.continfo);
      // }else{
      //   storage.del(storage.REMEMBERED_ENT_PHONE_NUM);
      // }

      this.onValidateData('cust-address');
      this.$refs.ruleForm.validate(async (valid) => {
        // console.log(valid, 'valid');
        if (valid) {
          if (
              this.custNumImageRemindTextShow(1) ||
              this.custNumImageRemindTextShow(2)
          ) {
            this.isSave = false;
            return;
          }
          // 校验成功调用保存接口
          await this.saveEvent();
          // 跳转到客户管理页面
          // this.$router.go(-1)
        } else {
          console.log("error submit!!");
        }
        // 关闭弹框
        this.isSave = false;
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    //校验表格中的数据
    onValidateData(type) {
      if (type == "cust-contact") {
        let phoneIndexArr = [];
        for (let i = 0; i < this.form.custContactInfos.length; i++) {
          if (this.form.custContactInfos[i].continfo == "") {
            phoneIndexArr.push(i + 1);
          }
        }
        if (phoneIndexArr.length > 0) {
          this.contactRemindText = "请填写客户的手机号码";
          // this.contactRemindText = "第" + phoneIndexArr.toString() + "个客户的手机号码为空";
        } else {
          this.contactRemindText = "";
        }
      }
      if (type == "cust-address") {
        let distIndexArr = [];
        let addrIndexArr = [];
        for (let i = 0; i < this.form.custAddressInfos.length; i++) {
          if (this.form.custAddressInfos[i].distcd == "") {
            distIndexArr.push(i + 1);
          }
          if (this.form.custAddressInfos[i].addrinfo == "") {
            addrIndexArr.push(i + 1);
          }
        }

        if (distIndexArr.length > 0 && addrIndexArr.length > 0) {
          this.addressRemindText = "请填写客户的行政区划和详细地址";
          // this.contactRemindText = "第" + phoneIndexArr.toString() + "个客户的手机号码为空";
        } else if (distIndexArr.length > 0 && addrIndexArr.length == 0) {
          this.addressRemindText = "请填写客户的行政区划";
        } else if (distIndexArr.length == 0 && addrIndexArr.length > 0) {
          this.addressRemindText = "请填写客户的详细地址";
        } else {
          this.addressRemindText = "";
        }
      }
    },
    // 弹框确定按钮
    handleOk() {
      console.log("ok");
      // 进行表单校验
      this.onSubmit();
    },
    // 弹框取消按钮
    handleCancel() {
      console.log("cancel");
      this.isSave = false;
      // this.resetForm();
    },
    cancleEvent() {
      this.isCancel = false;

      this.$router.go(-1)
    },

    // 删除上传文件
    async opreatDel(type) {
      if (this.$route.query.type == "add") {
        // 直接删除不调接口
        if (type == "up") {
          //删除人像面
          this.Upimgshow = false;
        } else if (type == "back") {
          // 删除国徽面
          this.Backimgshow = false;
        } else {
          this.license = false;
        }
      } else if (this.$route.query.type == "edit") {
        // console.log('edit')
        // 调用接口
        let idArr = [];
        let id =
            type == "up"
                ? this.form.custVedioInfos[0].id
                : type == "back"
                    ? this.form.custVedioInfos[1].id
                    : this.form.custVedioInfos[2].id;
        idArr.push(id);
        let res = await this.api.delImageApi(idArr);
        if (res.code == 200) {
          if (type == "up") {
            this.Upimgshow = false;
            this.getUpFile = "";
            this.upId = "";
          } else if (type == "back") {
            this.Backimgshow = false;
            this.getBackFile = "";
            this.backId = "";
          } else {
            this.license = false;
            this.getLicense = "";
            this.businessId = "";
          }
          return this.$message.success(res.info);
        } else {
          return this.$message.error(res.info);
        }
      }
    },
    //获取图片对象
    getImage(type) {
      let image = {};
      image.departmentId = this.form.companyInfo.departmentId;
      image.custnum = this.form.companyInfo.custnum;
      image.fileNo = "";
      image.filePath = "";
      image.memotxt = "";
      // image.createTime = ''
      image.createTime = getFormatTime(new Date(), "yyyy-mm-dd HH:mm:ss");
      image.creater = sessionStorage.getItem("username");
      image.updateTime = "";
      // image.updateTime = getFormatTime(new Date(), 'yyyy-mm-dd');
      image.updater = sessionStorage.getItem("username");
      image.recordStatus = 0;
      if (type == "image_up") {
        image.fileType = "01";
        image.id = this.$route.query.type == "edit" ? this.upId : "";
        image.fileNo = this.getUpFile;
      } else if (type == "image_back") {
        image.fileType = "02";
        image.id = this.$route.query.type == "edit" ? this.backId : "";
        image.fileNo = this.getBackFile;
      } else if (type == "business_license") {
        image.fileType = "12";
        image.id = this.$route.query.type == "edit" ? this.businessId : "";
        image.fileNo = this.getLicense;
      }
      return image;
    },
    getBasicVideo(fileType) {
      let image = {};
      image.departmentId = this.form.companyInfo.departmentId;
      image.custnum = this.form.companyInfo.custnum;
      image.fileType = fileType;
      image.fileNo = "";
      image.filePath = "";
      image.memotxt = "";
      image.createTime = "";
      // image.createTime = getFormatTime(new Date(), 'yyyy-mm-dd');
      image.creater = sessionStorage.getItem("username");
      image.updateTime = "";
      // image.updateTime = getFormatTime(new Date(), 'yyyy-mm-dd');
      image.updater = sessionStorage.getItem("username");
      image.recordStatus = 0;
      image.fileNo = "";
      return image;
    },
    getCustVideoArr(videos) {
      let newVideos = [];
      if (videos.length == 0) {
        newVideos.push(this.getBasicVideo("01"));
        newVideos.push(this.getBasicVideo("02"));
      } else if (videos.length == 1) {
        if (videos[0].fileType == "01") {
          newVideos = JSON.parse(JSON.stringify(videos));
          newVideos.push(this.getBasicVideo("02"));
        } else {
          newVideos.push(this.getBasicVideo("02"));
          newVideos.push(JSON.parse(JSON.stringify(videos[0])));
        }
      } else {
        newVideos = JSON.parse(JSON.stringify(videos));
      }
      return newVideos;
    },
    // 上传之前的处理
    handleBeforeUpload(file) {
      // console.log(file, 'file');
      const isLt1M = file.size / 1024 / 1024 > 1
      if (isLt1M) {
        return this.compressImage(file);
      }
      // if (!isLt2M) {
      //   this.$message.error('上传文件大小不能超过 2MB!')
      //   return false
      // }
      return true
    },
    async compressImage(file) {
      // console.log(file, 'compress');
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 1920; // 设置压缩后的图像宽度
            const maxHeight = 1080; // 设置压缩后的图像高度

            let newWidth = img.width;
            let newHeight = img.height;

            if (img.width > maxWidth) {
              newWidth = maxWidth;
              newHeight = (img.height * maxWidth) / img.width;
            }

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              newWidth = (img.width * maxHeight) / img.height;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // 将压缩后的图像转换为 Blob 对象
            canvas.toBlob(
                (blob) => {
                  const compressedFile = new File([blob], file.name, {
                    type: 'image/jpeg',
                  });
                  resolve(compressedFile);
                },
                'image/jpeg',
                0.9
            );
          };
        };
      });
    },
    // 上传身份证正面
    changeIdUpload(info) {
      if (this.Upimgedit) {
        this.$refs.IdUp.handleChangeUpload(info, "Edit");
      } else {
        this.$refs.IdUp.handleChangeUpload(info, "");
      }
    },
    // 身份证反面
    changeIdBack(info) {
      // console.log(info, 'info')
      if (this.Backimgedit) {
        this.$refs.IdBack.handleChangeUpload(info, "Edit");
      } else {
        this.$refs.IdBack.handleChangeUpload(info, "");
      }
    },

    // 营业执照
    changeLicense(info) {
      if (this.licenseedit) {
        this.$refs.licenseEvent.handleChangeUpload(info, "Edit");
      } else {
        this.$refs.licenseEvent.handleChangeUpload(info, "");
      }
    },
    // 拿到识别到的数据
    completedUpload(ocrInfo) {
      const {ocrCompanyResult: result} = ocrInfo.data
      // 客户名称
      this.form.companyInfo.custname = result.companyName
      // 证件号码
      this.form.custIdInfo.certnum = result.creditCode
      // 证件生效日期
      this.form.custIdInfo.certeffectdt = result.validFromDate.replaceAll(/(\d{4})\D*(\d{2})\D*(\d{2})/g, "$1-$2-$3")
      // 证件失效日期
      this.form.custIdInfo.certinvaliddt = result.validToDate.replaceAll(/(\d{4})\D*(\d{2})\D*(\d{2})/g, "$1-$2-$3")
      // 经营范围
      this.form.companyInfo.operbiz = result.businessScope
      this.form.custAddressInfos[0].addrinfo = result.businessAddress
      this.addressNormalization()
    },
    async addressNormalization() {
      let res = await this.api.addressNormalization(this.form.custAddressInfos[0].addrinfo)
      if (res.code === 200) {
        let area = this.busiDict.DstcCd.find(prov => prov.label === res.data.prov)
            ?.children.find(city => city.label === res.data.city)
            ?.children.find(district => district.label === res.data.district)
        this.form.custAddressInfos[0].distcd = area.value
      }
    },
    // 身份正面修改
    UpimgEdit(type) {
      const inputElement =
          this.$refs.uploadRef.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
      this.Upimgedit = type;
    },

    // 身份反面修改
    BackimgEdit(type) {
      const inputElement =
          this.$refs.Backimg.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
      this.Backimgedit = type;
    },
    // 营业执照
    licenseimgEdit(type) {
      const inputElement =
          this.$refs.license.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
      this.licenseedit = type;
    },

    // 编辑图片接口
    async CallEditimgApi(type) {
      // 调用接口修改成功后改变状态
      let data = {};
      data.departmentId = this.form.companyInfo.departmentId;
      data.createTime = "";
      if (type == "up") {
        data.id = this.upId;
        (data.fileNo = this.getUpFile),
            (data.fileType = "01"),
            (data.filePath = this.UpimgfilePath);
      } else if (type == "back") {
        data.id = this.backId;
        (data.fileNo = this.getBackFile),
            (data.fileType = "02"),
            (data.filePath = this.BackimgefilePath);
      } else if (type == "business") {
        data.id = this.businessId;
        (data.fileNo = this.getLicense),
            (data.fileType = "12"),
            (data.filePath = this.licensefilePath);
      }
      const res = await this.api.editImageApi(data);
      if (res.code == 200) {
        if (type == "up") {
          this.Upimgedit = false;
        }
        if (type == "back") {
          this.Backimgedit = false;
        }
        if (type == "business") {
          this.licenseedit = false;
        }
      }
    },
    // 取消事件
    clickCancel() {
      if (this.$route.query.type == "view") {
        this.$router.go(-1);
      } else {
        this.isCancel = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}

.check-box {
  margin-left: 50px;
}


.container {
  margin-bottom: 20px;

  .conHeader {
    margin: 20px 0;
    color: #000;
  }

  .contribution {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    .contributionItem {
      margin-right: 10px;
    }
  }
}

/deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;

  .el-input__inner,
  .el-cascader {
    height: 32px;
  }

  .ant-form-item-label {
    width: 260px;
    background-color: rgba(242, 242, 242, 1);
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
    border: 1px solid rgba(242, 242, 242, 1);
    padding: 5px 40px;

    .ant-input {
      width: 200px;
    }

    .ant-input-affix-wrapper {
      width: 200px;
    }

    .ant-select {
      width: 200px;
    }

    textarea {
      resize: none;
      width: 610px !important;
      height: 137px;
    }
  }

  .tableBody {
    min-width: 500px;
    border: 1px solid #f2f2f2;

    thead {
      tr:nth-child(1) {
        background-color: #f2f2f2;
        text-align: center;
        height: 30px;
      }
    }

    .ant-select-selection {
      border: none;
    }

    .el-input__inner {
      border: none;
    }

    tbody {
      .ant-input {
        border-radius: 0;
        border: none;
      }

      td {
        border-right: 1px solid #f2f2f2;
      }

      td:last-child {
        width: 100px;
        text-align: center;

        button {
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #aaaaaa;
          background-color: #fff;
          text-align: center;
          line-height: 20px;
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }

    .el-input.is-disabled .el-input__inner {
      background-color: #f5f5f5;
    }

    .el-input__inner {
      border: none;
    }

    .ant-select-selection {
      border: none;
    }

    .ant-input {
      // border: none;
      border-radius: 0;
      border: 1px solid #f2f2f2;
      border-bottom: none;
    }
  }

  .UserTableBody {
    min-width: 800px;
    border: 1px solid #f2f2f2;

    thead {
      tr:nth-child(1) {
        background-color: #f2f2f2;
        text-align: center;
        height: 30px;
      }
    }

    tbody {
      td {
        border-right: 1px solid #f2f2f2;
        text-align: center;
      }
    }
  }

  .cust-contact-remind-area {
    width: 100%;
    height: 40px;
    position: relative;

    .remind-text {
      color: red;
    }
  }

  .cust-address-remind-area {
    width: 100%;
    height: 40px;
    position: relative;

    .remind-text {
      color: red;
    }
  }

  .remind-text-area {
    width: 100%;
    height: 40px;
    position: relative;

    .remind-text {
      color: red;
    }

    .remind-text1 {
      position: absolute;
      left: 175px;
      top: 0px;
    }

    .remind-text2 {
      position: absolute;
      left: 560px;
      top: 0px;
    }
  }
}

/deep/ .ant-modal-content {
  width: 100%;
}

/deep/ .boxHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  .ant-input {
    width: 200px;
  }

  .ant-select-selection {
    width: 200px;
  }

  .boxItem {
    margin-right: 20px;
  }
}

/deep/ .certtypecd-style {
  .ant-select-arrow {
    display: none;
  }
}

/deep/ .paginationBox {
  margin: 10px 0;
  display: flex;
  justify-content: end;

  .ant-pagination-options {
    float: left;
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }

  .page-item {
    border: 1px solid #d9d9d9;
    width: 60px;
    text-align: center;
    border-radius: 5px;
    line-height: 30px;
    margin: 0 8px;
    cursor: pointer;
  }
}

.pricebox {
  width: 100%;

  /deep/ .ant-form-item-label {
    min-height: 180px;
    padding-left: 30px;
    display: flex;
    align-items: center;
  }

  /deep/ .ant-form-item-control-wrapper {
    min-height: 180px;
  }

  .row {
    display: flex;
    margin-top: 25px;

    .item {
      //margin-left: 50px;
      display: flex;
      // width: 600px;
      align-items: center;

      /deep/ .ant-upload {
        width: 200px;
        height: 122px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        padding: 0px;
        overflow: hidden;
        border: 1px dashed #d9d9d9 !important;
        background-color: #fafafa !important;

        .anticon-plus {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .ant-upload.ant-upload-select-picture-card {
        border: 1px solid #ccc;
      }

      .ant-upload.ant-upload-select-picture-card > .ant-upload {
        padding: 0;
      }

      .ant-upload-select-picture {
        width: 200px;
        height: 122px;
        border: 0;
      }
    }
  }
}
</style>
