<template>
  <div>
    <!-- 商户管理page页面 -->
    <a-card title="商户管理" style="margin: 20px">
      <!-- 筛选区域 -->
      <div class="selectBox">
        <div class="item">
          <span>所属机构</span>
          <DepartmentChoose :department-code.sync="paramsData.departmentCode" @change="handleQuery"></DepartmentChoose>
        </div>
        <div class="item">
          <span>商户编号</span>
          <a-input v-model.trim="paramsData.mchNo" placeholder="请输入商户编号"/>
        </div>
        <div class="item">
          <span>商户名称</span>
          <a-input v-model.trim="paramsData.mchName" placeholder="请输入商户名称"/>
        </div>
        <div class="item">
          <span>商户类型</span>
          <a-select v-model="paramsData.mchType">
            <a-select-option value=""> 全部</a-select-option>
            <a-select-option value="0">
              <a-tag color="blue">回款商户</a-tag>
            </a-select-option>
            <a-select-option value="1">
              <a-tag color="purple">融资商户</a-tag>
            </a-select-option>
          </a-select>
        </div>
        <!-- 产品类型 -->
        <div class="item">
          <span>商品类型</span>
          <a-select v-model="paramsData.goodsType">
            <a-select-option value=""> 全部</a-select-option>
            <a-select-option v-for="(type, value) in commonDict.goodsTypeConfig" :key="value" :value="value">
              <a-tag v-if="type.show" :color="type.color">{{ type.label }}</a-tag>
            </a-select-option>
          </a-select>
        </div>
        <div class="item">
          <a-button type="primary" @click="handleQuery" icon="search">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </div>
      </div>
      <!-- 表格部分 -->
      <div class="tableBox">
        <!-- 操作按钮 -->
        <div class="handleBtn">
          <a-button type="primary" v-if="$authority('mchAdd')">
            <router-link :to="{ name: 'AddMchManage', query: { type: 'add' } }" target="_self"
                         rel="opener">新增
            </router-link>
          </a-button>
          <a-button type="primary" :disabled="selectedRowKeys.length==0 || selectCustData[0]?.checkStatus=='1'"
                    @click="handleBtn('edit')"
                    icon="edit" v-if="$authority('mchEdit')">编辑
          </a-button>
          <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleBtn('view')"
                    icon="eye" v-if="$authority('mchView')">查看
          </a-button>
          <a-button type="danger" :disabled="!selectedRowKeys.length" @click="handleBtn('del')"
                    icon="delete" v-if="$authority('mchDel')">删除
          </a-button>
          <!-- <a-button type="primary" @click="approve" v-if="$authority('mchPush')">审批</a-button> -->
          <a-button type="primary" :disabled="selectedRowKeys.length==0" @click="approve" v-if="$authority('mchAudit')"
                    icon="audit">审批
          </a-button>

        </div>
        <!-- 表格 -->
        <div class="tableBody">
          <a-table size="small" :scroll="{ x: 2000, }" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :columns="columns" :customRow="clickrow" :data-source="data" :pagination="false"
                   :rowKey="(record, index) => `${record.id}`">
          </a-table>
        </div>
      </div>
      <lcfs-pagination :page-no.sync="pagination.pageNo" :page-size.sync="pagination.pageSize"
                       :total="total" :fn="getTableData"></lcfs-pagination>
      <!-- 删除弹框 -->
      <a-modal title="提示" :visible="isVisibleDelDialog" @cancel="isVisibleDelDialog = false" :centered="true"
               :body-style="{ textAlign: 'center' }">
        <template slot="footer">
          <a-button type="primary" @click="handleOk">确认</a-button>
          <a-button @click="isVisibleDelDialog = false">取消</a-button>
        </template>
        <p>是否确认删除所勾选数据？删除后数据将不可恢复</p>
      </a-modal>
    </a-card>
  </div>
</template>
<script>
import {message} from "ant-design-vue";
import {BizDict, BizDictCascade} from "@/utils/bizDict/kvMap";
import WalletInfo from '@/view/components/WalletInfo.vue'
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";
import LcfsPagination from "@/view/components/LcfsPagination.vue";
import ChannelInfo from "@/view/components/ChannelInfo.vue";
import {commonDict} from "@/utils/bizDict/commonDict.js";

const data = [];
export default {
  components: {LcfsPagination, DepartmentChoose, WalletInfo, ChannelInfo},
  name: "MchManage",
  data() {
    const columns = [
      {
        title: "No",
        customRender: (text, record, index) =>
            `${Number(this.pagination.pageNo - 1) *
            Number(this.pagination.pageSize) +
            Number(index + 1)
            }`,
        align: "center",
        width: 60,
      },

      {
        title: "类型",
        dataIndex: "mchType",
        align: "center",
        width: 60,
        customRender: (text) => {
          if (text === "0") {
            return <a-tag color="blue">回款</a-tag>
          } else if (text === "1") {
            return <a-tag color="purple">融资</a-tag>
          } else {
            return "";
          }
        },
      },
      {
        title: "商户编号",
        dataIndex: "mchNo",
        align: "center",
        width: 120,
      },
      {
        title: "商户",
        dataIndex: "mchName",
        align: "center",
      },
      {
        title: "商户主体",
        dataIndex: "accountName",
        align: "center",
        // width: 260,
        customRender: (text, row) => {
          if (row.custtypecd === "2") {
            return <span><a-tag>企</a-tag>
              {text}</span>
          } else {
            return text
          }
        }
      },
      {
        title: "主体钱包",
        dataIndex: "accountNoA1",
        width: 220,
        ellipsis: true,
        align: "center",
        customRender: (text, row) => {
          return <WalletInfo accountNo={text} accountClass='0' accountName={row.accountName}></WalletInfo>
        }
      },
      {
        title: "回款商户",
        dataIndex: "fatherName",
        width: 260,
        ellipsis: true,
        align: "center",
      },
      {
        title: "商品类型",
        dataIndex: "goodsType",
        align: "center",
        width: 100,
        ellipsis: true,
        customRender: (text) => {
          const type = commonDict.goodsTypeConfig[text] || commonDict.goodsTypeConfig.default;
          return (<a-tag color={type.color}>{type.label}</a-tag>);
        }
      },
      {
        title: "回款周期",
        dataIndex: "orderType",
        align: "center",
        width: 80,
        customRender: (text) => {
          if (text?.startsWith("T+")) {
            return text.substring(2) + "天"
          } else {
            return "手动"
          }
        },
      },

      {
        title: "贷款期限",
        dataIndex: "period",
        align: "center",
        width: 80,
        customRender: (text) => {
          return !text ? "---" : text + "天"
        },
      },
      {
        title: "状态",
        dataIndex: "checkStatus",
        align: "center",
        width: 80,
        customRender: (text, row) => {
          if (row.mchType === '0') {
            return ""
          } else if (text === '0') {
            return <a-tag color="blue">审批中</a-tag>
          } else if (text === '1') {
            return <a-tag color="green">通过</a-tag>
          } else if (text === '2') {
            return <a-tag color="red">拒绝</a-tag>
          } else if (text === '3') {
            return <a-tag color="red">异常</a-tag>
          } else {
            return "";
          }
        },
      },
      {
        title: "机构",
        dataIndex: "departmentId",
        align: "center",
        customRender: (text) => {
          return <DeptInfo dept-id={text}></DeptInfo>
        },
      },
      {
        title: "渠道",
        dataIndex: "channelNo",
        align: "center",
        width: 80,
        customRender: (text) => {
          return <ChannelInfo channel-no={text} size={20}></ChannelInfo>
        },
      },
    ];
    return {
      commonDict,
      loadProductId: undefined,
      // 机构
      treeData: [],
      departmentData: {},
      data,
      columns,
      selectedRowKeys: [],
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      total: 0,
      // 筛选参数
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      paramsData: {
        departmentId: undefined,
        departmentCode: undefined,
        // 商户编号
        mchNo: "",
        // 商户名称
        mchName: "",
        // 客户名称
        custname: "",
        // 回款周期(订单类型)
        orderType: "",
        // 贷款产品编号
        productId: undefined,
        // 客户类型
        custtypecd: "",
        // 商户类型
        mchType: "1",
        // 产品类型
        goodsType: "",
        // 同步状态
        synch: '',
      },
      // 选中的客户数组
      selectCustData: [],
      isVisibleDelDialog: false
    };
  },
  created() {
    if (this.$route.query?.mchNo) {
      this.paramsData.mchNo = this.$route.query.mchNo
    }
    this.busiDict = {...BizDict, ...BizDictCascade};
  },

  mounted() {
    // if (sessionStorage.getItem('msgInfo')) {
      // 从store里读取筛选条件
      // this.paramsData = this.$store.state.searchMsgInfo
    // }
    this.getTableData();
  },
  methods: {
    // 获取表格数据
    async getTableData() {
      const res = await this.api.getEcListApi({
        ...this.pagination,
        obj: this.paramsData
      });
      if (res.code === 200) {
        this.data = res.data.records;
        this.paramsData.pageNo = res.data.current;
        this.paramsData.pageSize = res.data.size;
        this.total = res.data.total;
      } else {
        return message.error(res.info);
      }
    },

    // 查询
    handleQuery() {
      this.selectCustData = []
      this.selectedRowKeys = []
      this.pagination.pageNo = 1;
      this.getTableData();
    },
    // 重置
    handleReset() {
      this.selectCustData = []
      this.selectedRowKeys = []
      this.loadProductId = undefined
      this.paramsData = {
        departmentId: undefined,
        // 当前页，
        pageNo: 1,
        // 一页多少条
        pageSize: 10,
        // 商户编号
        mchNo: "",
        // 商户名称
        mchName: "",
        // 客户名称
        custname: "",
        // 回款周期(订单类型)
        orderType: "",
        // 贷款产品编号
        productId: undefined,
        // 客户类型
        custtypecd: "",
        // 商户类型
        mchType: "",
        // 产品类型
        goodsType: "",
        // 同步状态
        synch: '',
      };
      this.getTableData();
    },
    // 操作事件
    handleBtn(handlleType) {

      if (handlleType == 'edit' || handlleType == 'view') {
        if (this.selectCustData.length != 1) {
          return message.warning("只能选择一个客户!");
        } else {
          // 存储筛选条件
          // this.$store.commit('saveSearchMsgInfo', this.paramsData)
          this.$router.push({
            name: "AddMchManage",
            query: {
              id: this.selectCustData[0].id,
              type: handlleType == 'edit' ? 'edit' : 'view',
            },
          });
        }
      } else if (handlleType == 'del') {
        // 删除操作
        if (this.selectCustData.length === 0) {
          return message.warning("请选择客户!");
        } else {
          this.isVisibleDelDialog = true;
        }
      }

    },
    // 审批
    approve() {
      if (this.selectCustData.length == 0) {
        return message.warning('请选择一个客户!')
      } else if (this.selectCustData.length > 1) {
        return message.warning('只能选择一个客户!')
      }
      // 跳转页面
      this.$router.push({
        name: "AddMchManage",
        query: {
          id: this.selectCustData[0].id,
          type: 'approve',
        },
      });
    },
    // 确认删除
    async handleOk() {
      // console.log(this.selectCustData, 'selectCustData');
      let idArr = []
      this.selectCustData.forEach(item => {
        idArr.push(item.id)
      })
      let res = await this.api.delMchManageDataApi(idArr)
      if (res.code == 200) {
        this.$message.success(res.info);
      }
      this.selectCustData = []
      this.selectedRowKeys = []
      this.isVisibleDelDialog = false;
      this.getTableData()
    },
    // 选中表格的事件函数
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys.splice(-1, 1)
      this.selectCustData = selectedRows.splice(-1, 1)
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            if (this.selectedRowKeys[0] === record.id.toString()) {
              this.selectedRowKeys = []
              this.selectCustData = []
            } else {
              this.selectedRowKeys = [record.id.toString()]
              this.selectCustData = [record]
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-card-body {
  padding: 24px 34px;
}

.selectBox {
  display: flex;
  flex-wrap: wrap;

  /deep/ .item {
    margin-bottom: 20px;
    margin-right: 20px;

    .ant-input {
      width: 200px;
      margin-left: 20px;
    }

    .ant-select {
      width: 200px;
      margin-left: 20px;
    }
  }
}

.tableBox {
  margin-bottom: 20px;

  .handleBtn {
    margin-bottom: 20px;
  }
}
</style>
