import Vue from 'vue';
import Antd from 'ant-design-vue';
import ElementUI from 'element-ui';
import VueRouter from 'vue-router';
import { message } from 'ant-design-vue';
import VueClipboard from 'vue-clipboard2';
import JsonViewer from 'vue-json-viewer';
import axios from './utils/axios';
import authority, { authorityInit } from './utils/authority';
import session from './utils/session';
import moment from 'moment';
import uploadfile from './utils/files.js';
import api from './api/Api';
import "./css/theme.scss";

export function createApp(config) {

    console.log("routes", config.routes);
    console.log("store", config.store);

    // Vue 插件使用
    Vue.use(ElementUI);
    Vue.use(JsonViewer);
    Vue.use(Antd);
    Vue.use(VueClipboard);

    // 全局属性
    Vue.prototype.$message = message;
    Vue.prototype.$uploadfile = uploadfile;
    Vue.prototype.$axios = axios;
    Vue.prototype.$authority = authority;
    Vue.prototype.$authorityInit = authorityInit;
    Vue.prototype.$session = session;
    Vue.prototype.$moment = moment;
    Vue.prototype.api = api;
    Vue.prototype.$titleSuffix = config.titleSuffix;

    // 配置消息提示
    message.config({
        duration: 3,
        top: `100px`,
        maxCount: 3,
    });

    // VueRouter 配置
    Vue.use(VueRouter);
    const originalPush = VueRouter.prototype.push;
    VueRouter.prototype.push = function push(location) {
        return originalPush.call(this, location).catch((err) => err);
    };

    // 路由配置检查
    if (!config.routes || !Array.isArray(config.routes) || config.routes.length === 0) {
        console.error("❌ Router 配置错误: config.routes 为空！请检查路由配置");
    }

    const router = new VueRouter({
        routes: config.routes || [],  // 确保路由不为空
        mode: 'history',
        base: config.basePath || '/',
    });
    console.log("appName", config.appName)
    sessionStorage.setItem('appName', config.appName);

    // 路由守卫：登录验证
    router.beforeEach((to, from, next) => {
        const isLogin = sessionStorage.getItem('isLogin');
        document.title = (to.matched[0]?.meta?.title || '') + (config.titleSuffix || '');

        console.info(`路由守卫: ${to.path}`);
        console.info(`title: ${ document.title}`);

        // 确保 loginPath 存在
        const loginPath = config.loginPath || '/login';


        if (!isLogin && to.path !== loginPath) {
            if (from.path === loginPath) {
                next(false);  // ✅ 防止死循环
                console.log("🔄 用户未登录，正在重定向到登录页面...");
            } else {
                console.log("🔄 用户未登录，重定向到:", loginPath);
                next(loginPath);  // 重定向到登录页面
            }
        } else {
            console.log("🔄 继续访问");
            next();  // 继续访问
        }
    });

    // 返回 Vue 实例
    return new Vue({
        store: config.store,
        router,
        render: (h) => h(config.App), // 渲染根组件
    });
}
