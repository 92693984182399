import Cockpit from '@/view/Cockpit/index.vue'
import CoreCompany from '@/view/Cockpit/CoreCompany.vue'
import FinancingEco from '@/view/Cockpit/FinancingEco.vue'
import AgentIncome from '@/view/Cockpit/AgentIncome.vue'

export default [
    { path: '/cockpit', component: Cockpit, meta: '驾驶舱' },
    { path: '/cockpit/coreCompany', component: CoreCompany, meta: '核心企业' },
    { path: '/cockpit/FinancingEco', component: FinancingEco, meta: '融资电商' },
    { path: '/cockpit/AgentIncome', component: AgentIncome, meta: '代理商收入' },
]
