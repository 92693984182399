<template>
  <a-form class="ant-advanced-search-form" :label-col="{ span: 4, offset: 1 }" :wrapperCol="{ span: 18, offset: 1 }"
    labelAlign="left" :colon="false">
    <a-row>
      <a-col :span="4">
        <a-form-item label="所属机构">
          <DepartmentChoose :departmentId.sync="selectFormData.companyId"></DepartmentChoose>
        </a-form-item>
      </a-col>

      <a-col :span="4">
        <a-form-item label="用户名称">
          <a-input v-model="selectFormData.name" placeholder="请输入用户名称" />
        </a-form-item>
      </a-col>

      <a-col :span="4">
        <a-form-item label="手机号">
          <a-input v-model="selectFormData.mobile" placeholder="请输入用户手机号" />
        </a-form-item>
      </a-col>

      <a-col :span="4">
        <a-form-item label="用户状态">
          <a-select v-model="selectFormData.status" placeholder="请选择用户状态" allowClear>
            <a-select-option :value="true">
              锁定
            </a-select-option>
            <a-select-option :value="false">
              未锁定
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="4">
        <a-form-item label="用户角色">
          <a-select v-model="selectFormData.roleId" placeholder="请选择用户角色" allowClear>
            <a-select-option v-for="item in roleData" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="4">
        <a-button style="margin-top:3px;margin-left:20px" type="primary" @click="handleFormLayoutChange">
          查询
        </a-button>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

export default {
  components: {DepartmentChoose},
  props: {
    treeData: Array,
    roleData: Array
  },
  data() {
    return {
      selectFormData: {
        name: undefined,
        mobile: undefined,
        companyId: undefined,
        status: undefined,
        type: undefined,
        roleId: undefined,
        currentPage: 1
      }
    };
  },
  computed: {

  },
  methods: {
    handleFormLayoutChange() {
      this.$emit('selectHandle', this.selectFormData)
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
  align-items: center;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.ant-advanced-search-form .ant-form-item {
  margin-bottom: 6px;
}
</style>