export const commonDict = {

        taskTypeConfig: {
            '0': {color: "#4A90E2", label: "个人开户", show: true},        // 信任蓝
            '1': {color: "#34C759", label: "企业开户", show: true},        // 企业绿
            '2': {color: "#FF9500", label: "个人贷款申请", show: true},    // 警示橙
            '3': {color: "#5856D6", label: "企业贷款申请", show: true},    // 商务紫
            '4': {color: "#5AC8FA", label: "归集协议添加", show: true},    // 连接青
            '5': {color: "#007AFF", label: "个人开户信息变更", show: true},// 深信任蓝
            '6': {color: "#28CD41", label: "企业开户信息变更", show: true},// 深企业绿
            '7': {color: "#AF52DE", label: "个人自助开户", show: true},    // 自助紫
            '8': {color: "#32ADE6", label: "企业自助开户", show: true},    // 自助蓝
            '9': {color: "#8E8E93", label: "账户销户", show: true},        // 中性灰
            '10': {color: "#FF3B30", label: "余额支付", show: true},       // 支付红
            '11': {color: "#FF6905", label: "融资支付", show: true},      // 融资橙
            '12': {color: "#FF2D55", label: "清分支付", show: true},       // 清分红
            '13': {color: "#A2845E", label: "非银垫资", show: true},       // 金融棕
            '14': {color: "#68758E", label: "非银清算", show: true},       // 清算灰
            default: {color: "#C7C7CC", label: "未知", show: false},       // 浅中性灰
        },
        taskStatusConfig: {
            '0': {color: "#4A90E2", label: "待处理", show: true},
            '1': {color: "#34C759", label: "已处理", show: true},
            '2': {color: "#FF2D55", label: "处理失败", show: true},
            default: {color: "#C7C7CC", label: "未知", show: false},

        },
        goodsTypeConfig: {
            '11': {color: "#DB5409", label: "商品类", show: true, addShow: true},
            '10': {color: "#DB5409", label: "商品类（特殊）", show: true, addShow: true},

            '20': {color: "#FF6B6B", label: "娱乐类", show: true, addShow: false},
            '30': {color: "#DBC309", label: "资金类", show: true, addShow: true},

            '40': {color: "#01D1DB", label: "代销类", show: true, addShow: true},
            '41': {color: "#01D1DB", label: "代销类(特殊)", show: true, addShow: false},

            '50': {color: "#7509DB", label: "权益类", show: true, addShow: true},
            '51': {color: "#6d8f29", label: "物流类", show: true, addShow: true},      //

            default: {color: "#C7C7CC", label: "未知", show: false, addShow: false},
        }
    }
;
