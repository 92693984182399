import MchManage from '@/view/Mch/MchManage/index.vue'
import AddMchManage from '@/view/Mch/MchManage/AddMchManage/index.vue'
import MchShopInvoice from '@/view/Mch/MchShopInvoice/index.vue'
import MchShopInvoiceAdd from '@/view/Mch/MchShopInvoice/add.vue'
import MchShopInvoiceNormal from '@/view/Mch/MchShopInvoice/normal.vue'
import MchShopInvoiceDepositImport from '@/view/Mch/MchShopInvoice/deposit/import.vue'
import MchShopManage from '@/view/Mch/MchShopManage/index.vue'
import AddMchShopManage from '@/view/Mch/MchShopManage/AddMchShopManage/index.vue'
import MchWithDrawal from '@/view/Mch/MchWithDrawal/index.vue'
import CheckList from '@/view/Mch/CheckList/index.vue'
import SettleAccountsDetails from "@/view/Wallet/ClearAgreementManage/settleAccountsDetails.vue";

export default [
    { path: '/Mch/MchManage', name: 'MchManage', component: MchManage, meta: '电商管理' },
    { path: '/Mch/MchManage/addMchManage', name: 'AddMchManage', component: AddMchManage, meta: '新增电商管理' },
    { path: '/Mch/MchShopInvoice', name: 'MchShopInvoice', component: MchShopInvoice, meta: '店铺发票管理' },
    { path: '/Mch/MchShopInvoiceAdd', name: 'MchShopInvoiceAdd', component: MchShopInvoiceAdd, meta: '店铺发票管理新增' },
    { path: '/Mch/MchShopInvoiceNormal', name: 'MchShopInvoiceNormal', component: MchShopInvoiceNormal, meta: '店铺发票管理匹配' },
    { path: '/Mch/MchShopInvoiceDepositImport', name: 'MchShopInvoiceDepositImport', component: MchShopInvoiceDepositImport, meta: '店铺发票到账流水导入' },
    { path: '/Mch/MchShopManage', name: 'MchShopManage', component: MchShopManage, meta: '店铺管理' },
    { path: '/Mch/MchShopManage/AddMchShopManage', name: 'AddMchShopManage', component: AddMchShopManage, meta: '新增店铺管理' },
    { path: '/Mch/MchWithDrawal', name: 'MchWithDrawal', component: MchWithDrawal, meta: '电商提现管理' },
    { path: '/Mch/CheckList', name: 'CheckList', component: CheckList, meta: '待审批事项' },
    {path: '/Mch/settleAccountsDetails', component: SettleAccountsDetails, meta: '清分协议详情'},
]
