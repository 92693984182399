import lender from '@/view/nbi/lender/index.vue'
import borrower from '@/view/nbi/borrower/index.vue'
import borrowerDetail from '@/view/nbi/borrower/index.vue'
import advance from "@/view/nbi/advance/index.vue";
import advanceAdd from "@/view/nbi/advance/add.vue";
import reclaim from "@/view/nbi/reclaim/index.vue";
import reclaimSettle from "@/view/nbi/reclaim/settle/index.vue";
import cg from "@/view/nbi/cg/index.vue";
import transRecord from "@/view/nbi/trans/record.vue";
import transDetail from "@/view/nbi/trans/detail.vue";

export default [
    { path: '/nbi/lender', component: lender, meta: '出资人' },
    { path: '/nbi/borrower', component: borrower, meta: '融资人' },
    { path: '/nbi/borrower/detail', component: borrowerDetail, meta: '融资人详情' },
    { path: '/nbi/advance', component: advance, meta: '垫资记录' },
    { path: '/nbi/advance/add', name: "advanceAdd", component: advanceAdd, meta: '新增垫资' },
    { path: '/nbi/reclaim', component: reclaim, meta: '清算记录' },
    { path: '/nbi/reclaim/settle', name: "reclaimSettle", component: reclaimSettle, meta: '清算' },
    { path: '/nbi/cg', component: cg, meta: 'CG' },
    { path: '/nbi/trans/record', component: transRecord, meta: '交易记录' },
    { path: '/nbi/trans/detail', component: transDetail, meta: '交易明细' },
]
