import axios from 'axios';
import {Loading} from 'element-ui';
import {message} from 'ant-design-vue';
import configUrl from './urlConfig.js';
import {ClientJS} from 'clientjs';
// 获取当前应用的 `router`
import ecommerceRouter from '../apps/ecommerce/routes.js'
import logisticsRouter from '../apps/logistics/routes.js'

let loadingInstance;
const options = { text: '正在加载，请稍等...' };

function getCurrentRouter() {
    if (window.location.pathname.startsWith('/logistics')) {
        return logisticsRouter;
    }
    return ecommerceRouter;
}

const instance = axios.create({
    baseURL: configUrl.url,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
    timeout: 100000,
});

// 请求拦截器
instance.interceptors.request.use(config => {
    let request = config;

    if (request.url.split('?')[0] === '/business/file/manager/getAccessUrlByFileNo') {
        loadingInstance = Loading.service();
    } else if (['/business/base/face/photographQuery', '/business/cust/account/returnBillDownload'].includes(request.url.split('?')[0])) {
        loadingInstance = undefined;
    } else if (request.headers['No-Loading']) {
        loadingInstance = undefined;
    } else {
        loadingInstance = Loading.service(options);
    }

    // 处理 Token
    if (request.url !== '/user/login' && sessionStorage.getItem('token')) {
        request.headers.token = sessionStorage.getItem('token');
    }

    // 添加设备指纹
    if (!sessionStorage.getItem('fp')) {
        let clientjs = new ClientJS();
        let fingerprints = clientjs.getFingerprint();
        sessionStorage.setItem('fp', fingerprints);
        request.headers.fp = fingerprints;
    }
    request.headers.fp = sessionStorage.getItem('fp');

    // 特定 URL 数据处理
    if (shouldHandleRequest(request.url)) {
        processRequestData(request.data);
    }
    //在logistics 应用下  /mch/live/page api查询默认新增入参
    let appName = sessionStorage.getItem('appName');

    if (appName === 'logistics' && request.url.includes('/mch/live/page')) {

        if (request.data && typeof request.data === 'object') {

            request.data.goodsType = '51';

        }
    }

    return request;
}, error => Promise.reject(error));

// 响应拦截器
instance.interceptors.response.use(response => {
    loadingInstance?.close();
    const data = response.data;

    if (data.code === 552) {
        message.error(data.info);
        sessionStorage.clear();
        window.location.href = "/";
    }

    return data;
}, error => {
    loadingInstance?.close();
    if (error.response?.status === 401) {
        message.error('登录过期，请重新登录');
        sessionStorage.clear();
        setTimeout(() => {
            sessionStorage.clear();
            window.location.href = "/";
        }, 500);
    } else {
        message.error('出错啦，请稍后再试');
    }
    return Promise.reject(error);
});

// 判断是否需要处理
function shouldHandleRequest(url) {
    const specialUrls = [
        '/business/busi/collect/orders/page',
        '/business/busi/payorcers/contract/page',
        '/business/mch/live/page',
        '/business/busi/oriorders/page',
        '/business/busi/payorders/page',
        '/business/cust/person/page',
        '/business/cust/company/page',
        '/business/mch/live/page',
        '/business/credit/bmd/page',
    ];
    return specialUrls.some(specialUrl => url.includes(specialUrl));
}

// 处理请求数据
function processRequestData(data) {
    const departIdCodeMapping = JSON.parse(sessionStorage.getItem('departIdCodeMapping'));

    if (data && typeof data === 'object' && departIdCodeMapping && data.departmentId) {
        const departmentCode = departIdCodeMapping[data.departmentId];
        if (departmentCode) {
            data.departmentCode = departmentCode;
            delete data.departmentId;
        }
    }
}



export default instance;
