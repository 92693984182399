<template>
  <div id="login">
    <div class="box_content">
      <div class="title">{{ pageTitle }}</div>
      <a-form :form="form" @submit="handleSubmit" layout="horizontal" :colon="false" labelAlign="left"
              :hideRequiredMark="true">
        <a-form-item>
          <a-input v-decorator="[
            'username',
            { rules: [{ required: true, message: '请输入用户名' }] },
          ]" placeholder="请输入用户名" autocomplete="username">
            <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input v-decorator="[
            'password',
            { rules: [{ required: true, message: '请输入密码' }] },
          ]" type="password" placeholder="请输入密码" autocomplete="current-password">
            <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
          </a-input>
        </a-form-item>
        <!--        <a-form-item>-->
        <!--          <a-input disabled v-model="partname" placeholder="部门名称">-->
        <!--            <a-icon slot="prefix" type="apartment" style="color: rgba(0,0,0,.25)" />-->
        <!--          </a-input>-->
        <!--        </a-form-item>-->
        <a-form-item>
          <a-button type="primary" html-type="submit" class="login-form-button" style="width:100%">
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div style="position:fixed;text-align:center;bottom:0;margin:0 auto;width:100%;color:#5c6b77">
      <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802013234"
         style="margin:0 10px;"><img src="https://www.beian.gov.cn/img/ghs.png"/>浙公网安备 33010802013234号</a>
      <a target="_blank" href="https://beian.miit.gov.cn/">浙ICP备2023021090号-1</a>
    </div>
    <a-modal class="addition-auth" v-model="codeshowvisible" title="验证安全手机 " :centered="true" width="480"
             :closable="false" :footer="null">
      <a-alert :message="'您的账号开启了手机验证，请在获取验证码之后，输入您' + mobile + '手机上接收到的验证码。'"
               type="info" show-icon/>
      <p></p>
      <p></p>
      <p></p>
      <a-form-modal>
        <a-form-model-item>
          <a-input v-model="code" placeholder="请输入验证码" :max-length="6" @keyup.enter="checkCode" @change="codeChange">
            <CountdownBtn slot="suffix" :wait-time="60" :error-wait-time="10" :sendCall="sendCode" send-text="发送验证码"></CountdownBtn>
          </a-input>
        </a-form-model-item>
        <!--          <a-input v-model="codeForm.code">-->
        <a-button style=" margin-top: 1px;" @click="checkCode" :disabled="!code?.match(/\d{4,6}/)" type="primary" block>提交验证</a-button>
      </a-form-modal>
    </a-modal>
  </div>
</template>
<script>
import md5 from 'md5'
import CountdownBtn from "@/view/components/CountdownBtn.vue";

export default {
  components: {CountdownBtn},
  data() {
    return {
      pageTitle: '',
      // partname: undefined,
      mobile: "",
      codeshowvisible: false,
      logindata: {},
      values: {},
      rules: {
        code: {required: true, message: '请输入手机号码'},
      },
      code: "", // 短信验证码
    }
  },
  created() {
    // 获取 Vue 实例中的 titleSuffix
    this.pageTitle = ` ${this.$titleSuffix}`;
  },

  computed: {},
  methods: {
    async handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.values = values
          const {username, password} = values
          this.$axios
              .apiGet('/user/login', {username, password: md5(password)})
              .then(async (res) => {
                if (res.code === 200) {
                  this.logindata = res.data
                  // console.log(this.logindata, ' this.logindata ');
                  if (this.logindata.isNeed2fa) { //需要两步认证验证
                    sessionStorage.setItem('token', this.logindata.token) // token先放进去，发短信要用
                    this.mobile = this.logindata.mobile.replace(/^(\d{3}).+(\d{4})$/, '$1****$2')
                    this.codeshowvisible = true
                  } else {
                    this.hideModal() // 直接登录
                  }
                } else {
                  this.$message.error('用户名或密码错误')
                }
              })
        }
      })
    },
    // getPartname(e) {
    //   const username = e.target.value
    //   if (e.target.value == "") {
    //     return
    //   }
    //   this.$axios
    //     .apiGet('/user/department/childrenList', { username })
    //     .then((res) => {
    //       if (res.code !== 200) return this.$message.error(res.info)
    //       this.partname = res.data.coreDepartment.name
    //     })
    // },
    // 
    hideModal() {
      this.login()
    },
    async login() {
      // console.log(res, 'login')
      const {auth, token, departmentLevel, companyId, companyCode, companyName, departIdCodeMapping} = this.logindata
      const tabBar = [
        {
          path: '/home',
          name: '首页',
        },
      ]
      let authPage = []
      let authButton = []
      for (let value of auth) {
        if (value.grade == 3 || value.grade == 4) {
          authButton.push(value)
        } else {
          authPage.push(value)
        }
      }
      // 提示用户，要进项验证码校验

      this.$session.setItem('departmentLevel', departmentLevel)
      this.$session.setItem('companyId', companyId)
      this.$session.setItem('companyCode', companyCode)
      this.$session.setItem('companyName', companyName)
      this.$session.setItem('isLogin', true)
      this.$session.setItem('token', token)
      this.$session.setItem('authPage', JSON.stringify(authPage))
      this.$session.setItem('authButton', JSON.stringify(authButton))
      this.$session.setItem('tabBar', JSON.stringify(tabBar))
      this.$session.setItem('username', this.values.username)

      this.$session.setItem('departIdCodeMapping', JSON.stringify(departIdCodeMapping))
      await this.loadCoreCompanies()
      await this.loadDepartmentList()

      const info = await this.api.downLoadTemplateApi(["contract_document", "mch_fee_tpl", "mch_pay_fee_tpl", "pay_withhold_fee_tpl", "incoming_whitelist_accounts", "announcement", "mch_input_user"]);
      if (info.code == 200) {
        try {
          const contractDocument = info.data.contract_document.constValue ? JSON.parse(info.data.contract_document.constValue) : {};
          this.$store.commit('savegetMapList', contractDocument);

          const mch_fee_tpl = info.data.mch_fee_tpl.constValue ? JSON.parse(info.data.mch_fee_tpl.constValue) : {};
          this.$store.commit('mchFeeTpl', mch_fee_tpl);

          const mch_pay_fee_tpl = info.data.mch_pay_fee_tpl.constValue ? JSON.parse(info.data.mch_pay_fee_tpl.constValue) : {};
          this.$store.commit('mchPayFeeTpl', mch_pay_fee_tpl);

          const pay_withhold_fee_tpl = info.data.pay_withhold_fee_tpl.constValue ? JSON.parse(info.data.pay_withhold_fee_tpl.constValue) : {};
          this.$store.commit('payWithholdFeeTpl', pay_withhold_fee_tpl);

          const incomingWhitelistAccounts = JSON.parse(info.data.incoming_whitelist_accounts.constValue);
          this.$store.commit('incomingWhitelistAccounts', incomingWhitelistAccounts)

          const announcement = JSON.parse(info.data.announcement?.constValue??'[]');
          this.$store.commit('announcement', announcement)

          const mch_input_user = JSON.parse(info.data.mch_input_user?.constValue??'[]');
          this.$store.commit('mchInputUser', mch_input_user)
        } catch (e) {
          console.log(e)
        }
      } else {
        console.error('Failed to get contractDocument data.');
        return {};
      }
      this.$authorityInit()
      await this.$router.push('/home')
    },
    async sendCode() {
      let res = await this.$axios
          .apiPost('/business/sms/vefifycode/registration')
      if (res.code != 200) {
        this.$message.error(res.info)
        return false
      } else {
        this.$message.info(res.info)
        return true
      }
    },
    codeChange() {
      if (this.code?.length === 6) {
        this.checkCode()
      }
    },
    async checkCode() {
      this.$axios
          .apiPost('/business/sms/vefifycode/login/authenticate?code=' + this.code)
          .then(async (res) => {
            if (res.code != 200) {
              this.$message.error(res.info)
            } else {
              this.hideModal()
            }
          })
    },
    //初始化核心企业
    async loadCoreCompanies() {
      let res = await this.api.coreCompanyInfoApi()
      if (res.code !== 200) {
        return this.$message.error("初始化核心企业数据出错")
      }

      let coreCompanyList = res.data.map(i => new Object({
        appNo: i.appNo,
        channelNo: i.channelNo,
        channelName: i.channelName,
        czcbBankName: i.czcbBankName,
        czcbBankNo: i.czcbBankNo
      }))
      this.$session.setItem("coreCompanyList", JSON.stringify(coreCompanyList))
    },
    async loadDepartmentList() {
      this.$axios.apiGet('/user/department/list').then(res => {
        if (res.code !== 200) {
          return this.$message.error(res.info)
        } else {
          let deptInfoMapping = {}
          res.data.forEach(i => deptInfoMapping[i.code] = i)
          this.$session.setItem("deptInfoMapping", JSON.stringify(deptInfoMapping))
        }
      })
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {name: 'normal_login'})
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-content {
  width: 400px;
}

.ant-btn {
  min-width: 70px;
  margin: 0px;
}
#login {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../assets/login/background.jpeg');

  .box_content {
    .title {
      height: 66px;
      line-height: 66px;
      text-align: center;
      font-size: 20px;
    }

    width: 348px;
    border: 1px solid #eee;
    background: #fafafa;
    padding: 0 18px 16px;
    border-radius: 5px;
    box-shadow: 0 0 10px #969b96;
  }
}
</style>
