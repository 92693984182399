<template>
  <div style="overflow: hidden;" class="wrapper">
    <div class="header">
      <!-- 顶部信息 -->
      <a-page-header
          style="border: 1px solid rgb(235, 237, 240)"
          title="业务确认"
          :sub-title="currentTaskType?.title"></a-page-header>
    </div>
    <!--  导航区域-->
    <div class="body">
      <a-steps :current="step" finish-status="success" direction="vertical">
        <a-step title="信息核对" v-if="currentTaskType.steps.indexOf('info')>=0">
          <template slot="description" v-if="currentTaskType.steps.indexOf('info')===step">
            <a-descriptions bordered :column="1" size="small">
              <a-descriptions-item label="业务类型">{{ currentTaskType.title || "其他" }}</a-descriptions-item>
              <a-descriptions-item label="办理渠道">{{ channelMap[busiData.channelNo] || "其他" }}（线上）</a-descriptions-item>
              <a-descriptions-item label="客户">{{ busiData.name }}</a-descriptions-item>
              <a-descriptions-item label="手机号">{{ busiData.mobile }}</a-descriptions-item>
              <a-descriptions-item v-for="(k,v) in exData" :key="v" :label="v">{{k}}</a-descriptions-item>
            </a-descriptions>
            <a-button type="primary" @click="check" block>确认无误，下一步</a-button>
          </template>
        </a-step>
        <a-step title="相关协议" v-if="currentTaskType.steps.indexOf('contract')>=0">
          <template slot="description" v-if="currentTaskType.steps.indexOf('contract')===step">
            <ul>
              <li v-for="(item,index) in currentTaskType.contracts" :key="index">
                <a-checkbox v-model="item.agreed">
                  我已阅读并同意
                  <a-button type="link" @click="contractOpen(item)">《{{ item.title }}》</a-button>
                </a-checkbox>
              </li>
            </ul>
            <a-button type="primary" @click="agreeCheck" block>下一步</a-button>
          </template>
        </a-step>
        <a-step title="人脸比对" v-if="currentTaskType.steps.indexOf('face')>=0">
          <template slot="description" v-if="currentTaskType.steps.indexOf('face')===step">
            <div style="display: flex">
              <input id="upload" ref="fileInput" style="display: none;" type="file" accept="image/*" capture="user"
                     @change="onFaceChange"/>
              <img style="width:100px;height:100px" src="../assets/img/face-img.png" alt="">
              <div
                  style="display: flex;height: 100px;align-items: center;">请保证光线充足，摘除帽子，正视屏幕，点击“开始采集”按钮
              </div>
            </div>
            <a-button type="primary" @click="face" block>开始采集</a-button>
          </template>
        </a-step>

        <a-step title="手机验证" v-if="currentTaskType.steps.indexOf('mobile')>=0">
          <template slot="description" v-if="currentTaskType.steps.indexOf('mobile')===step">
            <div style="color: #fa5c5c; padding: 10px 20px;">请确认手机号：{{ busiData.mobile }}</div>
            <div style="display: flex">
              <a-input v-model="verify_code" addon-before="验证码"></a-input>
              <a-button :disabled="sendCodeWait!==0" type="link"
                        @click="sendCode">
                发送{{ sendCodeWait === 0 ? '' : '(' + sendCodeWait + ')' }}
              </a-button>
            </div>
            <a-button type="primary" @click="submit" block>提交</a-button>
          </template>
        </a-step>
        <a-step title="完成" v-if="currentTaskType.steps.indexOf('result')>=0">>
          <template slot="description" v-if="currentTaskType.steps.indexOf('result')===step">
            <a-result
                status="success"
                title="办理完成"
                :sub-title="resultMsg"
            ></a-result>
          </template>
        </a-step>
        <a-step title="进度查询" v-if="currentTaskType.steps.indexOf('error')>=0">>
          <template slot="description" v-if="currentTaskType.steps.indexOf('error')===step">
            <a-result
                status="warning"
                title="出错啦"
                :sub-title="errorMsg || resultMsg"
            ></a-result>
          </template>
        </a-step>
      </a-steps>
    </div>
    <!-- loading -->
    <div class="loading" v-if="showLoading">
      <a-spin size="large"></a-spin>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import urlConfig from "@/utils/urlConfig";

// info: 业务确认
// contract: 相关协议
// face: 人脸比对
// mobile: 短信验证
// result: 办理结果
// error: 异常
let agreement_payment = {
  title: "浙江稠州商业银行用户支付服务协议",
  agreed: false,
  url: "https://oss-lcfs.oss-cn-hangzhou.aliyuncs.com/PLAT/%E6%B5%99%E6%B1%9F%E7%A8%A0%E5%B7%9E%E5%95%86%E4%B8%9A%E9%93%B6%E8%A1%8C%E7%94%A8%E6%88%B7%E6%94%AF%E4%BB%98%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf",
},
    agreement_privacy = {
  title: "浙江稠州商业银行用户支付服务隐私政策",
  agreed: false,
  url: "https://oss-lcfs.oss-cn-hangzhou.aliyuncs.com/PLAT/%E6%B5%99%E6%B1%9F%E7%A8%A0%E5%B7%9E%E5%95%86%E4%B8%9A%E9%93%B6%E8%A1%8C%E7%94%A8%E6%88%B7%E6%94%AF%E4%BB%98%E6%9C%8D%E5%8A%A1%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf",
}
const taskDefine = {
  0: {
    title: "稠州个人开户",
    steps: ['info', 'contract', 'face', 'mobile', 'result'],
    contracts: [agreement_payment, agreement_privacy],
    resultMsg: '开户验证成功，请联系开户渠道人员获取账户信息'
  },
  1: {
    title: "稠州企业开户",
    steps: ['info', 'mobile', 'result'],
    contracts: [agreement_payment, agreement_privacy],
    resultMsg: '开户验证成功，请联系开户渠道人员获取账户信息'
  },
  2: {
    title: "稠州个人授信申请",
    steps: ['info', 'mobile', 'result'],
    resultMsg: '贷款申请已提交，请等待银行审核通知'
  },
  3: {
    title: "稠州企业授信申请",
    steps: ['info', 'face', 'mobile', 'result'],
    resultMsg: '贷款申请已提交，请等待银行审核通知'
  },
  4: {
    title: "代扣协议签约",
    steps: ['info', 'mobile', 'result'],
    resultMsg: '代扣协议已提交，请使用同名账户向指定钱包进行打款（1元或以内任意金额），并等待银行审批',
  },
  5: {
    title: "个人开户信息变更",
    steps: ['info', 'face', 'mobile', 'result'],
    resultMsg: '个人开户信息变更成功 '
  },
  6: {
    title: "企业开户信息变更",
    steps: ['info', 'mobile', 'result'],
    resultMsg: '个人开户信息变更成功'
  },
  9: {
    title: "账号销户",
    steps: ['info', 'mobile', 'result'],
    resultMsg: '账号销户成功'
  },
  10: {
    title: "余额支付",
    steps: ['info', 'mobile', 'result'],
    resultMsg: '余额支付成功',
  },
  11: {
    title: "融资支付",
    steps: ['info', 'mobile', 'result'],
    resultMsg: '融资支付成功',
  },
  12: {
    title: "清分支付",
    steps: ['info', 'mobile', 'result'],
    resultMsg: '清分支付成功',
  },
  13: {
    title: "非银垫资",
    steps: ['info', 'mobile', 'result'],
    resultMsg: '垫资成功',
  },
  14: {
    title: "非银清算",
    steps: ['info', 'mobile', 'result'],
    resultMsg: '清算成功',
  },
  99: {
    title: "未知业务",
    steps: ['error'],
    resultMsg: "业务异常，请联系渠道方"
  }
}
export default {
  name: 'App',
  data() {
    return {
      title: "业务办理确认",
      currentTaskType: taskDefine[99],
      step: -1,
      task_no: undefined,
      verify_code: undefined,
      face_file_no: undefined,
      // enableFace: true, // 是否需要人脸比对
      sendCodeWait: 0,
      showLoading: true,
      resultMsg: "",
      errorMsg: "",
      channelMap: {
        "1021": "巽平",
        "1039": "阡陌",
        "1042": "微联达",
        "1041": "万顺",
        "1043": "兑吧",
      },
      busiData: undefined,
      exData: {}
    }
  },
  components: {
    // Loading,
  },
  mounted() {
    this.task_no = window.location.search.substring(3)
    this.loadTask()
  },
  methods: {
    loadTask() {
      this.showLoading = true
      // 加载数据
      axios.get(urlConfig.busiURL + `/custOpenAccTask/get?taskNo=${this.task_no}`).then((res) => {
        this.showLoading = false
        if (res.data.code !== 200) {
          this.currentTaskType = taskDefine[99]
          this.errorMsg = res.data.info
        } else {
          this.busiData = res.data.data
          try{
            if(this.busiData.exData){
              this.exData = JSON.parse(this.busiData.exData)
            }
          }catch(e){
            this.alert("解析业务数据异常")
          }
          this.currentTaskType = taskDefine[this.busiData?.taskType]
          if (this.busiData?.mobile) {
            this.busiData.mobile = this.busiData.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
          }
        }
        this.step = 0
      }).catch((a) => {
        this.showLoading = false
        this.alert("加载数据失败")
      })
    },
    check() {
      this.nextStep()
    },
    alert(msg){
      this.$error({title: "哎呀，出错了", content: msg})
    },
    async agreeCheck() {
      this.currentTaskType.contracts.forEach((item) => {
        item.agreed = item.agreed || false
        if (!item.agreed && window.confirm("是否同意《" + item.title + "》")) {
          item.agreed = true
        }
      })
      if (this.currentTaskType.contracts.find(item => !item.agreed)) {
        this.$message.info("请先阅读并同意相关协议")
      } else {
        this.nextStep()
      }
    },
    contractOpen(contract) {
      window.open(contract.url, "_blank")
    },
    face() {
      // 人脸比对, 若成功则调用nextStep
      this.$refs.fileInput.click()
    },
    async onFaceChange(event) {
      // 人脸比对, 若成功则调用nextStep
      const formData = new FormData()
      let file = event.target.files[0]
      let compressedFile = await this.compressFile(file)
      formData.append('file', compressedFile)
      formData.append('task_no', this.task_no)
      this.showLoading = true
      axios.post(urlConfig.busiURL + `/base/face/recognizeH5`, formData,
          {
            headers: {'Content-Type': 'multipart/form-data'},
          }
      )
          .then((res) => {
            this.showLoading = false
            if (res.data.code !== 200) {
              this.alert(res.data.info)
            } else {
              this.face_file_no = res.data.data.file_no
              this.nextStep()
            }
          })
          .catch((a) => {
            this.showLoading = false
          })
    },
    submit() {
      // 提交，若成功则调用nextStep
      this.showLoading = true
      axios.post(urlConfig.busiURL + `/custOpenAccTask/open`,
          {taskNo: this.task_no, verify_code: this.verify_code, fileNo: this.face_file_no})
          .then((res) => {
            this.showLoading = false
            if (res.data.code !== 200) {
              this.alert(res.data.info)
            } else {
              this.resultMsg = this.currentTaskType.resultMsg
              this.nextStep()
            }
          }).catch((a) => {
        this.showLoading = false
        this.alert("提交失败")
      })
    },
    sendCode() {
      this.showLoading = true
      // 发送验证码
      axios.get(urlConfig.busiURL + `/custOpenAccTask/sendOpenAccMsg?taskNo=${this.task_no}`)
          .then((res) => {
            this.showLoading = false
            if (res.data.code !== 200) {
              this.alert(res.data.info)
            }
          }).catch((a) => {
        this.showLoading = false
        this.alert("发送验证码失败")
      })
      this.sendCodeWait = 30
      let intHandler = window.setInterval(() => {
        if (this.sendCodeWait === 0) {
          window.clearInterval(intHandler)
          return
        }
        this.sendCodeWait--
      }, 1000)
    },
    nextStep() {
      this.step++
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      })
    },
    compressFile(file) {
      let that = this
      if (file.size <= 1024 * 1024) { // 1M以下不压缩
        return file
      }
      // compress image file above 1M
      let reader = new FileReader()
      reader.readAsDataURL(file)
      return new Promise((resolve, reject) => {
        reader.onload = function (e) {
          let img = new Image()
          img.src = e.target.result
          img.onload = function () {
            let canvas = document.createElement('canvas')
            let ctx = canvas.getContext('2d')
            let width = 750
            let height = img.height * 750 / img.width
            canvas.width = width
            canvas.height = height
            ctx.drawImage(img, 0, 0, width, height)
            let data = canvas.toDataURL('image/jpeg', 1)
            let newFile = that.dataURLtoFile(data, file.name)
            resolve(newFile)
          }
        }
      })
    },

  }
}
</script>
<style lang="less">
</style>



