<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>贷款申请</span>
            <a-icon type="right"/>
            <span>贷款申请</span>
          </div>
          <div class="right">
            <!-- 确认 -->
            <a-button type="primary" @click="confirm"> 提交</a-button>
            <!-- 取消 -->
            <a-button @click="cancelshow = true"> 取消</a-button>
          </div>
        </div>
      </template>
      <!-- 企业贷款 -->
      <div class="Individualaccountopening">
        <!-- 审批详情 -->
        <div class="Approvaldetails" v-if="$route.query.status">
          <h2>审批详情</h2>
          <div class="text">
            <div class="left">状态</div>
            <div class="right">
              <div class="status">{{ customRender($route.query.status) }}</div>
              <a-button type="primary" v-if="$route.query.status == '04'" @click="Signacontract">
                去签约
              </a-button>
              <a-button type="primary" v-if="$route.query.status == '06'" @click="Signacontract">
                查看合同
              </a-button>
            </div>
          </div>
        </div>
        <!-- 基础信息 -->
        <div class="formbox" v-if="!$route.query.status">
          <div class="toptitle">基础信息*</div>
          <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <!-- !客户编号 -->
            <a-form-model-item ref="custname" label="贷款申请人" prop="custname">
              <CustChooseDialog v-model="form.custname" custtypecd="2" :departmentId="form.departmentId"
                                :custnum="form.custnum" :custname="form.custname" @chooseDone="onCustChosen">
              </CustChooseDialog>
            </a-form-model-item>
            <!-- 账号 -->
            <a-form-model-item ref="account_no" class="account_no" label="钱包" prop="account_no">
              <WalletChooseDialog v-model="form.account_no" :custnum="form.custnum" :custname="form.custname"
                                  :searchable="false" :account-class="'0'" @chooseDone="onWalletChosen">
              </WalletChooseDialog>
            </a-form-model-item>
            <!-- 户名 -->
            <a-form-model-item ref="account_name" label="户名" prop="account_name">
              <a-input v-model.trim="form.account_name" :disabled="true" placeholder="请输入户名"></a-input>
            </a-form-model-item>

            <!-- 贷款产品 -->
            <a-form-model-item ref="account_no" class="account_no" label="贷款产品" prop="account_no">
              <ProductChooseDialog v-model="productName" :channel-no="form.channel_no" :department-id="form.departmentId"
                                   product-type="2" @chooseDone="onProductChosen"></ProductChooseDialog>
            </a-form-model-item>

            <!-- 申请金额 -->
            <a-form-model-item label="申请金额" class="business_apply_sum" ref="business_apply_sum" prop="business_apply_sum">
              <a-input v-model.trim="form.business_apply_sum" type="number" placeholder="请输申请金额">
                <template #suffix>
                  <div class="yaun">万元</div>
                </template>
              </a-input>
            </a-form-model-item>

            <!-- 申请利率 -->
            <a-form-model-item label="申请利率" class="business_rate" ref="business_rate" prop="business_rate">
              <a-input v-model.trim="form.business_rate" type="number" placeholder="请输入申请利率">
                <template #suffix>
                  <div class="yaun">%</div>
                </template>
              </a-input>
            </a-form-model-item>

            <!-- 贷款投向 -->
            <a-form-model-item label="贷款投向" class="direction" ref="direction" prop="direction">
              <el-cascader v-model.trim="form.direction" :options="WorkCorpIndusCDlist" placeholder="请选择贷款投向"
                           :show-all-levels="false" :props="{ emitPath: false }" style="width:200px"/>
            </a-form-model-item>

            <!--? 法定代表人信息 -->

            <div class="Imageinformation">法定代表人信息</div>

            <!-- 姓名 -->

            <a-form-model-item ref="gr_full_name" class="gr_full_name" label="姓名" prop="gr_full_name">
              <a-input v-model.trim="form.gr_full_name" placeholder="请输入姓名"></a-input>
            </a-form-model-item>

            <!-- 证件号码 -->
            <a-form-model-item ref="gr_cert_id" class="gr_cert_id" label="身份证号码" prop="gr_cert_id">
              <a-input v-model.trim="form.gr_cert_id" @change="changeGrCertId" placeholder="请输入身份证号码"></a-input>
            </a-form-model-item>

            <!-- 手机号码 -->
            <a-form-model-item ref="gr_mobile_telephone" class="gr_mobile_telephone" label="手机号码"
                               prop="gr_mobile_telephone">
              <a-input v-model.trim="form.gr_mobile_telephone" type="number" placeholder="请输入法定代表人手机号码"></a-input>
            </a-form-model-item>

            <!-- 个人性别 -->
            <a-form-model-item label="性别" class="gr_sex" prop="gr_sex">
              <a-radio-group v-model.trim="form.gr_sex" disabled>
                <a-radio v-for="item in sexlist" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!-- 出生日期 -->
            <a-form-model-item label="出生日期" class="gr_birthday" ref="gr_birthday" prop="gr_birthday">
              <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD" v-model.trim="form.gr_birthday" disabled/>
            </a-form-model-item>
            <!-- 证件到期日 -->
            <a-form-model-item label="证件到期日" class="gr_cert_enddate" ref="gr_cert_enddate" prop="gr_cert_enddate">
              <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD" v-model.trim="form.gr_cert_enddate"/>
              <a-checkbox style="margin-left: 50px;"
                          @change="$event.target.checked ? form.gr_cert_enddate = '9999-12-31' : form.gr_cert_enddate = ''">
                长期
              </a-checkbox>
            </a-form-model-item>

            <!-- 省/直辖市/自治区 -->
            <a-form-model-item class="gr_region_code" prop="gr_region_code" ref="gr_region_code" label="居住地省市">

<!--              <el-cascader v-model="form.gr_region_code" :options="citylist" placeholder="请选择所属地区"-->
<!--                           :props="{ emitPath: false }" style="width:200px"/>-->
              <!--              <a-cascader v-model.trim="form.gr_region_code" :options="citylist" placeholder="请选择所属地区" />-->
              <RegionChooseDialog
                  :channelNo="form.channel_no"
                  :appNo="app_no"
                  v-model.trim="form.gr_region_code"
                  @change="handleSelectionChange"
              />
            </a-form-model-item>

            <!-- 居住地址 -->
            <a-form-model-item ref="gr_family_add" class="gr_family_add" label="详细居住地址" prop="gr_family_add">
              <a-input v-model.trim="form.gr_family_add" placeholder="请输入居住地址"></a-input>
            </a-form-model-item>

            <!-- 最高学历 -->
            <a-form-model-item label="最高学历" prop="gr_edu_experience" class="gr_edu_experience">
              <a-select v-model.trim="form.gr_edu_experience" placeholder="请选择学历" style="width: 250px">
                <a-select-option v-for="item in eduexperiencelist" :key="item.label" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <!-- 婚姻状况 -->
            <a-form-model-item label="个人婚姻状况" class="gr_marriage" prop="gr_marriage">
              <a-select v-model.trim="form.gr_marriage" placeholder="请选择婚姻状况" style="width: 250px">
                <a-select-option v-for="item in marriagelist" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 配偶姓名 -->
            <a-form-model-item ref="po_customer_name" prop="po_customer_name" v-if="Number(this.form.gr_marriage) == 20"
                               :rules="Number(this.form.gr_marriage) == 20
                ? [{ required: true, message: '请输入配偶姓名' }]
                : []
                " class="po_customer_name" label="配偶姓名">
              <a-input v-model.trim="form.po_customer_name" placeholder="请输入配偶姓名"></a-input>
            </a-form-model-item>

            <!-- 配偶电话 -->
            <a-form-model-item ref="po_mobile_telephone" v-if="Number(this.form.gr_marriage) == 20"
                               class="po_mobile_telephone" label="配偶电话" prop="po_mobile_telephone" :rules="Number(this.form.gr_marriage) == 20
                ? [{ required: true, message: '请输入配偶电话' }]
                : []
                ">
              <a-input v-model.trim="form.po_mobile_telephone" placeholder="请输入配偶电话"></a-input>
            </a-form-model-item>

            <!-- 配偶证件号码 -->
            <a-form-model-item ref="po_cert_id" v-if="Number(this.form.gr_marriage) == 20" :rules="Number(this.form.gr_marriage) == 20
              ? [{ required: true, message: '请输入配偶身份证号' }]
              : []
              " class="po_cert_id" label="配偶身份证号码号码" prop="po_cert_id">
              <a-input v-model.trim="form.po_cert_id" placeholder="请输入配偶证件号码"></a-input>
            </a-form-model-item>
            <!-- 配偶性别 -->
            <a-form-model-item label="配偶性别" v-if="Number(this.form.gr_marriage) == 20" :rules="Number(this.form.gr_marriage) == 20
              ? [{ required: true, message: '请选择配偶性别' }]
              : []
              " class="po_sex" prop="po_sex">
              <a-radio-group v-model.trim="form.po_sex">
                <a-radio v-for="item in sexlist" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>

            <!-- 是否在本地购置房产 -->
            <a-form-model-item label="是否在本地购置房产" class="gr_is_localhouse" ref="gr_is_localhouse" prop="gr_is_localhouse">
              <a-radio-group v-model.trim="form.gr_is_localhouse">
                <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!-- 子女是否在本地就学（大学除外） -->
            <a-form-model-item label="子女是否在本地就学" class="gr_is_localread" ref="gr_is_localread" prop="gr_is_localread">
              <a-radio-group v-model.trim="form.gr_is_localread">
                <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-collapse>
              <a-collapse-panel header="更多信息">
                <!-- 个人客户分类 -->
                <a-form-model-item label="个人客户分类" class="gr_ind_customer_type" ref="gr_ind_customer_type"
                                   prop="gr_ind_customer_type">
                  <a-select v-model.trim="form.gr_ind_customer_type" placeholder="请选择个人客户分类" style="width: 250px">
                    <a-select-option v-for="item in Customerclassification" :key="item.label" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <!-- 个人户口性质 -->
                <a-form-model-item label="是否本地户籍" class="gr_hk_atr" ref="gr_hk_atr" prop="gr_hk_atr">
                  <a-radio-group v-model.trim="form.gr_hk_atr">
                    <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 个人是否市场经营户 -->
                <a-form-model-item label="个人是否市场经营户" class="gr_farmer_sort" ref="gr_farmer_sort" prop="gr_farmer_sort">
                  <a-radio-group v-model.trim="form.gr_farmer_sort">
                    <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 民族 -->
                <a-form-model-item label="民族" class="gr_nationality" prop="gr_nationality">
                  <a-select v-model.trim="form.gr_nationality" placeholder="请选择民族" style="width: 250px">
                    <a-select-option v-for="item in ethnicitylist" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>


                <!-- 经营企业名称 -->
                <a-form-model-item ref="gr_corp_name" class="gr_corp_name" label="经营企业名称" prop="gr_corp_name">
                  <a-input v-model.trim="form.gr_corp_name" placeholder="请输入经营企业名称"></a-input>
                </a-form-model-item>
                <!-- 经营企业营业执照号码 -->
                <a-form-model-item ref="gr_license_no" class="gr_license_no" label="经营企业营业执照号码" prop="gr_license_no">
                  <a-input v-model.trim="form.gr_license_no" placeholder="请输入经营企业营业执照号码"></a-input>
                </a-form-model-item>
                <!-- 经营地址 -->
                <a-form-model-item ref="gr_office_add" class="gr_office_add" label="经营地址" prop="gr_office_add">
                  <a-input v-model.trim="form.gr_office_add" placeholder="请输入经营地址"></a-input>
                </a-form-model-item>
                <!-- 经营种类 -->
                <a-form-model-item ref="gr_most_business" class="gr_most_business" label="经营种类" prop="gr_most_business">
                  <a-input v-model.trim="form.gr_most_business" placeholder="请输入经营种类"></a-input>
                </a-form-model-item>
                <!-- 住宅/经营地址电话 -->
                <a-form-model-item ref="gr_family_tel" class="gr_family_tel" label="住宅/经营地址电话" prop="gr_family_tel">
                  <a-input v-model.trim="form.gr_family_tel" placeholder="请输入住宅/经营地址电话"></a-input>
                </a-form-model-item>
                <!-- 居住状况 -->
                <a-form-model-item label="居住状况" class="gr_family_status" ref="gr_family_status" prop="gr_family_status">
                  <a-select v-model.trim="form.gr_family_status" placeholder="请选择居住状况" style="width: 250px">
                    <a-select-option v-for="item in family_status" :key="item.label" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <!-- 电子邮箱 -->
                <a-form-model-item ref="gr_email_add" class="gr_email_add" label="电子邮箱" prop="gr_email_add">
                  <a-input v-model.trim="form.gr_email_add" placeholder="请输入电子邮箱"></a-input>
                </a-form-model-item>
                <!-- 通讯地址 -->
                <a-form-model-item ref="gr_comm_add" class="gr_comm_add" label="通讯地址" prop="gr_comm_add">
                  <a-input v-model.trim="form.gr_comm_add" placeholder="请输入通讯地址"></a-input>
                </a-form-model-item>
                <!-- 通讯地址邮编 -->
                <a-form-model-item ref="gr_comm_zip" class="gr_comm_zip" label="通讯地址邮编" prop="gr_comm_zip">
                  <a-input v-model.trim="form.gr_comm_zip" :max-length="6" placeholder="请输入通讯地址邮编"></a-input>
                </a-form-model-item>
                <!-- 家庭月收入 -->
                <a-form-model-item ref="gr_family_month_income" class="gr_family_month_income" label="家庭月收入"
                                   prop="gr_family_month_income">
                  <a-input v-model.trim="form.gr_family_month_income" placeholder="请输入家庭月收入"></a-input>
                </a-form-model-item>
                <!-- 个人年收入 -->
                <a-form-model-item ref="gr_year_income" class="gr_year_income" label="个人年收入" prop="gr_year_income">
                  <a-input v-model.trim="form.gr_year_income" placeholder="请输入个人年收入"></a-input>
                </a-form-model-item>
                <!-- 从业时间 -->
                <a-form-model-item label="从业时间" class="gr_work_begindate" ref="gr_work_begindate"
                                   prop="gr_work_begindate">
                  <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD" v-model.trim="form.gr_work_begindate"/>
                </a-form-model-item>
                <!-- 个人基本帐户行 -->
                <a-form-model-item ref="gr_pay_account" class="gr_pay_account" label="个人基本帐户行" prop="gr_pay_account">
                  <a-input v-model.trim="form.gr_pay_account" placeholder="请输入个人基本帐户行"></a-input>
                </a-form-model-item>
                <!-- 基本帐户帐号 -->
                <a-form-model-item ref="gr_pay_account_bank" class="gr_pay_account_bank" label="基本帐户帐号"
                                   prop="gr_pay_account_bank">
                  <a-input v-model.trim="form.gr_pay_account_bank" placeholder="请输入基本帐户帐号"></a-input>
                </a-form-model-item>
                <!-- 首贷时间 -->
                <a-form-model-item label="首贷时间" class="gr_first_creditdate" ref="gr_first_creditdate"
                                   prop="gr_first_creditdate">
                  <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD" v-model.trim="form.gr_first_creditdate"/>
                </a-form-model-item>

                <!-- 备注 -->
                <a-form-model-item label=" 备注" class="qy_remark" ref="gr_remark" prop="gr_remark">
                  <a-textarea v-model.trim="form.gr_remark" laceholder="请输入备注">
                  </a-textarea>
                </a-form-model-item>
              </a-collapse-panel>
            </a-collapse>

            <!--?企业信息 -->

            <div class="Imageinformation">企业信息</div>

            <!-- 证件号码 -->
            <a-form-model-item label="营业执照号码" class="haoma">
              <a-input v-model.trim="Documentnumber" placeholder="请输入营业执照号码">
              </a-input>
            </a-form-model-item>
            <!-- 经济类型 -->
            <a-form-model-item label="经济类型" prop="qy_org_type" class="qy_org_type">
              <a-select v-model.trim="form.qy_org_type" placeholder="请选择企业经济类型" style="width: 250px">
                <a-select-option v-for="item in EcnmChrctrstcCdlist" :key="item.label" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 国民经济部门 -->
            <a-form-model-item label="国民经济部门" prop="qy_economy_type" class="qy_economy_type">
              <a-select v-model.trim="form.qy_economy_type" placeholder="请选择企业经济类型" style="width: 250px">
                <a-select-option v-for="item in NtnEcnmSctrCdlist" :key="item.label" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 经营品种 -->
            <a-form-model-item label="经营品种" prop="qy_most_business" class="qy_most_business">
              <a-input v-model.trim="form.qy_most_business" placeholder="请输入经营品种"/>
              <!--              <a-select v-model.trim="form.qy_most_business" placeholder="经营品种" style="width: 250px">-->
              <!--                <a-select-option v-for="item in Typeofoperation" :key="item.label" :value="item.value">-->
              <!--                  {{ item.label }}-->
              <!--                </a-select-option>-->
              <!--              </a-select>-->
            </a-form-model-item>
            <!-- 证件生效日期 -->
            <a-form-model-item label="证件生效日期" class="qy_licensedate" ref="qy_licensedate" prop="qy_licensedate">
              <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD" v-model.trim="form.qy_licensedate"/>
            </a-form-model-item>
            <!-- 证件失效日期 -->
            <a-form-model-item label="证件失效日期" class="qy_license_maturity" ref="qy_license_maturity"
                               prop="qy_license_maturity">
              <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD" v-model.trim="form.qy_license_maturity"/>
              <a-checkbox style="margin-left: 50px;"
                          @change="$event.target.checked ? form.qy_license_maturity = '9999-12-31' : form.qy_license_maturity = ''">
                长期
              </a-checkbox>

            </a-form-model-item>
            <!-- 企业成立日期 -->
            <a-form-model-item label="企业成立日期" class="qy_setup_date" ref="qy_setup_date">
              <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD" v-model.trim="form.qy_setup_date"/>
            </a-form-model-item>
            <!-- 企业所在国家(地区） -->
            <!--            <a-form-model-item label="企业所在国家(地区）" prop="qy_country_code" ref="qy_country_code" class="haoma">-->
            <!--              <a-input :disabled="true" v-model.trim="form.qy_country_code">-->
            <!--              </a-input>-->
            <!--            </a-form-model-item>-->

            <!-- 省直辖市/自治区地区 -->
            <a-form-model-item class="region_code" ref="qy_region_code" label="请选择所属地区" prop="qy_region_code">
              <!--              <a-cascader v-model.trim="form.qy_region_code" :options="citylist" placeholder="请选择所属地区" />-->
<!--              <el-cascader v-model.trim="form.qy_region_code" :options="citylist" placeholder="请选择所属地区"-->
<!--                           :props="{ emitPath: false }" style="width:200px"/>-->
              <RegionChooseDialog
                  :channelNo="form.channel_no"
                  :appNo="app_no"
                  v-model.trim="form.qy_region_code"
                  @change="handleSelectionChangeQy"
              />
            </a-form-model-item>
            <!-- 企业注册地址 -->
            <a-form-model-item label="企业注册地址" prop="qy_register_add" ref="qy_register_add" class="qy_register_add">
              <a-input v-model.trim="form.qy_register_add" placeholder="请输入企业注册地址">
              </a-input>
            </a-form-model-item>

            <!-- 国标行业分类（新标椎） WorkCorpIndusCDlist-->
            <!--            <a-form-model-item class="qy_industry_type2" ref="qy_industry_type2" prop="qy_industry_type2" label="国标行业分类">-->
            <!--              &lt;!&ndash;              <a-cascader v-model.trim="form.qy_industry_type2" :options="WorkCorpIndusCDlist" placeholder="请选国标行业分类" />&ndash;&gt;-->
            <!--              <el-cascader v-model.trim="form.qy_industry_type2" :options="WorkCorpIndusCDlist" placeholder="请选国标行业分类"-->
            <!--                :show-all-levels="false" :props="{ emitPath: false }" style="width:200px" />-->
            <!--            </a-form-model-item>-->
            <!-- 企业上年末企业从业人员 -->
            <a-form-model-item label="企业上年末企业从业人员" class="qy_employee_number" ref="qy_employee_number"
                               prop="qy_employee_number">
              <a-input v-model.trim="form.qy_employee_number" type="number" placeholder="请输入上年末企业从业人员">
                <template #suffix>
                  <div class="yaun">人次</div>
                </template>
              </a-input>
            </a-form-model-item>
            <!-- 企业上年营业收入 -->
            <a-form-model-item label="企业上年营业收入" class="qy_previous_income" ref="qy_previous_income"
                               prop="qy_previous_income">
              <a-input v-model.trim="form.qy_previous_income" type="number" placeholder="请输入企业上年营业收入">
                <template #suffix>
                  <div class="yaun">万元</div>
                </template>
              </a-input>
            </a-form-model-item>
            <!-- 企业上年末资产总额 -->
            <a-form-model-item label="企业上年末资产总额" class="qy_previous_asset" ref="qy_previous_asset"
                               prop="qy_previous_asset">
              <a-input v-model.trim="form.qy_previous_asset" type="number" placeholder="请输入资产总额">
                <template #suffix>
                  <div class="yaun">万元</div>
                </template>
              </a-input>
            </a-form-model-item>
            <!-- 企业类型 -->
            <a-form-model-item label="企业类型" prop="qy_scope1" class="qy_scope1">
              <a-select v-model.trim="form.qy_scope1" placeholder="请选择企业类型" style="width: 250px">
                <a-select-option v-for="item in firmEntpTp" :key="item.label" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <!-- 企业贷款卡号 -->
            <a-form-model-item label="企业贷款卡号" class="qy_loan_card_no" ref="qy_loan_card_no" prop="qy_loan_card_no">
              <a-input v-model.trim="form.qy_loan_card_no" placeholder="请输入企业贷款卡号">
              </a-input>
            </a-form-model-item>
            <!-- 企业贷款卡密码 -->
            <a-form-model-item label="企业贷款卡密码" class="qy_loan_card_password" ref="qy_loan_card_password"
                               prop="qy_loan_card_password">
              <a-input v-model.trim="form.qy_loan_card_password" placeholder="请输入企业贷款卡密码">
              </a-input>
            </a-form-model-item>
            <!--企业贷款号是否有效  -->
            <a-form-model-item label="企业贷款号是否有效" class="qy_loan_flag" ref="qy_loan_flag" prop="qy_loan_flag">
              <a-radio-group v-model.trim="form.qy_loan_flag">
                <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!-- 注册资本 -->
            <a-form-model-item label="注册资本" class="qy_register_capital" ref="qy_register_capital"
                               prop="qy_register_capital">
              <a-input v-model.trim="form.qy_register_capital" type="number" placeholder="请输入注册资本">
                <template #suffix>
                  <div>元</div>
                </template>
              </a-input>
              <div class="bizhong">
                <span>币种</span>
                <input :disabled="true" type="text" v-model.trim="rmb"/>
              </div>
            </a-form-model-item>
            <!-- 实收资本 -->
            <a-form-model-item label="实收资本" class="qy_paicl_up_capital" ref="qy_paicl_up_capital"
                               prop="qy_paicl_up_capital">
              <a-input v-model.trim="form.qy_paicl_up_capital" type="number" placeholder="请输入实收资本">
                <template #suffix>
                  <div>元</div>
                </template>
              </a-input>
              <div class="bizhong">
                <span>币种</span>
                <input :disabled="true" type="text" v-model.trim="rmb"/>
              </div>
            </a-form-model-item>
            <!-- 企业联系电话 -->
            <a-form-model-item label="企业联系电话" class="qy_office_tel" ref="qy_office_tel" prop="qy_office_tel">
              <a-input v-model.trim="form.qy_office_tel" type="number" placeholder="请输入企业联系电话">
              </a-input>
            </a-form-model-item>
            <!-- 企业财务部联系电话 -->
            <a-form-model-item label="企业财务部联系电话" class="qy_finance_dept_tel" ref="qy_finance_dept_tel"
                               prop="qy_finance_dept_tel">
              <a-input v-model.trim="form.qy_finance_dept_tel" type="number" placeholder="请输入企业财务部联系电话">
              </a-input>
            </a-form-model-item>

            <a-collapse>
              <a-collapse-panel header="更多信息">
                <!-- 企业基本账户是否在本行开立 -->
                <a-form-model-item label="企业基本账户是否在本行开立" class="qy_finance_ornot" ref="qy_budget_type">
                  <a-radio-group v-model.trim="form.qy_budget_type">
                    <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 企业基本账户行 -->
                <a-form-model-item label="企业基本账户行" class="qy_basic_bank" ref="qy_basic_bank" prop="qy_basic_bank">
                  <a-input v-model.trim="form.qy_basic_bank" type="number" placeholder="请输入企业基本账户行">
                  </a-input>
                </a-form-model-item>
                <!-- 企业基本账户号 -->
                <a-form-model-item label="企业基本账户号" class="qy_basic_account" ref="qy_basic_account"
                                   prop="qy_basic_account">
                  <a-input v-model.trim="form.qy_basic_account" placeholder="请输入企业基本账户号">
                  </a-input>
                </a-form-model-item>
                <!-- 企业国内办公行政区别 -->
                <a-form-model-item class="qy_office_area_code" ref="qy_office_area_code" label="省直辖市/企业国内办公行政区划">
                  <el-cascader v-model.trim="form.qy_office_area_code" :options="citylist" placeholder="请选择所属地区"
                               :props="{ emitPath: false }" style="width:200px"/>
                </a-form-model-item>
                <!-- 企业办公地址 -->
                <a-form-model-item label="企业办公地址" prop="qy_office_add" ref="qy_office_add" class="qy_office_add">
                  <a-input v-model.trim="form.qy_office_add" placeholder="请输入企业办公地址">
                  </a-input>
                </a-form-model-item>
                <!-- 办公地址更新日期 -->
                <a-form-model-item label="办公地址更新日期" class="qy_office_add_change_date" ref="qy_office_add_change_date">
                  <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD"
                                 v-model.trim="form.qy_office_add_change_date"/>
                </a-form-model-item>
                <!-- 是否市场经营户 -->
                <a-form-model-item label="是否市场经营户" class="qy_finance_ornot" ref="qy_finance_ornot"
                                   prop="qy_finance_ornot">
                  <a-radio-group v-model.trim="form.qy_finance_ornot">
                    <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!--  !隶属-->
                <a-form-model-item label="隶属" class="qy_enterprise_belong" ref="qy_enterprise_belong"
                                   prop="qy_enterprise_belong">
                  <a-select v-model.trim="form.qy_enterprise_belong" placeholder="请选择隶属" style="width: 250px">
                    <a-select-option v-for="item in Besubordinatetolist" :key="item.label" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <!-- 是否上市公司 -->
                <a-form-model-item label="是否上市公司" class="qy_listing_corp_ornot" ref="qy_listing_corp_ornot">
                  <a-radio-group v-model.trim="form.qy_listing_corp_ornot">
                    <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 是否私人控股 -->
                <a-form-model-item label="是否私人控股" class="qy_private_lyheld" ref="qy_private_lyheld"
                                   prop="qy_private_lyheld">
                  <a-radio-group v-model.trim="form.qy_private_lyheld">
                    <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 上市情况 -->

                <a-form-model-item label="上市情况" class="qy_listed_company" ref="qy_listed_company"
                                   prop="qy_listed_company">
                  <a-select v-model.trim="form.qy_listed_compan" placeholder="请选择上市情况" style="width: 250px">
                    <a-select-option v-for="item in Comeintothemarket" :key="item.label" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <!-- 企业邮政编码 -->
                <a-form-model-item label="企业邮政编码" class="qy_office_zip" ref="qy_office_zip" prop="qy_office_zip">
                  <a-input v-model.trim="form.qy_office_zip" type="text" :maxLength="6" placeholder="企业邮政编码">
                  </a-input>
                </a-form-model-item>

                <!-- 企业他行开户行 -->
                <a-form-model-item label="企业他行开户行" class="qy_other_bank" ref="qy_other_bank" prop="qy_other_bank">
                  <a-input v-model.trim="form.qy_other_bank" placeholder="请输入企业他行开户行">
                  </a-input>
                </a-form-model-item>
                <!-- 企业他行开户账号 -->
                <a-form-model-item label="企业他行开户账号" class="qy_other_bank_account" ref="qy_other_bank_account"
                                   prop="qy_other_bank_account">
                  <a-input v-model.trim="form.qy_other_bank_account" type="number" placeholder="请输入企业他行开户账号">
                  </a-input>
                </a-form-model-item>

                <!-- 企业在我行首次开立账户时间 -->
                <a-form-model-item label="企业在我行首次开立账户时间" class="qy_account_date" ref="qy_account_date">
                  <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD" v-model.trim="form.qy_account_date"/>
                </a-form-model-item>
                <!-- 企业首贷时间 -->
                <a-form-model-item label="企业首贷时间" class="qy_first_credit_date" ref="qy_first_credit_date">
                  <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD" v-model.trim="form.qy_first_credit_date"/>
                </a-form-model-item>
                <!-- 企业与我行建立信贷关系时间 -->
                <a-form-model-item label="企业与我行建立信贷关系时间" class="qy_first_loan_date" ref="qy_first_loan_date">
                  <a-date-picker style="width: 250px" valueFormat="YYYY-MM-DD" v-model.trim="form.qy_first_loan_date"/>
                </a-form-model-item>
                <!-- 财务报表类型 -->
                <a-form-model-item label="财务报表类型" class="qy_finance_belong" ref="qy_finance_belong"
                                   prop="qy_finance_belong">
                  <a-select v-model.trim="form.qy_finance_belong" placeholder="财务报表类型" style="width: 250px">
                    <a-select-option v-for="(item, ind) in Financialaffairslist" :key="ind" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>

                <!-- 企业退税登记证 -->
                <a-form-model-item label="企业退税登记证" class="qy_exchange_id" ref="qy_exchange_id" prop="qy_exchange_id">
                  <a-input v-model.trim="form.qy_exchange_id" placeholder="请输入企业退税登记证">
                  </a-input>
                </a-form-model-item>
                <!-- 有无进出口经营权 -->
                <a-form-model-item label="有无进出口经营权" class="qy_has_ieright" ref="qy_has_ieright" prop="qy_has_ieright">
                  <a-radio-group v-model.trim="form.qy_has_ieright">
                    <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 是否培养重点中小企业 -->
                <a-form-model-item label="是否培养重点中小企业" class="qy_private" ref="qy_private" prop="qy_private">
                  <a-radio-group v-model.trim="form.qy_private">
                    <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 是否集团客户 -->
                <a-form-model-item label="是否集团客户" class="qy_ecgroup_flag" ref="qy_ecgroup_flag" prop="qy_ecgroup_flag">
                  <a-radio-group v-model.trim="form.qy_ecgroup_flag">
                    <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 是否政府融乎台（银监） -->
                <a-form-model-item label="是否政府融乎台" class="qy_back_ground_flag" ref="qy_back_ground_flag"
                                   prop="qy_back_ground_flag">
                  <a-radio-group v-model.trim="form.qy_back_ground_flag">
                    <a-radio v-for="item in qyfinanceornot" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 政府融资平台分类（银监） -->
                <a-form-model-item label="政府融资平台分类（银监）" class="qy_back_ground_flag" ref="qy_back_ground_flag">
                  <a-radio-group>
                    <a-radio value="检测"> 检测</a-radio>
                    <a-radio value="监管"> 监管</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 是否政府融资平台（人行） -->
                <!-- 企业环境、安全等重大风险企业 -->
                <a-form-model-item label="企业环境、安全等重大风险企业" class="qy_environment_safe_risk" ref="qy_environment_safe_risk"
                                   prop="qy_environment_safe_risk">
                  <a-select v-model.trim="form.qy_environment_safe_risk" placeholder="请选择风险类型" style="width: 250px">
                    <a-select-option v-for="item in Enterpriseenvironment" :key="item.label" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <!--!企业风险预警级别 -->
                <!--!企业灰名单风险级别 -->
                <!-- 公司E-Mail -->
                <a-form-model-item label="公司E-Mail" class="qy_email_add" ref="qy_email_add" prop="qy_email_add">
                  <a-input v-model.trim="form.qy_email_add" type="email" placeholder="请输入公司E-Mail">
                  </a-input>
                </a-form-model-item>
                <!-- 企业公司网址 -->
                <a-form-model-item label="企业公司网址" class="qy_web_add" ref="qy_web_add" prop="qy_web_add">
                  <a-input v-model.trim="form.qy_web_add" placeholder="请输入企业公司网址">
                  </a-input>
                </a-form-model-item>
                <!-- 企业传真电话 -->
                <a-form-model-item label="企业传真电话" class="qy_office_fax" ref="qy_office_fax" prop="qy_office_fax">
                  <a-input v-model.trim="form.qy_office_fax" placeholder="请输入企业传真电话">
                  </a-input>
                </a-form-model-item>
                <!-- !企业合法经营情况 -->
                <!-- 企业客户历史沿革、管理水平简介 -->
                <a-form-model-item label="企业客户历史沿革、管理水平简介" class="qy_customer_history" ref="qy_customer_history"
                                   prop="qy_customer_history">
                  <a-textarea v-model.trim="form.qy_customer_history" placeholder="请输入企业客户历史沿革、管理水平简介">
                  </a-textarea>
                </a-form-model-item>
                <!-- 企业主要产品情况 -->
                <a-form-model-item label="企业主要产品情况" class="qy_main_production" ref="qy_main_production"
                                   prop="qy_main_production">
                  <a-textarea v-model.trim="form.qy_main_production" placeholder="请输入企业主要产品情况">
                  </a-textarea>
                </a-form-model-item>
                <!-- !企业摊位或店面 -->
                <!-- 企业经营场地面积 -->
                <a-form-model-item label="企业经营场地面积" class="qy_work_field_area" ref="qy_work_field_area"
                                   prop="qy_work_field_area">
                  <a-input v-model.trim="form.qy_work_field_area" :max-length="10" placeholder="请输入企业经营场地面积">
                    <template #suffix>
                      <div class="yaun">m3</div>
                    </template>
                  </a-input>
                </a-form-model-item>
                <!-- 企业经莒场地所有权 -->
                <a-form-model-item label="企业经莒场地所有权" class="qy_work_field_fee" ref="qy_work_field_fee"
                                   prop="qy_work_field_fee">
                  <a-radio-group v-model.trim="form.qy_work_field_fee">
                    <a-radio v-for="item in work_field_fee" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <!-- 企业上级公司名称（选填） -->
                <a-form-model-item label="企业上级公司名称（选填）" class="qy_super_corp_name" ref="qy_super_corp_name"
                                   prop="qy_super_corp_name">
                  <a-input v-model.trim="form.qy_super_corp_name" placeholder="请输入企业上级公司名称">
                  </a-input>
                </a-form-model-item>
                <!-- 企业上级公司组织机构代码（选填） -->
                <a-form-model-item label="企业上级公司组织机构代码（选填）" class="qy_super_cert_id" ref="qy_super_cert_id"
                                   prop="qy_super_cert_id">
                  <a-input v-model.trim="form.qy_super_cert_id" placeholder="请输入企业上级公司组织机构代码">
                  </a-input>
                </a-form-model-item>
                <!-- 企业上级公司贷款卡编号（选填） -->
                <a-form-model-item label="企业上级公司贷款卡编号（选填）" class="qy_super_loan_card_no" ref="qy_super_loan_card_no"
                                   prop="qy_super_loan_card_no">
                  <a-input v-model.trim="form.qy_super_loan_card_no" placeholder="请输入企业上级公司贷款卡编号">
                  </a-input>
                </a-form-model-item>
                <!-- 备注 -->
                <a-form-model-item label="备注" class="qy_remark" ref="qy_remark" prop="qy_remark">
                  <a-textarea v-model.trim="form.qy_remark" laceholder="请输入备注">
                  </a-textarea>
                </a-form-model-item>
              </a-collapse-panel>
            </a-collapse>
            <!-- ?影像信息 -->
            <div class="Imageinformation">影像信息</div>
            <!-- 营业执照 -->
            <a-form-model-item class="pricebox Imageinfor" ref="pricebox" label="营业执照" prop="personagee">
              <div class="row">

                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">营业执照</span>
                  <a-upload name="files" accept="image/*" list-type="picture-card" class="avatar-uploader"
                            :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="BusinesslicenseUpload">
                    <uploadImg v-show="verifyvadata.Businesslicenseurl"
                               @Uploaderror="verifyvadata.Businesslicenseloading = $event"
                               @onloadsuccsse="verifyvadata.Businesslicenseurl = $event"
                               @channelfileno="file_list[3].channel_file_no = $event"
                               @fileNo="file_list[3].file_no = $event"
                               @fileData="verifyvadata.BusinesslicenseFile = $event" ref="Businesslicense">
                    </uploadImg>
                    <div v-show="verifyvadata.Businesslicenseloading == false &&
                      !verifyvadata.Businesslicenseurl
                      ">
                      <a-icon type="plus"/>
                    </div>
                    <div v-if="verifyvadata.Businesslicenseloading == true &&
                      verifyvadata.Businesslicenseurl == ''
                      " class="example">
                      <a-spin/>
                    </div>
                  </a-upload>
                  <span v-if="verifyvadata.Businesslicense == false" class="erero">请先上传图片</span>
                </div>
              </div>
            </a-form-model-item>
            <!-- 身份证 -->
            <a-form-model-item class="pricebox " ref="pricebox" prop="Frontofidentitycard">
              <template #label>

                <span class="custom-label"> <span style="color: #ff0000;">*</span>法人代表身份证</span>
              </template>
              <div class="row">

                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">身份证人像面</span>
                  <a-upload name="files" accept="image/*" list-type="picture-card" class="avatar-uploader"
                            :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="changeIdBack">
                    <uploadImg v-show="verifyvadata.ReverseofIDcardurl" @
                               Uploaderror="verifyvadata.ReverseofIDcardloading = $event"
                               @onloadsuccsse="verifyvadata.ReverseofIDcardurl = $event"
                               @channelfileno="file_list[1].channel_file_no = $event"
                               @fileNo="file_list[1].file_no = $event"
                               @fileData="verifyvadata.ReverseofIDcardFile = $event" ref="IdBack">
                    </uploadImg>
                    <div v-show="verifyvadata.ReverseofIDcardloading == false &&!verifyvadata.ReverseofIDcardurl ">
                      <a-icon type="plus"/>
                    </div>
                    <div v-if="verifyvadata.ReverseofIDcardloading &&
                      verifyvadata.ReverseofIDcardurl == ''
                      " class="example">
                      <a-spin/>
                    </div>
                  </a-upload>
                  <span v-if="verifyvadata.ReverseofIDcard == false" class="erero">请先上传照片</span>
                </div>

                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">身份证国徽面</span>
                  <a-upload name="files" accept="image/*" list-type="picture-card" class="avatar-uploader"
                            :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="changeIdUpload">
                    <uploadImg v-show="verifyvadata.Frontofidentitycardurl"
                               @Uploaderror="verifyvadata.Frontofidentitycardloading = $event"
                               @onloadsuccsse="verifyvadata.Frontofidentitycardurl = $event "
                               @channelfileno="file_list[0].channel_file_no = $event"
                               @fileNo="file_list[0].file_no = $event"
                               @fileData="verifyvadata.FrontofidentitycardFile = $event"
                               ref="IdUp">
                    </uploadImg>
                    <div v-show="verifyvadata.Frontofidentitycardloading == false &&
                      !verifyvadata.Frontofidentitycardurl
                      ">
                      <a-icon type="plus"/>
                    </div>
                    <div v-if="verifyvadata.Frontofidentitycardloading == true &&
                      verifyvadata.Frontofidentitycardurl == ''
                      " class="example">
                      <a-spin/>
                    </div>
                  </a-upload>
                  <span v-if="verifyvadata.Frontofidentitycard == false" class="erero">请先上传照片</span>
                </div>
                <div class="third-item">
                  <FaceCollect ref="faceCollect" :app_no="app_no" :channel_no="form.channel_no"
                               :custname="form.gr_full_name" :id_no="form.gr_cert_id" @success="portraitUpload2"
                               @fail="portraitUploadFail"></FaceCollect>
                  <span v-if="verifyvadata.Humanface == false" class="erero">请先上传图片</span>
                </div>

              </div>
            </a-form-model-item>


            <!-- 个人征信授权 -->
            <a-form-model-item class="pricebox" ref="pricebox" label="授权" prop="personagee">
              <div class="row">

                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">个人征信授权</span>
                  <a-upload name="files" accept="image/*, .pdf, .doc, .docx" list-type="picture-card"
                            class="avatar-uploader" :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="personage">
                    <uploadImg v-show="verifyvadata.personageurl"
                               @Uploaderror="verifyvadata.personageloading = $event"
                               @onloadsuccsse="verifyvadata.personageurl = $event"
                               @channelfileno="file_list[4].channel_file_no = $event"
                               @fileNo="file_list[4].file_no = $event"
                               @fileData="verifyvadata.personageFile = $event" ref="personage">
                    </uploadImg>
                    <div v-show="verifyvadata.personageloading == false &&
                      !verifyvadata.personageurl
                      ">
                      <a-icon type="plus"/>
                    </div>
                    <div v-if="verifyvadata.personageloading == true &&
                      verifyvadata.personageurl == ''
                      " class="example">
                      <a-spin/>
                    </div>
                  </a-upload>
                  <a-button type="primary" @click="downLoadTemplate('TMPL_IND_CRD_AUTH')">下载模板</a-button>
                  <span v-if="verifyvadata.personage == false" class="erero">请先上传图片</span>
                </div>

                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">企业征信授权书</span>
                  <a-upload name="files" accept="image/*, .pdf, .doc, .docx" list-type="picture-card"
                            class="avatar-uploader" :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="Thirdparty">
                    <uploadImg v-show="verifyvadata.Thirdpartyurl"
                               @Uploaderror="verifyvadata.Thirdpartyloading = $event"
                               @onloadsuccsse="verifyvadata.Thirdpartyurl = $event"
                               @channelfileno="file_list[5].channel_file_no = $event"
                               @fileNo="file_list[5].file_no = $event"
                               @fileData="verifyvadata.ThirdpartyFile = $event" ref="Thirdparty">
                    </uploadImg>
                    <div v-show="verifyvadata.Thirdpartyloading == false &&
                      !verifyvadata.Thirdpartyurl
                      ">
                      <a-icon type="plus"/>
                    </div>
                    <div v-if="verifyvadata.Thirdpartyloading == true &&
                      verifyvadata.Thirdpartyurl == ''
                      " class="example">
                      <a-spin/>
                    </div>
                  </a-upload>
                  <a-button type="primary" @click="downLoadTemplate('TMPL_ENT_CRD_AUTH')">下载模板</a-button>
                  <span v-if="verifyvadata.Thirdparty == false" class="erero">请先上传图片</span>
                </div>
              </div>
            </a-form-model-item>

            <!-- 企业章程 -->
            <a-form-model-item class="pricebox" ref="pricebox" label="企业章程" prop="personagee">
              <div class="row">
                <!-- 企业章程-->
                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">企业章程</span>
                  <a-upload name="files" accept="image/*, .pdf, .doc, .docx" list-type="picture-card"
                            class="avatar-uploader" :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="ArticlesofassociationUpload">
                    <uploadImg v-show="verifyvadata.Articlesofassociationurl"
                               @Uploaderror="verifyvadata.Articlesofassociationloading = $event"
                               @onloadsuccsse="verifyvadata.Articlesofassociationurl = $event"
                               @channelfileno="file_list[6].channel_file_no = $event"
                               @fileData="verifyvadata.ArticlesofassociationFile = $event"
                               @fileNo="file_list[6].file_no = $event"
                               ref="Articlesofassociation">
                    </uploadImg>
                    <div v-show="verifyvadata.Articlesofassociationloading == false &&
                      !verifyvadata.Articlesofassociationurl
                      ">
                      <a-icon type="plus"/>
                    </div>
                    <div v-if="verifyvadata.Articlesofassociationloading == true &&
                      verifyvadata.Articlesofassociationurl == ''
                      " class="example">
                      <a-spin/>
                    </div>
                  </a-upload>
                  <a-button type="primary" @click="downLoadTemplate('TMPL_ENT_NOTES')">下载模板</a-button>
                  <span v-if="verifyvadata.Articlesofassociation == false" class="erero">请先上传图片</span>
                </div>
              </div>
            </a-form-model-item>

            <!-- 决议书 -->
            <a-form-model-item class="pricebox" ref="pricebox" label="决议书" prop="personagee">
              <div class="row">
                <!-- 决议书-->
                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">决议书</span>
                  <a-upload name="files" accept="image/*, .pdf, .doc, .docx" list-type="picture-card"
                            class="avatar-uploader" :show-upload-list="false" :action="urlConfig.busiURL +
                      '/file/management/scfs/uploadscfs?channel_no=' +
                      this.form.channel_no
                      " :headers="headers" :beforeUpload="handleBeforeUpload" @change="ResolutionpaperUpload">
                    <uploadImg v-show="verifyvadata.Resolutionpaperurl"
                               @Uploaderror="verifyvadata.Resolutionpaperloading = $event"
                               @onloadsuccsse="verifyvadata.Resolutionpaperurl = $event"
                               @channelfileno="file_list[7].channel_file_no = $event"
                               @fileData="verifyvadata.ResolutionpaperFile = $event"
                               @fileNo="file_list[7].file_no = $event"
                               ref="Resolutionpaper">
                    </uploadImg>
                    <div v-show="verifyvadata.Resolutionpaperloading == false &&
                      !verifyvadata.Resolutionpaperurl
                      ">
                      <a-icon type="plus"/>
                    </div>
                    <div v-if="verifyvadata.Resolutionpaperloading == true &&
                      verifyvadata.Resolutionpaperurl == ''
                      " class="example">
                      <a-spin/>
                    </div>
                  </a-upload>
                  <a-button type="primary" @click="downLoadTemplate('TMPL_ENT_DECI')">下载模板</a-button>
                  <span v-if="verifyvadata.Resolutionpaper == false" class="erero">请先上传图片</span>
                </div>
              </div>
            </a-form-model-item>

            <!--!手机号 -->
            <a-form-model-item label="手机号" class="mobile" ref="mobile" prop="mobile">
              <a-input v-model.trim="form.mobile" type="number" placeholder="请输入手机号"></a-input>
              <div></div>
            </a-form-model-item>
            <!-- 验证码 -->
            <a-form-model-item label="验证码" class="code" ref="verify_code" prop="verify_code">
              <a-input v-model.trim="form.verify_code" :max-length="6" placeholder="请输入验证码">

                <CountdownBtn slot="suffix" :wait-time="60" :error-wait-time="10" :send-call="sendcode"></CountdownBtn>
              </a-input>
              <div></div>
            </a-form-model-item>
          </a-form-model>
          <QRConfirm :url="qrConfirmUrl" :display.sync="qrConfirmShow" busi_intro="企业贷款"
                     :username="form.account_name"></QRConfirm>
        </div>
      </div>
    </a-card>
    <!-- 确认 -->
    <a-modal title="提示" class="confirmloading" :maskClosable="true" @cancel="confirmshow = false" :centered="true"
             :visible="confirmshow">
      <div class="text">
        申请贷款需要您个人征信以及第三方数据信息
        查询和使用授权，是否确认授权？确认授权后 将提交该信贷申请
      </div>
      <template v-slot:footer>
        <!-- 贷款 -->
        <div class="custom-footer">
          <a-button type="primary" @click="ConfirmSave">确认</a-button>
          <a-button @click="confirmshow = false">取消</a-button>
          <a-button @click="ConfirmQRPush">邀请扫码办理</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 取消 -->
    <a-modal title="提示" class="confirmloading" :maskClosable="true" @cancel="cancelshow = false" :centered="true"
             :visible="cancelshow">
      <div class="canceltext">
        <!--个人开户  -->

        是否取消申请？取消后该页面 信息将不会被保存
      </div>
      <template v-slot:footer>
        <!-- 提现 -->
        <div class="custom-footer">
          <a-button type="primary" @click="
            cancelshow = false
          $router.go(-1)
            ">确认
          </a-button>
          <a-button @click="cancelshow = false">取消</a-button>

        </div>
      </template>
    </a-modal>
    <a-modal title="提示" class="confirmloading" :maskClosable="true" @cancel="cancelRestore" :centered="true"
             :visible="restoreShow">
      <div class="canceltext">
        检测到您有失败的申请，是否恢复上一次的申请？
      </div>
      <template v-slot:footer>
        <div class="custom-footer">
          <a-button type="primary" @click="confirmRestore">确认</a-button>
          <a-button @click="cancelRestore">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {BizDict, BizDictCascade} from "../../../utils/bizDict/kvMap.js";

const token = () => sessionStorage.getItem("token");
const ENT_APPLY_KEY = "__ENT_APPLY_KEY__"
import {validatorCardNumber} from "@/utils/validatorMethods.js";
import uploadImg from "../../../components/uploadImg/index";
import urlConfig from "@/utils/urlConfig";

import dayjs from "dayjs";
import CustChooseDialog from "@/view/components/ChooseDialog/CustChooseDialog.vue";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import RegionChooseDialog from "@/view/components/ChooseDialog/RegionChooseDialog.vue";
import {contractDocTemp} from "@/utils/contractDocTemp";
import ProductChooseDialog from "@/view/components/ChooseDialog/ProductChooseDialog.vue";
import CountdownBtn from "@/view/components/CountdownBtn.vue";
import QRConfirm from "@/view/components/QRConfirm.vue";
import FaceCollect from "@/view/components/FaceCollect.vue";

export default {
  name: "Enterpriseloan",
  data() {
    return {
      qrConfirmUrl: "",
      qrConfirmShow: false,
      pdfImg: require('../../../assets/img/pdf-img.png'),
      wordImg: require('../../../assets/img/word-img.png'),
      confirmshow: false,
      cancelshow: false,
      restoreShow: false,
      urlConfig: urlConfig,
      latitude: "", // 经度
      longitude: "", // 维度
      labelCol: {span: 4},
      wrapperCol: {span: 20},
      // 民族列表
      ethnicitylist: BizDict.RaceCd,
      // 性别list
      sexlist: BizDict.GndFlg,
      // 职业列表
      OcpCdlist: BizDictCascade.OcpCd,
      // 学历列表
      eduexperiencelist: BizDict.HestEdct,
      // 婚姻
      marriagelist: BizDict.MrtlSt,
      // 城市
      citylist: BizDictCascade.DstcCd,
      // 联系人关系
      relationshiplist: BizDict.AtchRltnpTp,

      // 经营类型

      certnum: "", // 验证码客户省份证号
      cardNo: "", // 银行卡号
      app_no: "", //	应用编号
      Typeofoperation: BizDict.EntpTp,
      // 企业类型
      firmEntpTp: [
        {
          label: "大型企业",
          value: "2 ",
        },
        {
          label: "中型企业",
          value: "3",
        },
        {
          label: "小型企业",
          value: "4",
        },
        {
          label: "微型企业",
          value: "5",
        },
        {
          label: "其他",
          value: "9",
        },
      ],
      // 国标行业分类
      WorkCorpIndusCDlist: BizDictCascade.WorkCorpIndusCD,

      // 企业经济类型
      EcnmChrctrstcCdlist: BizDict.EcnmChrctrstcCd,
      // 国民经济部门
      NtnEcnmSctrCdlist: BizDict.NtnEcnmSctrCd,

      // 产业结构调整类型
      industry: [
        {
          label: "鼓励",
          value: "010",
        },
        {
          label: "限制",
          value: "020",
        },
        {
          label: "淘汰",
          value: "030",
        },
        {
          label: "未列入目录",
          value: "040",
        },
      ],
      // 工业转型升级标识
      upgrade: [
        {
          label: "是",
          value: "1",
        },
        {
          label: "否",
          value: "2",
        },
      ],
      // 市场经营户
      qyfinanceornot: [
        {
          label: "是",
          value: "1",
        },
        {
          label: "否",
          value: "2",
        },
      ],
      // 机构树形数组

      treeData: [],
      loading: false,

      // 贷款产品列表
      productName: "",
      // productList: [],
      // productSelected: undefined,

      // 控制影像是否上传
      verifyvadata: {
        // 身份证
        Frontofidentitycard: true, // 正面
        Frontofidentitycardurl: "", // 正面url
        FrontofidentitycardFile: "", // 图片数据
        Frontofidentitycardloading: false,
        ReverseofIDcard: true, // 反面
        ReverseofIDcardurl: "", // 反面
        ReverseofIDcardFile: "", // 反面
        ReverseofIDcardloading: false, // 反面

        // 人脸
        Humanface: true,
        Humanfaceurl: "",
        HumanfaceFile: "",
        Humanfaceloading: false,

        // 营业执照
        Businesslicense: true,
        Businesslicenseurl: "",
        BusinesslicenseFile: "",
        Businesslicenseloading: false,
        // 个人征信授权
        personage: true, // 个人
        personageurl: "", // 个人
        personageFile: "", // 个人
        personageloading: false, // 个人
        Thirdparty: true, // 企业
        Thirdpartyurl: "", // 企业
        ThirdpartyFile: "", // 企业
        Thirdpartyloading: false, // 企业
        // 企业章程
        Articlesofassociation: true, // 企业章程
        Articlesofassociationurl: "", // 企业章程
        ArticlesofassociationFile: "", // 企业章程
        Articlesofassociationloading: false, // 企业章程
        //决议书
        Resolutionpaper: true, //  决议书
        Resolutionpaperurl: "", //  决议书
        ResolutionpaperFile: "", //  决议书
        Resolutionpaperloading: false, //  决议书
      },
      // 最高学位
      HestDgrlist: BizDict.HestDgr,

      // 支农用途
      accept_int_typelist: BizDict.InvFrmUsg,

      // 上传字段
      file_list: [
        // 身份证
        {
          file_no: "",
          file_type: "01",
          channel_file_no: "",
        },
        {
          file_no: "",
          file_type: "02",
          channel_file_no: "",
        },
        // 人脸
        {
          file_no: "",
          file_type: "04",
          channel_file_no: "",
        },
        // 营业执照
        {
          file_no: "",
          file_type: "12",
          channel_file_no: "",
        },
        // 个人
        {
          file_no: "",
          file_type: "14",
          channel_file_no: "",
        },
        // 企业
        {
          file_no: "",
          file_type: "21",
          channel_file_no: "",
        },
        // 企业章程
        {
          file_no: "",
          file_type: "22",
          channel_file_no: "",
        },
        // 决议
        {
          file_no: "",
          file_type: "23",
          channel_file_no: "",
        },
      ],
      // 居住状况

      family_status: [
        {
          label: "自置",
          value: "1",
        },
        {
          label: "按揭",
          value: "2",
        },
        {
          label: "亲属楼宇",
          value: "3",
        },
        {
          label: "集体宿舍",
          value: "4",
        },
        {
          label: "共有住宅",
          value: "5",
        },
        {
          label: "自置",
          value: "6",
        },
        {
          label: "其他",
          value: "7",
        },

        {
          label: "未知",
          value: "9",
        },
      ],
      // 企业环境
      Enterpriseenvironment: [
        {
          label: "非环境、安全等重大风险企业",
          value: "0",
        },
        {
          label: "涉及环境保护违法违规且尚未完成整改的企业",
          value: "1",
        },
        {
          label: "涉及安全生产违法违规且尚未完成整改的企业",
          value: "2",
        },
        {
          label: "涉及落后产能且尚未完成淘汰的企业",
          value: "3",
        },
        {
          label: "涉及职业病预防控制措施不达标且尚未完成整改的企业",
          value: "4",
        },
      ],

      // 上市
      Comeintothemarket: [
        {
          label: "A股上市",
          value: "A",
        },
        {
          label: "B股上市",
          value: "B",
        },
        {
          label: "香港上市",
          value: "H",
        },
        {
          label: "海外上市",
          value: "F",
        },
      ],
      // 隶属

      Besubordinatetolist: [
        {
          label: "中央",
          value: "01",
        },
        {
          label: "省部属",
          value: "02",
        },
        {
          label: "市属",
          value: "03",
        },
        {
          label: "区、县属及以下",
          value: "04",
        },
      ],
      // 财务报表

      Financialaffairslist: [
        {
          label: "通用财务报表(简易版)",
          value: "000",
        },
        {
          label: "事业类单位财务报表",
          value: "019",
        },
        {
          label: "新会计财务报表",
          value: "020",
        },
        {
          label: "同业客户财务报表",
          value: "030",
        },
        {
          label: "通用财务报表(简易版)",
          value: "040",
        },
        {
          label: "通用财务报表(简易版)",
          value: "050",
        },
      ],

      work_field_fee: [
        {
          label: "自有",
          value: "1",
        },
        {
          label: "租赁",
          value: "2",
        },
        {
          label: "其他",
          value: "3",
        },
      ],
      // 客户分类
      rmb: "人民币",
      Customerclassification: [
        {
          label: "本地客户",
          value: "010",
        },
        {
          label: "户籍在外客户",
          value: "020",
        },
        {
          label: "实体在外客户",
          value: "030",
        },
        {
          label: "两头在外客户",
          value: "040",
        },
      ],
      // 战略新兴产业类型
      new_industry_typelist: [
        {
          label: "节能环保",
          value: "010",
        },
        {
          label: "新一代信息技术",
          value: "020",
        },
        {
          label: "生物医药",
          value: "030",
        },
        {
          label: "高端装备制造",
          value: "040",
        },
        {
          label: "新能源",
          value: "050",
        },
        {
          label: "新材料",
          value: "060",
        },
        {
          label: "新能源汽车",
          value: "070",
        },
        {
          label: "数据创意",
          value: "080",
        },
        {
          label: "相关服务业",
          value: "090",
        },
        {
          label: "未列入目录",
          value: "999",
        },
      ],
      headers: {
        token: token()
      },
      // 客户
      custnumlist: [],
      // 账户
      account_nolist: [],
      show: true,
      Countdown: 60, // 倒计时
      // 绑定的数据不需要提交
      Documentnumber: "", // 证件号码
      form: {
        departmentId: undefined, /// 所属机构
        custnum: undefined, // /客户名称
        mobile: "", // 手机号
        // 产品编号
        product_id: undefined,
        channel_no: "", // 渠道编号
        account_name: "", //客户名称
        account_no: undefined, // 账号填充

        verify_code: "", // 验证码

        business_apply_sum: "", //申请金额
        business_rate: "10", //申请利率
        direction: 'F5219', // 贷款投向
        // direction: [], // 贷款投向
        repay_fund_source: "电商商品销售，来自电商平台回款", //还贷资金来源
        // accept_int_type: [], // 支农用途
        accept_int_type: ['A'], // 支农用途
        industry_adjust: "010", //产业结构调整类型
        industry_upgrade: "2", // 工业转型升级标识
        new_industry_type: "999", //战略新兴产业类型
        qy_org_type: "15", // 企业经济类型
        qy_economy_type: "C01", // 国民经济部门
        qy_most_business: "化工产品、日用百货", // 经营品种
        qy_licensedate: "", //企业 证件生效日期
        qy_license_maturity: "", // 到期时间
        qy_setup_date: "", //企业成立时间
        qy_country_code: "CHN", // 企业所在国家
        qy_region_code: "", // 地址
        qy_register_add: "", //企业注册地址
        qy_office_area_code: "", //企业国内办公行政区划代码
        qy_office_add: "", // 企业办公地址
        qy_office_add_change_date: "", // 办公地址更新日期
        qy_finance_ornot: "2", // 市场经营户
        qy_industry_type2: "F5219", //企业国标行业分类（新标准）

        qy_employee_number: "", //企业上年末企业从业人员
        qy_previous_income: "", //企业上年营业收入
        qy_previous_asset: "", // 企业上年末资产总额
        qy_scope1: undefined, // 企业类型
        qy_budget_type: "1", // 是否在本行开立
        qy_basic_bank: "", //企业基本帐户行
        qy_basic_account: "", //企业基本帐户号
        qy_loan_card_no: "", //企业贷款卡号
        qy_loan_card_password: "", //企业贷款卡密码
        qy_loan_flag: "1", //// 企业贷款号是否有效
        qy_register_capital: "", /// 	企业注册资本(元)
        qy_rc_currency: "01", // 人民币
        qy_paicl_up_capital: "", //实收资本
        qy_enterprise_belong: undefined, // 企业隶属，
        qy_listing_corp_ornot: "", //	企业是否上市公司 1-是 2-否
        qy_private_lyheld: "", // 	企业是否上市公司 1-是 2-否
        qy_listed_company: "", // 上市情况
        qy_office_zip: "", // ，企业邮政编码
        qy_other_bank: "", // 其他开户行
        qy_other_bank_account: "", // 其他开户行账号
        qy_account_date: "", //企业在我行首次开立账户时间
        qy_first_credit_date: "", //企业首贷时间
        qy_first_loan_date: "", //企业与我行建立信贷关系时间
        qy_finance_belong: undefined, //财务报表类型
        qy_exchange_id: "", //企业退税登记证
        qy_has_ieright: "", //有无进出口经营权
        qy_private: "1", //是否培养重点中小企业
        qy_ecgroup_flag: "", //是否集团客户
        qy_back_ground_flag: "1", // 企业是否政府融资平台
        qy_environment_safe_risk: undefined, //企业环境、安全等重大风险企业

        qy_office_tel: "", //企业联系电话
        qy_finance_dept_tel: "", //企业财务部联系电话

        qy_email_add: "", //公司E-Mail
        qy_web_add: "", //企业公司网址
        qy_office_fax: "", //企业传真电话
        qy_customer_history: "", //企业客户历史沿革、管理水平简介

        qy_main_production: "", //企业主要产品情况
        qy_work_field_area: "", //企业经营场地面积（平方米）
        qy_work_field_fee: "", //企业经营场地所有权

        qy_super_corp_name: "", //	企业上级公司名称

        qy_super_cert_id: "", //	企业上级公司组织机构代码

        qy_super_loan_card_no: "", //qy_super_loan_card_no

        // 法定代表人信息
        gr_full_name: "", // 个人姓名
        gr_cert_type: "Ind01", // 身份证
        gr_cert_id: "", //个人证件号码
        gr_sex: "", //个人性别
        gr_birthday: "", //个人出生日期
        gr_cert_enddate: "", //个人证件到期日
        gr_edu_experience: undefined, //	个人最高学历
        gr_edu_degree: undefined, // 最高学位
        gr_sino: "", //个人社会保险号
        gr_is_localhouse: "", //个人是否在本地购置房产 1-是 2-否
        gr_is_localread: "", //个人子女是否在本地就学(大学除外) 1-是 2-否
        gr_ind_customer_type: '010', //	个人客户分类
        gr_hk_atr: "1", //个人户口性质1-本地户籍2-非本地户籍
        gr_if_farmer: "2", //个人是否农户 1-是 2-否
        gr_farmer_sort: "2", // 个人是否市场经营户 1-是 2-否
        gr_market_name: "", //个人市场名称
        gr_nationality: "01", //个人民族01-汉02-其他少数民族
        gr_marriage: undefined, //	个人婚姻状况
        gr_country_code: "CHN", //个人所在国家(地区) 默认：CHN-中华人民共和国
        // gr_region_code: [], //个人省份、直辖市、自治区
        gr_region_code: "", //个人省份、直辖市、自治区
        gr_family_add: "", //个人居住地址
        gr_family_zip: "", //个人居住地址邮编
        gr_corp_name: "", //	个人经营企业名称，默认填申请企业名称
        gr_license_no: "", //个人经营企业营业执照号码，默认填申请企业的
        gr_office_add: "", //个人经营地址，默认填申请企业的
        gr_most_business: "", // 个人经营品种
        gr_family_tel: "", // 个人住宅/经营地址电话
        gr_family_status: undefined, // 个人居住状况
        gr_mobile_telephone: "", //个人手机号码
        gr_email_add: "", //个人电子邮箱
        gr_comm_add: "", // 个人通讯地址
        gr_comm_zip: "", //个人通讯地址邮编
        gr_family_month_income: "", //	个人家庭月收入(元)
        gr_year_income: "", //	个人个人年收入(元)
        gr_work_begindate: "", //	个人从业时间
        gr_pay_account: "", // 个人基本帐户行
        gr_pay_account_bank: "", //	个人基本帐户帐号
        gr_first_creditdate: "", //个人首贷时间
        gr_first_loandate: "", //	个人与我行建立信贷关系时间
        gr_remark: "", //	个人备注
        po_customer_name: "", //	配偶姓名，当已婚时必输
        po_mobile_telephone: "", //	配偶电话，当已婚时必输
        po_cert_type: "Ind01", //	配偶证件类型 默认 Ind01- 身份证，当已婚时必输
        po_sex: "", //	配偶性别，当已婚时必输
        po_cert_id: "", //	配偶身份证，当已婚时必输
      },

      rules: {
        departmentId: [
          {required: true, message: "请选择所属机构", trigger: "change"},
        ],
        custnum: [
          {required: true, message: "请选择客户编号", trigger: "change"},
        ],
        account_name: [
          {required: true, message: "请输入客户名称", trigger: "change"},
        ],
        account_no: [
          {required: true, message: "请输入客户名称", trigger: "change"},
        ],
        channel_no: [
          {required: true, message: "请输入账号", trigger: "blur"},
        ],
        mobile: [
          {required: true, message: "请输入手机号", trigger: "blur"},
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: "手机格式不正确",
            trigger: "blur",
          },
        ],
        // verify_code: [
        //   { required: true, message: "请输入验证码", trigger: "blur" },
        // ],

        business_apply_sum: [
          {required: true, message: "请输入申请金额", trigger: "blur"},
        ],
        business_rate: [
          {required: true, message: "请输入申请利率", trigger: "blur"},
        ],
        direction: [
          {required: true, message: "请输入贷款投向", trigger: "blur"},
        ],
        repay_fund_source: [
          {required: true, message: "请选择还贷资金来源", trigger: "change"},
        ],
        accept_int_type: [
          {required: true, message: "请选择支农用途", trigger: "change"},
        ],
        qy_org_type: [
          {required: true, message: "请选择企业经济类型", trigger: "change"},
        ],
        qy_economy_type: [
          {
            required: true,
            message: "请选择企业国民经济部门",
            trigger: "change",
          },
        ],
        qy_most_business: [
          {required: true, message: "请选择企业经营品种", trigger: "change"},
        ],
        qy_licensedate: [
          {
            required: true,
            message: "请选择企业营业执照登记日",
            trigger: "change",
          },
        ],
        qy_license_maturity: [
          {
            required: true,
            message: "请选择企业营业执照到期日",
            trigger: "change",
          },
        ],
        qy_country_code: [
          {required: true, message: "请选择企业所在国家", trigger: "change"},
        ],
        qy_region_code: [
          {required: true, message: "请选择企业所在国家", trigger: "change"},
        ],
        qy_register_add: [
          {required: true, message: "请选择企业注册地址", trigger: "change"},
        ],
        qy_employee_number: [
          {
            required: true,
            message: "请输入企业上年末企业从业人员",
            trigger: "blur",
          },
        ],
        gr_region_code: {
          required: true,
          message: "请选择地区地址",
          trigger: "change",
        },
        qy_previous_income: [
          {
            required: true,
            message: "请输入企业上年营业收入",
            trigger: "blur",
          },
        ],
        qy_previous_asset: [
          {
            required: true,
            message: "请输入企业上年末资产总额",
            trigger: "blur",
          },
        ],
        qy_loan_card_no: [
          { required: true, message: "请输入企业贷款卡号", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!/^[a-zA-Z0-9]*$/.test(value)) {
                callback(new Error("卡号只能包含字母和数字"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          },
        ],
        qy_loan_card_password: [
          { required: true, message: "请输入企业贷款卡密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!/^[a-zA-Z0-9]*$/.test(value)) {
                callback(new Error("密码只能包含字母和数字"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          },
        ],
        qy_loan_flag: [
          {
            required: true,
            message: "请选择企业贷款卡是否有效",
            trigger: "change",
          },
        ],
        qy_private: [
          {
            required: true,
            message: "请选择是否培养重点中小企业",
            trigger: "change",
          },
        ],
        qy_rc_currency: [
          {
            required: true,
            message: "请选择企业注册资本币种",
            trigger: "change",
          },
        ],
        qy_industry_type2: [
          {
            required: true,
            message: "请选择企业国标行业分类",
            trigger: "change",
          },
        ],
        qy_register_capital: [
          {required: true, message: "请输入企业注册资本", trigger: "blur"},
        ],
        qy_back_ground_flag: [
          {
            required: true,
            message: "请选择企业是否政府融资平台",
            trigger: "change",
          },
        ],
        qy_office_tel: [
          {required: true, message: "请输入企业联系电话", trigger: "blur"},
        ],
        qy_finance_dept_tel: [
          {
            required: true,
            message: "请输入企业财务部联系电话",
            trigger: "blur",
          },
        ],
        gr_full_name: [
          {required: true, message: "请输入个人姓名", trigger: "blur"},
        ],
        gr_cert_type: [
          {required: true, message: "请输入个人证件类型", trigger: "blur"},
        ],
        gr_cert_id: [
          {required: true, trigger: "blur", message: "请填写身份证号码"},
          {validator: validatorCardNumber, trigger: "blur"},
        ],
        gr_sex: [
          {required: true, message: "请选择个人性别", trigger: "change"},
        ],
        gr_birthday: [
          {required: true, message: "请选择个人出生日期", trigger: "change"},
        ],
        gr_cert_enddate: [
          {
            required: true,
            message: "请选择个人证件到期日",
            trigger: "change",
          },
        ],
        gr_edu_experience: [
          {required: true, message: "请选择个人最高学历", trigger: "change"},
        ],
        gr_marriage: [
          {required: true, message: "请选择个人婚姻状况", trigger: "change"},
        ],
        gr_country_code: [
          {required: true, message: "请选择个人所在国家", trigger: "change"},
        ],
        gr_family_add: [
          {required: true, message: "请输入个人居住地址", trigger: "change"},
        ],
        gr_mobile_telephone: [
          {required: true, message: "请输入个人手机号码", trigger: "change"},
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: "手机格式不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    FaceCollect,
    WalletChooseDialog,
    RegionChooseDialog,
    CustChooseDialog,
    ProductChooseDialog,
    uploadImg,
    CountdownBtn,
    QRConfirm,
  },
  created() {
    this.$nextTick(() => {
      let data = sessionStorage.getItem(ENT_APPLY_KEY)
      if (data) {
        this.restoreShow = true
      }
    });

    if (this.$route.params.type === "add") {
      // 加载选中的客户信息
      this.onCustChosen({custnum: this.$route.params.custnum})
      this.onWalletChosen(this.$store.state.Walletinformation)
      // TODO: 加载钱包信息
    }
  },
  mounted() {
    if (this.$route.query.status) {
      sessionStorage.setItem('msgInfo', true)
    }
  },
  destroyed() {
    sessionStorage.removeItem(ENT_APPLY_KEY)
  },
  methods: {
    stashRestore() {
      sessionStorage.setItem(ENT_APPLY_KEY, JSON.stringify({
        form: this.form,
        Documentnumber: this.Documentnumber,
        verifyvadata: this.verifyvadata,
        app_no: this.app_no,
        file_list: this.file_list,
      }))
    },
    confirmRestore() {
      let data = JSON.parse(sessionStorage.getItem(ENT_APPLY_KEY))
      this.form = data.form
      this.verifyvadata = data.verifyvadata
      this.Documentnumber = data.Documentnumber
      this.app_no = data.app_no
      this.file_list = data.file_list
      this.restoreShow = false
      //TODO: 展示图片
    },
    cancelRestore() {
      this.restoreShow = false
    },
    // 下载模板
    async downLoadTemplate(type) {
      contractDocTemp.call(this, type, this.api, this.$message);
    },

    // 状态筛选

    customRender: (text) => {
      return BizDict.LoanAppStatus.find(item => item.value === text)?.label || "---"
    },
    // 保存
    async ConfirmSave() {
      if (this.form.verify_code == "") {
        this.$message.warning("请先填写验证码");
        return;
      }
      // 检查是否所有影像信息都已填写
      const filesToCheck = [
        'FrontofidentitycardFile',
        'ReverseofIDcardFile',
        'HumanfaceFile',
        'BusinesslicenseFile',
        'personageFile',
        'ThirdpartyFile',
        'ArticlesofassociationFile',
        'ResolutionpaperFile',
      ];

      if (filesToCheck.some(file => this.verifyvadata[file] === "")) {
        console.log("请先填写影像信息", this.verifyvadata);
        this.$message.warning("请先填写影像信息");
        return;
      }

      // 检查证件日期
      const endtimearr = [
        this.form.qy_license_maturity,
        this.form.gr_cert_enddate,
      ];

      const arr = endtimearr.map(item =>
          this.endverify(item || dayjs().format("YYYY-MM-DD"))
      );


      const flagbefore = arr.some(item => item === 0);
      const flagafter = this.checkArrayConditions(arr);



      if (flagbefore) {
        this.$message.warning("您的证件已经到期，建议使用最新的证件进行注册");
        return  ;
      }

      if (flagafter&&! this.confirmshow ) {
        this.$confirm({
          title: "提示",
          content: "您的证件将在半年内到期，届时银行将对您的账号进行管制。解除管制需要本人持有效证件前往稠州银行网点更新，建议使用最新的证件进行注册， 确定继续吗？",
          onOk: () => { this.confirmshow = true; },
          onCancel: () => { /* 可选操作 */ },
        });
        return  ;
      }

      // 保存申请数据，以便下次还原
      this.stashRestore()

      // 处理数据
      let saveform = JSON.parse(JSON.stringify(this.form));

      delete saveform.mobile;

      saveform.accept_int_type =this.form.accept_int_type.length > 0 ? this.form.accept_int_type[this.form.accept_int_type.length - 1] : ""; // 支农用途

      saveform.file_list = JSON.parse(JSON.stringify(this.file_list));

      saveform.app_no = this.app_no;

      // 变换申请金额
      saveform.business_apply_sum = saveform.business_apply_sum * 10000
      // 国标行业分行不需要重新填，直接用贷款投向
      saveform.qy_industry_type2 = saveform.direction

      const res = await this.api.creditQyApplyApi(saveform);

      if (res.code == 200) {
        if (res.data.online_serial_no) {
          this.$message.success("申请成功,等待审核");
          this.confirmshow = false;
          this.$router.go(-1)
          sessionStorage.removeItem(ENT_APPLY_KEY)
        } else {
          this.$message.error("申请失败")
          let applyList = await this.api.creaditApplyInfoByCust(this.form.custnum)
          this.$message.error(applyList.data[0].statusDesc)
        }
      } else {
        this.$message.error(res.info);
        this.confirmshow = false;
      }
    },


    async ConfirmQRPush() {
      // 保存申请数据，以便下次还原
      this.stashRestore()

      // 检查是否所有影像信息都已填写
      const filesToCheck = [
        'FrontofidentitycardFile',
        'ReverseofIDcardFile',
        'BusinesslicenseFile',
        'personageFile',
        'ThirdpartyFile',
        'ArticlesofassociationFile',
        'ResolutionpaperFile',
      ];

      console.log("ConfirmQRPush",this.verifyvadata)
      if (filesToCheck.some(file => this.verifyvadata[file] === "")) {
        console.log("请先填写影像信息", this.verifyvadata);
        this.$message.warning("请先填写影像信息");
        return;
      }

      // 检查证件日期
      const endtimearr = [
        this.form.qy_license_maturity,
        this.form.gr_cert_enddate,
      ];

      const arr = endtimearr.map(item =>
          this.endverify(item || dayjs().format("YYYY-MM-DD"))
      );

      const flagbefore = arr.some(item => item === 0);
      const flagafter = this.checkArrayConditions(arr);



      if (flagbefore) {
        this.$message.warning("您的证件已经到期，建议使用最新的证件进行注册");
        return true;
      }

      if (flagafter&&! this.confirmshow ) {
        this.$confirm({
          title: "提示",
          content: "您的证件将在半年内到期，届时银行将对您的账号进行管制。解除管制需要本人持有效证件前往稠州银行网点更新，建议使用最新的证件进行注册， 确定继续吗？",
          onOk: () => { this.confirmshow = true; },
          onCancel: () => { /* 可选操作 */ },
        });
        return true;
      }

      // 处理数据
      let saveform = JSON.parse(JSON.stringify(this.form));
      console.log(saveform);
      delete saveform.mobile;

      // 处理数据级联选择器 默认要传给后段最后一个
      saveform.accept_int_type =
          this.form.accept_int_type.length > 0
              ? this.form.accept_int_type[this.form.accept_int_type.length - 1]
              : ""; // 支农用途


      saveform.file_list = JSON.parse(JSON.stringify(this.file_list));

      saveform.app_no = this.app_no;

      // 变换申请金额
      saveform.business_apply_sum = saveform.business_apply_sum * 10000
      // 国标行业分行不需要重新填，直接用贷款投向
      saveform.qy_industry_type2 = saveform.direction

      const res = await this.api.custOpenAccTaskSave({
        channelNo: this.form.channel_no,
        appNo: this.app_no,
        taskType: 3,
        name: this.form.account_name,
        idNo: this.Documentnumber,
        mobile: this.form.mobile,
        cardNo: this.form.account_no,
        reqData: saveform,

        exData: {
          "金额": `￥${Number(this.form.business_apply_sum).toFixed(2)}万元`,
          "利率": `年化${Number(this.form.business_rate).toFixed(2)}%`
        }
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.confirmshow = false;
      this.qrConfirmUrl = res.data
      this.qrConfirmShow = true

    },


    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              this.form.open_longitude = position.coords.longitude;
              this.form.open_dimensions = position.coords.latitude;
              // 在这里使用经纬度进行其他操作
            },
            () => {
              // 处理错误情况

              this.$message.warning("请在浏览器隐私和安全授权获取位置信息");
            }
        );
      } else {
        // 浏览器不支持Geolocation API
      }
    },

    // 选择机构触发
    async departmentchange(value) {
      // findList
      if (value !== "") {
        const res = await this.api.findListApi({
          departmentId: value,
        });

        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        var that = this;
        // console.log(res);
        // 首先进行判断 如果 机构下面所有的  custAccountInfos 都为空， 说明没有开户，弹出弹窗提醒去开户
        //
        if (res.data.every((item) => item.custAccountInfos.length == 0)) {
          this.$warning({
            content: "当前渠道下客户均未开通账户，请先开户",
            okText: "去开户",
            onOk() {
              that.$router.push({
                path: "/wallet/CorporateAccountOpening",
              });
            },
          });

          return;
        }
        // 进行数据处理
        this.custnumlist = [];
        res.data.forEach((item, index) => {
          this.custnumlist.push({
            label: item.custCompanyInfo.custname,
            value: item.custCompanyInfo.custnum,
            custAccountInfos: item.custAccountInfos,
            index: index,
            // 影像
            custVedioInfos: item.custVedioInfos,
            // 企业客户基础
            custCompanyInfo: item.custCompanyInfo,
            custEandpInfos: item.custEandpInfos,
            custIdInfo: item.custIdInfo,
          });
        });
      }
    },
    // 选定钱包
    async onWalletChosen(wallet) {
      // 回填钱包信息
      this.form.account_name = wallet.accountName
      this.form.account_no = wallet.accountNo

      this.form.mobile = wallet.mobile
      // 	渠道编号
      this.form.channel_no = wallet.channelNo;
      this.cardNo = wallet.cardList
          ? wallet.cardList[0]?.bankCardNo
          : ""; // 银行卡号
      this.app_no = wallet.appNo; //	应用编号
      this.form.gr_mobile_telephone = this.form.mobile // 简化使用企业手机号

      // 选择渠道编号，拿到渠道编号，发请求获取 个人贷款的产品编号 productType 1 个人 2 企业
      const res = await this.api.creditProductInfolistApi({
        channelNo: this.form.channel_no,
        productType: 2,
      });
      // console.log(res);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.productList = res.data
    },
    // 选中贷款产品
    onProductChosen(product) {
      this.form.product_id = product.productId
      this.form.business_apply_sum = Math.floor(product.creditLimitAmount / 10000)
      this.form.business_rate = product.rateEnd
    },
    // 选定客户
    async onCustChosen(custChosen) {
      // 置空账号
      this.form.account_no = ""
      this.form.account_name = ""

      let res = await this.api.getEnterpriseCust(custChosen.custnum)
      if (res.code !== 200) {
        this.$message.warning(res.info)
        return
      }

      let custDetail = res.data

      //基础信息
      this.form.custnum = custDetail.custCompanyInfo.custnum
      this.form.departmentId = custDetail.custCompanyInfo.departmentId
      this.form.custname = custDetail.custCompanyInfo.custname

      this.form.qy_org_type =
          custDetail.custCompanyInfo.econtypecd || this.form.qy_org_type // 经济类型

      this.form.qy_economy_type =
          custDetail.custCompanyInfo.nationecodeptcd || this.form.qy_economy_type  // 国民经济部门

      let openbiz = custDetail.custCompanyInfo.operbiz // 截取经营范围作为经营品种
      if (openbiz && openbiz.length > 0)
        this.form.qy_most_business = openbiz.substring(0, 100)

      this.form.qy_setup_date =
          custDetail.custCompanyInfo.corpfounddt; // 企业成立日期

      // 证件信息
      this.Documentnumber = custDetail.custIdInfo.certnum
      this.form.qy_licensedate = custDetail.custIdInfo.certeffectdt
      this.form.qy_license_maturity = custDetail.custIdInfo.certinvaliddt

      // 回填法定代表人信息
      if (custDetail.custEandpInfos && custDetail.custEandpInfos.length > 0) {
        let fr = custDetail.custEandpInfos.find(s => s.islegalperson === "1")
        if (fr) {
          this.form.gr_cert_id = fr.certnnum//法人证件号码
          this.form.gr_full_name = fr.custname//法人姓名
          this.form.gr_sex = fr.gendercd//法人性别
          this.form.gr_cert_enddate = fr.certinvaliddt//法人证件到期日
          let birthday = fr.certnnum.substring(6, 14)
          this.form.gr_birthday = birthday.slice(0, 4) + '-' + birthday.slice(4, 6) + '-' + birthday.slice(6)
          const frInfo = await this.api.getCustData(fr.custnum)

          if (frInfo.data.custIdInfo) {
            // this.form.gr_cert_id = frInfo.data.custIdInfo.certnum
            // this.form.gr_cert_enddate = frInfo.data.custIdInfo.certinvaliddt
          }

          if (frInfo.data.custPersonInfo) {
            this.form.gr_full_name = frInfo.data.custPersonInfo.custname
            this.form.gr_sex = frInfo.data.custPersonInfo.gendercd
            this.form.gr_birthday = frInfo.data.custPersonInfo.birthdt
            this.form.gr_edu_experience = frInfo.data.custPersonInfo.highestedudegreecd
            this.form.gr_marriage = frInfo.data.custPersonInfo.marriagesitucd
          }

          if (frInfo.data.custContactInfos && frInfo.data.custContactInfos.length > 0) {
            this.form.gr_mobile_telephone = frInfo.data.custContactInfos[0].continfo
          }
          if (frInfo.data.custAddressInfos && frInfo.data.custAddressInfos.length > 0) {
            this.form.gr_region_code = frInfo.data.custAddressInfos[0].distcd
            this.form.gr_family_add = frInfo.data.custAddressInfos[0].addrinfo
          }
        }
      }


      // 地址信息
      let addrInfo = custDetail.custAddressInfos
      if (addrInfo && addrInfo.length > 0) {
        // 取第一个
        // this.form.qy_region_code = addrInfo[0].distcd
        // TODO:
        this.form.qy_register_add = addrInfo[0].addrinfo
      }
    },
    // 发送验证码
    async sendcode() {
      // 手动触发表单校验
      var validateFieldList = [];
      this.$refs.ruleForm.validateField(
          ["mobile", "account_name"],
          async (errorMsg) => {
            if (errorMsg) {
              // 校验未通过
              // console.log(errorMsg)
            } else {
              validateFieldList.push(errorMsg);
              if (
                  validateFieldList.length == 2 &&
                  validateFieldList.every((item) => item == "")
              ) {
                const res = await this.api.vefifycodesend({
                  appNo: this.app_no,
                  channelNo: this.form.channel_no,
                  mobile: Number(this.form.mobile),
                  modelNo: '102017',
                  accountNo: this.form.account_no,
                  accountName: this.form.account_name,
                  idNo: this.certnum,
                  tradeType: 9,
                  cardNo: this.cardNo, // 银行卡
                });

                if (res.code == 200) {
                  // console.log("11111");
                  this.$message.success("验证码发送成功");
                  this.show = false;
                  // 60秒倒计时

                  var timer = setInterval(() => {
                    this.Countdown--;
                    if (this.Countdown <= 0) {
                      this.show = true;
                      this.Countdown = 60;
                      clearInterval(timer);
                    }
                  }, 1000);
                } else {
                  this.$message.error(res.msg);
                }
              }
              //之后的操作
            }
          }
      );
    },
    // 变更法定代表人证件
    changeGrCertId() {
      let cert_no = this.form.gr_cert_id.trim()
      if (cert_no.length == 18) {
        if (parseInt(cert_no[16]) % 2 === 1) {
          // 第17位奇数为男，偶数为女
          this.form.gr_sex = "1"
        } else {
          this.form.gr_sex = "2"
        }
        this.form.gr_birthday = cert_no.substring(6, 10) + "-" + cert_no.substring(10, 12) + "-" + cert_no.substring(12, 14)

      }
    },
    // 处理选中变化的方法
    handleSelectionChange(zoningCode) {
      console.log('Selected zoningCode:', zoningCode);
      this.form.gr_region_code = zoningCode
      console.log('Selected this.form.gr_region_code:', this.form.gr_region_code);
    },

    handleSelectionChangeQy(zoningCode) {
      console.log('Selected zoningCode:', zoningCode);
      this.form.qy_region_code = zoningCode
      console.log('Selected this.form.qy_region_code:', this.form.qy_region_code);
    },
    // 上传之前的处理
    handleBeforeUpload(file) {
      // console.log(file, 'file');
      const isImage = file.type.startsWith('image/');
      const isLt3M = file.size / 1024 / 1024 > 3
      if (isLt3M && isImage) {
        return this.compressImage(file);
      } else if (isLt3M) {
        this.$message.error('上传文件大小不能超过3M!')
        return false
      }
    },
    async compressImage(file) {
      // console.log(file, 'compress');
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 1920; // 设置压缩后的图像宽度
            const maxHeight = 1080; // 设置压缩后的图像高度

            let newWidth = img.width;
            let newHeight = img.height;

            if (img.width > maxWidth) {
              newWidth = maxWidth;
              newHeight = (img.height * maxWidth) / img.width;
            }

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              newWidth = (img.width * maxHeight) / img.height;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // 将压缩后的图像转换为 Blob 对象
            canvas.toBlob(
                (blob) => {
                  const compressedFile = new File([blob], file.name, {
                    type: 'image/jpeg',
                  });
                  resolve(compressedFile);
                },
                'image/jpeg',
                0.9
            );
          };
        };
      });
    },
    // 上传身份证正面
    changeIdUpload(info) {
      if (this.verifyvadata.FrontofidentitycardFile !== "") {
        this.verifyvadata.Frontofidentitycardloading = false;
        this.verifyvadata.FrontofidentitycardFile = "";
        this.verifyvadata.Frontofidentitycardurl = "";
      }
      if (info.file.status === "uploading") {
        this.verifyvadata.Frontofidentitycardloading = true;
        // return;
      }
      this.$refs.IdUp.handleChangeUpload(info);
    },
    // 身份证反面
    changeIdBack(info) {
      if (this.verifyvadata.ReverseofIDcardFile !== "") {
        this.verifyvadata.ReverseofIDcardloading = false;
        this.verifyvadata.ReverseofIDcardFile = "";
        this.verifyvadata.ReverseofIDcardurl = "";
      }
      if (info.file.status === "uploading") {
        this.verifyvadata.ReverseofIDcardloading = true;
        // return;
      }
      this.$refs.IdBack.handleChangeUpload(info);
    },

    // 人像上传失败
    portraitUploadFail(data) {
      this.$message.warning("人像采集失败" + data.info)
    },

    // 人像上传成功
    async portraitUpload2(data) {
      if (this.verifyvadata.HumanfaceFile !== "") {
        this.verifyvadata.Humanfaceloading = false;
        this.verifyvadata.HumanfaceFile = "";
        this.verifyvadata.Humanfaceurl = "";
      }
      this.verifyvadata.HumanfaceFile = data.file_no
      this.verifyvadata.Humanfaceloading = true;
      this.verifyvadata.Humanface = true;
      this.file_list[2].channel_file_no = data.channel_file_no;
      this.file_list[2].file_no = data.file_no;
      // this.$refs.Humanface.HumanfaceEcho(data.file_no);
    },

    // 人像上传成功


    // 授权 个人

    personage(info) {
      if (this.verifyvadata.personageFile !== "") {
        this.verifyvadata.personageloading = false;
        this.verifyvadata.personageFile = "";
        this.verifyvadata.personageurl = "";
      }
      if (info.file.status === "uploading") {
        this.verifyvadata.personageloading = true;
        // return;
      }
      // this.$refs.personage.handleChangeUpload(info);
      this.returnImg(info, 'personage')
    },
    // 第三方
    Thirdparty(info) {
      if (this.verifyvadata.ThirdpartyFile !== "") {
        this.verifyvadata.Thirdpartyloading = false;
        this.verifyvadata.ThirdpartyFile = "";
        this.verifyvadata.Thirdpartyurl = "";
      }
      if (info.file.status === "uploading") {
        this.verifyvadata.Thirdpartyloading = true;
        // return;
      }
      // this.$refs.Thirdparty.handleChangeUpload(info);
      this.returnImg(info, 'Thirdparty')

    },
    // 营业执照
    BusinesslicenseUpload(info) {
      if (this.verifyvadata.BusinesslicenseFile !== "") {
        this.verifyvadata.Businesslicenseloading = false;
        this.verifyvadata.BusinesslicenseFile = "";
        this.verifyvadata.Businesslicenseurl = "";
      }
      if (info.file.status === "uploading") {
        this.verifyvadata.Businesslicenseloading = true;
        // return;
      }
      // this.$refs.Businesslicense.handleChangeUpload(info);
      this.returnImg(info, 'Businesslicense')

    },
    // 企业章程
    ArticlesofassociationUpload(info) {
      if (this.verifyvadata.ArticlesofassociationFile !== "") {
        this.verifyvadata.Articlesofassociationloading = false;
        this.verifyvadata.ArticlesofassociationFile = "";
        this.verifyvadata.Articlesofassociationurl = "";
      }
      if (info.file.status === "uploading") {
        this.verifyvadata.Articlesofassociationloading = true;
        // return;
      }
      // this.$refs.Articlesofassociation.handleChangeUpload(info);
      this.returnImg(info, 'Articlesofassociation')

    },
    // 决议书
    ResolutionpaperUpload(info) {
      if (this.verifyvadata.ResolutionpaperFile !== "") {
        this.verifyvadata.Resolutionpaperloading = false;
        this.verifyvadata.ResolutionpaperFile = "";
        this.verifyvadata.Resolutionpaperurl = "";
      }
      if (info.file.status === "uploading") {
        this.verifyvadata.Resolutionpaperloading = true;
        // return;
      }
      // this.$refs.Resolutionpaper.handleChangeUpload(info);
      this.returnImg(info, 'Resolutionpaper')

    },

    // 点击校验
    confirm() {
      // 企业信息证件过期日期 qy_license_maturity  法定代表人证件到期日  gr_cert_enddate  校验时间是否到期
      var that = this;

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.confirmshow = true;

          // 校验通过后判断影像信息

        } else {
          this.$message.warning("请先填写贷款信息");
        }
      });
    },
    // 去签约
    async Signacontract() {
      let data = {
        channel_no: this.$store.state.Corporateloaninformation.channelNo,
        app_no: this.$store.state.Corporateloaninformation.appNo,
        product_id: this.$store.state.Corporateloaninformation.productId,
        online_serial_no: this.$store.state.Corporateloaninformation.onlineSerialNo,
      }
      const res = await this.api.creditSignFlowsUrlsApi(data)
      // console.log(res);
      if (res.code !== 200) {
        this.$message.warning(res.info)
        return
      }
      window.open(res.data.recode_list[0].sign_url)
    },

    checkArrayConditions(arr) {
      const allOnes = arr.every((elem) => elem === 1);
      const allOnesAndTrue = arr.every((elem) => elem === 1 || elem === 2);

      return allOnes || allOnesAndTrue;
    },

    endverify(time) {
      // 首先进项判断 如果省份证和当天时间相比过期 提示用户 不能进行开户，如果身份证过期时间跟当前相比低于180天，则提示用户
      const start = dayjs().format("YYYY-MM-DD");
      const end = dayjs(time);
      const daysDiff = end.diff(start, "day");

      if (daysDiff <= 0) {
        return 0;
      } else if (daysDiff > 0 && daysDiff < 180) {
        return 1;
      } else if (daysDiff > 180) {
        return 2;
      }
    },
    // 获取上传类型
    getFileType(file) {
      const fileType = file.type;
      if (fileType === 'application/pdf') {
        return 'PDF';
      } else if (fileType.startsWith('image/')) {
        return 'Image';
      } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return 'Word';
      } else {
        return 'Unknown';
      }
    },
    // 封装返回图片方法
    returnImg(info, name) {
      let type = this.getFileType(info.file)
      if (info.file.status === "done") {
        let imgurl = ''
        if (type === 'PDF') {
          imgurl = this.pdfImg
        } else if (type === 'Word') {
          // word
          imgurl = this.wordImg
        }
        this.$refs[name].handleChangeUpload(info, '', imgurl)
      } else if (info.file.status === "error") {
        this.$message.error("上传失败")
        this.verifyvadata.personageloading = false
      }
    }
  },
};
</script>
<style lang="less">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.ant-modal-confirm-body-wrapper {
  svg {
    width: 20px;
    height: 20px;
  }
}
</style>

<style lang="less" scoped>
.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}

/deep/ .ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}

/deep/ .ant-modal-footer {
  width: 100%;
  border: none;
  padding-bottom: 20px;

  text-align: center;
}

/deep/ .ant-modal-content {
  width: 420px;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 38px;
      border: none;

      &:first-child {
        width: 108px;

        color: #ffff;
      }

      &:last-child {
        width: 108px;
        background-color: #d7d7d7;
        color: #ffff;
      }
    }
  }
}

.Individualaccountopening {
  padding-bottom: 100px;

  .Approvaldetails {
    width: 100%;
    min-height: 58px;
    margin-bottom: 25px;

    h2 {
      font-weight: 600;
    }

    .text {
      width: 100%;
      height: 44px;
      display: flex;

      .left {
        width: 227px;
        height: 44px;
        display: flex;
        align-items: center;
        color: #242424;
        padding-left: 20px;
        background-color: #f2f2f2;
      }

      .right {
        display: flex;
        align-items: center;
        padding-left: 30px;
        flex: 1;
        height: 44px;
        border-top: 1px solid #d7d7d7;
        border-bottom: 1px solid #d7d7d7;

        .status {
          display: flex;
          align-items: center;
          font-weight: 700;
          // font-size: 16px;
          height: 100%;
          margin-right: 220px;
        }
      }
    }
  }

  .toptitle {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .departmentId {
    /deep/ .ant-form-item-control-wrapper {
      border-top: 1px solid #d7d7d7;
    }
  }

  .app_no {
    // /deep/.ant-form-item-required {
    //   display: none;
    // }

    .app_nobox {
      display: flex;

      span {
        display: flex;
        width: 120px;
        align-items: center;
      }
    }

    p {
      height: 20px;
    }
  }

  /deep/ .ant-form-item-label {
    min-height: 45px;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  // .channel_no {
  //   // /deep/ .ant-form-item-label {
  //   //   padding-top: 10px;

  //   // }
  // }

  .id_type,
  .id_no,
  .job_note,
  .open_dimensions,
  .open_longitude {
    /deep/ .ant-form-item-label {
      min-height: 45px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }
  }

  /deep/ .ant-input-suffix {
    right: 0;
    width: 40px;

    height: 30px;
    border-radius: 0px 4px 4px 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
  }

  .code {
    /deep/ .ant-input-suffix {
      right: -88px;
      width: 50px;
      border-radius: 6px;
      height: 30px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #02a7f0;
    }
  }

  // 影像信息
  .Imageinformation,
  .mate,
  .Operatingentity {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    font-weight: 600;

    span {
      font-weight: normal;
      margin-left: 5px;
    }

    .fill {
      margin-left: 20px;
      height: 24px;
    }
  }

  .Imageinfor {
    /deep/ .ant-form-item-control-wrapper {
      border-top: 1px solid #d7d7d7;
    }
  }

  .pricebox {
    width: 100%;

    /deep/ .ant-form-item-label {
      min-height: 180px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }

    /deep/ .ant-form-item-control-wrapper {
      min-height: 180px;
    }

    .row {
      display: flex;

      .item {
        position: relative;
        margin-left: 50px;
        display: flex;
        align-items: center;
        height: 122px;

        span {
          line-height: 25px;
          text-align: center;
        }

        /deep/ .ant-upload {
          width: 200px;
          height: 122px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          padding: 0px;
          overflow: hidden;
          border: 1px dashed #d9d9d9 !important;
          background-color: #fafafa !important;

          .anticon-plus {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .erero {
          position: absolute;
          bottom: -30px;
          left: 100px;
          color: #f5222d;
        }

        .ant-upload.ant-upload-select-picture-card {
          border: 1px solid #ccc;
        }

        .ant-upload.ant-upload-select-picture-card > .ant-upload {
          padding: 0;
        }

        .ant-upload-select-picture {
          width: 200px;
          height: 122px;
          border: 0;
        }
      }

      .third-item {
        display: flex;
        align-items: center;

      }
    }
  }

  /deep/ .ant-form-item {
    background-color: #f2f2f2;
    min-height: 50px;
    margin-bottom: 0px !important;
  }

  /deep/ .ant-form-item-control-wrapper {
    padding-left: 35px;
    padding-left: 20px;
    background-color: #fff;
    border-bottom: 1px solid #d7d7d7;
    border-left: none;
    display: flex;
    min-height: 50px;
    align-items: center;

    .ant-form-item-control {
      min-width: 250px;
    }
  }
}

.qy_customer_history,
.qy_remark,
.qy_main_production {
  /deep/ .ant-form-item-control-wrapper {
    padding-left: 35px;
    padding-left: 20px;
    background-color: #fff;
    border-bottom: 1px solid #d7d7d7;
    border-left: none;
    display: flex;
    min-height: 128px;
    align-items: center;

    .ant-form-item-control {
      min-width: 250px;
    }

    .ant-input {
      height: 100px;
      width: 700px;
    }
  }
}

.qy_paicl_up_capital,
.qy_register_capital {
  position: relative;

  .bizhong {
    position: absolute;
    right: -250px;
    top: -8px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: flex;
      margin-right: 20px;
    }

    input {
      width: 100px;
      padding: 10px 10px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #e3e4e6;
    }
  }
}

.confirmloading {
  color: #333333;

  .text {
    padding: 20px 10px;
    text-align: center;
    font-size: 16px;
    color: #494949;
  }

  .canceltext {
    width: 200px;
    margin: auto;
    text-align: center;
  }

  .custom-footer {
    width: 100%;

    button {
      &:first-child {
        color: #fff;
        margin-right: 80px !important;
      }

      &:last-child {
        background-color: #f2f2f2;
        color: #494949;
      }
    }
  }
}
</style>
