<template>
  <!-- 贷款产品 -->
  <div>
    <a-card title="贷款产品" style="margin: 20px">
      <div class="loan">
        <!-- !表单区域 -->
        <div class="formbox">
          <!-- 所属机构 -->
          <div class="inputbox">
            <div class="lefttitle">所属机构</div>
            <!-- 所属机构 -->
            <a-tree-select v-model.trim="form.departmentId" style="width: 200px"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="treeData" placeholder="请选择所属机构"
              tree-default-expand-all>
            </a-tree-select>
          </div>

          <!-- 渠道编号 -->
          <div class="inputbox">
            <div class="lefttitle">渠道编号</div>
            <a-input v-model.trim="form.channelNo" placeholder="请输入渠道编号" />
          </div>
          <!-- 	产品编号 -->
          <div class="inputbox">
            <div class="lefttitle">产品编号</div>
            <a-input v-model.trim="form.productId" placeholder="请输入产品编号" />
          </div>
          <!-- 		产品名称 -->
          <div class="inputbox">
            <div class="lefttitle">产品名称</div>
            <a-input v-model.trim="form.productName" placeholder="请输入产品名称" />
          </div>


          <!-- 查询 -->

          <a-button class="inquire" @click="search" type="primary" v-if="$authority('loanproductQuery')">
            查询
          </a-button>
          <!-- 重置 -->
          <a-button @click="reset" class="reset">重置</a-button>
        </div>

        <div class="Applyfor">
          <!--  新增  Add-->
          <a-button @click="newAdd" type="primary" v-if="$authority('loanproductAdd')">
            新增
          </a-button>
          <!--  编辑-->
          <a-button @click="edit" type="primary" v-if="$authority('loanproductEdit')"> 编辑 </a-button>

          <!-- 删除 -->
          <a-button @click="Deletebutton" type="danger" v-if="$authority('loanproductDel')"> 删除 </a-button>

        </div>
        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" size="small" :rowKey="(record, index) => `${record.id}`" :row-selection="{
              selectedRowKeys: selectedRowKeys,
              hideDefaultSelections: true,
              onChange: onSelectChange,
            }" :columns="columns" :customRow="clickrow" :data-source="data" :pagination="false">
            </a-table>
          </div>
          <!-- 分页部分 -->
          <div class="paginationBox">
            <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
              首页
            </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
              show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
              :item-render="itemRender">

            </a-pagination>
            <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
              class="Trailingpage">
              尾页
            </a-button>
          </div>
        </div>
      </div>
    </a-card>
    <a-modal :title="addform.id ? '编辑' : '新增'" class="addform" :visible="modalBool" @ok="handleOk"
      @cancel="modalBool = false">
      <a-form-model ref="addruleForm" :model="addform" :label-col="{ span: 6, offset: 1 }"
        :wrapperCol="{ span: 15, offset: 1 }" labelAlign="left">
        <div class="context">

          <!-- 所属机构 -->
          <a-form-model-item label="所属机构" prop="departmentId"
            :rules="[{ required: true, message: '请选择所属机构', trigger: 'change', }]">
            <a-tree-select v-model.trim="addform.departmentId" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="treeData" placeholder="请选择所属机构" tree-default-expand-all>
            </a-tree-select>
          </a-form-model-item>


          <!-- 	渠道编号 -->
          <a-form-model-item label="渠道" :rules="[{ required: true, message: '请选择渠道', trigger: 'blur', }]"
            prop="channelNo">
            <a-select v-model.trim="addform.channelNo" placeholder="请选择渠道" style="width: 200px">
              <a-select-option v-for="item in companylist" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>

          </a-form-model-item>
          <!-- 产品类型 -->
          <a-form-model-item label="产品类型" :rules="[{ required: true, message: '请选择产品类型', trigger: 'change', }]"
            prop="productType">
            <a-select v-model.trim="addform.productType" placeholder="请选择产品类型">
              <a-select-option v-for="item in productTypelist" :key="item.label" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <!--产品id -->
          <a-form-model-item label="产品编号" :rules="[{ required: true, message: '请输入产品编号', trigger: 'blur', }]"
            prop="productId">
            <a-input v-model.trim="addform.productId" placeholder="请输入产品编号" />
          </a-form-model-item>
          <!--产品名称  -->
          <a-form-model-item label="产品名称" :rules="[{ required: true, message: '请输入产品名称', trigger: 'blur', }]"
            prop="productName">
            <a-input v-model.trim="addform.productName" placeholder="请输入产品名称" />
          </a-form-model-item>
          <!-- 总授信额度 -->
          <a-form-model-item label="总授信额度" :rules="[{ required: true, message: '请输入总授信额度', trigger: 'blur', }]"
            prop="creditLimitAmount">
            <a-input type="number" v-model.trim="addform.creditLimitAmount" placeholder="请输入总授信额度" suffix="￥"/>
          </a-form-model-item>
          <!-- 基础利率 -->
          <a-form-model-item label="基础利率" :rules="[{ required: true, message: '请输入基础利率', trigger: 'blur', }]"
            prop="baseRate">
            <a-input v-model.trim="addform.baseRate" type="number" placeholder="请输入基础利率" suffix="%" />
          </a-form-model-item>

          <!-- 利率区间起 -->
          <a-form-model-item label="利率区间起">
            <a-input v-model.trim="addform.rateStart" type="number" placeholder="请输入利率区间起" suffix="%" />
          </a-form-model-item>
          <!-- 利率区间止 -->
          <a-form-model-item label="利率区间止">
            <a-input v-model.trim="addform.rateEnd" type="number" placeholder="请输入利率区间止" suffix="%" />
          </a-form-model-item>
          <!--  产品说明-->
          <a-form-model-item label="产品说明">
            <a-input v-model.trim="addform.productRemark" placeholder="请输入产品说明" />
          </a-form-model-item>

        </div>

      </a-form-model>
    </a-modal>
    <!-- 删除 -->
    <a-modal title="提示" :maskClosable="true" @cancel="deleteshow = false" :centered="true" :visible="deleteshow">
      <div class="Openanaccount">
        是否确认删除所勾选数据？删除后数据将不可恢复
      </div>
      <template v-slot:footer>
        <div class="custom-footer">
          <a-button type="primary" @click="Confirmdelete">确认</a-button>
          <a-button @click="deleteshow = false">取消</a-button>

        </div>

      </template>
    </a-modal>

  </div>
</template>

<script>

import { BizDict } from "@/utils/bizDict/kvMap";
import { BizDictCascade } from "../../../utils/bizDict/kvMap.js";
import { handleChannelNo } from '@/utils/commonMethods'
// import dayjs from "dayjs";
export default {
  name: 'loanproduct',
  data() {
    return {
      treeData: [],
      deleteshow: false, // 贷款弹窗
      statuslist: BizDict.LoanAppStatus,
      total: 0,
      modalBool: false,
      Examineandapproveshow: false,
      selectedRowKeys: [],
      selectedRows: [], // 选数数组的值,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      institutiontrees: {},
      // 核心企业列表
      companylist: [],
      data: [],
      productTypelist: [
        {
          label: "个人贷款",
          value: '1',

        }, {
          label: "企业贷款",
          value: '2',
        }
      ],
      limitUnitlist: [
        {
          label: "日",
          value: '日',

        }, {
          label: "月",
          value: '月',
        },
        {
          label: "年",
          value: '年',
        }
      ],
      // 合同期限
      contractPeriodlist: [
        {
          label: "3",
          value: '3',
        },
        {
          label: "6",
          value: '6',
        },
        {
          label: "9",
          value: '9',
        },
        {
          label: "12",
          value: '12',
        },
        {
          label: "18",
          value: '18',
        },
        {
          label: "24",
          value: '24',
        },
        {
          label: "36",
          value: '36',
        },

      ],
      // 还息间隔
      payPrinIntvllist: [{
        label: "0个月",
        value: '0',
      },
      {
        label: "1个月",
        value: '1',
      },

      {
        label: "3个月",
        value: '3',
      },
      {
        label: "6个月",
        value: '6',
      },
      {
        label: "12个月",
        value: '12',
      },],
      // 还款方式
      payMethodlist: [
        {
          label: "一次性还本付息",
          value: '4',
        },
        {
          label: "按期付息、任意还本",
          value: '5',
        },
        {
          label: "按期付息、任意还本",
          value: '6',
        },
        {
          label: "按期付息、到期一次性还本",
          value: '7',
        },
      ],
      addform: {
        departmentId: undefined, // 机构id
        channelNo: '',//渠道编号
        productType: undefined,// 	产品类型 1：个人贷款 2：企业贷款 
        productId: "",// 产品编号
        productName: '',// 产品名称
        creditLimitAmount: '',//	总授信额度
        isCheckCredit: undefined,//	是否校验总授信额度 (0:否 1:是)
        isCheckList: undefined,//是否白名单校验 (0:否 1:是)
        isCheckBelong: undefined,//	是否法人信息校验 (0:否 1:是)
        baseRate: "",//基础利率
        rateStart: '',//	利率区间起
        rateEnd: '',//	利率区间止
        productRemark: '',//产品说明
        creditPeriod: '',//借款期限
        limitUnit: undefined,//借款期限单位 （日，月，年）
        subProductId: '',//子产品编号
        contractPeriod: undefined,//合同期限（3，6，9，12，18，24，36），单位月
        payMethod: undefined,//还款方式（4：一次性还本付息，5：按期付息、任意还本，6：按期付息、任意还本，7：按期付息、到期一次性还本
        payPrinIntvl: undefined,//	还息间隔：0、0个月，1、1个月，3、3个月，6、6个月，12、12个月
        isChannelPayIntvl: undefined,//	是否平台还息（0、否 1、是）
        isChannelDc: undefined,//	是否平台代偿（0、否 1、是）
        isAutoRepay: undefined,//	是否自动还款（0、否 1、是）
        overdueDay: '',//	逾期天数逾期天数：如果当前日期>到日日期+逾期天数，则进行代偿	
        loanScale: '',//	放款比例
        repayAccountClass: undefined,//还款账户类型，对应account_class
        isPSign: '1',//是否配偶签字（0、否 1、是）默认是
        isAddEName: '1',//是否添加经营实体名称（0、否 1、是）默认是

        loanPurpose: '',//贷款用途
        direction: undefined,// 贷款投向
        repayFundSource: '',// 还贷资金来源
        acceptIntType: undefined,//支农用途

      },
      //  是 否
      Whetherornotlist: [{
        value: '0',
        label: '否'
      }, {
        value: '1',
        label: '是'
      },

      ],
      // 还款账户类型
      repayAccountClasslist: [
        {
          value: '0',
          label: '活期'
        },
        {
          value: '6',
          label: '冻结'
        },
        {
          value: '贷款',
          label: '是'
        },
      ],
      // 贷款投向
      WorkCorpIndusCDlist: BizDictCascade.WorkCorpIndusCD,
      // 支农用途
      accept_int_typelist: BizDict.InvFrmUsg,
      form: {
        pageNo: 1,
        pageSize: 10,
        departmentId: undefined, // 机构id
        channelNo: '',// 渠道编号
        productId: '',// 	产品编号
        productName: '',//	产品名称
      },
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
        },
        {
          title: '渠道',
          dataIndex: 'channelNo',
          align: 'center',
          customRender: (text) => {
            return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
          },
        },
        {
          title: '	产品类型',
          dataIndex: 'productType',
          align: 'center',
          customRender: (text) => {
            if (Number(text) == 1) {
              return '个人贷款'
            }
            if (Number(text) == 2) {
              return '企业贷款'
            }
          },
        },
        {
          title: '产品编号',
          dataIndex: 'productId',
          align: 'center',
        },
        {
          title: '产品名称',
          dataIndex: 'productName',
          align: 'center',
        },
        {
          title: '总授信额度',
          dataIndex: 'creditLimitAmount',
          align: 'center',
        },
        {
          title: '基础利率',
          dataIndex: 'baseRate',
          align: 'center',
          customRender: (text) => {
            return text + '%'
          },
        },
        // {
        //   title: '产品说明',
        //   dataIndex: 'productRemark',
        //   align: 'center',
        // },
        // {
        //   title: '借款期限',
        //   dataIndex: 'creditPeriod',
        //   align: 'center',
        // },
        // {
        //   title: '还款方式',
        //   dataIndex: 'payMethod',
        //   align: 'center',
        //   customRender: (text) => {
        //     if (Number(text) == 4) {
        //       return '个人贷款'
        //     }
        //     if (Number(text) == 5) {
        //       return '企业贷款'
        //     }
        //     if (Number(text) == 6) {
        //       return '企业贷款'
        //     }
        //     if (Number(text) == 7) {
        //       return '企业贷款'
        //     }
        //   },
        // },

        {
          title: '机构名称',
          dataIndex: 'departmentId',
          align: 'center',
          customRender: (text) => {
            return this.findItemById(this.institutiontrees, text)
          },
        },




      ],
    }
  },
  created() {
    this.getCustList()
    this.getcommercePage()
    this.getChannelList()



  },
  methods: {
    // 获取核心企业列表
    async getChannelList() {
      const res = await this.api.coreCompanyInfoApi()
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      // 处理核心企业的渠道编号
      this.companylist = []
      res.data.forEach((item) => {
        this.companylist.push({
          label: item.channelName + '（' + item.channelNo + '）',
          value: item.channelNo,
          info: item,
        })
      })
      if (this.companylist.length === 1) {
        this.form.channel_no = this.companylist[0]?.value
        this.channel_nochange(this.form.channel_no)
      }
    },
    // 选择所属机构去触发
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    // 获取所属机构下拉框数据
    async getCustList() {
      let data = {
        username: this.$session.getItem('username'),
      }
      const res = await this.api.agencyList(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      let trees = []
      this.institutiontrees = res.data
      trees.push(res.data)

      this.treeData = this.getResettingTrees(trees)
        ? this.getResettingTrees(trees)
        : []
    },


    // 获取table列表
    async getcommercePage() {

      const res = await this.api.creditProductInfopageApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      this.data = res.data.records
    },


    // 点击新增
    newAdd() {
      this.addform = {
        departmentId: undefined, // 机构id
        channelNo: '',//渠道编号
        productType: undefined,// 	产品类型 1：个人贷款 2：企业贷款 
        productId: "",// 产品编号
        productName: '',// 产品名称
        creditLimitAmount: '',//	总授信额度
        isCheckCredit: undefined,//	是否校验总授信额度 (0:否 1:是)
        isCheckList: undefined,//是否白名单校验 (0:否 1:是)
        isCheckBelong: undefined,//	是否法人信息校验 (0:否 1:是)
        baseRate: "",//基础利率
        rateStart: '',//	利率区间起
        rateEnd: '',//	利率区间止
        productRemark: '',//产品说明
        creditPeriod: '',//借款期限
        limitUnit: undefined,//借款期限单位 （日，月，年）
        subProductId: '',//子产品编号
        contractPeriod: undefined,//合同期限（3，6，9，12，18，24，36），单位月
        payMethod: undefined,//还款方式（4：一次性还本付息，5：按期付息、任意还本，6：按期付息、任意还本，7：按期付息、到期一次性还本
        payPrinIntvl: undefined,//	还息间隔：0、0个月，1、1个月，3、3个月，6、6个月，12、12个月
        isChannelPayIntvl: undefined,//	是否平台还息（0、否 1、是）
        isChannelDc: undefined,//	是否平台代偿（0、否 1、是）
        isAutoRepay: undefined,//	是否自动还款（0、否 1、是）
        overdueDay: '',//	逾期天数逾期天数：如果当前日期>到日日期+逾期天数，则进行代偿	
        loanScale: '',//	放款比例
        repayAccountClass: undefined,//还款账户类型，对应account_class
        isPSign: '1',//是否配偶签字（0、否 1、是）默认是
        isAddEName: '1',//是否添加经营实体名称（0、否 1、是）默认是

        loanPurpose: '',//贷款用途
        direction: undefined,// 贷款投向
        repayFundSource: '',// 还贷资金来源
        acceptIntType: undefined,//支农用途

      },
        this.modalBool = true
      // 获取钱包和产品信息

    },

    // 进行循环递归处理数据
    getResettingTrees(tree) {
      // 定义子级数组
      if (tree.length == 0) return []
      let arr = []

      tree.forEach((item) => {
        // 递归循环将每一项都处理城组件需要的的格式 [ {title:"" children:[{title:""}]  }]
        arr.push({
          title: item.coreDepartment.name,
          value: item.coreDepartment.id,
          key: item.coreDepartment.id,
          children:
            item.children && item.children.length > 0
              ? this.getResettingTrees(item.children)
              : [],
        })
      })
      // 处理好的数组返回出去
      return arr
    },
    // 点击重置表单
    reset() {
      this.form = {
        pageNo: 1,
        pageSize: 10,
        departmentId: undefined, // 机构id
        channelNo: '',// 渠道编号
        productId: '',// 	产品编号
        productName: '',//	产品名称
      },
        this.getcommercePage()
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getcommercePage()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
        this.getcommercePage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getcommercePage()
    },
    pagechange(val) {
      this.form.pageNo = val

      this.getcommercePage()
    },
    // 搜索查询
    search() {
      this.form.pageNo = 1
      this.getcommercePage()
    },


    // 点击新增确认
    handleOk() {
      // 首先进行表单校验
      this.$refs.addruleForm.validate((valid) => {
        if (valid) {

          if (this.addform.id) {

            this.api.creditProductInfoupdateApi(this.addform).then((res) => {
              if (res.code !== 200) {
                this.$message.warning(res.info)
                return
              }

              this.$message.success('编辑成功')
              this.addform = {
                departmentId: undefined, // 机构id
                channelNo: '',//渠道编号
                productType: undefined,// 	产品类型 1：个人贷款 2：企业贷款 
                productId: "",// 产品编号
                productName: '',// 产品名称
                creditLimitAmount: '',//	总授信额度
                isCheckCredit: undefined,//	是否校验总授信额度 (0:否 1:是)
                isCheckList: undefined,//是否白名单校验 (0:否 1:是)
                isCheckBelong: undefined,//	是否法人信息校验 (0:否 1:是)
                baseRate: "",//基础利率
                rateStart: '',//	利率区间起
                rateEnd: '',//	利率区间止
                productRemark: '',//产品说明
                creditPeriod: '',//借款期限
                limitUnit: undefined,//借款期限单位 （日，月，年）
                subProductId: '',//子产品编号
                contractPeriod: undefined,//合同期限（3，6，9，12，18，24，36），单位月
                payMethod: undefined,//还款方式（4：一次性还本付息，5：按期付息、任意还本，6：按期付息、任意还本，7：按期付息、到期一次性还本
                payPrinIntvl: undefined,//	还息间隔：0、0个月，1、1个月，3、3个月，6、6个月，12、12个月
                isChannelPayIntvl: undefined,//	是否平台还息（0、否 1、是）
                isChannelDc: undefined,//	是否平台代偿（0、否 1、是）
                isAutoRepay: undefined,//	是否自动还款（0、否 1、是）
                overdueDay: '',//	逾期天数逾期天数：如果当前日期>到日日期+逾期天数，则进行代偿	
                loanScale: '',//	放款比例
                repayAccountClass: undefined,//还款账户类型，对应account_class
                isPSign: '1',//是否配偶签字（0、否 1、是）默认是
                isAddEName: '1',//是否添加经营实体名称（0、否 1、是）默认是

                loanPurpose: '',//贷款用途
                direction: undefined,// 贷款投向
                repayFundSource: '',// 还贷资金来源
                acceptIntType: undefined,//支农用途

              },
                this.modalBool = false
              this.selectedRowKeys = []
              this.selectedRows = [] // 选数数组的值,
              this.getcommercePage()

            }
            )

          } else {
            this.api.creditProductInfoaddApi(this.addform).then((res) => {
              if (res.code !== 200) {
                this.$message.warning(res.info)
                return
              }

              this.$message.success('新增成功')
              this.addform = {
                departmentId: undefined, // 机构id
                channelNo: '',//渠道编号
                productType: undefined,// 	产品类型 1：个人贷款 2：企业贷款 
                productId: "",// 产品编号
                productName: '',// 产品名称
                creditLimitAmount: '',//	总授信额度
                isCheckCredit: undefined,//	是否校验总授信额度 (0:否 1:是)
                isCheckList: undefined,//是否白名单校验 (0:否 1:是)
                isCheckBelong: undefined,//	是否法人信息校验 (0:否 1:是)
                baseRate: "",//基础利率
                rateStart: '',//	利率区间起
                rateEnd: '',//	利率区间止
                productRemark: '',//产品说明
                creditPeriod: '',//借款期限
                limitUnit: undefined,//借款期限单位 （日，月，年）
                subProductId: '',//子产品编号
                contractPeriod: undefined,//合同期限（3，6，9，12，18，24，36），单位月
                payMethod: undefined,//还款方式（4：一次性还本付息，5：按期付息、任意还本，6：按期付息、任意还本，7：按期付息、到期一次性还本
                payPrinIntvl: undefined,//	还息间隔：0、0个月，1、1个月，3、3个月，6、6个月，12、12个月
                isChannelPayIntvl: undefined,//	是否平台还息（0、否 1、是）
                isChannelDc: undefined,//	是否平台代偿（0、否 1、是）
                isAutoRepay: undefined,//	是否自动还款（0、否 1、是）
                overdueDay: '',//	逾期天数逾期天数：如果当前日期>到日日期+逾期天数，则进行代偿	
                loanScale: '',//	放款比例
                repayAccountClass: undefined,//还款账户类型，对应account_class
                isPSign: '1',//是否配偶签字（0、否 1、是）默认是
                isAddEName: '1',//是否添加经营实体名称（0、否 1、是）默认是

                loanPurpose: '',//贷款用途
                direction: undefined,// 贷款投向
                repayFundSource: '',// 还贷资金来源
                acceptIntType: undefined,//支农用途

              },
                this.modalBool = false
              this.selectedRowKeys = []
              this.selectedRows = [] // 选数数组的值,
              this.getcommercePage()

            }
            )
          }



        } else {
          this.$message.warning("请先填写信息");
        }
      });

    },
    // 点击编辑
    edit() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户')
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('查看只能选择一个账户账户')
        return
      }
      let data = JSON.parse(JSON.stringify(this.selectedRows[0]))
      this.addform = data
      this.modalBool = true
    },


    // 删除
    Deletebutton() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户')
        return
      }

      this.deleteshow = true
    },
    // 点击删除
    async Confirmdelete() {
      let data = []
      this.selectedRows.forEach((item) => {
        data.push(item.id)
      })
      const res = await this.api.creditProductInfodeleteApi(data)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.$message.success('删除成功')
      this.selectedRowKeys = []
      this.selectedRows = [] // 选数数组的值,
      this.deleteshow = false
      this.getcommercePage()
    },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      // if (selectedRows.length > 1) {
      //   //获取选中的数据的key
      //   var selectNumber = this.selectedRowKeys[1]
      //   //清空选中的key
      //   this.selectedRowKeys = []
      //   //选中的数据的key重新赋值给selectedRowKeys
      //   this.selectedRowKeys.push(selectNumber)
      // }
      // //获取选中的数据详细信息
      // this.selectedRows = selectedRows[0]

    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            let index = this.selectedRowKeys.findIndex((item) => id == item)
            if (index == -1) {
              this.selectedRowKeys.push(id)
              this.selectedRows.push(record)

            } else {

              this.selectedRowKeys.splice(index, 1)
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

  },
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.addform {

  .context {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .ant-form-item {
      width: 368px;


    }
  }

  .ant-modal {
    width: 1200px !important;
  }

  .ant-modal-content {

    width: 1200px;
  }
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

/* 自定义表头样式 */
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.ant-modal-footer {

  padding-bottom: 20px;
}

.ant-modal-content {
  min-width: 420px;
}



.loan {
  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 260px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      input {
        width: 180px;
      }

      .lefttitle {
        min-width: 80px;
      }
    }

    .zhaungtai {
      width: 280px;
      margin-right: 120px;
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }

    .ant-tag {
      cursor: pointer;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .ant-tag {
      cursor: pointer;
    }
  }

  .paginationBox {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  .Applyfor {
    padding-top: 10px;
    margin-bottom: 20px;
  }
}
</style>
