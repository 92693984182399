import Accountview from '@/view/Wallet/view/Accountview.vue'
import Individualaccountopening from '@/view/Wallet/view/Individualaccountopening.vue'
import CorporateAccountOpening from '@/view/Wallet/view/CorporateAccountOpening.vue'
import openAccPersonalInfo from '@/view/Wallet/view/openAccPersonalInfo.vue'
import openaccEnterpriseInfo from '@/view/Wallet/view/openaccEnterpriseInfo.vue'
import InnerAccountOpen from '@/view/Wallet/view/InnerAccountOpen.vue'
import CorporateAccountEdit from '@/view/Wallet/Components/CorporateAccountEdit.vue'
import IndividualAccountEdit from '@/view/Wallet/Components/IndividualAccountEdit.vue'
import Accountlist from '@/view/Wallet/Accountlist/index.vue'
import SettleAccounts from "@/view/Wallet/SettleAccounts/index.vue"
import ClearAgreementManage from '@/view/Wallet/ClearAgreementManage/index'

export default [
    { path: '/wallet/accountview', component: Accountview, meta: '账户查看' },
    { path: '/wallet/accountlist', name: 'accountList', component: Accountlist, meta: '账户列表' },
    { path: '/wallet/settleAccounts', component: SettleAccounts, meta: '待清算账户' },
    { path: '/wallet/Individualaccountopening', name: "Individualaccountopening", component: Individualaccountopening, meta: '个人开户' },
    { path: '/wallet/CorporateAccountOpening', name: "CorporateAccountOpening", component: CorporateAccountOpening, meta: '企业开户' },
    { path: '/wallet/openAccPersonalInfo', name: "openAccPersonalInfo", component: openAccPersonalInfo, meta: '个人开户信息详情' },
    { path: '/wallet/openaccEnterpriseInfo', name: "openaccEnterpriseInfo", component: openaccEnterpriseInfo, meta: '企业开户信息详情' },
    { path: '/wallet/InnerAccountOpen', name: "InnerAccountOpen", component: InnerAccountOpen, meta: '内部户开户' },
    { path: '/wallet/CorporateAccountEdit', name: "CorporateAccountEdit", component: CorporateAccountEdit, meta: '企业账户修改' },
    { path: '/wallet/IndividualAccountEdit', name: "IndividualAccountEdit", component: IndividualAccountEdit, meta: '个人账户修改' },
    { path: '/wallet/ClearAgreementManage', name: 'ClearAgreementManage', component: ClearAgreementManage, meta: '清分协议管理' },
]
