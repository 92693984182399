<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>账户管理</span>
            <a-icon type="right"/>
            <span>个人开户</span>
          </div>
          <!-- 隐私协议 -->
          <div style="display: flex">
            <div class="agreement">
              <div class="item">
                <a-checkbox v-model="Userauthorization">
                  本人已阅读并同意
                  <a-tooltip>
                    <template slot="title"> 点击下载</template>
                    <a-button type="link" size="small" @click="downLoadTemplate('TMPL_CZCB_SRVC')">{{
                        mapMapList("TMPL_CZCB_SRVC").state
                      }}
                    </a-button>
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title"> 点击下载</template>
                    <a-button type="link" size="small" @click="downLoadTemplate('TMPL_CZCB_PRVC')">{{
                        mapMapList("TMPL_CZCB_PRVC").state
                      }}
                    </a-button>
                  </a-tooltip>
                </a-checkbox>
              </div>
            </div>
            <div class="right">
              <!-- 确认 -->
              <a-button type="primary" @click="confirm"> 确认</a-button>
              <!-- 取消 -->
              <a-button @click="cancelshow = true"> 取消</a-button>
            </div>
          </div>
        </div>
      </template>
      <!-- 个人开户页面 -->
      <div class="Individualaccountopening">
        <!-- 基础信息 -->
        <div class="formbox">
          <div class="toptitle">基础信息*</div>
          <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <!-- 渠道编号 -->
            <a-form-model-item ref="channel_no" class="channel_no" label="渠道归属" prop="channel_no">
              <a-select v-model.trim="form.channel_no" @change="channel_nochange" placeholder="请选择核心企业"
                  style="width: 200px">
                <a-select-option v-for="item in companylist" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 客户名称 -->
            <a-form-model-item ref="account_name" label="客户" prop="account_name">
              <CustChooseDialog custtypecd="1" :department-id="form.departmentId" v-model="form.account_name"
                  :custnum="form.custnum" @chooseDone="onCustChosen">
              </CustChooseDialog>
            </a-form-model-item>
            <!-- 应用编号 -->
            <a-form-model-item class="app_no" ref="app_no" prop="app_no" style="display: none">
              <template #label>
                <span>应用编号</span>
              </template>
              <!-- <span>应用编号：</span> -->
              <a-input :disabled="true" v-model.trim="form.app_no" placeholder="请输入应用编号"></a-input>
              <div></div>
              <!-- <p v-if="form.app_no == ''">请输入应用编号</p> -->
            </a-form-model-item>
            <!-- 手机号 -->

            <!-- 开户行号 -->
            <!--            <a-form-model-item class="bank_no" ref="bank_no" label="开户行号" prop="bank_no">-->
            <!--              <a-input v-model.trim="form.bank_no" placeholder="请输入开户行号"></a-input>-->
            <!--            </a-form-model-item>-->
            <!-- 证件类型 -->
            <a-form-model-item label="证件类型" class="id_type" ref="id_type" prop="id_type" style="display: none">
              身份证
            </a-form-model-item>
            <!-- 证件号码 -->
            <a-form-model-item label="身份证号" class="id_no" ref="id_no" prop="id_no">
              <a-input v-model.trim="form.id_no" :disabled="true" placeholder="请输入身份证号"></a-input>
            </a-form-model-item>

            <!-- 证件生效日期 -->
            <a-form-model-item label="证件生效日期" class="id_startdate" ref="id_startdate" prop="id_startdate">
              <a-date-picker style="width: 200px" valueFormat="YYYY-MM-DD" v-model.trim="form.id_startdate"/>
            </a-form-model-item>
            <!-- 证件失效日期 -->
            <a-form-model-item label="证件失效日期" class="id_enddated_no" ref="id_enddate" prop="id_enddate">
              <a-date-picker style="width: 200px" ref="datePicker" valueFormat="YYYY-MM-DD"
                  v-model.trim="form.id_enddate"/>
              <span class="check-box" style="margin-left: 50px">
                <a-checkbox v-model="checkBoxValue" @change="changeData">
                  长期
                </a-checkbox>
              </span>
            </a-form-model-item>
            <!-- 签发机关名称 -->
            <a-form-model-item label="签发机关名称" class="sign_name" ref="sign_name" prop="sign_name">
              <a-input v-model.trim="form.sign_name" placeholder="请输入签发机关名称"></a-input>
            </a-form-model-item>

            <!-- 证件地址 -->
            <a-form-model-item label="证件地址" class="id_address" ref="id_address" prop="id_address">
              <a-input v-model.trim="form.id_address" placeholder="请输入证件地址或常住地址"></a-input>
            </a-form-model-item>
            <!-- 性别 -->
            <a-form-model-item label="性别" prop="sex">
              <a-radio-group v-model.trim="form.sex">
                <a-radio v-for="item in sexlist" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!-- 民族 -->
            <a-form-model-item label="民族" prop="ethnic">
              <a-select v-model.trim="form.ethnic" placeholder="请选择民族" style="width: 200px">
                <a-select-option v-for="item in ethnicitylist" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <!-- 职业 OcpCdlist-->
            <a-form-model-item label="职业" prop="job">
              <el-cascader style="width: 200px" v-model="form.job" placeholder="请选择职业" :options="OcpCdlist"
                  :props="{ emitPath: false }" :show-all-levels="false">
              </el-cascader>
            </a-form-model-item>
            <!-- 证件照片 -->
            <a-form-model-item class="pricebox" ref="pricebox" prop="pricebox">
              <template #label>
                <!-- 自定义标签内容 -->
                <span class="custom-label"> <span style="color: #ff0000;">*</span> 证件照片</span>
              </template>
              <div class="row">
                <!-- 身份证 反面 -->
                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 120px;
                    ">身份证人像面</span>
                  <a-upload name="file" list-type="picture-card" accept="image/*" class="avatar-uploader"
                      ref="ReverseofIDcard" :show-upload-list="false" :action="busiURL" :headers="headers"
                      :customRequest="changeIdBack">
                    <uploadImg :showEditicon="true" v-show="verifyvadata.ReverseofIDcardurl" @Uploaderror="
                      verifyvadata.ReverseofIDcardloading = $event
                      " @Editimg="ReverseofIDcardEdit" @onloadsuccsse="verifyvadata.ReverseofIDcardurl = $event"
                        @fileData="verifyvadata.ReverseofIDcardFile = $event" ref="IdBack">
                    </uploadImg>
                    <div v-show="verifyvadata.ReverseofIDcardloading == false &&
                      !verifyvadata.ReverseofIDcardurl
                      ">
                      <a-icon type="plus"/>
                    </div>
                    <div v-if="verifyvadata.ReverseofIDcardloading &&
                      verifyvadata.ReverseofIDcardurl == ''
                      " class="example">
                      <a-spin/>
                    </div>
                  </a-upload>
                  <span v-if="verifyvadata.ReverseofIDcard == false" class="erero">请先上传照片</span>
                </div>
                <!-- 身份证 正面 -->
                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 120px;
                    ">身份证国徽面</span>
                  <a-upload name="file" list-type="picture-card" accept="image/*" ref="Frontofidentitycard"
                      class="avatar-uploader" :show-upload-list="false" :action="busiURL" :headers="headers"
                      :customRequest="changeIdUpload">
                    <uploadImg :showEditicon="true" v-show="verifyvadata.Frontofidentitycardurl" @Uploaderror="
                      verifyvadata.Frontofidentitycardloading = $event
                      " @onloadsuccsse="
    verifyvadata.Frontofidentitycardurl = $event
    " @fileData="verifyvadata.FrontofidentitycardFile = $event" @Editimg="FrontofidentitycardEdit" ref="IdUp">
                    </uploadImg>
                    <div v-show="verifyvadata.Frontofidentitycardloading == false &&
                      !verifyvadata.Frontofidentitycardurl
                      ">
                      <a-icon type="plus"/>
                    </div>
                    <div v-if="verifyvadata.Frontofidentitycardloading == true &&
                      verifyvadata.Frontofidentitycardurl == ''
                      " class="example">
                      <a-spin/>
                    </div>
                  </a-upload>
                  <span v-if="verifyvadata.Frontofidentitycard == false" class="erero">请先上传照片</span>
                </div>

                <div class="third-item">
                  <FaceCollect ref="faceCollect" :app_no="form.app_no" :channel_no="form.channel_no"
                      :custname="form.account_name" :id_no="form.id_no" @success="portraitUpload"
                      @fail="portraitUploadFail"></FaceCollect>
                </div>
                <!-- 人脸识别 portraitUpload  -->
                <!-- <div class="item">
                  <FaceCollect :app_no="this.form.app_no" :channel_no="this.form.channel_no"
                    :custname="this.form.account_name" :id_no="this.form.id_no"></FaceCollect>
                </div> -->
              </div>

              <!-- 提示 -->
              <div style="color: red">
                *请确保身份证信息在有效期范围内，若证件信息过期
                需要去线下银行网点更新
              </div>
            </a-form-model-item>
            <!-- 银行卡号 -->
            <a-form-model-item label="银行卡号" class="primary_account" ref="primary_account" prop="primary_account">
              <a-input :type="'number'" v-model.trim="form.primary_account" placeholder="请输入银行卡号"></a-input>
            </a-form-model-item>
            <!-- 备注 -->
            <a-form-model-item label="手机号" class="mobile" ref="mobile" prop="mobile">
              <a-input :type="'number'" v-model.trim="form.mobile" placeholder="请输入手机号"></a-input>
              <div></div>
            </a-form-model-item>
            <!-- 验证码 -->
            <a-form-model-item label="验证码" class="code" ref="code" prop="verify_code">
              <a-input v-model.trim="form.verify_code" placeholder="请输入验证码">
                <CountdownBtn slot="suffix" :wait-time="60" :error-wait-time="10" :send-call="sendcode"></CountdownBtn>
              </a-input>
              <div></div>
            </a-form-model-item>
            <!-- 开户经度 -->
            <!-- <a-form-model-item label="开户经度" class="open_longitude">

              <a-input :disabled="true" v-model.trim="form.open_longitude" />

            </a-form-model-item> -->
            <!-- 开户维度 -->
            <!-- <a-form-model-item label="开户维度" class="open_dimensions">

              <a-input :disabled="true" v-model.trim="form.open_dimensions" />

            </a-form-model-item> -->
            <!-- 开户ip -->
            <!-- <a-form-model-item label="开户ip" class="job_note">

              <a-input :disabled="true" v-model.trim="form.open_ip" style="width: 200px;" />

            </a-form-model-item> -->
          </a-form-model>
          <QRConfirm :url="qrConfirmUrl" :display.sync=qrConfirmShow busi_intro="个人开户"
              :username="this.form.account_name" @close="$router.go(-1)"></QRConfirm>
        </div>
      </div>
    </a-card>
    <!-- 确认 -->
    <a-modal title="提示" class="confirmloading" :maskClosable="true" @cancel="confirmshow = false" :centered="true"
        :visible="confirmshow">
      <div class="text">是否确认开户？</div>
      <template v-slot:footer>
        <!-- 提现 -->
        <div class="custom-footer">
          <a-button type="primary" @click="ConfirmSave">保存</a-button>
          <a-button @click="confirmshow = false">取消</a-button>
          <a-button @click="ConfirmQRPush">邀请扫码办理</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 取消 -->
    <a-modal title="提示" class="confirmloading" :maskClosable="true" @cancel="cancelshow = false" :centered="true"
        :visible="cancelshow">
      <div class="canceltext">
        <!--个人开户  -->

        是否取消开户？取消后该页面 信息将不会被保存
      </div>
      <template v-slot:footer>
        <!-- 提现 -->
        <div class="custom-footer">
          <a-button type="primary" @click="
            cancelshow = false;
          $router.go(-1);
          ">确认
          </a-button>
          <a-button @click="cancelshow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import urlConfig from "@/utils/urlConfig";
import axios from "axios";
import {BizDict, BizDictCascade} from "../../../utils/bizDict/kvMap.js";
// import qrcode from 'qrcode';
import uploadImg from "../../../components/uploadImg/index.vue";
import dayjs from "dayjs";
import {dict_rawdata_cascade} from "@/utils/bizDict/rawdata";
import CustChooseDialog from "@/view/components/ChooseDialog/CustChooseDialog.vue";
import FaceCollect from "@/view/components/FaceCollect.vue";
import {contractDocTemp} from "@/utils/contractDocTemp";
import CountdownBtn from "@/view/components/CountdownBtn.vue";
import QRConfirm from "@/view/components/QRConfirm.vue";

const getToken = () => sessionStorage.getItem("token");
export default {
  data() {
    return {
      confirmshow: false,
      cancelshow: false,
      qrConfirmUrl: "",
      qrConfirmShow: false,
      latitude: "", // 经度
      checkBoxValue: false,
      longitude: "", // 维度
      busiURL: urlConfig.busiURL + "/file/manager/upload",
      labelCol: {span: 3},
      wrapperCol: {span: 21},
      Facecollectionshow: false, // 人脸采集弹窗
      // 民族列表
      ethnicitylist: BizDict.RaceCd,
      // 性别list
      sexlist: BizDict.gender,
      // 职业列表
      OcpCdlist: BizDictCascade.OcpCd,
      // 机构树形数组
      treeData: [],
      loading: false,
      userinfo: "", // 身份证识别信息
      headers: {
        token: getToken(),
      },
      Userauthorization: false, // 用户协议是否通过

      // 控制影像是否上传
      verifyvadata: {
        // 身份证
        Frontofidentitycard: true, // 正面
        Frontofidentitycardurl: "", // 正面url
        FrontofidentitycardFile: "", // 图片数据
        Frontofidentitycardloading: false,

        ReverseofIDcard: true, // 反面
        ReverseofIDcardurl: "", // 反面
        ReverseofIDcardFile: "", // 反面
        ReverseofIDcardloading: false, // 反面

        // 人脸
        Humanface: true,
        Humanfaceurl: "",
        HumanfaceFile: "",
        Humanfaceloading: false,
      },
      // 影像信息
      file_list: [
        // 身份证照片正
        {
          file_no: "",
          channel_file_no: "",
          file_type: "01",
          page: "1",
        },
        // 身份证照片反
        {
          file_no: "",
          channel_file_no: "",
          file_type: "02",
          page: "2",
        },
        // 人脸
        {
          file_no: "",
          channel_file_no: "",
          file_type: "04",
          page: "3",
        },
      ],
      // 客户编号数据list
      custnumlist: [],
      account_namelist: [],
      // 倒计时
      show: true, // 验证码发送

      Upimgshow: "", // 正面图片
      getUpFile: "", // 正面图片数据
      getUplloading: false,
      Backimgshow: "", // 反面图片
      getBackFile: "", //反面图片数据
      getBackloading: false,
      portraitshow: "", // 人像图片
      portraitFile: "", // 人像数据
      portraitloading: false,

      form: {
        departmentId: undefined, /// 所属机构
        custnum: undefined, // /客户编号
        account_name: "", //客户名称
        channel_no: undefined, //渠道编号
        app_no: "", //应用编号

        mobile: "", // 手机号
        account_property: "1", // 账号性质1 个人

        verify_code: "", // 验证码
        primary_account: "", //银行卡号
        bank_no: undefined, //开户行号
        id_type: "1", // 证件类型身份证
        id_no: "", // 证件号码
        id_startdate: "", //证件有效日期
        id_enddate: "", //证件结束日期
        sign_name: "", //签发机关名称
        id_address: "", // 证件地址
        sex: "", // 性别
        ethnic: "01", // 民族
        job: undefined, //职业
        open_longitude: "", // open_longitude
        open_dimensions: "", // open_dimensions
        open_ip: "", //ip
        // 职业备注
      },
      // 核心企业列表
      companylist: [],
      // 身份证正
      IDcardfront: "",
      // 身份证反
      IDcardback: "",
      // 人脸
      faceimage: "",
      rules: {
        departmentId: [
          {required: true, message: "请选择所属机构", trigger: "change"},
        ],
        custnum: [{required: true, message: "请选择客户", trigger: "change"}],
        account_name: [
          {required: true, message: "请输入客户名称", trigger: "change"},
        ],
        app_no: [
          {required: true, message: "请输入应用编号", trigger: "blur"},
        ],
        channel_no: [
          {required: true, message: "请输入渠道编号", trigger: "change"},
        ],
        mobile: [
          {required: true, message: "请输入手机号", trigger: "blur"},
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入有效的手机号码",
            trigger: "blur",
          },
        ],
        // verify_code: [
        //   { required: true, message: "请输入验证码", trigger: "blur" },
        // ],
        primary_account: [
          {
            required: true,
            message:
                "请输入任意银行的银行卡号，仅支持16位或19位“62”开头的银联储蓄卡",
          },
          {
            pattern: /^62\d{14}(\d{3})?$/,
            message:
                "请输入任意银行的银行卡号，仅支持16位或19位“62”开头的银联储蓄卡",
            trigger: "blur",
          },
        ],

        // bank_no: [{required: true, message: '请选择开户行号', trigger: 'blur'}],
        id_startdate: [
          {required: true, message: "请选择证件生效日期", trigger: "change"},
        ],
        id_enddate: [
          {required: true, message: "请选择证件失效日期", trigger: "change"},
        ],
        sign_name: [
          {required: true, message: "请输入签发机关名称", trigger: "blur"},
        ],
        id_address: [
          {required: true, message: "请输入证件地址", trigger: "blur"},
        ],
        id_no: [{required: true, message: "请输入证件号码", trigger: "blur"}],
        job: [{required: true, message: "请选择职业", trigger: "change"}],
      },
    };
  },
  components: {
    QRConfirm,
    CountdownBtn,
    CustChooseDialog,
    FaceCollect,
    uploadImg,
  },
  async created() {

    this.getChannelList();
    // this.form.departmentId = sessionStorage.getItem("companyId");
    // 加载选中客户信息
    if (this.$route.params.type === "add") {
      this.onCustChosen({custnum: this.$route.params.custnum});
    }
  },
  mounted() {

  },
  watch: {
    "form.id_enddate": {
      handler: function (newVal) {
        if (newVal == "9999-12-31") {
          this.checkBoxValue = true;
        } else {
          this.checkBoxValue = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    async onCustChosen(cust) {
      let res = await this.api.getCustData(cust.custnum);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      let custDetail = res.data;

      this.form.departmentId = custDetail.custPersonInfo.departmentId;
      this.form.custnum = custDetail.custPersonInfo.custnum;

      this.form.account_name = custDetail.custPersonInfo.custname;
      this.form.ethnic = custDetail.custPersonInfo.ethniccd; //民族
      this.form.sex = custDetail.custPersonInfo.gendercd === "1" ? "0" : "1"; // 性别 客户表和开户时用的不是同一套码表， 需要手动转换一下
      this.form.job = custDetail.custPersonInfo.careercd; // 职业
      if (custDetail.custIdInfo) {
        this.form.id_no = custDetail.custIdInfo.certnum; // 证件号
        this.form.id_startdate = custDetail.custIdInfo.certeffectdt; // 证件生效日期
        this.form.id_enddate = custDetail.custIdInfo.certinvaliddt; // 证件到期日期
        this.form.sign_name = custDetail.custIdInfo.certissueorgname; // 签发机关
      }
      if (
          custDetail.custAddressInfos &&
          custDetail.custAddressInfos.length > 0
      ) {
        // 抓取省市区， 并和后面的证件地址拼起来
        let custDistcd = custDetail.custAddressInfos[0].distcd;
        let dict = dict_rawdata_cascade.region_raw.find(
            (i) => i.r === custDistcd
        );
        if (dict) {
          this.form.id_address =
              dict.c + dict.d + custDetail.custAddressInfos[0].addrinfo; // 证件地址
        } else {
          this.form.id_address = custDetail.custAddressInfos[0].addrinfo; // 证件地址
        }
      }
      if (custDetail.custContactInfos && custDetail.custContactInfos.length > 0)
        this.form.mobile = custDetail.custContactInfos[0].continfo; // 手机号
    },
    // 下载模板
    async downLoadTemplate(type) {
      contractDocTemp.call(this, type, this.api, this.$message);
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              this.form.open_longitude = position.coords.longitude;
              this.form.open_dimensions = position.coords.latitude;
              // 在这里使用经纬度进行其他操作
            },
            () => {
              // 处理错误情况

              this.$message.warning("请在浏览器隐私和安全授权获取位置信息");
            }
        );
      } else {
        // 浏览器不支持Geolocation API
      }
    },
    changeData() {
      if (this.checkBoxValue) {
        this.form.id_enddate = "9999-12-31";
      } else {
        this.form.id_enddate = "";
      }
    },
    // 获取核心企业列表
    async getChannelList() {
      const res = await this.api.coreCompanyInfoApi();
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }

      this.companylist = []
      res.data.forEach((item) => {
        this.companylist.push({
          label: item.channelName + "（" + item.channelNo + "）",
          value: item.channelNo,
          info: item,
        });
      });
      if (this.companylist.length === 1) {
        this.form.channel_no = this.companylist[0]?.value
        this.channel_nochange(this.form.channel_no)
      }
    },

    //
    mapMapList(code) {
      let obj = "";
      let mapList = this.$store.state.getMapList;
      if (!mapList) {
        return obj
      }
      mapList.forEach((item) => {
        if (item.code === code) {
          obj = item;
        }
      });
      return obj;
    },
    // 渠道变更
    channel_nochange(val) {
      this.companylist.forEach((item) => {
        if (Number(val) == Number(item.value)) {
          this.form.app_no = item.info.appNo;
        }
      });
    },

    // 发送验证码
    async sendcode() {
      // 手动触发表单校验
      var validateResult = [];
      await this.$refs.ruleForm.validateField(
          ["app_no", "mobile", "channel_no", "primary_account", "account_name",],
          (errorMsg) => validateResult.push(errorMsg)
      );
      if (validateResult.every((item) => item === "")) {
        const res = await this.api.vefifycodesend({
          appNo: this.app_no,
          channelNo: this.form.channel_no,
          mobile: Number(this.form.mobile),
          cardNo: this.form.primary_account,
          accountName: this.form.account_name,
          idNo: this.form.id_no,
          tradeType: 0,
        });
        if (res.code === 200) {
          this.$message.success(res.info);
          return true
        } else {
          this.$message.error(res.msg);
          return false
        }
      } else {
        return false
      }
    },
    // 点击校验
    confirm() {

      //   Userauthorization: false,// 用户协议是否通过
      if (!this.Userauthorization) {
        this.$message.warning(
            `请先同意${this.mapMapList("TMPL_CZCB_SRVC").state}和${this.mapMapList("TMPL_CZCB_PRVC").state
            }`
        );
        return;
      }
      // Riskwarning: false,//风险提示
      const that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 校验通过后判断影像信息
          if (
              this.verifyvadata.FrontofidentitycardFile == "" ||
              this.verifyvadata.ReverseofIDcardFile == "" //|| this.verifyvadata.HumanfaceFile == ""
          ) {
            this.$message.warning("请先填写影像信息");
          } else {
            // 首先进项判断 如果省份证和当天时间相比过期 提示用户 不能进行开户，如果身份证过期时间跟当前相比低于180天，则提示用户
            const start = dayjs().format("YYYY-MM-DD");
            const end = dayjs(this.form.id_enddate);
            const daysDiff = end.diff(start, "day");

            if (daysDiff <= 0) {
              this.$message.warning(
                  "您的证件已经到期，建议使用最新的证件进行注册"
              );
              return;
            }
            if (daysDiff > 0 && daysDiff < 180) {
              this.$confirm({
                title: "提示",
                content:
                    "您的证件将在半年内到期，届时银行将对您的账号进行管制。解除管制需要本人持有效证件前往稠州银行网点更新，建议使用最新的证件进行注册， 确定继续吗？",
                onOk() {
                  that.confirmshow = true;
                },
                onCancel() {
                  that.$refs.datePicker.focus();
                },
              });
              return;
            }
            if (daysDiff > 180) {
              that.confirmshow = true;
            }
          }
        } else {
          this.$message.warning("请先填写开户信息");
        }
      });
    },
    // 保存
    async ConfirmSave() {
      // openAccPersonalApi
      if (this.form.verify_code == "") {
        this.$message.warning("请先填写验证码");
      }
      delete this.form.departmentId;

      // 添加影像信息
      this.form.file_list = this.file_list;
      const res = await this.api.openAccPersonalApi(this.form);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.confirmshow = false;
      this.$message.success("申请开户成功");
      // this.$router.go(-1);
      this.$router.push({
        path: "/wallet/accountlist",
        query: {
          fromAddPage: true
        }
      })
    },

    async ConfirmQRPush() {
      delete this.form.departmentId;

      // 添加影像信息
      this.form.file_list = this.file_list.filter(file => file.file_type !== '04'); // 使用 filter 方法移除满足条件的元素
      const res = await this.api.custOpenAccTaskSave({
        channelNo: this.form.channel_no,
        appNo: this.form.app_no,
        taskType: 0,
        name: this.form.account_name,
        idNo: this.form.id_no,
        mobile: this.form.mobile,
        cardNo: this.form.primary_account,
        reqData: this.form
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.confirmshow = false;
      this.qrConfirmUrl = res.data
      this.qrConfirmShow = true

    },
    // 选择机构触发
    async departmentchange(val) {
      const res = await this.api.custPage({
        pageNo: 1,
        pageSize: 100000,
        obj: {
          departmentId: val,
        },
      });
      if (res.code !== 200) {
        this.$message.warning(res.info);
        return;
      }
      this.custnumlist = res.data.records;
      this.account_namelist = [];
      // 通过所选择的所属机构帅选出 下面对应的请选择客户编号
      // account_namelist
      this.custnumlist.forEach((item) => {
        this.account_namelist.push({
          label: item.custname + "（" + item.custnum + "）",
          value: item.custnum,
        });
      });
      this.form.custnum = this.$route.params.custnum;
    },
    changeIdUpload(info) {

      if (this.form.channel_no == undefined) return this.$message.error('请先选择渠道')
      if (this.verifyvadata.FrontofidentitycardFile !== "") {
        this.verifyvadata.Frontofidentitycardloading = false;
        this.verifyvadata.FrontofidentitycardFile = "";
        this.verifyvadata.Frontofidentitycardurl = "";
      }
      const isLt1M = info.file.size / 1024 / 1024 > 1;
      if (isLt1M) {
        const result = this.compressImage(info.file);
        result.then((value) => {
          this.Fileupload("01", value);
        })
      } else {
        this.Fileupload("01", info.file);
      }
      this.verifyvadata.Frontofidentitycardloading = true;

      // if (info.file.status === 'uploading') {
      //   this.verifyvadata.Frontofidentitycardloading = true;
      //   // return;
      // }
      // this.$refs.IdUp.handleChangeUpload(info);
    },

    async compressImage(file) {
      // console.log(file, 'compress');
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 1920; // 设置压缩后的图像宽度
            const maxHeight = 1080; // 设置压缩后的图像高度

            let newWidth = img.width;
            let newHeight = img.height;

            if (img.width > maxWidth) {
              newWidth = maxWidth;
              newHeight = (img.height * maxWidth) / img.width;
            }

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              newWidth = (img.width * maxHeight) / img.height;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // 将压缩后的图像转换为 Blob 对象
            canvas.toBlob(
                (blob) => {
                  const compressedFile = new File([blob], file.name, {
                    type: 'image/jpeg',
                  });
                  resolve(compressedFile);
                },
                'image/jpeg',
                0.9
            );

          };
        };
      });
    },
    // 身份证反面
    changeIdBack(info) {
      if (this.form.channel_no == undefined) return this.$message.error('请先选择渠道')
      if (this.verifyvadata.ReverseofIDcardFile !== "") {
        this.verifyvadata.ReverseofIDcardloading = false;
        this.verifyvadata.ReverseofIDcardFile = "";
        this.verifyvadata.ReverseofIDcardurl = "";
      }
      this.verifyvadata.ReverseofIDcardloading = true;
      const isLt1M = info.file.size / 1024 / 1024 > 1;
      if (isLt1M) {
        const result = this.compressImage(info.file);
        result.then((value) => {
          this.Fileupload("02", value);
        })
      } else {
        this.Fileupload("02", info.file);
      }

      // if (info.file.status === 'uploading') {
      //   this.verifyvadata.ReverseofIDcardloading = true;
      //   // return;
      // }
      // this.$refs.IdBack.handleChangeUpload(info);
    },


    // 人像上传成功
    async portraitUpload(data) {
      // this.api.downloadImage(data.file_no).then((res) => {
      //   if (res.code !== 200) {
      //     this.$message.error(res.info);
      //   }
      //   this.$refs.faceCollect.imgSrc = res.data

      // });
      if (this.verifyvadata.HumanfaceFile !== "") {
        this.verifyvadata.Humanfaceloading = false;
        this.verifyvadata.HumanfaceFile = "";
        this.verifyvadata.Humanfaceurl = "";
      }
      this.verifyvadata.HumanfaceFile = data.file_no
      this.verifyvadata.Humanfaceloading = true;
      this.file_list[2].channel_file_no = data.channel_file_no;
      this.file_list[2].file_no = data.file_no;
      // this.$refs.Humanface.HumanfaceEcho(data.file_no);
    },
    // 人像上传失败
    portraitUploadFail(data) {
      this.$message.warning("人像采集失败" + data.info)
    },
    // 文件上传
    Fileupload(type, file) {
      const formData = new FormData();
      formData.append("file", file);
      axios
      .post(
          urlConfig.busiURL +
          `/base/ocr/ocrUpload?file_type=${type}&channel_no=${this.form.channel_no}&app_no=${this.form.app_no}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              token: getToken(), // 替换YOUR_TOKEN为实际的Token值
            },
          }
      )
      .then((res) => {
        // console.log(res)
        if (res.data.code !== 200) {
          this.$message.error(res.data.info);
          if (type == "01") {
            this.verifyvadata.Frontofidentitycardloading = false;
          } else if (type == "02") {
            this.verifyvadata.ReverseofIDcardloading = false;
          }

          return;
        }
        if (type == "01") {
          // 正，国徽
          this.$refs.IdUp.HumanfaceEcho(res.data.data.file_no);
          this.file_list[1].channel_file_no = res.data.data.channel_file_no;
          this.file_list[1].file_no = res.data.data.file_no;
          // 处理证件时间格式
          var dateRange = res.data.data.ocrRecognizeResult.validDate;
          var dates = dateRange.split("-");

          var startDate = dates[0].replace(/\./g, "-");
          var endDate = dates[1].replace(/\./g, "-");
          this.form.id_startdate = this.form.id_startdate || startDate; //证件有效日期
          if (dateRange.includes('长期')) {
            this.form.id_enddate = '9999-12-31'
          } else {
            this.form.id_enddate = this.form.id_enddate || endDate; //证件结束日期
          }

          this.form.sign_name =
              this.form.sign_name || res.data.data.ocrRecognizeResult.authority; //签发机关名称
          this.$refs.ruleForm.validateField([
            "sign_name",
            "id_enddate",
            "id_startdate",
          ]);
        } else if (type == "02") {
          // 反 人像
          this.$refs.IdBack.HumanfaceEcho(res.data.data.file_no);
          this.userinfo = res.data.data.ocrRecognizeResult;
          // 证件号码
          if (this.form.id_no != res.data.data.ocrRecognizeResult.idcard) {
            this.$message.error(`录入的证件号${this.form.id_no}与上传照片${res.data.data.ocrRecognizeResult.idcard}不符,请确认并重新上传`)
          }
          // this.name = res.data.data.ocrRecognizeResult.name
          if (res.data.data.ocrRecognizeResult.sex == "男") {
            this.form.sex = this.form.sex || "0";
          }
          if (res.data.data.ocrRecognizeResult.sex == "女") {
            this.form.sex = this.form.sex || "1";
          }
          this.file_list[0].channel_file_no = res.data.data.channel_file_no;
          this.file_list[0].file_no = res.data.data.file_no;
          // 地址
          this.form.id_address =
              this.form.id_address || res.data.data.ocrRecognizeResult.address;
          this.$refs.ruleForm.validateField(["id_address", "id_no"]);
        }
      })
      .catch(() => {
        if (type == "01") {
          this.verifyvadata.Frontofidentitycardloading = false;
        } else if (type == "02") {
          this.verifyvadata.ReverseofIDcardloading = false;
        }
      });
    },
    // 人像修改
    ReverseofIDcardEdit() {
      const inputElement =
          this.$refs.ReverseofIDcard.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
    },

    // 国徽修改
    FrontofidentitycardEdit() {
      const inputElement =
          this.$refs.Frontofidentitycard.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
    },
    // 人脸识别修改
    HumanfaceEdit() {
      const inputElement =
          this.$refs.HumanfaceEdit.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>

<style lang="less" scoped>
.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}

/deep/ .ant-modal-footer {
  border: none;
  padding-bottom: 20px;
}

/deep/ .ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}

/deep/ .ant-modal-content {
  width: 420px;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 18px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .agreement {
    /deep/ .ant-checkbox-wrapper {
      margin-right: 2px;
    }

    margin-right: 100px;
    display: flex;
    align-items: center;

    .item {
      margin-left: 15px;

      span {
        &:hover {
          line-height: 20px;
          color: #40a9ff;
          border-bottom: 1px solid #40a9ff;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    button {
      height: 38px;
      border: none;

      &:first-child {
        width: 108px;

        color: #ffff;
      }

      &:last-child {
        width: 108px;
        background-color: #d7d7d7;
        color: #ffff;
      }
    }
  }
}

.Facecollection {
  .dimensionalcode {
    text-align: center;
    display: flex;
    min-height: 250px;
    align-items: center;
    justify-content: center;

    .img-box {
      width: 250px;
      height: 250px;
      margin-bottom: 20px;
    }
  }

  p {
    margin-bottom: 0px;
    color: #333333;
  }

  .custom-footer {
    button {
      width: 88px;
    }
  }
}

.Individualaccountopening {
  padding-bottom: 100px;

  .departmentId {
    /deep/ .ant-form-item-control-wrapper {
      border-top: 1px solid #d7d7d7;
    }
  }

  .app_no {
    // /deep/.ant-form-item-required {
    //   display: none;
    // }

    .app_nobox {
      display: flex;

      span {
        display: flex;
        width: 120px;
        align-items: center;
      }
    }

    p {
      height: 20px;
    }
  }

  /deep/ .ant-form-item-label {
    min-height: 45px;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  // .channel_no {
  //   // /deep/ .ant-form-item-label {
  //   //   padding-top: 10px;

  //   // }
  // }



  .id_type,
  .id_no,
  .job_note,
  .open_dimensions,
  .open_longitude {
    /deep/ .ant-form-item-label {
      min-height: 45px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }
  }


  .pricebox {
    width: 100%;

    /deep/ .ant-form-item-label {
      min-height: 180px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }

    /deep/ .ant-form-item-control-wrapper {
      min-height: 180px;
    }

    .row {
      display: flex;
      // flex-wrap: wrap;

      .item {
        margin-left: 50px;
        display: flex;
        align-items: center;

        /deep/ .ant-upload {
          width: 200px;
          height: 122px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          padding: 0px;
          overflow: hidden;
          border: 1px dashed #d9d9d9 !important;
          background-color: #fafafa !important;

          .anticon-plus {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .ant-upload.ant-upload-select-picture-card {
          border: 1px solid #ccc;
        }

        .ant-upload.ant-upload-select-picture-card > .ant-upload {
          padding: 0;
        }

        .ant-upload-select-picture {
          width: 200px;
          height: 122px;
          border: 0;
        }
      }

      .third-item {
        display: flex;
        align-items: center;

      }
    }
  }

  /deep/ .ant-form-item {
    background-color: #f2f2f2;
    min-height: 50px;
    margin-bottom: 0px !important;
  }

  /deep/ .ant-form-item-control-wrapper {
    padding-left: 35px;
    padding-left: 20px;
    background-color: #fff;
    border-bottom: 1px solid #d7d7d7;
    border-left: none;
    display: flex;
    min-height: 50px;
    align-items: center;

    .ant-form-item-control {
      min-width: 200px;
    }
  }

  .confirmloading {
    color: #333333;

    .text {
      padding: 20px 0px;
      text-align: center;
      font-size: 16px;
      color: #494949;
    }

    .canceltext {
      width: 200px;
      margin: auto;
      text-align: center;
    }

    .custom-footer {
      button {
        &:first-child {
          color: #fff;
        }

        &:last-child {
          background-color: #f2f2f2;
          color: #494949;
        }
      }
    }
  }
}
</style>
