<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>

        <div class="top-title">
          <div class="title-text">
            <span>交易明细 </span>
          </div>
          <div class="right">
            <a-button type="primary" @click="back">返回</a-button>
          </div>
        </div>
      </template>
      <div>
        <template>
          <a-descriptions title="" :column="3" bordered>

            <a-descriptions-item label="机构">
              <DeptInfo :dept-id='transactionDetail.departmentId'></DeptInfo>
            </a-descriptions-item>

            <a-descriptions-item label="交易时间">{{ formattedPayTime }}</a-descriptions-item>
            <a-descriptions-item label="金额">
              <Balance :amount="transactionDetail.payAmount" dcflag="c" prefix="￥" suffix="元"/>
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label>
                状态
              </template>
              <a-tag :color="nbiDict.statusConfig[transactionDetail.payStatus]?.color">
                {{ nbiDict.statusConfig[transactionDetail.payStatus]?.label }}
              </a-tag>
              <a-button
                  v-if="nbiDict.statusConfig[transactionDetail.payStatus]?.showButton"
                  type="link"
                  icon="reload"
                  @click="retryOnFailure">
              </a-button>
            </a-descriptions-item>
            <a-descriptions-item label="失败原因" v-if="transactionDetail.payStatus===2||transactionDetail.payStatus===4">
              {{
                transactionDetail. payResult}}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions title="" :column="2" bordered>
            <a-descriptions-item label="出资人">{{ transactionDetail.lenderAccountName }}</a-descriptions-item>
            <a-descriptions-item label="出资账号">
              <WalletInfo v-if='transactionDetail?.lender?.accountNo'
                          :account-no="transactionDetail.lender.accountNo"
                          :account-name="transactionDetail.lender.accountName"
                          :account-class="transactionDetail.lender.accountClass"
                          :show-balance="false"
              ></WalletInfo>
            </a-descriptions-item>
            <a-descriptions-item label="融资人">{{ transactionDetail.borrowerAccountName }}</a-descriptions-item>
            <a-descriptions-item label="融资账号">
              <WalletInfo v-if='transactionDetail?.borrower?.accountNo'
                          :account-no="transactionDetail.borrower.accountNo"
                          :account-name="transactionDetail.borrower.accountName"
                          :account-class="transactionDetail.borrower.accountClass"
                          :show-balance="false"
              ></WalletInfo>
            </a-descriptions-item>
            <a-descriptions-item label="收款人">{{ transactionDetail.receiverAccountName }}</a-descriptions-item>
            <a-descriptions-item label="出资账号">
              <WalletInfo v-if='transactionDetail?.receiver?.accountNo'
                          :account-no="transactionDetail.receiver.accountNo"
                          :account-name="transactionDetail.receiver.accountName"
                          :account-class="transactionDetail.receiver.accountClass"
                          :show-balance="false"
              ></WalletInfo>
            </a-descriptions-item>
          </a-descriptions>
        </template>
        <div class="loan">
          <div class="top-title">
            <div class="left">
              <span>明细</span>
            </div>
          </div>
          <div class="table-box">
            <a-table :columns="columns" :data-source="transactionDetail.atomList" :row-key="(record) => record.id" :pagination="false" bordered size="small">
            </a-table>
          </div>
        </div>
      </div>

      <div>


      </div>
    </a-card>
  </div>


</template>

<script>
import moment from "moment";
import WalletInfo from "@/view/components/WalletInfo.vue";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import DetailReceivers from "@/view/nbi/borrower/detail/detail-receivers.vue";
import DetailRepayAccounts from "@/view/nbi/borrower/detail/detail-repay-accounts.vue";
import DetailFees from "@/view/nbi/borrower/detail/detail-fees.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";
import {nbiDict} from "@/utils/bizDict/nbiDict.js";
import Balance from "../../components/Balance.vue";

export default {
  name: "transDetail",
  components: {Balance, DeptInfo, DetailRepayAccounts, DetailReceivers, DetailFees, WalletChooseDialog, WalletInfo},
  data() {

    const transactionDetail = {
      id: undefined,
      departmentId: undefined,
      departmentCode: undefined,
      channelNo: undefined,
      appNo: undefined,
      lenderId: undefined,
      lenderName: undefined,
      borrowerName: undefined,
      accountNo: undefined,
      accountName: undefined,
      accountClass: undefined,
      status: undefined,
      createTime: undefined,
      creater: undefined,
      updateTime: undefined,
      updater: undefined,
      recordStatus: undefined,
      lender: undefined,
      borrowerFeeInfoList: [],
      repayAccountList: [],
      receiverList: [],

    }

    return {
      nbiDict,
      transactionDetail,
      columns: [

        {
          title: '交易金额',
          dataIndex: 'payAmount',
          key: 'payAmount',
          align: 'center',
          width: 160,
          customRender: (text) => {
            return <Balance amount={text} dcflag="c"></Balance>
          }
        },
        {
          title: "交易状态",
          dataIndex: "payStatus",
          align: "center",
          customRender: (text, record) => {
            const statusConfig = nbiDict.statusConfig[text] || nbiDict.statusConfig.default;

            return (
                <span>
                 {text !== '1' ? (
                     <a-tooltip title={record.payResult}>
                       <a-tag color={statusConfig.color}>{statusConfig.label}</a-tag>
                     </a-tooltip>
                 ) : <a-tag color={statusConfig.color}>{statusConfig.label}</a-tag>}
                </span>
            );
          }
        },
        {
          title: '付款人',
          dataIndex: 'accountNo',
          align: 'center',
          customRender: (text, record) => {
            if (record.contractNo) {
              return "待清算 " + record.contractNo + " （" + record.accountName+"）";
            } else {
              return (
                  <WalletInfo
                      accountName={record.accountName}
                      accountNo={record.accountNo}
                      showAccountName={true}
                      showAccountNo={false}
                  />
              );
            }
          },
        },

        {
          title: '收款人', dataIndex: 'oppAccountNo', align: 'center', customRender: (text, record) =>
              <WalletInfo accountName={record.oppAccountName} accountNo={record.oppAccountNo}
                          showAccountName={true} showAccountNo={false}/>
        },
        {
          title: '摘要', dataIndex: 'remark', align: 'center',
        }
      ]
    }
  },

  computed: {
    formattedPayTime() {
      return this.transactionDetail.createTime ? moment(this.transactionDetail.createTime).format("YYYY-MM-DD HH:mm:ss") : "";
    }
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {

    retryOnFailure: async function () {
      const res = await this.api.transactionRetryOnFailureApi(this.transactionDetail.txnNo)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success('重试成功')
    },

    back() {
      //回到上一页
      this.$router.go(-1)
    },

    //刷新融资人开通状态
    async fetchBorrowerStatus() {
      await this.api.borrowerRefreshContractApi(this.transactionDetail.id)
    },
    async fetchDetail() {
      let txnNo = this.$route.query.txnNo;
      if (!txnNo) {
        return
      }
      let res = await this.api.transactionDetailApi(txnNo)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.transactionDetail = {...res.data}
    },
  },
}


</script>

<style scoped lang="less">
.description-container {
  display: flex;
  justify-content: center;

  .ant-descriptions-title {
    text-align: center;
  }
}

.top-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .left {
    span {
      height: 38px;
      font-size: 15px;
      font-weight: 700;
    }
  }

  .right {
    position: absolute;
    right: 0; /* 将按钮固定在最右侧 */

    button {
      height: 38px;
      border: none;
      width: 108px;
      color: #fff;
    }
  }
}

img {
  cursor: pointer;
}

.ant-btn-icon-only {
  height: 22px;
}
</style>