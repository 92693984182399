<template>
  <div>
    <a-card>
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>待清算发票确认</span>
          </div>
        </div>
      </template>

      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <div style="display:flex; justify-content: center;">
          <div style="width: 500px;margin: 0 20px">
            <a-form-model-item ref="collAccountName" label="到账明细文件" prop="collAccountName">

              <a-upload-dragger :fileList="fileList"
                                :remove="handleRemove"
                                :customRequest="beforeUpload"
                                class="invoice-uploader"
                                accept=".xlsx,.xls,.csv"
              >

                <div>
                  <p class="ant-upload-drag-icon">
                    <a-icon :type="loading ? 'loading' : 'inbox'"/>
                  </p>
                  <div class="ant-upload-text">
                    拖入或点击上传明细，支持xlsx,xls,csv
                  </div>
                </div>
              </a-upload-dragger>


            </a-form-model-item>

            <a-form-model-item ref="registerDate" label="登记日期" prop="registerDate">
              <a-date-picker v-model="form.registerDate" :disabled="true"/>
            </a-form-model-item>

            <a-form-model-item ref="mchNo" label="商户" prop="mchNo">
              <a-select class="dianshang" :dropdownMatchSelectWidth="false" placeholder="请选择商户" :show-search="true"
                        v-model.trim="form.mchNo"
                        style="width: 100%;" @change="getShopList">
                <a-select-option v-for="item in mchNolist" :key="item.value" :value="item.value">
                  <div style="display: flex; justify-content: space-between">
                    <span> {{ item.label }} </span>
                    <code style="color: grey;font-size: 0.8em">{{ item.value }}</code>
                  </div>
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item ref="shopNo" label="收款账号" prop="shopNo">
              <div style="display: grid; align-items: center;">
                <a-select :dropdownMatchSelectWidth="false" placeholder="请选择回款账号" :show-search="true"
                          v-model.trim="form.shopNo"
                          style="width: 100%;" @change="onShopChange">
                  <a-select-option v-for="item in shoplist" :key="item.shopNo" :value="item.shopNo">
                    <div style="display: flex; justify-content: space-between">
                      <span>{{ item.shopName }}</span>
                      <code style="color: grey;font-size: 0.8em">{{ item.refundAccountNo }}</code></div>
                  </a-select-option>
                </a-select>

                <div style="margin-top: 5px;">
                <span>   <Balance :amount="currentBalance" :align-right="false" dcflag="d" prefix="余额￥"/></span>
                  <a-button type="link" icon="reload" size="small" @click="qryAccountBalance(form.channelNo,form.refundAccountNo)">刷新</a-button>


                </div>
              </div>


            </a-form-model-item>


            <div class="buttons">
              <a-button type="primary" @click="submitInvoice">导入</a-button>
              <a-button @click="cancelInvoice">取消</a-button>
            </div>
          </div>
          <div style="width: 900px;height: 550px">
            <a-collapse :showArrow="true" :activeKey="[1,2]">
              <a-collapse-panel header="当日待清算明细" key="2">
                <a-table size="small" :scroll="{x:800, y: 400, }" :columns="columnsUp"
                         :data-source="allSuccessMatchItems"
                         :pagination="{showSizeChanger:true,pageSizeOptions:['10','20','50','100']} "
                         :row-key="(record) => record.id"
                         bordered>
                  <template slot="action" slot-scope="text, record">
                    <a-button type="link" style="color:red" @click="close(record)" size="small" icon="close"> </a-button>
                  </template>
                  <template slot="footer">
                    <Balance :amount="allSuccessMatchAmount" :align-right="false" dcflag="c" prefix="待清算总额￥"/>
                    <a-button type="link" size="small" @click="invoiceAutoMatchByTrans"  :disabled="allSuccessMatchAmount<=0">立即清算</a-button>
                    <a-button style="float:right" type="link" size="small" @click="mchShopInvoiceTransItemList" icon="reload"></a-button>
                  </template>
                </a-table>
              </a-collapse-panel>

              <a-collapse-panel header="待选明细" key="1">
                <a-table size="small" :scroll="{x:800, y: 400, }" :columns="columnsDown"
                         :data-source="allFailMatchItems"
                         :pagination="{showSizeChanger:true,pageSizeOptions:['10','20','50','100'],defaultPageSize:100}"
                         :row-key="(record) => record.id"
                         :row-selection="rowSelection"
                         bordered>
                  <template slot="action" slot-scope="text, record">
                    <a-button type="link" style="color:green" @click="check(record)" icon="plus" size="small"></a-button>
                  </template>
                  <template slot="footer">
                    <a-button type="link" size="small" @click="checkAll" icon="plus" :disabled="!currSelectCount">添加到待清算</a-button>
                    <span>当前选中<span>{{ currSelectCount }}</span>笔，总金额:
                      <Balance dcflag="c" :amount="Number(currSelectTotal)" :align-right="false"></Balance></span>
                  </template>
                </a-table>
              </a-collapse-panel>
            </a-collapse>

          </div>
        </div>
      </a-form-model>


      <div class="del-dialog">
        <a-modal
            title="是否确定导入并覆盖"
            :visible="registerModalVisible"
            okText="导入并覆盖"
            okType="danger"
            cancelText="返回"
            @ok="importInvoiceReceiptOk"
            @cancel="handleCancel"
        >
          <a-descriptions bordered>
            <a-descriptions-item label="登记日期" :span="3">
              {{ this.form.registerDate.format('YYYY-MM-DD') }}
            </a-descriptions-item>
            <a-descriptions-item label="商户" :span="3">
              {{ form.mchName }}-{{ form.mchNo }}
            </a-descriptions-item>
            <a-descriptions-item label="收款账号" :span="3">
              <WalletInfo :account-no="selectedShop.refundAccountNo"
                          :account-name="selectedShop.refundAccountName"></WalletInfo>
            </a-descriptions-item>
          </a-descriptions>
        </a-modal>
      </div>
    </a-card>
  </div>
</template>
<script>
import uploadImg from "@/components/uploadImg/index.vue";
import WalletInfo from "@/view/components/WalletInfo.vue";
import Balance from "@/view/components/Balance.vue";
import {mchshopinvoiceDict} from "@/utils/bizDict/mchshopinvoiceDict";

export default {
  name: 'MchShopInvoiceDepositImport',
  // eslint-disable-next-line vue/no-unused-components
  components: {WalletInfo, uploadImg, Balance},
  data() {
    return {
      settleStatus: mchshopinvoiceDict.settleStatus,
      matchStatus: mchshopinvoiceDict.matchStatus,
      invalidStatus: mchshopinvoiceDict.invalidStatus,

      fileList: [],
      loading: false,
      registerModalVisible: false,
      invoiceTransItemListData: [],
      allSuccessMatchItems: [],
      allFailMatchItems: [],
      allSuccessMatchAmount: 0.00,
      selectedShop: {}, // 存储选中店铺的完整信息
      rawInvoiceInfo: {}, // 发票原始识别信息
      selectedRows: [], //选中的
      currentBalance: 0,
      form: {
        mchNo: '',
        channelNo: '',
        mchName: '',
        shopNo: '',
        shopName: '',
        refundAccountNo: '',
        registerDate: this.$moment().startOf('day')
      },
      registerConfirmed: false,
      rules: {
        mchNo: [{required: true, message: '请选择电商', trigger: 'change'}],
        shopNo: [{required: true, message: '请选择店铺', trigger: 'change'}],

      },
      mchNolist: [],
      shoplist: [],
      columns: [
        {
          title: "No",
          customRender: (text, record, index) => `${Number(index + 1)}`,
          align: "center",
          width: 60,
        },
        {
          width: 60,
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          scopedSlots: {customRender: 'action'},
        },
        {
          title: '到款日期',
          align: 'center',
          dataIndex: 'arrivalDate',
          width: 110,
          sorter: (a, b) => {
            return a.arrivalDate.localeCompare(b.arrivalDate)
          },
          filters: [
            {text: "当天", value: 1},
            {text: "3天内", value: 3},
            {text: "7天内", value: 7},
            {text: "30天内", value: 30},
          ],
          filterMultiple: false,
          onFilter: (value, record) => {
            return this.$moment(record.arrivalDate).isAfter(this.$moment().startOf('day').subtract(value, 'days'))
          }
        },
        {
          title: '到款金额',
          dataIndex: 'totalAmount',
          align: 'center',
          width: 110,
          sorter: (a, b) => Number(a.totalAmount ?? 0) - Number(b.totalAmount ?? 0),
          ellipsis: true,
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },
        // {
        //   title: '开票日期',
        //   align: 'center',
        //   dataIndex: 'invoiceDate',
        //   width: 110,
        //   sorter: (a, b) => a < b ? 1 : -1,
        // },
        {
          title: '发票信息',
          dataIndex: 'invoiceNumber',
          align: 'center',
          width: 200,
          ellipsis: true,
          filters: mchshopinvoiceDict.transItemStatus.map(
              (item) => {
                return {
                  text: <a-tag color={item.color}>{item.text}</a-tag>,
                  value: item.value
                }
              }
          ),
          onFilter: (value, record) => {
            return value === record.status
          },
          customRender:(text, record)=> {
            const defaultStatus = {text: '未知状态', color: 'grey'};
            const {
              text: statusText,
              color: statusColor
            } = mchshopinvoiceDict.transItemStatusMap[record.status] || defaultStatus;

            if(record.status === 2) {
              return <a-tag color={statusColor}>{statusText}</a-tag>
            } else {
              return <a-popover placement="top">
                {record.invoiceNumber} &nbsp;
                <a-tag color={statusColor}>{statusText}</a-tag>
                <template slot="content">
                  <p>发票代码：{record.invoiceCode}</p>
                  <p>发票号码：{record.invoiceNumber}</p>
                  <p>开票日期：{record.invoiceDate}</p>
                  <p>开票金额：<Balance dcflag="d" align-right={false} amount={record.invoiceTotalAmount}></Balance></p>
                  <p>付款方：{record.purchaserName}</p>
                  <p>备注：{record.remark}</p>
                </template>
              </a-popover>
            }
          },
        },
      ],
    };
  },

  methods: {

    async getMchList() {
      const res = await this.api.mchshopsApi({pageNo: 1, pageSize: 10000, mchType: 1, goodsTypes: ['50','51']});
      this.mchNolist = res.data.records.map(x => ({label: x.mchName, value: x.mchNo, fatherName: x.fatherName}));
      // 默认选中第一个，并刷新店铺列表

      if (this.mchNolist.length > 0 && !this.form.mchNo) {
        this.form.mchNo = this.mchNolist[0].value
        this.form.mchName = this.mchNolist[0].label
        await this.getShopList()
      } else {
        await this.getShopList()
      }

    },
    async getShopList() {
      this.shoplist = [];
      const res = await this.api.managerpageApi({
        pageNo: 1,
        pageSize: 10000,
        mchType: 1,
        departmentId: this.departmentId,
        mchNo: this.form.mchNo
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.shoplist = res.data.records;
      if (this.shoplist.length > 0 && !this.form.shopNo) {
        this.form.shopNo = this.shoplist[0].shopNo
        this.onShopChange(this.form.shopNo)
      } else {
        this.handleMchNoChange(this.form.mchNo)
        this.handleShopNoChange(this.form.shopNo)
      }
    },

    async mchShopInvoiceTransItemList() {
      const res = await this.api.mchShopInvoiceTransItemListApi({
        refundAccountNo: this.form.refundAccountNo,
        mchNo: this.form.mchNo,
        registerDate: this.form.registerDate.format('YYYY-MM-DD'),
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.allSuccessMatchItems = res.data.allSuccessMatchItems
      this.allFailMatchItems = res.data.allFailMatchItems
      this.allSuccessMatchAmount = res.data.allSuccessMatchAmount
    },
    async invoiceAutoMatchByTrans() {
      this.$confirm({
        title: "请确认",
        content: "立即清算?",
        onOk: async () => {
          const res = await this.api.invoiceAutoMatchByTransApi({
            refundAccountNo: this.form.refundAccountNo,
            mchNo: this.form.mchNo,
            registerDate: this.form.registerDate.format('YYYY-MM-DD'),
          });
          if (res.code !== 200) {
            this.$message.error(res.info);
          }else {
            this.$confirm({
              content: <a-descriptions title="清算结果" column={2} bordered size="small">
                <a-descriptions-item label="成功">{res.data?.allSuccessMatchNum ?? 0}笔</a-descriptions-item>
                <a-descriptions-item label="失败"> <span style="color:red">
                  {(res.data?.tranItemTotal ?? 0) - (res.data.allSuccessMatchNum ?? 0)}笔
                </span> </a-descriptions-item>
                <a-descriptions-item label="清算金额" span={2}><Balance dcflag="d" align-right={false} amount={res.data?.allSuccessMatchAmount}></Balance>
                </a-descriptions-item>
              </a-descriptions>
            })
          }
        }
      })
    },

    //删除上传文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);

      this.fileList = newFileList;
    },

    async beforeUpload(info) {
      this.fileList = [];
      this.fileList.push(info.file);
    },


    handleCancel() {
      this.registerModalVisible = false
    },


    async submitInvoice() {
      if (this.form.shopNo == null || this.form.shopNo == '') {
        this.$message.error('请选择店铺')
        return
      }

      if (this.fileList.length == 0) {
        this.$message.error('请选择文件')
        return
      }
      this.registerModalVisible = true
    },


    async importInvoiceReceiptOk() {

      const formData = new FormData();
      formData.append('file', this.fileList[0]);
      formData.append('shopNo', this.form.shopNo);
      formData.append('registerDate', this.form.registerDate.format('YYYY-MM-DD'));

      const res = await this.api.mchShopInvoiceDepositApi(formData);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.$msgbox({
        type: "info",
        customClass: 'msg-box-wide',
        message: <a-descriptions title="导入并覆盖结果" bordered>

          <a-descriptions-item label="成功" span="2">
            {res.data.importReslut.succeed}
          </a-descriptions-item>
          <a-descriptions-item label="金额合计" span="1">
            <Balance amount={res.data.importReslut.totalAmount}></Balance>
          </a-descriptions-item>
        </a-descriptions>,
        callback: () => {


        }
      })

      this.fileList = []
      this.registerModalVisible = false


      this.allSuccessMatchItems = res.data.matchReslut.allSuccessMatchItems
      this.allFailMatchItems = res.data.matchReslut.allFailMatchItems
      this.allSuccessMatchAmount = res.data.matchReslut.allSuccessMatchAmount
    },

    async close(record) {
      const res = await this.api.mchShopInvoiceMarktApi({
        ids: [record.id],
        mark: 0
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      await this.mchShopInvoiceTransItemList()
    },
    async checkAll() {
      if (this.selectedRowKeys?.length <= 0) {
        return this.$message.error("请选择至少一条记录")
      }
      return this.checkIds(this.selectedRowKeys)
    },
    async check(record) {
      return this.checkIds([record.id])
    },
    async checkIds(ids) {
      const res = await this.api.mchShopInvoiceMarktApi({
        ids,
        mark: 1
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      } else {
        if (res.data?.fail?.length === 1 && res.data?.success?.length === 0) {
          this.$message.error(res.data.fail[0])
        } else if (res.data?.fail?.length === 0 && res.data?.success?.length === 1) {
          this.$message.info('已标记为待清算')
        } else {
          this.$msgbox({
            type: "info",
            customClass: 'msg-box-wide',
            message: <a-descriptions title="结果" bordered>

              <a-descriptions-item label="成功" span="2">
                {res.data?.success?.length}
              </a-descriptions-item>
              <a-descriptions-item label="失败" span="1">
                {res.data?.fail?.length}
              </a-descriptions-item>
            </a-descriptions>,
          })
        }
      }
      this.selectedRows = []
      await this.mchShopInvoiceTransItemList()
    },
    onShopChange(newShopNo) {
      // 当选择器的值变化时，更新selectedShop
      const matchedShop = this.shoplist.find(item => item.shopNo === newShopNo);
      if (matchedShop) {
        this.selectedShop = matchedShop;
      } else {
        this.selectedShop = {}; // 清空或设置默认值
      }
      this.handleMchNoChange(this.form.mchNo)
      this.handleShopNoChange(newShopNo)
      this.mchShopInvoiceTransItemList()
      this.qryAccountBalance(matchedShop.channelNo,matchedShop.refundAccountNo)
      this.form.channelNo = matchedShop.channelNo
    },


    handleMchNoChange(value) {
      const selectedItem = this.mchNolist.find(item => item.value === value);
      if (selectedItem) {
        this.form.mchName = selectedItem.label
      }
    },

    handleShopNoChange(value) {
      const shop = this.shoplist.find(item => item.shopNo === value);
      if (shop) {
        this.form.shopName = shop.shopName
        this.form.refundAccountNo = shop.refundAccountNo
        this.selectedShop = shop
      }

    },

    cancelInvoice() {
      //返回上一页
      this.$router.push({
        path: '/Mch/MchShopInvoice',
        query: this.$route.query
      });
    },


    async qryAccountBalance(channelNo,refundAccountNo) {

      const res = await this.api.accountqueryBalancedApi({
        channel_no: channelNo,
        account_no: refundAccountNo
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.currentBalance = res.data.detail_list[0].cur_balance


    }
  },
  async created() {

    const queryParams = this.$route.query; // 获取查询参数

    this.form.mchNo = queryParams.mchNo
    this.form.shopNo = queryParams.shopNo

    await this.getMchList()
    await this.mchShopInvoiceTransItemList()


  },
  mounted() {
  },

  computed: {
    columnsUp() {
      return this.columns.slice(0, 2).concat(this.columns.slice(2));
    },
    columnsDown() {
      return this.columns.slice(1);
    },
    currSelectCount(){
      return (this.selectedRows ?? []).length
    },
    currSelectTotal(){
      return (this.selectedRows ?? []).reduce((acc, cur) => acc + (cur.totalAmount || 0), 0).toFixed(2);
    },
    selectedRowKeys(){
        return (this.selectedRows??[]).map(row=>row.id)
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onSelect: (record, selected, selectedRows, nativeEvent) => {
          this.selectedRows = selectedRows
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          this.selectedRows = selectedRows
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.status !== 0, // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
  }

};
</script>

<style scoped>


.top-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

a-input {
  width: 100%;
}

.invoice-uploader {
  width: 100px;
  height: 100px;

  .anticon-inbox /deep/ svg {
    font-size: 60px;
  }
}

.ant-form-horizontal {
  /deep/ .ant-form-item-label {
    width: 100px;
  }

  /deep/ .ant-form-item-control-wrapper {
    width: 300px;
    display: inline-block;
  }

  /deep/ .ant-collapse-item {

    /deep/ .ant-form-item-label {
      width: 140px;
    }

    /deep/ .ant-form-item-control-wrapper {
      width: 400px;
    }

    .ant-form-item {
      margin-bottom: 2px;
    }
  }

  /deep/ .ant-upload-select-picture-card {
    display: block;
    float: none;
    min-width: 300px;
    min-height: 200px;
    padding: 4px 0;
    object-fit: contain;
  }

  /deep/ .ant-collapse-content-box {
    background-color: #eaeaea;
  }
}

.ant-modal-root .ant-descriptions {
  span.pass {
    color: green;
  }

  span.fail {
    code {
      display: block;
      color: red;
    }

    span {
      border: 1px solid #eaeaea;
      color: gray;
    }
  }
}

div.credit, div.debit {
  display: inline-block;
}
</style>
