<template>
  <div style="overflow: hidden;" class="wrapper">
    <div class="header">
      <!-- 顶部信息 -->
      <a-page-header
          style="border: 1px solid rgb(235, 237, 240)"
          title="自助开户"
          :sub-title="currentType?.title"></a-page-header>
    </div>
    <!--  导航区域-->
    <div class="body">
      <a-steps :current="step" finish-status="success" direction="vertical" @change="onStepChange">
        <a-step title="服务确认" v-if="currentType.steps.indexOf('info')>=0"
                :disabled="currentType.steps.indexOf('info')>step">
          <template slot="description" v-if="currentType.steps.indexOf('info')===step">
            <a-descriptions bordered :column="1" size="small">
              <a-descriptions-item v-for="(k,v) in exData" :key="v" :label="v">{{ k }}</a-descriptions-item>
            </a-descriptions>
            <a-checkbox v-model="agreed" @click.stop="agreed=!agreed">
              <span>已阅读并同意</span>
              <span>
              <a v-for="(item,index) in currentType.contracts" :key="index"
                 :href="item.url"> 《{{ item.title }}》 </a>
              </span>
            </a-checkbox>
            <a-button type="primary" @click.stop="agreeCheck" block>同意并确认</a-button>
          </template>
        </a-step>
        <a-step title="证件照片" v-if="currentType.steps.indexOf('idCard')>=0"
                :disabled="currentType.steps.indexOf('idCard')>step">
          <template slot="description" v-if="currentType.steps.indexOf('idCard')===step">
            <a-alert type="info" message="请上传营业执照、法定代表人身份证正反面照片，四角完整，画面清晰"></a-alert>
            <a-upload
                list-type="picture-card"
                accept="image/*"
                capture="environment"
                :show-upload-list="false"
                :before-upload="file=>beforeUpload(file, 'businessLicense')"
            >
              <div class="id-placeholder id-face">
                <img v-if="files.businessLicense.preview" :src="files.businessLicense.preview"/>
                <img v-else src="../assets/img/id-social.webp"/>
              </div>
            </a-upload>
            <a-upload
                list-type="picture-card"
                accept="image/*"
                capture="environment"
                :show-upload-list="false"
                :before-upload="file=>beforeUpload(file, 'idCardFace')"
            >
              <div class="id-placeholder id-nes">
                <img v-if="files.idCardFace.preview" :src="files.idCardFace.preview"/>
                <img v-else src="../assets/img/id-face@2x.webp"/>
              </div>
            </a-upload>
            <a-upload
                list-type="picture-card"
                accept="image/*"
                capture="environment"
                :show-upload-list="false"
                :before-upload="file=>beforeUpload(file, 'idCardNes')"
            >
              <div class="id-placeholder id-nes">
                <img v-if="files.idCardNes.preview" :src="files.idCardNes.preview"/>
                <img v-else src="../assets/img/id-emblem@2x.webp"/>
              </div>
            </a-upload>
            <a-button type="primary" @click.stop="nextStep" block
                      v-if="files.idCardFace.file && files.idCardNes.file && files.businessLicense.file">
              下一步
            </a-button>
          </template>
        </a-step>
        <a-step title="对公结算账户" v-if="currentType.steps.indexOf('bankCardInfo') >= 0"
                :disabled="currentType.steps.indexOf('bankCardInfo') >step">
          <template slot="description" v-if="currentType.steps.indexOf('bankCardInfo') === step">
            <div class="form">
              <label>结算账号</label>
              <div>
                <a-input v-model.trim="reqData.primary_account" placeholder="请输入银行结算账号"></a-input>
              </div>
            </div>
            <div class="form">
              <label> 开户网点</label>
              <div>
                <a-select style="width: 100%;  font-size: 12px;"
                          placeholder="请输入开户网点名称"
                          show-search
                          v-model="reqData.bank_no"
                          :filter-option="false"
                          :show-arrow="bankSearchIndicating"
                          :not-found-content="bankSearchEmptyContent"
                          @search="handleBankSearch"
                          @change="handleBankChange"
                >
                  <a-icon slot="suffixIcon" type="loading" v-if="bankSearchIndicating"/>
                  <a-select-option v-for="bank in bankCodeList" :key="bank.bankNo" :value="bank.bankNo">
                    <span style="font-size: 12px">{{ bank.bankName }}</span>
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <a-button type="primary" @click.stop="bankCheck" block>下一步</a-button>
          </template>
        </a-step>

        <a-step title="手机验证" v-if="currentType.steps.indexOf('mobile')>=0"
                :disabled="currentType.steps.indexOf('mobile')>step">
          <template slot="description" v-if="currentType.steps.indexOf('mobile')===step">
            <div style="display: flex">
              <a-input v-model.trim="reqData.mobile" :max-length="11" addon-before="手机号"></a-input>
            </div>
            <a-button type="primary" @click="confirmInfo" block :disabled="reqData?.mobile?.length!==11">立即开户</a-button>
          </template>
        </a-step>
        <a-step title="完成" v-if="currentType.steps.indexOf('result')>=0"
                :disabled="currentType.steps.indexOf('result')>step">
          <template slot="description" v-if="currentType.steps.indexOf('result')===step">
            <a-result
                status="success"
                title="办理完成"
                :sub-title="resultMsg"
            ></a-result>
          </template>
        </a-step>
        <a-step title="进度查询" v-if="currentType.steps.indexOf('error')>=0"
                :disabled="currentType.steps.indexOf('error')>step">
          <template slot="description" v-if="currentType.steps.indexOf('error')===step">
            <a-result
                status="warning"
                title="出错啦"
                :sub-title="errorMsg || resultMsg"
            ></a-result>
          </template>
        </a-step>
      </a-steps>
    </div>
    <!-- loading -->
    <div class="loading" v-if="showLoading">
      <a-spin size="large"></a-spin>
    </div>
    <a-modal v-model="confirmVisible" :footer="false">
      <a-descriptions :column="1" bordered title="要素确认" size="small">
        <a-descriptions-item label="企业名称">{{ reqData.account_name }}</a-descriptions-item>
        <a-descriptions-item label="统一社会信用代码">{{ reqData.id_no }}</a-descriptions-item>
        <a-descriptions-item label="法定代表人">{{ reqData.company_id_name }}</a-descriptions-item>
        <a-descriptions-item label="法定代表人证件号">{{ reqData.company_id_no }}</a-descriptions-item>
        <a-descriptions-item label="结算账号">{{ reqData.primary_account }}</a-descriptions-item>
        <a-descriptions-item label="开户行">{{ reqData.bank_name }}</a-descriptions-item>
        <a-descriptions-item label="手机号">{{ reqData.mobile }}</a-descriptions-item>
      </a-descriptions>
      <div >
        <div style="display: flex">
          <a-input v-model="reqData.verify_code" :max-length="6" size="large" addon-before="验证码"></a-input>
          <a-button :disabled="sendCodeWait!==0" type="link" size="large"
                    @click.stop="sendCode">
            发送{{ sendCodeWait === 0 ? '' : '(' + sendCodeWait + ')' }}
          </a-button>
        </div>
        <div style="display: flex">
        <a-button type="primary" @click.stop="submit" block icon="check">确认无误，开户</a-button>
        <a-button type="link" @click.stop="confirmVisible=false" icon="arrow-left">返回修改</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import extraApi from "@/extra/extraApi";
import {dict_rawdata_cascade} from "@/utils/bizDict/rawdata";

// info: 业务确认
// businessLicense: 营业
// idCardFace: 身份证人脸
// idcardNes: 身份证人脸
// face: 人脸比对
// mobile: 联系方式
// bankCardInfo: 银行卡号
// contract: 相关协议
// result: 办理结果
// error: 异常opac
let agreement_payment = {
      title: "浙江稠州商业银行用户支付服务协议",
      agreed: false,
      url: "https://oss-lcfs.oss-cn-hangzhou.aliyuncs.com/PLAT/%E6%B5%99%E6%B1%9F%E7%A8%A0%E5%B7%9E%E5%95%86%E4%B8%9A%E9%93%B6%E8%A1%8C%E7%94%A8%E6%88%B7%E6%94%AF%E4%BB%98%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf",
    },
    agreement_privacy = {
      title: "隐私政策",
      agreed: false,
      url: "https://oss-lcfs.oss-cn-hangzhou.aliyuncs.com/PLAT/%E6%B5%99%E6%B1%9F%E7%A8%A0%E5%B7%9E%E5%95%86%E4%B8%9A%E9%93%B6%E8%A1%8C%E7%94%A8%E6%88%B7%E6%94%AF%E4%BB%98%E6%9C%8D%E5%8A%A1%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf",
    }
const taskDefine = {
  7: {
    title: "稠州自助开户-个人电子钱包",
    steps: ['info', 'idCard', 'face', 'bankCardInfo', 'mobile', 'result'],
    contracts: [agreement_payment, agreement_privacy],
    resultMsg: '开户成功'
  },
  8: {
    title: "稠州自助开户-企业电子钱包",
    steps: ['info','idCard',  'bankCardInfo', 'mobile', 'result'],
    contracts: [agreement_payment, agreement_privacy],
    resultMsg: '开户成功，请联系开户渠道人员获取账户信息'
  },
  99: {
    title: "未知业务",
    steps: ['error'],
    resultMsg: "业务异常，请联系渠道方"
  }
}
export default {
  name: 'App',
  data() {
    return {
      bankCodeList: [],
      isShowBankChoose: false,
      exData: {},

      title: "业务办理确认",
      currentType: taskDefine[99],//1 个人开户
      step: -1,

      agreed: false,
      files: {
        idCardFace: {
          file: undefined,
          preview: undefined,
        },
        idCardNes: {
          file: undefined,
          preview: undefined,
        },
        businessLicense: {
          file: undefined,
          preview: undefined,
        }
      },

      bankSearchHandler: undefined,
      bankSearchIndicating: false,
      bankSearchInput: undefined,
      bankSearchMinLen: 4,
      taskInfo: {
        taskNo: undefined,
        departmentId: undefined,
        channelNo: "",
        appNo: "",
      },
      sendCodeWait: 0,

      confirmVisible: false,
      showLoading: true,
      resultMsg: "",
      resultTaskNo: "",
      errorMsg: "",

      reqData: {
        // ------------ 企业基本信息
        custnum: "",
        legalCustnum: "",
        account_name: "",
        channel_no: "",
        app_no: "",
        id_type: "C",
        id_no: "",
        id_startdate: "",
        id_enddate: "",
        sign_name: "",
        id_address: "",
        distcd: "",
        zsopscope: "",
        industry: "",
        leader_id_type: undefined,
        operator_id_type: undefined,

        // ------------ 公户
        primary_account: undefined,
        bank_no: undefined,
        bank_name: undefined,

        // ------------  验证码

        mobile: "",
        verify_code: "",
        // ------------  法人=   受益人

        company_id_type: 1,  //法定代表人证件类型，企业户必输
        company_id_no: "", //法定代表人证件号，企业户必输
        company_id_name: "", //法定代表人证件类型，企业户必输
        company_opto: "", //法定代表人有效期止，企业户必输 yyyy-MM-dd
        controller_id_type: undefined,  //法定代表人有效期止


        bene_name: "",  //受益人姓名
        bene_sex: "",  //受益人性别0:男 1:女
        bene_nationality: "156",  //受益人国籍 默认中国
        bene_is_shareholider: 1, //受益人是否股东 1:是 2：否
        bene_ratio: 100,  //受益人持股比例（百分比）
        bene_addr: "", //受益人地址
        bene_id_type: 1,
        bene_id_no: "", //受益人证件号
        bene_opto: "", //受益人有效期止，企业户必输 yyyy-MM-dd

        file_list: [
          {
            file_no: "",
            file_type: "01",
            page: "1",
            channel_file_no: ""
          },
          {
            file_no: "",
            file_type: "02",
            page: "2",
            channel_file_no: ""
          },
          {
            file_no: "",
            file_type: "12",
            page: "3",
            channel_file_no: ""
          }
        ]
      }
    }
  },
  components: {
    // Loading,
  },
  mounted() {
    this.parseBusiParam()
    this.loadTask()
  },
  methods: {
    async parseBusiParam() {
      return new Promise((resolve) => {
        const params = new URLSearchParams(window.location.search);

        this.taskInfo.taskNo = params.get("t");

        console.log("loadTask1")
        resolve();
      });
    },
    async loadTask() {

      this.showLoading = true

      const res = await extraApi.get(`/custOpenAccTask/get?taskNo=${this.taskInfo.taskNo}`)
      console.log("res", res)
      this.showLoading = false
      if (res.code !== 200) {
        this.currentType = taskDefine[99]
        this.errorMsg = res.info
      } else {
        this.busiData = res.data
        this.taskInfo = this.busiData
        this.currentType = taskDefine[this.busiData?.taskType]

        console.log("loadTask", this.taskInfo, this.currentType)
        if (this.busiData.exData) {
          this.exData = JSON.parse(this.busiData.exData)
        }
      }
      this.step = 0
    },
    check() {
      this.nextStep()
    },
    alert(msg) {
      this.$error({title: "哎呀，出错了", content: msg})
    },
    async agreeCheck() {
      if (!this.agreed && window.confirm("是否同意各项协议？")) {
        this.agreed = true
      }
      if (!this.agreed) {
        this.$message.info("请先阅读并同意相关协议")
      } else {
        this.nextStep()
      }
    },
    beforeUpload(file, type) {
      if (type === "idCardFace") {
        this.uploadIdCardFace(file)
      } else if (type === "idCardNes") {
        this.uploadIdCardNes(file)
      } else if (type === "businessLicense") {
        this.uploadBusinessLicense(file)
      }
      // 阻止默认上传
      return false
    },
    async uploadIdCard(file) {
      this.showLoading = true
      const res = await extraApi.uploadFile(file, {
        taskNo: this.taskInfo.taskNo,
        channelNo: this.taskInfo.channelNo,
        depatId: this.taskInfo.departmentId,
        custNum: this.reqData.legalCustnum,
      }, '/custOpenAccTask/idcard/opaccRecognize')
      this.showLoading = false
      if (res.code !== 200) {
        this.alert(res.info);
        return undefined
      }
      return res.data
    },
    async uploadIdCardFace(file) {
      try {
        const {ocrRecognizeResult, file_no, channel_file_no, custnum} = await this.uploadIdCard(file)

        const extracted = {
          legalCustnum: custnum,
          company_id_type: 1,  //法定代表人证件类型，企业户必输
          company_id_no: ocrRecognizeResult.idcard, //法定代表人证件号，企业户必输
          company_id_name: ocrRecognizeResult.name, //法定代表人证件类型，企业户必输

          bene_name: ocrRecognizeResult.name,  //受益人姓名
          bene_sex: ocrRecognizeResult.sex === "男" ? "0" : "1",  //受益人性别0:男 1:女
          bene_nationality: "156",  //受益人国籍 默认中国
          bene_is_shareholider: 1, //受益人是否股东 1:是 2：否
          bene_ratio: 100,  //受益人持股比例（百分比）
          bene_addr: ocrRecognizeResult.address, //受益人地址
          bene_id_type: 1,
          bene_id_no: ocrRecognizeResult.idcard,//受益人证件号
        }

        this.reqData.file_list[0] = {file_no, channel_file_no, file_type: "01"}
        this.reqData = {...this.reqData, ...extracted}
        this.files.idCardFace.preview = URL.createObjectURL(file)
        this.files.idCardFace.file = file
      } catch (e) {
        console.error(e)
        return this.alert("身份证（人像面）识别失败，请重新上传")
      }
    },
    async uploadIdCardNes(file) {
      try {
        const {ocrRecognizeResult, file_no, channel_file_no} = await this.uploadIdCard(file)
        const validDates = ocrRecognizeResult.validDate.split("-")
        const extracted = {
          company_opto: validDates[1].replaceAll(".", "-"), //法定代表人有效期止，企业户必输 yyyy-MM-dd
          bene_opto: validDates[1].replaceAll(".", "-"),  //受益人有效期止，企业户必输 yyyy-MM-dd
        }
        this.reqData.file_list[1] = {file_no, channel_file_no, file_type: "02"}
        this.reqData = {...this.reqData, ...extracted}
        this.files.idCardNes.preview = URL.createObjectURL(file);
        this.files.idCardNes.file = file
      } catch (e) {
        console.error(e)
        return this.alert("身份证（国徽面）识别失败，请重新上传")
      }
    },
    async uploadBusinessLicense(file) {
      try {
        this.showLoading = true
        const res = await extraApi.uploadFile(file, {
          taskNo: this.taskInfo.taskNo,
          channelNo: this.taskInfo.channelNo,
          depatId: this.taskInfo.departmentId,
        }, '/custOpenAccTask/recognizeBusinessLicense', 2)
        this.showLoading = false
        if (res.code !== 200) {
          this.alert(res.info);
          return;
        }
        const {ocrCompanyResult, appNo, channelNo, file_no, channel_file_no, custnum} = res.data;
        const extracted = {
          channel_no: channelNo,
          app_no: appNo,
          custnum: custnum,
          account_name: ocrCompanyResult.companyName
              .replaceAll(/\(/g, "（").replaceAll(/\)/g, "）"),
          zsopscope: ocrCompanyResult.businessScope.substring(0, 200),
          id_no: ocrCompanyResult.creditCode,
          id_startdate: this.convertDateFormat(ocrCompanyResult.validFromDate),
          id_enddate: this.convertDateFormat(ocrCompanyResult.validToDate),
          distcd: this.getRegionCode(ocrCompanyResult.businessAddress),
          id_address: ocrCompanyResult.businessAddress,
          id_type: "C",
          sign_name: "国家市场监督管理总局",
          industry: "F5219",  //行业类别，2H:批发和零售业
        }
        this.reqData.file_list[2] = {file_no, channel_file_no, file_type: "12"}
        this.reqData = {...this.reqData, ...extracted}
        this.files.businessLicense.preview = URL.createObjectURL(file);
        this.files.businessLicense.file = file
      } catch (e) {
        console.error(e)
        return this.alert("营业执照识别失败，请重新上传")
      }
      this.showLoading = false
    },

    /**
     * 获取区域编号
     * @param {String} address - 输入的完整地址
     * @returns {String} - 匹配的区域编号（如果未找到，返回 '未知编号'）
     */
    getRegionCode: function (address) {
      if (!address || typeof address !== 'string') {
        console.warn("Invalid address:", address);
        return ''; // 非法输入时返回默认值
      }
      console.log("getRegionCode address ", address)
      // 匹配地址中的省、市、区或县信息
      let match = dict_rawdata_cascade.region_raw.find(item => {
        return (
            address.includes(item.p) && // 匹配省
            address.includes(item.c) && // 匹配市
            address.includes(item.d)    // 匹配区/县
        );
      });


      if (!match) {
        match = dict_rawdata_cascade.region_raw.find(item => {
          return (
              address.includes(item.p) && // 匹配省
              address.includes(item.c)   // 匹配市
          );
        });
      }
      if (!match) {
        match = dict_rawdata_cascade.region_raw.find(item => {
          return (
              address.includes(item.p)   // 匹配省
          );
        });
      }
      if (!match) {
        match = dict_rawdata_cascade.region_raw.find(item => {
          return (
              address.includes(item.c)   // 匹配市
          );
        });
      }
      console.log("getRegionCode match ", match)

      return match ? match.r.toString() : ''; // 确保返回值为字符串类型
    },


    /**
     * 转换日期格式
     * @param {String} dateStr - 原始日期字符串（格式：YYYYMMDD）
     * @returns {String} - 转换后的日期字符串（格式：YYYY-MM-DD）
     */
    convertDateFormat: function (dateStr) {
      // 检查日期格式是否合法
      if (!dateStr || dateStr.length !== 8 || isNaN(dateStr)) {
        console.warn("Invalid date string:", dateStr);
        return dateStr; // 返回原始值
      }

      const year = dateStr.slice(0, 4);
      const month = dateStr.slice(4, 6);
      const day = dateStr.slice(6, 8);

      return `${year}-${month}-${day}`; // 返回转换后的日期格式
    },


    sendCode: async function () {
      //判断是否填写手机号
      if (this.reqData.mobile.length !== 11) {
        this.$error({title: "手机号填写不正确", content: "请填写正确的手机号"})
        return
      }
      console.log("sendCode taskinfo ", this.taskInfo)
      let params = {
        "taskNo": this.taskInfo.taskNo,
        "channelNo": this.reqData.channel_no,
        "mobile": this.reqData.mobile,
        "cardNo": this.reqData.primary_account,
        "accountName": this.reqData.account_name,
        "idNo": this.reqData.id_no,
        "tradeType": 0
      }

      this.showLoading = true;
      const res = await extraApi.get(`/custOpenAccTask/sendOpenAccMsg`, params, {isFormData: true});
      this.showLoading = false

      if (res.code !== 200) {
        this.alert(res.info);
        return;
      } else {
        this.$success({title: "发送成功", content: "验证码已发送至您的手机，请查收"})
        this.sendCodeWait = 60
        let intHandler = window.setInterval(() => {
          if (this.sendCodeWait === 0) {
            window.clearInterval(intHandler)
            return
          }
          this.sendCodeWait--
        }, 1000)
      }
    },
    confirmInfo() {
      this.confirmVisible = true
    },
    handleBankSearch(input) {
      this.bankSearchInput = input
      if (this.bankSearchInput?.trim()?.length < this.bankSearchMinLen) return

      if (this.bankSearchHandler) {
        clearTimeout(this.bankSearchHandler);
        this.bankSearchHandler = null;
      }
      this.bankSearchHandler = setTimeout(async () => {
        this.bankSearchIndicating = true
        this.bankCodeList = []
        let bankNameSeg = this.bankSearchInput.trim().replace(/(.*银行)(.*股份有限公司|有限责任公司)?(.+)/,"+$1 $3")
        let params = {
          "taskNo": this.taskInfo.taskNo,
          "bankName": bankNameSeg
        }
        const res = await extraApi.post(`/custOpenAccTask/getBankInfo`, params, {isFormData: true});
        console.log(" searchBank res", res)
        if (res.code !== 200) {
          // this.$error({title: "暂无对应行号"})
        } else {
          this.bankCodeList = res.data
        }
        this.bankSearchIndicating = false
      }, 800)
    },

    // 处理银行选择
    handleBankChange(bankNo) {
      const selectedBank = this.bankCodeList.find(bank => bank.bankNo === bankNo);
      if (selectedBank) {
        this.reqData.bank_name = selectedBank.bankName;
        this.reqData.bank_no = selectedBank.bankNo;
      }
      console.info(this.reqData.bank_name, this.reqData.bank_no)
    },
    bankCheck() {
      if (!this.reqData.bank_name || !this.reqData.bank_no || !this.reqData.primary_account) {
        this.$error({
          title: "银行信息填写不正确",
          content: "请选择银行，填写银行卡号，并确认银行卡号是否正确",
        });
        return; // 停止后续操作
      }
      this.nextStep()
    },
    submit: async function () {
      //判断是否填写验证码
      if (this.reqData.verify_code.length !== 6) {
        this.$error({title: "验证码格式填写不正确", content: "请填写正确的验证码"})
        return
      }
      this.confirmVisible = false
      this.showLoading = true;
      const res = await extraApi.post(`/custOpenAccTask/open`, {
        taskNo: this.taskInfo.taskNo,
        verify_code: this.reqData.verify_code,
        openAccCompanyReq: this.reqData
      });
      this.showLoading = false
      if (res.code !== 200) {
        this.alert(res.info);
        return;
      }
      this.nextStep()
    },
    nextStep() {
      this.step++
    },
    onStepChange(step) {
      if (typeof step === "number")
        this.step = step
    }
  },
  computed: {
    bankSearchEmptyContent() {
      if (this.bankSearchIndicating) return '搜索中...'
      if (this.bankSearchInput?.trim()?.length >= this.bankSearchMinLen) return "若检索不到，可选择同城其他网点"
      return `请输入至少前${this.bankSearchMinLen}个汉字`
    }
  }
}
</script>
<style lang="less">

</style>



