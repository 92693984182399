var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"login"}},[_c('div',{staticClass:"box_content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.pageTitle))]),_c('a-form',{attrs:{"form":_vm.form,"layout":"horizontal","colon":false,"labelAlign":"left","hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          { rules: [{ required: true, message: '请输入用户名' }] },
        ]),expression:"[\n          'username',\n          { rules: [{ required: true, message: '请输入用户名' }] },\n        ]"}],attrs:{"placeholder":"请输入用户名","autocomplete":"username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: true, message: '请输入密码' }] },
        ]),expression:"[\n          'password',\n          { rules: [{ required: true, message: '请输入密码' }] },\n        ]"}],attrs:{"type":"password","placeholder":"请输入密码","autocomplete":"current-password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"100%"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 登录 ")])],1)],1)],1),_vm._m(0),_c('a-modal',{staticClass:"addition-auth",attrs:{"title":"验证安全手机 ","centered":true,"width":"480","closable":false,"footer":null},model:{value:(_vm.codeshowvisible),callback:function ($$v) {_vm.codeshowvisible=$$v},expression:"codeshowvisible"}},[_c('a-alert',{attrs:{"message":'您的账号开启了手机验证，请在获取验证码之后，输入您' + _vm.mobile + '手机上接收到的验证码。',"type":"info","show-icon":""}}),_c('p'),_c('p'),_c('p'),_c('a-form-modal',[_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"请输入验证码","max-length":6},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.checkCode.apply(null, arguments)},"change":_vm.codeChange},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}},[_c('CountdownBtn',{attrs:{"slot":"suffix","wait-time":60,"error-wait-time":10,"sendCall":_vm.sendCode,"send-text":"发送验证码"},slot:"suffix"})],1)],1),_c('a-button',{staticStyle:{"margin-top":"1px"},attrs:{"disabled":!_vm.code?.match(/\d{4,6}/),"type":"primary","block":""},on:{"click":_vm.checkCode}},[_vm._v("提交验证")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"fixed","text-align":"center","bottom":"0","margin":"0 auto","width":"100%","color":"#5c6b77"}},[_c('a',{staticStyle:{"margin":"0 10px"},attrs:{"target":"_blank","href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802013234"}},[_c('img',{attrs:{"src":"https://www.beian.gov.cn/img/ghs.png"}}),_vm._v("浙公网安备 33010802013234号")]),_c('a',{attrs:{"target":"_blank","href":"https://beian.miit.gov.cn/"}},[_vm._v("浙ICP备2023021090号-1")])])
}]

export { render, staticRenderFns }